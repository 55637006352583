import {Controller} from "react-hook-form";
import {FieldProps} from "./FieldProps";
import React, {KeyboardEvent, useCallback, useEffect, useRef} from "react";
import {Autocomplete, CircularProgress, TextField} from "@mui/material";

interface AutocompleteFieldProps extends FieldProps<any> {
    compareWith?(option: any, value: any): boolean;

    getOptionLabel(option: any): string;

    getOptions(filtro: string): Promise<Array<any>>;
}

export default function AutocompleteFormField(props: AutocompleteFieldProps) {
    const {compareWith, getOptions, getOptionLabel} = props;
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<readonly any[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const optionsTimeout = useRef<any>();

    function actualOnChange(onChange: (...event: any[]) => void) {
        return (event: any, value: any) => {
            onChange(value);
            if (props.onChange) {
                props.onChange(value);
            }
        };
    }

    const filtra = useCallback((event: KeyboardEvent) => {
        clearTimeout(optionsTimeout.current);
        setOptions([]);
        setLoading(true);
        optionsTimeout.current = setTimeout(async () => {
            getOptions((event.target as HTMLInputElement).value)
                .then(setOptions)
                .finally(() => setLoading(false));
        }, 500);
    }, [optionsTimeout, getOptions]);

    useEffect(() => {
        getOptions('').then(setOptions);
    }, [getOptions, setOptions]);

    return (
        <>
            <Controller
                name={props.name}
                control={props.control}
                defaultValue={props.value}
                render={({field: {onChange, value, ...restField}, fieldState: {error}}) => (
                    <Autocomplete
                        id={props.name + "_input"}
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        onKeyUp={filtra}
                        isOptionEqualToValue={compareWith}
                        getOptionLabel={getOptionLabel}
                        onChange={actualOnChange(onChange)}
                        options={options}
                        loading={loading}
                        loadingText="Procurando..."
                        noOptionsText="Nenhum registro encontrado."
                        {...restField}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={props.label}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                )}
                rules={{validate: props.validate}}
            />
        </>
    );
}
