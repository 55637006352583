import buildTabRoute from "../route/buildTabRoute";
import React from "react";

export interface CrudRouteConfig {
    basePath: string;
    labelConsulta: string;
    labelCadastro?: string;
    labelEdicao: string;
    consultaElement: React.ReactNode;
    cadastroElement?: React.ReactNode;
    edicaoElement?: React.ReactNode;
}

export default function buildCrudRoute(config: CrudRouteConfig) {
    return buildTabRoute({
        path: config.basePath,
        title: config.labelConsulta,
        tabs: [
            {
                label: config.labelCadastro || "+ Novo",
                path: `/${config.basePath}/cadastro`
            }
        ],
        subroutes: [
            {
                path: "",
                element: config.consultaElement,
            },
            {
                path: 'cadastro',
                title: config.labelCadastro || " + Novo",
                element: config.cadastroElement || config.edicaoElement
            },
            {path: 'edicao/:id', title: config.labelEdicao || "Edição", element: config.edicaoElement},
        ]
    });
}
