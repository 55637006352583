import useDialogRef from "./useDialogRef";
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";


export default function DialgoConfirmacao(props: { mensagem: React.ReactNode, titulo?: string, okLabel?: string, cancelLabel?: string }) {
    const {mensagem, titulo, okLabel, cancelLabel} = props;
    const {dialogRef} = useDialogRef<boolean>();

    return (
        <>
            <DialogTitle>{titulo || "Atenção!"}</DialogTitle>
            <DialogContent>
                {mensagem}
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={() => dialogRef.close(false)}>
                    {cancelLabel || "Não"}
                </Button>
                <Button variant="contained" type="button" onClick={() => dialogRef.close(true)}>
                    {okLabel || "Sim"}
                </Button>
            </DialogActions>
        </>
    );
}
