import {useCallback, useRef, useState} from "react";
import {ClienteDTO, PendenciaApi, PendenciaDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import useConsulta from "../../../main-class/form/crud/useConsulta";
import {useNavigate, useParams} from "react-router-dom";
import useArquivo from "../../../main-class/componentes/arquivo/useArquivo";

export interface FiltroPendencia {
    codigo?: string;
    cliente?: ClienteDTO;
    status?: PendenciaDTO.StatusEnum;
    tipo?: PendenciaDTO.TipoEnum;
}

export default function useConsultaHistoricoPendencias() {

    const pendenciaApi = useRef(new PendenciaApi(ApiConfiguration));
    const navigate = useNavigate();

    const {download} = useArquivo();

    const status = Object.values(PendenciaDTO.StatusEnum);
    const tipos = Object.values(PendenciaDTO.TipoEnum);

    const {cliente} = useParams();
    const {pendencia} = useParams();

    const [termo, setTermo] = useState("")

    const {
        filtro,
        pagina,
        consulta,
        formControl,
        submitForm,
    } = useConsulta<FiltroPendencia, PendenciaDTO>({
        consultaAction: (filtro, pagina, tamanhoPagina) => pendenciaApi.current.consultaHistoricoPendencias(
            filtro.codigo,
            filtro.tipo,
            filtro.status,
            cliente as any || filtro.cliente?.id,
            null,
            pagina,
            tamanhoPagina
        ),
    });

    const exporHistoricoCliente = useCallback(async () => {
        await pendenciaApi.current.exportaHistoricoCliente(cliente as any).then(arquivo => {
            download(arquivo);
        })
    }, [pendenciaApi, cliente, download])

    const onDetalha = useCallback((pendencia: PendenciaDTO) => {
        navigate(`/boigordo/pendencia/${pendencia.id}`);
        navigate(0)
    }, [navigate]);

    return {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        status,
        tipos,
        onDetalha,
        exporHistoricoCliente,
        hasCliente: !!cliente,
        isPendencia: !!pendencia,
        pendencia,
        termo,
        setTermo,
        cliente
    };


}
