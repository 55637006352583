import {FetchInterceptor, FetchInterceptorResponse} from "fetch-intercept";
import moment from "moment";

const regexDateTime = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}.+$/;
const regexDate = /^\d{4}-\d{2}-\d{2}$/;
const regexTime = /^\d{2}:\d{2}:\d{2}\.\d{3}.+$/;
const regexTimeNoTimeZone = /^\d{2}:\d{2}:\d{2}\.\d{3}$/;

function parseRequest(req: any) {
    if (req?.headers && req.headers['content-type'] === 'application/json') {
        return req.clone({
            body: contertDateToString(req.body),
        });
    }

    return req;
}

function parseResponse(response: FetchInterceptorResponse): FetchInterceptorResponse {
    if (response?.clone) {
        const clonedResponse = response.clone();
        const json = () => clonedResponse.json().then((data) => convertStringToDate(data));

        response.json = json;
    }
    return response;
}

/**
 * Realiza a conversão de strings de datas em objetos Date javascript de acordo com o pattern definido pela API
 */
function convertStringToDate(data: any): any {
    let match;

    if (typeof data === 'string') {
        match = data.match(regexDateTime);
        if (match) {
            return moment(match[0], 'YYYY-MM-DDTHH:mm:ss.SSSZZ').toDate();
        }

        match = data.match(regexDate);
        if (match) {
            return moment(match[0], 'YYYY-MM-DD').toDate();
        }

        match = data.match(regexTime);
        if (match) {
            return moment(`1970-01-01T${match[0]}`, 'YYYY-MM-DDTHH:mm:ss.SSSZZ').toDate();
        }

        match = data.match(regexTimeNoTimeZone);
        if (match) {
            return moment(`1970-01-01T${match[0]}`, 'YYYY-MM-DDTHH:mm:ss.SSS').toDate();
        }
    } else if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
            data[i] = convertStringToDate(data[i]);
        }
    } else if (typeof data === 'object' && !!data) {
        for (const key of Object.keys(data)) {
            data[key] = convertStringToDate(data[key]);
        }
    }

    return data;
}

function contertDateToString(data: any): any {
    if (data === null || data === undefined) {
        return data;
    } else if (data instanceof Date) {
        return moment(data).format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
    } else if (Array.isArray(data)) {
        const parsed = [];
        for (let i = 0; i < data.length; i++) {
            parsed[i] = contertDateToString(data[i]);
        }
        return parsed;
    } else if (typeof data === 'object' && !!data) {
        const parsed = {} as any;
        for (const key of Object.keys(data)) {
            parsed[key] = contertDateToString(data[key]);
        }
        return parsed;
    }

    return data;
}

const DateTypeInterceptor: FetchInterceptor = {
    request: (url: string, config: any): Promise<any[]> | any[] => {
        return [url, parseRequest(config)];
    },
    response: (response: FetchInterceptorResponse): FetchInterceptorResponse => {
        return parseResponse(response);
    }
}

export default DateTypeInterceptor;
