import React from "react";
import TableColumn, {parseTableColumn} from "./TableColumn";
import {Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from "@mui/material";

interface ArrayTableProps<T> {
    items?: Array<T>;
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
}

export default function ArrayTable<T extends { id?: any }>(props: ArrayTableProps<T>) {
    const {items, children, className, style} = props;

    const columns = children instanceof Array ? children
            .filter(col => col.type === TableColumn)
            .map(col => parseTableColumn(col.props)) :
        [parseTableColumn((children as React.ReactElement).props)];

    return <>
        <TableContainer>
            <Table className={className} style={style}>
                <TableHead>
                    <TableRow>
                        {
                            columns.map(col =>
                                <TableCell component="th"
                                           key={col.name + "_header"}
                                           className={(col.className || "") + " " + (col.header?.className || "")}
                                           style={{...(col.style || {}), ...(col.header?.style || {})}}>
                                    {col.header?.children}
                                </TableCell>
                            )
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        items?.map((item, idx) =>
                            <TableRow key={item.id + "_index_" + idx} >
                                {
                                    columns.map(col =>
                                        <TableCell component="td" key={col.name + "_cell_" + item.id}
                                                   className={(col.className || "") + " " + (col.cell?.className || "")}
                                                   style={{...(col.style || {}), ...(col.cell?.style || {})}}>
                                            {col.cell?.content && col.cell?.content(item)}
                                        </TableCell>)
                                }
                            </TableRow>
                        )
                    }
                    {
                        !items?.length &&
                        <TableRow>
                            <TableCell colSpan={columns.length} className="empty-state">
                                Nenhum registro encontrado.
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
                {
                    columns.find(col => !!col.footer) &&
                    <TableFooter>
                        <TableRow>
                            {
                                columns.map(col =>
                                    <TableCell component="th"
                                               key={col.name + "_footer"}
                                               className={(col.className || "") + " " + (col.footer?.className || "")}
                                               style={{...(col.style || {}), ...(col.footer?.style || {})}}>
                                        {col.footer?.children}
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    </TableFooter>
                }
            </Table>
        </TableContainer>
    </>;
}
