import {Button} from "@mui/material";
import React, {useCallback, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {lastValueFrom} from "rxjs";
import {PendenciaApi, PendenciaDTO} from "../../../../dao";
import useDialog from "../../../../main-class/template/dialog/useDialog";
import useLoader from "../../../../main-class/componentes/loader/useLoader";
import ApiConfiguration from "../../../../infra/api/ApiConfiguration";

export default function AguardandoContagem(props: { pendencia: PendenciaDTO, onUpdate: any }) {
    const pendenciaApi = useRef(new PendenciaApi(ApiConfiguration));
    const {pendencia, onUpdate} = props;
    const navigate = useNavigate();
    const {confirmacao} = useDialog();
    const {loadingAround} = useLoader();

    const volta = useCallback(() => {
        navigate("/boigordo");
    }, [navigate]);

    const contagemAprovada = useCallback(async () => {
        const confirmado = await lastValueFrom(confirmacao(<>
            Essa ação não pode ser desfeita.<br/><br/>
            Confirma essa ação?
        </>).afterClosed());
        if (confirmado) {
            await loadingAround(pendenciaApi.current.aprovaContagem(pendencia.id));
            onUpdate();
        }
    }, [confirmacao,onUpdate, pendencia, loadingAround, pendenciaApi]);

    const contagemRecusada = useCallback(async () => {
        const confirmado = await lastValueFrom(confirmacao(<>
            O cliente será notificado no seu dispositivo móvel.<br/><br/>
            Confirma essa ação?
        </>).afterClosed())
        if (confirmado) {
            await loadingAround(pendenciaApi.current.rejeitaContagem(pendencia.id));
            onUpdate();
        }
    }, [confirmacao, onUpdate, pendencia, loadingAround, pendenciaApi]);

    return (
        <>
            <div className="acoes">
                <Button onClick={() => contagemAprovada()} variant="contained"
                        color="secondary">Aprovar contagem</Button>
                <Button onClick={() => contagemRecusada()} variant="contained"
                        color="error">Recusar contagem</Button>
                <Button color="inherit" onClick={() => volta()}>Voltar</Button>
            </div>
        </>
    );
}
