import buildTabRoute from "../../main-class/template/route/buildTabRoute";
import ConsultaBoiGordo from "./boigordo/consulta/ConsultaBoiGordo";
import ConsultaHistoricoPendencias from "./historico/ConsultaHistoricoPendencias";
import GraficoPendencia from "./grafico/GraficoPendencia";
import DetalhePendencia from "./detalhe/DetalhePendencia";

export default function pendenciaRoute() {
    return buildTabRoute({
        path: "/boigordo",
        title: "Pendências",
        tabs: [{
            label: "Históricos",
            path: "historico"
        }],
        subroutes: [
            {
                path: "",
                element: <ConsultaBoiGordo/>,
            },
            {
                title: "Históricos",
                path: "historico",
                element: <ConsultaHistoricoPendencias/>
            },
            {
                path: "cliente/:cliente",
                title: "Histórico de Cliente",
                subroutes: [
                    {
                        path: "",
                        element: <ConsultaHistoricoPendencias/>,
                    },
                ]
            },
            {
                title: "Gráfico",
                path: "cliente/:cliente/grafico",
                element: <GraficoPendencia/>
            },
            {
                path: "pendencia/:pendencia",
                title: "Detalhe",
                element: <DetalhePendencia/>,
            },
        ]
    });
}
