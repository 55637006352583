import ArrayTable from "../../../../main-class/componentes/table/ArrayTable";
import TableColumn from "../../../../main-class/componentes/table/TableColumn";
import ColumnHeader from "../../../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../../../main-class/componentes/table/ColumnCell";
import {PendenciaApi, PendenciaDTO} from "../../../../dao";
import React, {useRef} from "react";
import {useParams} from "react-router-dom";
import useConsulta from "../../../../main-class/form/crud/useConsulta";
import ApiConfiguration from "../../../../infra/api/ApiConfiguration";
import useFormat from "../../../../main-class/componentes/format/useFormat";
import useLabels from "../../../../i18n/useLabels";

export default function ConsultaHistoricoPendencia() {
    const pendenciaApi = useRef(new PendenciaApi(ApiConfiguration));
    const {pendencia} = useParams();

    const {formatDate} = useFormat();
    const {ptBR} = useLabels();

    const {
        pagina,
    } = useConsulta<any, PendenciaDTO>({
        consultaAction: (filtro, pagina, tamanhoPagina) => pendenciaApi.current.consultaHistoricoPendencia(
            pendencia as any,
            undefined,
            undefined,
            undefined,
            1,
            200
        ),
    });


    return (
        <>

            <ArrayTable items={pagina?.itens}>

                <TableColumn name="dataHora" className="text-center">
                    <ColumnHeader>Data/Hora</ColumnHeader>
                    <ColumnCell
                        content={(item: PendenciaDTO) => formatDate(item.dataHora, 'DD/MM/YYYY | HH:mm:ss ')}/>
                </TableColumn>

                <TableColumn name="tipo" className="text-center">
                    <ColumnHeader>Tipo</ColumnHeader>
                    <ColumnCell content={(item: PendenciaDTO) => ptBR.enum.tipo_pendencia[item.tipo]}/>
                </TableColumn>

                <TableColumn name="status" className="text-center">
                    <ColumnHeader>Status</ColumnHeader>
                    <ColumnCell content={(item: PendenciaDTO) => ptBR.enum.status_pendencia[item.status]}/>
                </TableColumn>

                <TableColumn name="responsavel">
                    <ColumnHeader>Responsável</ColumnHeader>
                    <ColumnCell content={(item: PendenciaDTO) => item.responsavel?.nome}/>
                </TableColumn>
            </ArrayTable>
        </>
    );
}
