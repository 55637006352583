import {Button, TextField} from "@mui/material";
import React, {useCallback, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {lastValueFrom} from "rxjs";
import {PendenciaApi, PendenciaDTO} from "../../../../dao";
import useDialog from "../../../../main-class/template/dialog/useDialog";
import useLoader from "../../../../main-class/componentes/loader/useLoader";
import ApiConfiguration from "../../../../infra/api/ApiConfiguration";
import MultipleArquivosFormField from "../../../../main-class/form/input/MultipleArquivosFormField";
import useLabels from "../../../../i18n/useLabels";

export default function AguardandoConclusao(props: { pendencia: PendenciaDTO, onUpdate: any }) {
    const pendenciaApi = useRef(new PendenciaApi(ApiConfiguration));
    const {pendencia, onUpdate} = props;
    const navigate = useNavigate();
    const {confirmacao} = useDialog();
    const {loadingAround} = useLoader();
    const {ptBR} = useLabels();

    const volta = useCallback(() => {
        navigate("/boigordo");
    }, [navigate]);

    const concluiNegociacao = useCallback(async () => {
        const confirmado = await lastValueFrom(confirmacao(<>
            Você está <strong>CONCLUINDO</strong> esse chamado<br/>
            Ele não será mais apresentado na lista de pendencias.<br/><br/>
            Confirma essa ação?
        </>).afterClosed())
        if (confirmado) {
            await loadingAround(pendenciaApi.current.concluiPendencia(pendencia.id));

            onUpdate();
        }
    }, [confirmacao, onUpdate, pendencia, loadingAround, pendenciaApi]);

    return (
        <>
            {
                !!pendencia.termo &&
                <>
                    <h2>Termo de negociação de boi gordo</h2>

                    <TextField
                        multiline
                        disabled
                        name="termo" key="termo"
                        value={pendencia.termo} label="Termo"
                    />
                </>
            }

            {
                (pendencia.motivoRecusaContagem || pendencia.motivoRecusaNegociacao) &&
                <>
                    <h2>Motivo de Recusa</h2>
                    {
                        pendencia.motivoRecusaContagem?.map(motivo => (<>
                            {
                                motivo === PendenciaDTO.MotivoRecusaContagemEnum.Outro ?
                                    pendencia.outroMotivoRecusaContagem :
                                    ptBR.enum.motivo_recusa_contagem[motivo]
                            }<br/>
                        </>))
                    }
                    {
                        pendencia.motivoRecusaNegociacao?.map(motivo => (<>
                            {
                                motivo === PendenciaDTO.MotivoRecusaNegociacaoEnum.Outro ?
                                    pendencia.outroMotivoRecusaNegociacao :
                                    ptBR.enum.motivo_recusa_negociacao[motivo]
                            }<br/>
                        </>))
                    }
                </>
            }
            {
                (pendencia.motivoCliente) &&
                <>
                    <h2>Motivo de Alteração pelo Cliente</h2>
                    {
                        pendencia.motivoCliente === PendenciaDTO.MotivoClienteEnum.Outro ?
                            pendencia.outroMotivoAlteracaoCliente :
                            ptBR.enum.motivo_alteracao_cliente[pendencia.motivoCliente]
                    }
                    {
                        pendencia?.motivoCliente && pendencia?.anexosMotivoCliente &&
                        <MultipleArquivosFormField name="arquivo" value={pendencia?.anexosMotivoCliente}
                                                   disabled={true}/>
                    }
                </>
            }
            {
                (pendencia.motivoCancelamento) &&
                <>
                    <h2>Motivo de Cancelamento</h2>
                    {
                        pendencia.motivoCancelamento === PendenciaDTO.MotivoCancelamentoEnum.Outro ?
                            pendencia.outroMotivoCancelamento :
                            ptBR.enum.motivo_cancelamento[pendencia.motivoCancelamento]
                    }
                </>
            }

            <div className="acoes">
                <Button onClick={() => concluiNegociacao()} variant="contained"
                        color="secondary">Concluir</Button>
                <Button color="inherit" onClick={() => volta()}>Voltar</Button>
            </div>
        </>
    );
}
