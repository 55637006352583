import {useCallback, useEffect, useRef, useState} from "react";
import {DesembarqueApi, DesembarqueDTO, LoteApi} from "../../../dao";
import {useNavigate, useParams} from "react-router-dom";
import useLoader from "../../../main-class/componentes/loader/useLoader";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";

export default function useDetalheCliente() {
    const loteApi = useRef(new LoteApi(ApiConfiguration));
    const desembarqueApi = useRef(new DesembarqueApi(ApiConfiguration));
    const {cliente: id} = useParams();
    const {loadingAround} = useLoader();
    const [qtdeDesembarquesPendentes, setQtdeDesembarquesPendentes] = useState(0);
    const [qtdeDesembarquesIniciados, setQtdeDesembarquesIniciados] = useState(0);
    const [qtdeDesembarquesEncerrados, setQtdeDesembarquesEncerrados] = useState(0);
    const [qtdeLotes, setQtdeLotes] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        loteApi.current.consulta(undefined, undefined, undefined, undefined, Number(id), 1, 0)
            .then(pagina => setQtdeLotes(pagina.totalItens || 0));
        desembarqueApi.current.consulta(undefined, Number(id), undefined, undefined, DesembarqueDTO.StatusEnum.Pendente, undefined, 1, 0)
            .then(pagina => setQtdeDesembarquesPendentes(pagina.totalItens || 0));
        desembarqueApi.current.consulta(undefined, Number(id), undefined, undefined, DesembarqueDTO.StatusEnum.Iniciado, undefined, 1, 0)
            .then(pagina => setQtdeDesembarquesIniciados(pagina.totalItens || 0));
        desembarqueApi.current.consulta(undefined, Number(id), undefined, undefined, DesembarqueDTO.StatusEnum.Encerrado, undefined, 1, 0)
            .then(pagina => setQtdeDesembarquesEncerrados(pagina.totalItens || 0));
    }, [id, desembarqueApi, loteApi, loadingAround]);

    const onDesembarque = useCallback(() => {
        navigate(`/cliente/${id}/desembarque`);
    }, [navigate, id]);

    const onLote = useCallback(() => {
        navigate(`/cliente/${id}/lote`);
    }, [navigate, id]);

    return {
        onDesembarque, onLote, quantidades: {
            qtdeDesembarquesPendentes,
            qtdeDesembarquesIniciados,
            qtdeDesembarquesEncerrados,
            qtdeLotes
        }
    };
}
