import "./InputOtp.scss";
import useInputOtp from "./useInputOtp";

export default function InputOtp(props: { email: string, onOtpDigitado?: (otp: string) => any }) {
    const {onType} = useInputOtp();
    const {email, onOtpDigitado} = props;

    return (
        <>
            <div className="otp">
                <input onKeyUp={e => onType(e, email, onOtpDigitado, 0)} type="text" maxLength={1}/>
                <input onKeyUp={e => onType(e, email, onOtpDigitado, 1)} type="text" maxLength={1}/>
                <input onKeyUp={e => onType(e, email, onOtpDigitado, 2)} type="text" maxLength={1}/>
                <input onKeyUp={e => onType(e, email, onOtpDigitado, 3)} type="text" maxLength={1}/>
                <input onKeyUp={e => onType(e, email, onOtpDigitado, 4)} type="text" maxLength={1}/>
                <input onKeyUp={e => onType(e, email, onOtpDigitado, 5)} type="text" maxLength={1}/>
            </div>
        </>
    );
}
