import {useCallback, useRef} from "react";
import {DenunciaApi, DenunciaDTO, UsuarioAdministradorApi, UsuarioDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import useConsulta from "../../../main-class/form/crud/useConsulta";
import {lastValueFrom} from "rxjs";
import useDialog from "../../../main-class/template/dialog/useDialog";
import useLoader from "../../../main-class/componentes/loader/useLoader";
import {useSearchParams} from "react-router-dom";

export interface FiltroDenuncia {
    data?: Date;
    responsavel?: UsuarioDTO;
    status?: DenunciaDTO.StatusEnum;
    motivo?: DenunciaDTO.MotivoEnum;
}

export default function useConsultaDenuncia() {
    const denunciaApi = useRef(new DenunciaApi(ApiConfiguration));
    const usuarioApi = useRef(new UsuarioAdministradorApi(ApiConfiguration));
    const status = Object.values(DenunciaDTO.StatusEnum);
    const motivo = Object.values(DenunciaDTO.MotivoEnum);
    const {confirmacao} = useDialog();
    const {loadingAround} = useLoader();
    const [searchParams] = useSearchParams();

    const {
        filtro,
        pagina,
        submitForm: submitFormConsulta,
        formControl: formControlConsulta,
        consulta,
    } = useConsulta<FiltroDenuncia, DenunciaDTO>({
        filtroInicial: {
            status: searchParams.get('status') as DenunciaDTO.StatusEnum,
        },
        consultaAction: (filtro, pagina, tamanhoPagina) =>
            denunciaApi.current.consulta(
                filtro.data,
                filtro.responsavel?.id,
                filtro.status,
                filtro.motivo,
                pagina,
                tamanhoPagina
            ),
    });

    const filtraUsuarios = useCallback(async (filtro: string) => {
        const pagina = await usuarioApi.current.consulta(
            undefined,
            filtro,
            undefined,
            undefined,
            1,
            5
        );

        return pagina.itens || [];
    }, [usuarioApi]);

    const aprova = useCallback(async (denuncia: DenunciaDTO) => {
        const confirmado = await lastValueFrom(
            confirmacao(`A denúncia será aprovada e o comentário será excluído. Deseja continuar?`).afterClosed()
        );

        if (confirmado) {
            await loadingAround(denunciaApi.current.aprova(denuncia.id!));

            consulta();
        }
    }, [consulta, loadingAround, confirmacao]);

    const rejeita = useCallback(async (denuncia: DenunciaDTO) => {
        const confirmado = await lastValueFrom(
            confirmacao(`A denúncia será rejeitada e o comentário será liberado. Deseja continuar?`).afterClosed()
        );

        if (confirmado) {
            await loadingAround(denunciaApi.current.aprova(denuncia.id!));

            consulta();
        }
    }, [consulta, loadingAround, confirmacao]);

    return {
        pagina,
        consulta,
        filtro,
        formControlConsulta,
        submitFormConsulta,
        filtraUsuarios,
        aprova,
        rejeita,
        status,
        motivo,
    };
}
