import useLabels from "../../../i18n/useLabels";
import {Box, Card, MenuItem} from "@mui/material";
import {MoreVert, Search} from "@mui/icons-material";
import TextFormField from "../../../main-class/form/input/TextFormField";
import PaginatedTable from "../../../main-class/componentes/table/PaginatedTable";
import TableColumn from "../../../main-class/componentes/table/TableColumn";
import ColumnHeader from "../../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../../main-class/componentes/table/ColumnCell";
import {AnimalDTO, LoteDTO} from "../../../dao";
import useConsultaAnimal from "./useConsultaAnimal";
import SelectFormField from "../../../main-class/form/input/SelectFormField";
import React from "react";
import NumberFormField from "../../../main-class/form/input/NumberFormField";
import IconOptions from "../../../componentes/iconOptions/IconOptions";
import {useNavigate} from "react-router-dom";
import DateFormField from "../../../main-class/form/input/DateFormField";
import useFormat from "../../../main-class/componentes/format/useFormat";


export default function ConsultaAnimal(props: { etapa?: string }) {
    const {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        status,
        raca,
    } = useConsultaAnimal();

    const {etapa} = props;
    const {ptBR} = useLabels();
    const navigate = useNavigate();
    const {formatDate, formatCurrency, formatNumeric} = useFormat();

    return (
        <>

            <Card>
                <h2><Search/> Filtrar</h2>

                <form onSubmit={submitForm}>

                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{flex: '1'}}>

                            <TextFormField label="BOSCH" value={filtro.bosch}
                                           control={formControl} name="bosch"
                                           onChange={submitForm}
                            />
                        </Box>
                        <Box sx={{flex: '1'}}>
                            <TextFormField label="SISBOV" value={filtro.sisbov} control={formControl} name="sisbov"
                                           onChange={submitForm}
                            />
                        </Box>
                        <Box sx={{flex: '1'}}>
                            <SelectFormField name="raca" label="Raça"
                                             value={filtro.status} control={formControl}
                                             onChange={submitForm}>
                                <MenuItem value={null}/>
                                {
                                    raca.map(raca =>
                                        <MenuItem key={"MenuItem_Raca_" + raca} value={raca}>
                                            {raca.nome}
                                        </MenuItem>
                                    )
                                }
                            </SelectFormField>
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', gap: '20px'}}>

                        <Box sx={{flex: '1'}}>
                            <NumberFormField name="pesoMinimoBalancinha"
                                             value={filtro.pesoMinimoBalancinha}
                                             control={formControl}
                                             onChange={submitForm}
                                             key="pesoMinimoBalancinha"
                                             label="Peso mínimo balancinha"/>


                        </Box>
                        <Box sx={{flex: '1'}}>
                            <NumberFormField name="pesoMaximoBalancinha"
                                             value={filtro.pesoMaximoBalancinha}
                                             control={formControl}
                                             onChange={submitForm}
                                             key="pesoMaximoBalancinha"
                                             label="Peso máximo balancinha"/>

                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{flex: '1'}}>
                            <DateFormField label="Data" value={filtro.data} control={formControl}
                                           name="data"
                                           onChange={submitForm}
                            />
                        </Box>

                        <Box sx={{flex: '1'}}>

                            <SelectFormField name="status" label="Status"
                                             value={filtro.status} control={formControl}
                                             onChange={submitForm}>
                                <MenuItem value={null}/>
                                {
                                    status.map(sts =>
                                        <MenuItem key={"MenuItem_Status_" + sts} value={sts}>
                                            {ptBR.enum.status_animal[sts]}
                                        </MenuItem>
                                    )
                                }
                            </SelectFormField>

                        </Box>

                    </Box>

                </form>
            </Card>


            <Card>
                <PaginatedTable onPageChange={(pagina, tamanhoPagina) => consulta(filtro, {pagina, tamanhoPagina})}
                                pagina={pagina}>


                    {
                        etapa === LoteDTO.EtapaEnum.ProcessamentoEntrada &&
                        <TableColumn name="data" style={{textAlign: 'center'}}>
                            <ColumnHeader>Data</ColumnHeader>
                            <ColumnCell
                                content={(item: AnimalDTO) => formatDate(item.dataProcessamento as any, 'DD/MM/YYYY')}/>
                        </TableColumn>
                    }

                    <TableColumn name="bosch" style={{textAlign: 'center'}}>
                        <ColumnHeader>Bosch</ColumnHeader>
                        <ColumnCell content={(item: AnimalDTO) => item.bosch}/>
                    </TableColumn>

                    <TableColumn name="sisbov" style={{textAlign: 'center'}}>
                        <ColumnHeader>Sisbov</ColumnHeader>
                        <ColumnCell content={(item: AnimalDTO) => item.sisbov}/>
                    </TableColumn>

                    {
                        !etapa &&
                        <TableColumn name="raca">
                            <ColumnHeader>Raça</ColumnHeader>
                            <ColumnCell content={(item: AnimalDTO) => item.raca?.nome}/>
                        </TableColumn>
                    }

                    <TableColumn name="peso" style={{textAlign: 'right'}}>
                        <ColumnHeader>Peso</ColumnHeader>
                        <ColumnCell content={(item: AnimalDTO) => formatNumeric(item.pesoBalancinha) + 'Kg'}/>
                    </TableColumn>

                    {
                        !etapa &&
                        <TableColumn name="diasCosho" style={{textAlign: 'right'}}>
                            <ColumnHeader>Dias de cocho</ColumnHeader>
                            <ColumnCell content={(item: AnimalDTO) => item.diasDeCocho}/>
                        </TableColumn>
                    }

                    {
                        !etapa &&
                        <TableColumn name="protocolo" style={{textAlign: 'center'}}>
                            <ColumnHeader>Protocolo de entrada (Número)</ColumnHeader>
                            <ColumnCell content={(item: AnimalDTO) => item.protocoloEntrada}/>
                        </TableColumn>

                    }

                    {
                        !etapa &&
                        <TableColumn name="status">
                            <ColumnHeader>Status</ColumnHeader>
                            <ColumnCell content={(item: AnimalDTO) => ptBR.enum.status_animal[item.status]}/>
                        </TableColumn>
                    }

                    {
                        etapa === LoteDTO.EtapaEnum.ProcessamentoEntrada &&
                        <TableColumn name="valorDiaria" style={{textAlign: 'center'}}>
                            <ColumnHeader>Valor da diária</ColumnHeader>
                            <ColumnCell content={(item: AnimalDTO) => formatCurrency(item.valorDiaria)}/>
                        </TableColumn>
                    }

                    {
                        etapa === LoteDTO.EtapaEnum.ProcessamentoEntrada &&
                        <TableColumn name="diasLimite" style={{textAlign: 'center'}}>
                            <ColumnHeader>Dias limites</ColumnHeader>
                            <ColumnCell content={(item: AnimalDTO) => item.diasLimite}/>
                        </TableColumn>
                    }

                    <TableColumn name="opcoes" style={{textAlign: 'right'}}>
                        <ColumnHeader/>
                        <ColumnCell content={
                            (item: AnimalDTO) => <>
                                {
                                    item.status === AnimalDTO.StatusEnum.Inativo &&
                                    <IconOptions icon={<MoreVert/>}>
                                        <MenuItem onClick={() => {
                                            navigate(`/lote/${item.lote.id}/animal/${item.id}/laudo-morte`)
                                        }}>Laudo de óbito
                                        </MenuItem>
                                    </IconOptions>
                                }
                            </>
                        }/>
                    </TableColumn>
                </PaginatedTable>
            </Card>
        </>


    );
}

