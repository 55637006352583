import {Box, Button, MenuItem} from "@mui/material";
import {OcorrenciaLaudoEntradaDTO} from "../../../../dao";
import MultipleArquivosFormField from "../../../../main-class/form/input/MultipleArquivosFormField";
import {Add} from "@mui/icons-material";
import React from "react";
import {useForm, useWatch} from "react-hook-form";
import SelectFormField from "../../../../main-class/form/input/SelectFormField";
import useLabels from "../../../../i18n/useLabels";
import NumberFormField from "../../../../main-class/form/input/NumberFormField";
import useValidation from "../../../../main-class/form/validation/useValidation";

export interface FormAddOcorrenciasProps {
    opcoes: Array<OcorrenciaLaudoEntradaDTO.OcorrenciaEnum>;
    onOcorrenciaAdd: (ocorrencia: OcorrenciaLaudoEntradaDTO) => any;
}

export default function FormAddOcorrencias(props: FormAddOcorrenciasProps) {
    const {control, getValues, handleSubmit, reset} = useForm<OcorrenciaLaudoEntradaDTO>();
    const {onOcorrenciaAdd} = props;
    const ocorrencia = getValues();
    const {ptBR} = useLabels();
    const {validate, required} = useValidation();

    const actualHandleSubmit = handleSubmit(entity => {
        onOcorrenciaAdd(entity);
        reset({});
    });

    return (
        <fieldset style={{border: '1px solid #dedede', marginBottom: '20px', borderRadius: '10px', padding: '10px'}}>
            <Box sx={{display: 'flex', gap: '20px'}}>

                <Box sx={{flex: 1}}>
                    <SelectFormField name="ocorrencia" label="Ocorrências"
                                     control={control as any}
                                     value={ocorrencia.ocorrencia}
                                     validate={validate(
                                         required()
                                     )}
                    >
                        {props.opcoes.map(sts => <MenuItem key={"MenuItem_TipoOcorrencia_" + sts} value={sts}>
                                {ptBR.enum.tipo_ocorrencia[sts]}
                            </MenuItem>
                        )}

                    </SelectFormField>
                </Box>
                <Box sx={{flex: 1}}>
                    <QuantidadeOcorrencia quantidade={ocorrencia.quantidade} control={control}/>
                </Box>

            </Box>


            <Box sx={{flex: 1}}>
                <MultipleArquivosFormField
                    name="midias" accept={[".mp4", ".png", ".jpeg", ".jpg"]}
                    control={control as any}
                    />
            </Box>


            <div className="acoes">
                <Button variant="contained" type="button"
                        onClick={() => actualHandleSubmit()}>
                    <Add sx={{marginRight: '10px'}}/>
                    Adicionar Ocorrência
                </Button>
            </div>
        </fieldset>
    );
}


function QuantidadeOcorrencia(props: { quantidade?: number, control: any }) {
    const {control, quantidade} = props;
    const {validate, required} = useValidation();
    const ocorrencia: OcorrenciaLaudoEntradaDTO.OcorrenciaEnum = useWatch({control, name: 'ocorrencia'})

    return (
        <>
            {ocorrencia !== OcorrenciaLaudoEntradaDTO.OcorrenciaEnum.Carrapatos &&
                <NumberFormField name="quantidade"
                                 value={quantidade}
                                 control={control}
                                 label="Quantidade"
                                 validate={
                                     validate(
                                         required()
                                     )
                                 }/>
            }
        </>
    )
}
