import {useCallback, useContext, useEffect, useRef, useState} from "react";
import { LoteApi, OcorrenciaLaudoEntradaDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import { useParams} from "react-router-dom";

import useLoader from "../../../main-class/componentes/loader/useLoader";
import SincronizacaoContext from "../../../infra/sincronizacao/SincronizacaoContext";



export default function useConsultaOcorrencia() {

    const loteApi = useRef(new LoteApi(ApiConfiguration));
    const {lote: id} = useParams();
    const {loadingAround} = useLoader();
    const [ocorrencias, setOcorrencias] = useState([] as OcorrenciaLaudoEntradaDTO[]);
    const sincornizacaoState = useContext(SincronizacaoContext);

    const loadOcorrencia = useCallback(() => {
        loadingAround(loteApi.current.consultaOcorrenciasLaudoEntrada(Number(id)))
            .then(setOcorrencias)
    }, [id, loteApi, loadingAround]);

    useEffect(() => {
        loadOcorrencia();
    }, [loadOcorrencia]);

    useEffect(() => {
        const subscription = sincornizacaoState.loteObservable
            .subscribe(() => loadOcorrencia())

        return () => subscription.unsubscribe();
    }, [sincornizacaoState,loadOcorrencia]);

    return {ocorrencias};


}
