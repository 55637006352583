import * as React from "react";
import {useContext} from "react";
import MenuContext from "./MenuContext";
import MenuOption from "./MenuOption";
import "./Menu.scss";

export default function Menu(props: { menuBottom?: React.ReactNode }) {
    const {menuBottom} = props;
    const menus = useContext(MenuContext);

    return (
        <div className="menu">
            {
                menus.map(menu => <MenuOption key={menu.key + "_menuOption"} menu={menu}/>)
            }

            <div className="spacer"></div>

            {menuBottom || ""}
        </div>
    );
}
