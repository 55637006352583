import useDialogRef from "./useDialogRef";
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";

export default function DialgoInformacao(props: { mensagem: React.ReactNode, titulo?: string, okLabel?: string }) {
    const {mensagem, titulo, okLabel} = props;
    const {dialogRef} = useDialogRef<any>();

    return (
        <>
            <DialogTitle>{titulo || "Atenção!"}</DialogTitle>
            <DialogContent>
                {mensagem}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" type="button" onClick={() => dialogRef.close(true)}>
                    {okLabel || "OK"}
                </Button>
            </DialogActions>
        </>
    );
}
