import {Box, Card} from "@mui/material";
import {Warning} from "@mui/icons-material";
import {PaginaTotalPendenciaDTO} from "../../../../dao";

export default function FaixaQuantidadeAnimais(props: { color: string, pendencias: PaginaTotalPendenciaDTO, label: string }) {
    const {color, pendencias, label} = props;

    return (
        <>
            <Card style={{marginBottom: '10px'}}>

                <Box sx={{display: 'flex', gap: '20px'}}>
                    <h2 style={{margin: 0}}><Warning style={{color: color}}/></h2>

                    <h2 style={{flex: '1 1 auto', margin: 0}}>{label}</h2>

                    <h2 style={{flex: 1, margin: 0}}>Total de Clientes: <span>{pendencias?.totalItens || 0}</span></h2>

                    <h2 style={{flex: 1, margin: 0}}>Total de Machos: <span>{pendencias?.totalMachos || 0}</span></h2>

                    <h2 style={{flex: 1, margin: 0}}>Total de Fêmeas: <span>{pendencias?.totalFemeas || 0}</span></h2>
                </Box>


            </Card>
        </>
    );

}
