import {AnimalApi, LaudoMorteDTO, UsuarioAdministradorApi, UsuarioAdministradorDTO} from "../../../dao";
import {useCallback, useEffect, useRef, useState} from "react";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import useConsulta from "../../../main-class/form/crud/useConsulta";

export interface FiltroLaudoMorte {
    codigo?: string;
    data?: Date;
    lote?: any
    sisbov?: string;
    bosch?: string;
    veterinario?: UsuarioAdministradorDTO
    local?: number;
    tipo?: number;
    status?: LaudoMorteDTO.StatusEnum;
}

export default function useConsultaLaudoMorte() {
    const animalApi = useRef(new AnimalApi(ApiConfiguration));
    const usuarioApi = useRef(new UsuarioAdministradorApi(ApiConfiguration));
    let {lote: idLote} = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const status = Object.values(LaudoMorteDTO.StatusEnum);
    const [tipos, setTipos] = useState([]);
    const [locais, setLocais] = useState([]);

    const {
        filtro,
        pagina,
        consulta,
        formControl,
        submitForm
    } = useConsulta<FiltroLaudoMorte, LaudoMorteDTO & { id: number }>({
        filtroInicial: {
          status: searchParams.get('status') as LaudoMorteDTO.StatusEnum,
        },
        consultaAction: (filtro, pagina, tamanhoPagina) => animalApi.current.consultaLaudoMorte(
            filtro.codigo,
            filtro.data,
            filtro.lote,
            filtro.sisbov,
            filtro.bosch,
            filtro.local,
            filtro.tipo,
            filtro.status,
            filtro.veterinario?.id,
            !!idLote ? Number(idLote) : undefined,
            undefined,
            pagina,
            tamanhoPagina
        ).then(laudos => ({...laudos, itens: laudos.itens?.map(ld => ({...ld, id: ld.animal.id}))})),
    });

    const navigateLaudo = useCallback((laudo: LaudoMorteDTO) => {
        navigate(`/lote/${laudo.animal.lote.id}/animal/${laudo.animal.id}/laudo-morte`);
    }, [navigate])


    const filtraVeterinarios = useCallback(async (filtro: string) => {

        const pagina = await usuarioApi.current.consultaVeterinarios(
            undefined,
            filtro,
            undefined,
            1,
            5
        );
        return pagina.itens || [];
    }, [usuarioApi]);

    useEffect(() => {
        animalApi.current.consultaLocaisMorte().then(response => {
            setLocais(response)
        })
    }, []);

    useEffect(() => {
        animalApi.current.consultaTiposMorte().then(response => {
            setTipos(response)
        })
    }, []);

    return {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        status,
        tipos,
        locais,
        navigateLaudo,
        filtraVeterinarios,
        inLoteContext: !!idLote
    };


}


