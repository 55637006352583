import ConsultaCliente from "../cliente/consulta/ConsultaCliente";
import DetalheCliente from "./detalhe/DetalheCliente";
import ConsultaDesembarque from "../desembarque/consulta/ConsultaDesembarque";
import ConsultaLote from "../lote/consulta/ConsultaLote";
import SumarioCliente from "./sumario/SumarioCliente";
import DocumentoDesembarque from "../desembarque/documento/DocumentoDesembarque";
import buildTabRoute from "../../main-class/template/route/buildTabRoute";

export default function clienteRoute() {
    return <>
        {
            buildTabRoute({
                path: "/cliente",
                title: "Clientes",
                subroutes: [
                    {
                        path: "",
                        element: <ConsultaCliente/>,
                    },
                    {
                        path: ":cliente",
                        title: "Detalhar",
                        element: <SumarioCliente/>,
                        subroutes: [
                            {
                                path: "",
                                element: <DetalheCliente/>,
                            },
                            {
                                path: "desembarque",
                                title: "Desembarque",
                                subroutes: [
                                    {
                                        path: "",
                                        element: <ConsultaDesembarque/>,
                                    },
                                    {
                                        path: ":desembarque",
                                        title: "Documentos",
                                        element: <DocumentoDesembarque/>,
                                    }
                                ]
                            },
                            {
                                path: "lote",
                                title: "Lotes",
                                element: <ConsultaLote/>,
                            },
                        ]
                    }
                ]
            })
        }
    </>
}
