import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {CargaApi, DadosUltimaCargaDTO} from "../../dao";
import ApiConfiguration from "../../infra/api/ApiConfiguration";
import SincronizacaoContext from "../../infra/sincronizacao/SincronizacaoContext";
import StatusEnum = DadosUltimaCargaDTO.StatusEnum;

const REFRESH_INTERVAL_ACTIVE = 500;
const REFRESH_INTERVAL_IDLE = 15000;

export default function useBotaoCarga() {
    const cargaApi = useRef(new CargaApi(ApiConfiguration));
    const interval = useRef<any>();
    const timeout = useRef<any>();
    const [status, setStatus] = useState<DadosUltimaCargaDTO>();
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const sincornizacaoState = useContext(SincronizacaoContext);

    const consultaCarga = useCallback(() => {
        cargaApi.current.consultaDadosUltimaCarga().then((newStatus) => {
            const shouldShowTooltip = status?.status === StatusEnum.Processando && newStatus?.status !== StatusEnum.Processando;

            if (shouldShowTooltip) {
                if (newStatus.qtdDesembarquesRegistrados) {
                    sincornizacaoState.notifyDesembarque();
                }

                if (newStatus.qtdLotesRegistrados) {
                    sincornizacaoState.notifyLote();
                }

                if (newStatus.qtdClientesRegistrados) {
                    sincornizacaoState.notifyCliente();
                }

                if (newStatus.qtdSaidasRegistradas) {
                    sincornizacaoState.notifySaida();
                }
            }

            setStatus(newStatus);
            setShowTooltip(shouldShowTooltip);
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                setShowTooltip(false);
            }, 5000);
        });
    }, [cargaApi, status, sincornizacaoState]);

    const processaCarga = useCallback(() => {
        cargaApi.current.exceutaProcessamentoCarga()
            .then(() => consultaCarga());
    }, [cargaApi, consultaCarga]);

    const processando = status?.status === DadosUltimaCargaDTO.StatusEnum.Processando;

    useEffect(() => {
        interval.current = setInterval(() => {
            consultaCarga();
        }, processando ? REFRESH_INTERVAL_ACTIVE : REFRESH_INTERVAL_IDLE);

        console.log(processando);

        return () => clearInterval(interval.current);
    }, [interval, consultaCarga, processando])

    return {processaCarga, status, showTooltip};
}
