import {Box, Card} from "@mui/material";
import React from "react";
import useSumarioLote from "./useSumarioLote";
import useFormat from "../../../main-class/componentes/format/useFormat";
import useLabels from "../../../i18n/useLabels";
import LoteContext from "../loteContext";
import { Outlet } from "react-router-dom";

export default function SumarioLote() {
    const {lote, reload} = useSumarioLote();
    const {formatDate} = useFormat();
    const {ptBR} = useLabels();

    return (
        <>
            <LoteContext.Provider value={{lote: lote || {}, reload}}>
                <Card>
                    <Box sx={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                        <Box sx={{flex: 2}}>
                            <h2 style={{margin: '0 0 10px'}}>{lote.codigo} | {lote.proprietario?.nome}</h2>
                            <p style={{margin: 0}}>{formatDate(lote.dataHora, 'DD/MM/YYYY')}</p>
                        </Box>
                        <Box sx={{flex: 1, textAlign: 'center'}}>
                            <p style={{margin: '0 0 5px'}}>Total</p>
                            <h2 style={{margin: '0 0 5px'}}>{lote.qtdAnimais}</h2>
                            <p style={{margin: 0}}>{ptBR.enum.sexo[lote.sexoAnimais]}</p>
                        </Box>
                        <Box sx={{flex: 1, textAlign: 'center'}}>
                            <h2 style={{margin: '0 0 10px'}}>Etapa</h2>
                            <h1 style={{margin: 0}}>{ptBR.enum.etapa_lote[lote.etapa]}</h1>
                        </Box>
                    </Box>
                </Card>

                <Outlet/>
            </LoteContext.Provider>
        </>
    );
}
