import buildTabRoute from "../../main-class/template/route/buildTabRoute";
import {Home} from "./Home";

export default function homeRoute() {
    return buildTabRoute({
        path: "",
        title: "Dashboard",
        element: <Home />,
    })
}
