import useFormat from "../../../main-class/componentes/format/useFormat";
import {Box, Button, Card, IconButton, MenuItem} from "@mui/material";
import {FileDownload, Search} from "@mui/icons-material";
import TextFormField from "../../../main-class/form/input/TextFormField";
import SelectFormField from "../../../main-class/form/input/SelectFormField";
import PaginatedTable from "../../../main-class/componentes/table/PaginatedTable";
import TableColumn from "../../../main-class/componentes/table/TableColumn";
import ColumnHeader from "../../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../../main-class/componentes/table/ColumnCell";
import {PendenciaDTO} from "../../../dao";
import React from "react";
import useLabels from "../../../i18n/useLabels";
import useConsultaHistoricoPendencias from "./useConsultaHistoricoPendencias";
import SumarioCLienteQtdAnimais from "../sumario/SumarioClienteQtdAnimais";
import SelectCliente from "../../../componentes/selectCliente/SelectClient";

export default function ConsultaHistoricoPendencias() {
    const {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        status,
        tipos,
        onDetalha,
        exporHistoricoCliente,
        hasCliente,
        isPendencia,
        pendencia,
        cliente

    } = useConsultaHistoricoPendencias();

    const {formatDate} = useFormat();
    const {ptBR} = useLabels();


    return (
        <>
            {!!cliente && <SumarioCLienteQtdAnimais cliente={cliente!}/>}

            <Card>

                <Box sx={{display: 'flex', textAlign: 'end'}}>
                    <Box sx={{}}>
                        <h2><Search/> Filtrar</h2>
                    </Box>
                    <Box sx={{flex: '1', margin: '20px 0 0'}}>
                        {
                            hasCliente && !isPendencia &&
                            <Button onClick={exporHistoricoCliente} variant="outlined" color="primary">
                                <FileDownload/>
                                Download
                            </Button>
                        }
                    </Box>

                </Box>

                <form onSubmit={submitForm}>

                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{flex: '1'}}>

                            <TextFormField label="Código" value={filtro.codigo} control={formControl} name="codigo"
                                           onChange={submitForm}
                            />
                            {
                                !hasCliente &&
                                <SelectCliente
                                    name="cliente" label="Cliente"
                                    value={filtro.cliente} control={formControl}
                                    onChange={submitForm}
                                />
                            }


                        </Box>
                        <Box sx={{flex: '1'}}>

                            <SelectFormField name="tipo" label="Tipo"
                                             value={filtro.tipo} control={formControl}
                                             onChange={submitForm}>
                                <MenuItem value={null}/>
                                {
                                    tipos.map(tp =>
                                        <MenuItem key={"MenuItem_de_" + tp} value={tp}>
                                            {ptBR.enum.tipo_pendencia[tp]}
                                        </MenuItem>
                                    )
                                }
                            </SelectFormField>

                        </Box>

                        <Box sx={{flex: '1'}}>
                            <SelectFormField name="status" label="Status"
                                             value={filtro.status} control={formControl}
                                             onChange={submitForm}>
                                <MenuItem value={null}/>
                                {
                                    status.map(sts =>
                                        <MenuItem key={"MenuItem_de_" + sts} value={sts}>
                                            {ptBR.enum.status_pendencia[sts]}
                                        </MenuItem>
                                    )
                                }
                            </SelectFormField>

                        </Box>

                    </Box>

                </form>


            </Card>


            <Card>

                {
                    isPendencia && <h2>Código: {pendencia}</h2>
                }

                <PaginatedTable onPageChange={(pagina, tamanhoPagina) => consulta(filtro, {pagina, tamanhoPagina})}
                                pagina={pagina}>

                    <TableColumn name="codigo">
                        <ColumnHeader>Código</ColumnHeader>
                        <ColumnCell content={(item: PendenciaDTO) => item.codigo}/>
                    </TableColumn>

                    <TableColumn name="cliente">
                        <ColumnHeader>Cliente</ColumnHeader>
                        <ColumnCell content={(item: PendenciaDTO) => item.cliente.nome + " " + item.cliente.telefone}/>
                    </TableColumn>

                    <TableColumn name="dataHora">
                        <ColumnHeader>Data/Hora</ColumnHeader>
                        <ColumnCell
                            content={(item: PendenciaDTO) => formatDate(item.dataHora, 'DD/MM/YYYY | HH:mm:ss ')}/>
                    </TableColumn>

                    <TableColumn name="tipo">
                        <ColumnHeader>Tipo</ColumnHeader>
                        <ColumnCell content={(item: PendenciaDTO) => ptBR.enum.tipo_pendencia[item.tipo]}/>
                    </TableColumn>

                    <TableColumn name="status">
                        <ColumnHeader>Status</ColumnHeader>
                        <ColumnCell content={(item: PendenciaDTO) => ptBR.enum.status_pendencia[item.status]}/>
                    </TableColumn>

                    <TableColumn name="responsavel">
                        <ColumnHeader>Responsável</ColumnHeader>
                        <ColumnCell content={(item: PendenciaDTO) => item.responsavel?.nome}/>
                    </TableColumn>

                    {
                        !isPendencia && <TableColumn name="detalha" style={{width: '60px', textAlign: 'center'}}>
                            <ColumnHeader/>
                            <ColumnCell
                                content={(item: PendenciaDTO) =>
                                    <>
                                        <IconButton onClick={() => onDetalha(item)}>
                                            <Search/>
                                        </IconButton>
                                    </>}/>
                        </TableColumn>
                    }
                </PaginatedTable>

            </Card>
        </>
    );
}

