import useLabels from "../../../i18n/useLabels";
import {Box, Card, IconButton, MenuItem} from "@mui/material";
import SelectFormField from "../../../main-class/form/input/SelectFormField";
import TextFormField from "../../../main-class/form/input/TextFormField";
import PaginatedTable from "../../../main-class/componentes/table/PaginatedTable";
import TableColumn from "../../../main-class/componentes/table/TableColumn";
import ColumnHeader from "../../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../../main-class/componentes/table/ColumnCell";
import {LoteDTO} from "../../../dao";
import {Add, Search} from "@mui/icons-material";
import useConsultaLote from "./useConsultaLote";
import React from "react";
import useFormat from "../../../main-class/componentes/format/useFormat";
import AutocompleteFormField from "../../../main-class/form/input/AutocompleteFormField";
import DateFormField from "../../../main-class/form/input/DateFormField";

export default function ConsultaLote(props: {etapa?: LoteDTO.EtapaEnum , status?: LoteDTO.StatusEnum}) {
    const {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        status,
        etapas,
        showProprietario,
        showAdicionarLaudo,
        showProcessamentoEntrada,
        filtraProprietarios,
        onDetalhe,
        navigateLaudo
    } = useConsultaLote({etapa: props.etapa , status: props.status});
    const {ptBR} = useLabels();
    const {formatDate, formatCurrency, formatNumeric} = useFormat();

    return (
        <>
            <Card>
                <h2><Search/> Filtrar</h2>

                <form onSubmit={submitForm}>

                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{flex: '1'}}>
                            <TextFormField label="Código" value={filtro.codigo}
                                           control={formControl} name="codigo"
                                           onChange={submitForm}
                            />
                        </Box>
                        <Box sx={{flex: '1'}}>
                            <DateFormField label="Data" value={filtro.data} control={formControl} name="data"
                                           onChange={submitForm}
                            />
                        </Box>
                        {
                            !showProcessamentoEntrada && !props.etapa &&
                            <Box sx={{flex: '1'}}>
                                <SelectFormField name="status" label="Status"
                                                 value={filtro.status} control={formControl}
                                                 onChange={submitForm}>
                                    <MenuItem value={null}/>
                                    {
                                        status.map(sts =>
                                            <MenuItem key={"MenuItem_Status_" + sts} value={sts}>
                                                {ptBR.enum.status_lote[sts]}
                                            </MenuItem>
                                        )
                                    }
                                </SelectFormField>
                            </Box>
                        }
                        {
                            !showProcessamentoEntrada && !props.etapa &&
                            <Box sx={{flex: '1'}}>
                                <SelectFormField name="etapa" label="Etapa"
                                                 value={filtro.etapa} control={formControl}
                                                 onChange={submitForm}>
                                    <MenuItem value={null}/>
                                    {
                                        etapas.map(etp =>
                                            <MenuItem key={"MenuItem_Etapa_" + etp} value={etp}>
                                                {ptBR.enum.etapa_lote[etp]}
                                            </MenuItem>
                                        )
                                    }
                                </SelectFormField>
                            </Box>
                        }
                    </Box>

                    {
                        showProprietario &&
                        <AutocompleteFormField
                            name="proprietario" label="Proprietário"
                            value={filtro.proprietario} control={formControl}
                            onChange={submitForm} getOptionLabel={cliente => cliente.nome}
                            getOptions={filtraProprietarios} compareWith={(c1, c2) => c1.id === c2.id}
                        />
                    }

                </form>
            </Card>


            <Card>
                <PaginatedTable onPageChange={(pagina, tamanhoPagina) => consulta(filtro, {pagina, tamanhoPagina})}
                                pagina={pagina}>
                    <TableColumn name="codigo">
                        <ColumnHeader>Código</ColumnHeader>
                        <ColumnCell content={(item: LoteDTO) => item.codigo}/>
                    </TableColumn>
                    {
                        showProprietario &&
                        <TableColumn name="proprietario">
                            <ColumnHeader>Proprietario</ColumnHeader>
                            <ColumnCell content={(item: LoteDTO) => item.proprietario?.nome}/>
                        </TableColumn>
                    }
                    {   showProcessamentoEntrada &&
                        <TableColumn name="data" style={{textAlign: 'center'}}>
                            <ColumnHeader>Data</ColumnHeader>
                            <ColumnCell content={(item: LoteDTO) => formatDate(item.dataHora, 'DD/MM/YYYY')}/>
                        </TableColumn>

                    }
                    {
                        !showProcessamentoEntrada &&
                        <TableColumn name="quantidade" style={{textAlign: 'right'}}>
                            <ColumnHeader>Quantidade</ColumnHeader>
                            <ColumnCell content={(item: LoteDTO) => item.qtdAnimais}/>
                        </TableColumn>
                    }

                    {
                        showProcessamentoEntrada &&
                        <TableColumn name="quantidadeAnimaisProcessados" style={{textAlign: 'right'}}>
                            <ColumnHeader>Animais processados</ColumnHeader>
                            <ColumnCell content={(item: LoteDTO) => item.qtdAnimaisProcessados}/>
                        </TableColumn>
                    }
                    {
                        showProcessamentoEntrada &&
                        <TableColumn name="quantidadeAnimaisProcessadosRestante" style={{textAlign: 'right'}}>
                            <ColumnHeader>Animais restante </ColumnHeader>
                            <ColumnCell content={(item: LoteDTO) => (item.qtdAnimais - item.qtdAnimaisProcessados)}/>
                        </TableColumn>
                    }

                    {
                       !showProcessamentoEntrada &&
                        <TableColumn name="peso-total" style={{textAlign: 'right'}}>
                            <ColumnHeader>Peso total</ColumnHeader>
                            <ColumnCell content={(item: LoteDTO) => formatNumeric(item.pesoTotal) + 'Kg'}/>
                        </TableColumn>
                    }
                    <TableColumn name="peso-medio" style={{textAlign: 'right'}}>
                        <ColumnHeader>Peso Médio</ColumnHeader>
                        <ColumnCell content={(item: LoteDTO) => formatNumeric(item.pesoMedio) + 'Kg'}/>
                    </TableColumn>

                    {
                        showProcessamentoEntrada &&
                        <TableColumn name="valor-medio" style={{textAlign: 'right'}}>
                            <ColumnHeader>VALOR DA DIÁRIA MÉDIO</ColumnHeader>
                            <ColumnCell content={(item: LoteDTO) => formatCurrency(item.mediaValorDiarias)}/>
                        </TableColumn>
                    }

                    {   showProcessamentoEntrada &&
                        <TableColumn name="dias-limite-medio" style={{textAlign: 'right'}}>
                            <ColumnHeader>Dias limites médio</ColumnHeader>
                            <ColumnCell content={(item: LoteDTO) => item.mediaDiasLimites}/>
                        </TableColumn>
                    }



                    { !showProcessamentoEntrada &&
                        <TableColumn name="status" style={{textAlign: 'center'}}>
                            <ColumnHeader>Status</ColumnHeader>
                            <ColumnCell content={(item: LoteDTO) => ptBR.enum.status_lote[item.status]}/>
                        </TableColumn>
                    }
                    {   !showProcessamentoEntrada &&
                        <TableColumn name="etapa" style={{textAlign: 'center'}}>
                            <ColumnHeader>Etapa</ColumnHeader>
                            <ColumnCell content={(item: LoteDTO) => ptBR.enum.etapa_lote[item.etapa]}/>
                        </TableColumn>
                    }
                    <TableColumn name="opcoes" style={{textAlign: 'right'}}>
                        <ColumnHeader/>
                        {
                            !showAdicionarLaudo && <ColumnCell content={
                                (item: LoteDTO) => <>
                                    <IconButton onClick={() => onDetalhe(item)} type="button"><Search/></IconButton>

                                </>
                            }/>
                        }
                        {
                            showAdicionarLaudo&& <ColumnCell content={
                                (item: LoteDTO) => <>
                                    <IconButton onClick={() => navigateLaudo(item)} type="button"><Add/></IconButton>

                                </>
                            }/>
                        }
                    </TableColumn>
                </PaginatedTable>
            </Card>
        </>


    );
}

