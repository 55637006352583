import {Button, TextField} from "@mui/material";
import {AlteracaoClienteDTO, PendenciaApi, PendenciaDTO} from "../../../../dao";
import React, {useCallback, useRef} from "react";
import {useNavigate} from "react-router-dom";
import useDialog from "../../../../main-class/template/dialog/useDialog";
import ModalAlteracaoPeloCliente, {TipoModal} from "./ModalAlteracaoPeloCliente";
import ApiConfiguration from "../../../../infra/api/ApiConfiguration";
import useLoader from "../../../../main-class/componentes/loader/useLoader";
import {lastValueFrom} from "rxjs";

export default function AguardandoDecisaoCliente(props: { pendencia: PendenciaDTO, onUpdate: any }) {
    const pendenciaApi = useRef(new PendenciaApi(ApiConfiguration));
    const {pendencia, onUpdate} = props;
    const navigate = useNavigate();
    const {open} = useDialog();
    const {loadingAround} = useLoader();

    const volta = useCallback(() => {
        navigate("/boigordo");
    }, [navigate]);

    const aprovaPeloCliente = useCallback(async () => {
        const resultado = await lastValueFrom(
            open<AlteracaoClienteDTO>(<ModalAlteracaoPeloCliente tipo={TipoModal.aprovacao_negociacao}/>).afterClosed()
        );
        if (!!resultado) {
            await loadingAround(pendenciaApi.current.aprovaPeloCliente(pendencia.id, resultado));
            onUpdate();
        }
    }, [pendencia, onUpdate, loadingAround, pendenciaApi, open]);

    const recusaPeloCliente = useCallback(async () => {
        const resultado = await lastValueFrom(
            open<AlteracaoClienteDTO>(<ModalAlteracaoPeloCliente tipo={TipoModal.recusa_negociacao}/>).afterClosed()
        );
        if (!!resultado) {
            await loadingAround(pendenciaApi.current.rejeitaPeloCliente(pendencia.id, resultado));
            onUpdate();
        }
    }, [pendencia, onUpdate, loadingAround, pendenciaApi, open]);

    return (
        <>
            {
                !!pendencia.termo &&
                <>
                    <h2>Termo de negociação de boi gordo</h2>

                    <TextField
                        multiline
                        disabled
                        name="termo" key="termo"
                        value={pendencia.termo} label="Termo"
                    />
                </>
            }

            <div className="acoes">
                <Button onClick={() => aprovaPeloCliente()} variant="contained"
                        color="secondary">Aprovar pelo cliente</Button>
                <Button onClick={() => recusaPeloCliente()} variant="contained"
                        color="error">Recusar pelo cliente</Button>
                <Button color="inherit" onClick={() => volta()}>Voltar</Button>
            </div>
        </>
    );
}
