import {Button, CircularProgress, Tooltip} from "@mui/material";
import useBotaoCarga from "./useBotaoCarga";
import useFormat from "../../main-class/componentes/format/useFormat";
import {CheckCircle, PlayCircle, Warning} from "@mui/icons-material";
import {DadosUltimaCargaDTO} from "../../dao";

export default function BotaoCarga() {
    const {processaCarga, showTooltip, status} = useBotaoCarga();
    const {tempoLegivel} = useFormat();

    return (
        <>
            <Tooltip className="copy-tooltip"
                     title={
                         <>
                             {
                                 status?.status === DadosUltimaCargaDTO.StatusEnum.ConcluidoSucesso &&
                                 <>
                                     <CheckCircle style={{height: '10px', width: '10px', fontSize: '10px'}}
                                                  color="success"/> {tempoLegivel(status.dataTermino.getTime() - status.dataTermino.getTime())} |
                                     Clientes: {status.qtdClientesRegistrados} |
                                     Desembarques: {status.qtdDesembarquesRegistrados} |
                                     Lotes: {status.qtdLotesRegistrados} |
                                     Saídas: {status.qtdSaidasRegistradas}
                                 </>
                             }
                             {
                                 status?.status === DadosUltimaCargaDTO.StatusEnum.FalhaProcessamento &&
                                 <>
                                     Última execução: <Warning
                                     style={{height: '10px', width: '10px', fontSize: '10px'}}
                                     color="error"/> {tempoLegivel(status.dataTermino.getTime() - status.dataTermino.getTime())} |
                                     Clientes: {status.qtdClientesRegistrados} |
                                     Desembarques: {status.qtdDesembarquesRegistrados} |
                                     Lotes: {status.qtdLotesRegistrados} |
                                     Saídas: {status.qtdSaidasRegistradas}
                                 </>
                             }
                         </>
                     }
                     placement="bottom"
                     open={showTooltip}>
                <Button disabled={status?.status === DadosUltimaCargaDTO.StatusEnum.Processando}
                        variant="outlined" onClick={processaCarga}>
                    {
                        status?.status === DadosUltimaCargaDTO.StatusEnum.Processando &&
                        <>
                            <CircularProgress style={{verticalAlign: 'middle', marginRight: '5px'}} size={14}/>
                            Sincronizando...
                        </>
                    }
                    {
                        status?.status !== DadosUltimaCargaDTO.StatusEnum.Processando &&
                        <>
                            <PlayCircle color="info" style={{
                                height: '16px',
                                width: '16px',
                                fontSize: '16px',
                                marginRight: '5px',
                                verticalAlign: 'middle'
                            }}/> Sincronizar<br/>
                        </>
                    }
                </Button>
            </Tooltip>
        </>
    );
}
