import {ReactNode, useState} from "react";
import {Icon, Tooltip} from "@mui/material";
import copy from "clipboard-copy";
import "./CopyContent.scss";

export interface CopyContentProps {
    content: string;
    children?: ReactNode;
}

export default function CopyContent(props: CopyContentProps) {
    const {children, content} = props;
    const [showTooltip, setShowTooltip] = useState(false);

    function onCopy() {
        copy(content);
        setShowTooltip(true);
    }

    return (
        <>
            <Tooltip className="copy-tooltip"
                     title="Copiado!"
                     placement="top"
                     open={showTooltip}
                     leaveDelay={1500}
                     onClose={() => setShowTooltip(false)}>
                <div onClick={() => onCopy()}>
                    {children || content}
                    <Icon className="copy-icon">content_copy</Icon>
                </div>
            </Tooltip>
        </>
    );
}
