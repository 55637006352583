import CopyContent from "../../../main-class/componentes/copyContent/CopyContent";
import {Box, Card} from "@mui/material";
import React from "react";
import useFormat from "../../../main-class/componentes/format/useFormat";

import {PendenciaDTO} from "../../../dao";

export default function SumarioPendenciaQtdAnimais(props: { pendencia: PendenciaDTO }) {
    const {pendencia} = props;
    const {formatTelefone} = useFormat();

    return (
        <>
            <Card>
                <Box sx={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                    <Box sx={{flex: 1}}>
                        <h2 style={{margin: '0 0 10px'}}> {pendencia.cliente.codigo} | {pendencia.cliente.nome}</h2>
                        <CopyContent content={pendencia.cliente.email}>
                            {pendencia.cliente.email}
                        </CopyContent>
                        <CopyContent content={pendencia.cliente.telefone}> |
                            {formatTelefone(pendencia.cliente.telefone)}
                        </CopyContent>
                    </Box>


                    <Box sx={{flex: 1, textAlign: 'center'}}>
                        <div style={{display: 'flex',}}>
                            <Box sx={{flex: '1'}}>
                                <span style={{margin: 0, fontSize: 13}}>Macho</span>
                                <h1 style={{margin: '0 0 5px'}}>{props.pendencia.quantidadeAnimais60a74DiasMacho || '0'}</h1>
                            </Box>
                            <Box sx={{flex: '1'}}>
                                <p style={{margin: 0, fontSize: 13}}>Fêmea</p>
                                <h1 style={{margin: '0 0 5px'}}>{pendencia.quantidadeAnimais60a74DiasFemea || '0'}</h1>
                            </Box>
                        </div>


                        <h2 style={{margin: 0}}>60 a 74 dias</h2>
                    </Box>

                    <Box sx={{flex: 1, textAlign: 'center'}}>
                        <div style={{display: 'flex',}}>
                            <Box sx={{flex: '1'}}>
                                <p style={{margin: 0, fontSize: 13}}>Macho</p>
                                <h1 style={{margin: '0 0 5px'}}>{pendencia.quantidadeAnimais75a94DiasMacho || '0'}</h1>
                            </Box>
                            <Box sx={{flex: '1'}}>
                                <p style={{margin: 0, fontSize: 13}}>Fêmea</p>
                                <h1 style={{margin: '0 0 5px'}}>{pendencia.quantidadeAnimais75a94DiasFemea || '0'}</h1>
                            </Box>
                        </div>


                        <h2 style={{margin: 0}}>75 a 94 dias</h2>
                    </Box>


                    <Box sx={{flex: 1, textAlign: 'center'}}>
                        <div style={{display: 'flex',}}>
                            <Box sx={{flex: '1'}}>
                                <p style={{margin: 0, fontSize: 13}}>Macho</p>
                                <h1 style={{margin: '0 0 5px'}}>{pendencia.quantidadeAnimais95DiasMacho || '0'}</h1>
                            </Box>
                            <Box sx={{flex: '1'}}>
                                <p style={{margin: 0, fontSize: 13}}>Fêmea</p>
                                <h1 style={{margin: '0 0 5px'}}>{pendencia.quantidadeAnimais95DiasFemea || '0'}</h1>
                            </Box>
                        </div>


                        <h2 style={{margin: 0}}>95 dias ou mais</h2>
                    </Box>

                </Box>
            </Card>
        </>
    );
}
