import {TemplateConfig, TemplateConfigContext} from "./main-class/template/base/Base";
import logo from "./logo.png";
import {BrowserRouter} from "react-router-dom";
import MenuContext from "./main-class/template/menu/MenuContext";
import * as React from "react";
import {useRef} from "react";
import useMenu from "./infra/menu/useMenu";
import HeaderActions from "./infra/header/HeaderActions";
import routes from "./feature/routes";
import ArquivoContext from "./main-class/componentes/arquivo/ArquivoContext";
import useArquivoManager from "./infra/arquivo/useArquivoManager";
import SincronizacaoContext, {SincronizacaoState} from "./infra/sincronizacao/SincronizacaoContext";
import DialgoContainer from "./main-class/template/dialog/DialogContainer";

const templateConfig: TemplateConfig = {
    logoSrc: logo,
    direitos: 'Fazenda Santa Fé @ 2022',
    headerActions: <HeaderActions/>
}

function App() {
    const {menu} = useMenu();
    const {arquivoManager} = useArquivoManager();
    const sincronizacaoState = useRef(new SincronizacaoState());

    return (
        <BrowserRouter>
            <MenuContext.Provider value={menu}>
                <SincronizacaoContext.Provider value={sincronizacaoState.current}>
                    <ArquivoContext.Provider value={arquivoManager}>
                        <TemplateConfigContext.Provider value={templateConfig}>
                            <DialgoContainer>
                                {routes}
                            </DialgoContainer>
                        </TemplateConfigContext.Provider>
                    </ArquivoContext.Provider>
                </SincronizacaoContext.Provider>
            </MenuContext.Provider>
        </BrowserRouter>
    );
}

export default App;
