import {Box, Button, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {OcorrenciaLaudoEntradaDTO} from "../../../../dao";
import MultipleArquivosFormField from "../../../../main-class/form/input/MultipleArquivosFormField";
import React from "react";
import {useForm} from "react-hook-form";
import useLabels from "../../../../i18n/useLabels";
import useValidation from "../../../../main-class/form/validation/useValidation";
import useDialogRef from "../../../../main-class/template/dialog/useDialogRef";
import {Close} from "@mui/icons-material";

export interface FormEdicaoOcorrenciasProps {
    value: OcorrenciaLaudoEntradaDTO;
    disabled?: any;
}

export default function FormEdicaoOcorrencias(props: FormEdicaoOcorrenciasProps) {
    const {control, getValues, handleSubmit} = useForm({
        defaultValues: props.value,
    });
    const ocorrencia = getValues();
    const {ptBR} = useLabels();
    const {validate, required} = useValidation();
    const {dialogRef} = useDialogRef();

    const actualHandleSubmit = handleSubmit(entity => {
        dialogRef.close(entity);
    });

    return (
        <>
            <DialogTitle style={{display: 'flex'}}>
                {ptBR.enum.tipo_ocorrencia[ocorrencia.ocorrencia]} | {ocorrencia.quantidade}
                <div style={{flex: '1 1 auto'}}/>
                <IconButton type="button" onClick={() => dialogRef.close()}>
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>

                <Box sx={{flex: 1}}>
                    <MultipleArquivosFormField
                        name="midias" accept={[".mp4", ".png", ".jpeg", ".jpg"]}
                        control={control as any}
                        disabled={props.disabled}
                        validate={
                            validate(
                                required()
                            )
                        }/>
                </Box>

            </DialogContent>
            <DialogActions>
                {
                    !props.disabled &&
                    <Button variant="contained" type="button"
                            onClick={() => actualHandleSubmit()}>
                        Aplicar Alteração
                    </Button>
                }
            </DialogActions>
        </>
    )
        ;
}
