import {Bar, BarChart, CartesianGrid, Legend, ReferenceLine, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import React from "react";
// @ts-ignore
import range from "lodash/range";
import {Box, Card} from "@mui/material";
import useGraficoPendencia from "./useGraficoPendencia";
import SumarioCLienteQtdAnimais from "../sumario/SumarioClienteQtdAnimais";


export default function GraficoPendencia() {
    const {quantidades, cliente, label, max, menorDia} = useGraficoPendencia();

    return (
        <>
            <SumarioCLienteQtdAnimais cliente={cliente}/>

            <Card>
                <Box sx={{display: 'grid', gap: '40px'}}>

                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                        <ResponsiveContainer width="100%" aspect={2}>

                            <BarChart
                                data={quantidades?.quantidades} margin={{top: 25, right: 25, left: 20, bottom: 5}}
                                style={{stroke: '#333', strokeWidth: 1, fontsize: 18}}>

                                <CartesianGrid strokeDasharray="3 3"/>

                                <XAxis dataKey="diasDeCocho" type="number" domain={[menorDia - 1, 95]} minTickGap={1}
                                       ticks={[...range(menorDia - 1, 96)]} orientation={'top'}
                                       label={{value: 'Dias', position: 'top'}}/>

                                <YAxis domain={[0, max(quantidades?.quantidades)]}
                                       label={{value: 'Quantidade', angle: -90, position: 'insideLeft'}}/>

                                <ReferenceLine x={quantidades?.proximoContato}
                                               style={{stroke: '#f03535', strokeWidth: 1}}
                                               label={{value: 'Proximo Contato', angle: -90, position: 'insideRight'}}/>

                                <Legend iconType="circle"/>


                                <Bar dataKey="quantidadeMacho" key={"macho"} name="Macho" fill="#72bddb" barSize={40}
                                     isAnimationActive={false} label={label}/>

                                <Bar dataKey="quantidadeFemea" key={"femea"} name="Femea" fill="#f2b6f1" barSize={40}
                                     isAnimationActive={false} label={label}/>

                            </BarChart>

                        </ResponsiveContainer>

                    </Box>

                </Box>
            </Card>
        </>
    )
}
