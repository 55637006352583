import {
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from "@mui/material";
import {Controller} from "react-hook-form";
import {FieldProps} from "./FieldProps";
import React, {ChangeEvent, useRef} from "react";
import {AttachFile, Close, FileOpenOutlined, Warning} from "@mui/icons-material";
import useArquivo from "../../componentes/arquivo/useArquivo";
import {IArquivo} from "../../componentes/arquivo/ArquivoContext";

export interface ArquivoFormFieldProps extends FieldProps<IArquivo | any> {
    accept?: string | string[];
    limit?: string;
}

export default function ArquivoFormField(props: ArquivoFormFieldProps) {
    const inputFileUpload = useRef<HTMLInputElement>();
    const {fileLength, fileFormat, upload} = useArquivo();

    function clearSelection(onChange: (event: any) => any) {
        return () => {
            return onChange('');
        }
    }

    function onFileChange(onChange: (event: any) => any) {
        return async (event: ChangeEvent<any>) => {
            const file = event.target.files[0];

            event.target.value = '';

            const arquivo: IArquivo = {
                tamanho: file.size,
                uploadProgress: 0,
                nome: file.name
            };

            onChange(arquivo);

            if (fileLength(props.limit)(arquivo)) {
                onChange({
                    tamanho: file.size,
                    nome: file.name
                });
                return;
            }

            if (fileFormat(props.accept)(arquivo)) {
                onChange({
                    tamanho: file.size,
                    nome: file.name
                });
                return;
            }

            try {
                const resultado = await upload(file, (event) => {
                    onChange(event.attachment);
                });

                onChange(resultado);
            } catch (ex) {
                console.log(ex);

                onChange({
                    tamanho: file.size,
                    nome: file.name,
                    error: ex,
                });
            }
        };
    }

    function openFileChooser() {
        inputFileUpload.current.click();
    }

    function validateArquivo(arquivo: IArquivo) {
        if (arquivo.error) {
            return "Falha no upload do arquivo.";
        }

        if (fileLength(props.limit)(arquivo)) {
            return "Tamanho ultrapaça o valor limit de " + props.limit;
        }

        if (fileFormat(props.accept)(arquivo)) {
            return "Formato do arquivo inválido.";
        }

        if (arquivo.uploadProgress !== undefined) {
            return "Aguarde o término do upload do arquivo.";
        }

        if (props.validate) {
            return props.validate(arquivo);
        }

        return null;
    }

    return (
        <>
            <Controller
                name={props.name}
                control={props.control}
                defaultValue={props.value || ""}
                rules={{validate: validateArquivo}}
                render={({field: {onChange, value}, fieldState: {error}}) => (
                    <>
                        <FormControl variant={props.variant} required={props.required}>
                            <InputLabel htmlFor={props.name + "_input"}>{props.label}</InputLabel>
                            <OutlinedInput
                                id={props.name + "_input"}
                                value={value?.nome || ''}
                                label={props.label}
                                error={!!error || !!value.error}
                                readOnly={true}
                                startAdornment={
                                    <>
                                        {
                                            !value?.error &&
                                            <InputAdornment position="start">
                                                <AttachFile/>
                                            </InputAdornment>
                                        }
                                        {
                                            !!value?.error &&
                                            <InputAdornment position="start">
                                                <Warning color="error"/>
                                            </InputAdornment>
                                        }
                                    </>
                                }
                                endAdornment={
                                    <>
                                        {
                                            !value &&
                                            <Button variant="outlined" onClick={openFileChooser}>
                                                <FileOpenOutlined sx={{marginRight: '10px'}}/>
                                                Selecionar
                                            </Button>
                                        }
                                        {
                                            value && value.uploadProgress === undefined &&
                                            <IconButton onClick={clearSelection(onChange)}>
                                                <Close/>
                                            </IconButton>
                                        }
                                        {
                                            value && value.uploadProgress !== undefined &&
                                            <CircularProgress size={24}
                                                              variant={value.uploadProgress ? "determinate" : "indeterminate"}
                                                              value={value.uploadProgress * 100}/>
                                        }
                                    </>
                                }
                            />
                            <FormHelperText style={{color: 'red'}}>{error?.message}</FormHelperText>
                        </FormControl>
                        <input type="file" style={{display: 'none'}}
                               onChange={onFileChange(onChange)}
                               ref={inputFileUpload}/>
                    </>
                )}
            />
        </>
    );
}
