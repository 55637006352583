import React, {useRef} from "react";
import {
    AnimalApi,
    DocumentoDigitalDTO,
    LaudoEntradaDTO,
    LoteApi,
    LoteDTO,
    OcorrenciaLaudoEntradaDTO,
    QuantidadeRacaDTO
} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import useDialog from "../../../main-class/template/dialog/useDialog";
import useEdicao from "../../../main-class/form/crud/useEdicao";
import {lastValueFrom} from "rxjs";
import ModalAssinatura from "../../../componentes/modalAssinatura/ModalAssinatura";
import {useNavigate, useParams} from "react-router-dom";
import useArquivo from "../../../main-class/componentes/arquivo/useArquivo";
import useMensageria from "../../../main-class/componentes/mensageria/useMensageria";
import useSumarioLote from "../../lote/sumario/useSumarioLote";

export interface InnerQuantidadeRacaDTO extends QuantidadeRacaDTO {
    habilitado?: boolean;
}

export interface InnerLaudoDTO extends LaudoEntradaDTO {
    racas: Array<InnerQuantidadeRacaDTO>;
    documentoDigital?: DocumentoDigitalDTO;
}

export default function useCadastroLaudoEntrada() {
    const loteApi = useRef(new LoteApi(ApiConfiguration));
    const animalApi = useRef(new AnimalApi(ApiConfiguration));
    const navigate = useNavigate();
    const {confirmacao, open} = useDialog();
    const {download} = useArquivo();
    const {addMensagemSucesso, addMensagemErro} = useMensageria();
    const {lote} = useSumarioLote();
    const {lote: idLote} = useParams();

    const enumOcorrencias = Object.values(OcorrenciaLaudoEntradaDTO.OcorrenciaEnum);

    const {entity, submitForm, formControl, reset} = useEdicao<InnerLaudoDTO>({
        idParam: 'lote',
        initialEntity: {racas: []} as InnerLaudoDTO,
        buildInitialEntity: async () => {
            const racas = await animalApi.current.consultaRacas();
            console.log(racas);
            return {
                racas: racas?.map(raca => ({raca, quantidade: 0, habilitado: false})) || [],
                idade: undefined,
                condEscoreCorporal: undefined,
                baiaProcessamentoTemporario: undefined,
                observacao: undefined
            } as InnerLaudoDTO;
        },
        findAction: async id => {
            const [laudo, racas] = await Promise.all([
                loteApi.current.consultaLaudoEntrada(id),
                animalApi.current.consultaRacas()
            ]);
            console.log(racas.map(raca => {

                const qtd = laudo?.racas?.find(q => q.raca.id === raca.id);
                return {
                    raca,
                    habilitado: !!qtd,
                    quantidade: qtd?.quantidade || 0,
                };
            }))

            return {
                ...laudo,
                racas: racas.map(raca => {
                    const qtd = laudo?.racas?.find(q => q.raca.id === raca.id);
                    return {
                        raca,
                        habilitado: !!qtd,
                        quantidade: qtd?.quantidade || 0,
                    };
                })
            };
        },
        preSave: async entity => {
            if (!entity.racas?.length) {
                addMensagemErro("Selecione ao menos uma raça.");
                return false;
            }

            const quantidade = entity.racas.filter(qtd => qtd.habilitado)
                .map(qtd => qtd.quantidade)
                .reduce((q1, q2) => q1 + q2, 0);

            let quantideAnimaisLote;

            await loteApi.current.detalhe(Number(idLote)).then(response => {
                quantideAnimaisLote = response.qtdAnimais;
            });

            if (quantidade !== quantideAnimaisLote) {
                addMensagemErro("A soma da quantidade de animais por raça não confere com a quantidade total de animais no lote.");
                return false;
            }

            return await lastValueFrom(confirmacao('O cliente será notificado no seu dispositivo móvel. Essa ação é irreversível. Deseja continuar?').afterClosed());
        },
        saveAction: async (entity, id) => {

            const documentoDigital = await loteApi.current.finalizaLaudoEntrada(id, {
                ...entity,
                racas: entity.racas.filter(rca => rca.habilitado)
            });
            return {
                ...entity,
                documentoDigital
            };
        },
        postSave: (entity, id) => {
            open(<ModalAssinatura condicaoAssinatura={() => verificaAssinatura(id)}
                                  label={"Assinatura de Laudo de entrada"}
                                  linkAssinatura={entity.documentoDigital!.linkAssinatura}/>, {
                fullWidth: true,
                maxWidth: 'xl'
            }).afterClosed().subscribe(sucesso => {
                if (sucesso) {
                    navigate(`/laudo-entrada`);
                    addMensagemSucesso('Laudo de entrada registrado com sucesso.');
                }
            });
        },
        ignoreMensageSucesso: true,
    });

    function downloadDocumentoAssinado() {
        download(entity.documentoAssinado);
    }

    async function verificaAssinatura(id: number) {
        const lote = await loteApi.current.detalhe(id);

        return lote.etapa !== LoteDTO.EtapaEnum.Desembarque;
    }

    return {
        entity,
        submitForm,
        formControl,
        reset,
        enumOcorrencias,
        downloadDocumentoAssinado,
        cadastroHabilitado: lote.etapa === LoteDTO.EtapaEnum.Desembarque
    };
}
