// @ts-ignore
import {ClienteDTO, ProcessamentoSaidaApi, ProcessamentoSaidaDTO} from "../../../dao";
import {useCallback, useContext, useEffect, useRef} from "react";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import {useNavigate} from "react-router-dom";
import useConsulta from "../../../main-class/form/crud/useConsulta";
import SincronizacaoContext from "../../../infra/sincronizacao/SincronizacaoContext";

export interface FiltroProcessamentoSaida {
    codigo?: string;
    data?: Date;
    status?: ProcessamentoSaidaDTO.StatusEnum;
    proprietario?: ClienteDTO;

    lote?: number;
}

export default function useConsultaProcessamentoSaida() {

    const api = useRef(new ProcessamentoSaidaApi(ApiConfiguration));

    const navigate = useNavigate();

    const status = Object.values(ProcessamentoSaidaDTO.StatusEnum);

    const sincornizacaoState = useContext(SincronizacaoContext);

    const {
        filtro,
        pagina,
        consulta,
        formControl,
        submitForm
    } = useConsulta<FiltroProcessamentoSaida, ProcessamentoSaidaDTO>({
        consultaAction: (filtro, pagina, tamanhoPagina) => api.current.consulta(
            filtro.codigo,
            filtro.data,
            filtro.proprietario?.id,
            filtro.status,
            filtro.lote,
            pagina,
            tamanhoPagina
        ),
    });

    const onDetalhe = useCallback((processamento: ProcessamentoSaidaDTO) => {
        navigate(`/processamento-saida/cliente/${processamento.proprietario.id}/detalhe/${processamento.id}`);
    }, [navigate])

    useEffect(() => {
        const subscription = sincornizacaoState.saidaObservable
            .subscribe(() => consulta())

        return () => subscription.unsubscribe();
    }, [sincornizacaoState, consulta]);

    return {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        status,
        onDetalhe
    };


}
