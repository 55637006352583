import React, {useEffect, useRef, useState} from "react";
import {DashboardApi, ItemTelaDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import useLoader from "../../../main-class/componentes/loader/useLoader";
import useFormat from "../../../main-class/componentes/format/useFormat";
import ArrayTable from "../../../main-class/componentes/table/ArrayTable";
import TableColumn from "../../../main-class/componentes/table/TableColumn";
import ColumnHeader from "../../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../../main-class/componentes/table/ColumnCell";

export function RelatorioTelas() {
    const dashboardApi = useRef(new DashboardApi(ApiConfiguration));

    const [telas, setTelas] = useState<Array<ItemTelaDTO & { id: any }>>([]);

    const {loadingAround} = useLoader();

    const {formatNumeric} = useFormat();

    useEffect(() => {
        loadingAround(dashboardApi.current.consultaRelatorioTelas())
            .then(relatorio => setTelas(relatorio.telas.map(t => ({...t, id: t.path}))));
    }, [dashboardApi, loadingAround]);

    return (
        <>
            <ArrayTable items={telas}>
                <TableColumn name="tela">
                    <ColumnHeader>Tela</ColumnHeader>
                    <ColumnCell content={(tela: ItemTelaDTO) => tela.path}/>
                </TableColumn>
                <TableColumn name="qtdeAcessos" className="text-right">
                    <ColumnHeader>QTD. ACESSOS</ColumnHeader>
                    <ColumnCell content={(tela: ItemTelaDTO) => formatNumeric(tela.qtdeAcessos)}/>
                </TableColumn>
                <TableColumn name="qtdeUsuarios" className="text-right">
                    <ColumnHeader>QTD. USUÁRIOS</ColumnHeader>
                    <ColumnCell content={(tela: ItemTelaDTO) => formatNumeric(tela.qtdeAcessosPorUsuario)}/>
                </TableColumn>
                <TableColumn name="qtdeSessoes" className="text-right">
                    <ColumnHeader>QTD. SESSÕES</ColumnHeader>
                    <ColumnCell content={(tela: ItemTelaDTO) => formatNumeric(tela.qtdeAcessosPorSessao)}/>
                </TableColumn>
            </ArrayTable>
        </>
    );
}
