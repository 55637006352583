import TableColumn from "../../../../../main-class/componentes/table/TableColumn";
import ColumnHeader from "../../../../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../../../../main-class/componentes/table/ColumnCell";
import {IconButton} from "@mui/material";
import {FileDownload} from "@mui/icons-material";
import React from "react";
import useDesembarqueLote from "./useDesembarquesLote";
import PaginatedTable from "../../../../../main-class/componentes/table/PaginatedTable";
import useFormat from "../../../../../main-class/componentes/format/useFormat";
import {DesembarqueDTO} from "../../../../../dao";
import useLabels from "../../../../../i18n/useLabels";
import useArquivo from "../../../../../main-class/componentes/arquivo/useArquivo";

export default function DesembarquesLote() {
    const {consulta, pagina} = useDesembarqueLote();
    const {formatDate} = useFormat();
    const {ptBR} = useLabels();
    const {download} = useArquivo();

    return (
        <>
            <PaginatedTable pagina={pagina}
                            onPageChange={(pagina, tamanhoPagina) => consulta({}, {pagina, tamanhoPagina})}>
                <TableColumn name="codigo">
                    <ColumnHeader>Código</ColumnHeader>
                    <ColumnCell content={(des: DesembarqueDTO) => des.codigo}/>
                </TableColumn>
                <TableColumn name="dataHora">
                    <ColumnHeader style={{textAlign: 'center'}}>Data/Hora</ColumnHeader>
                    <ColumnCell style={{textAlign: 'center'}}
                                content={(des: DesembarqueDTO) => formatDate(des.dataHoraEntrada, "DD/MM/YYYY | HH:mm:ss")}/>
                </TableColumn>
                <TableColumn name="carreta">
                    <ColumnHeader style={{textAlign: 'center'}}>Carreta</ColumnHeader>
                    <ColumnCell style={{textAlign: 'center'}} content={(des: DesembarqueDTO) => des.placaCarreta}/>
                </TableColumn>
                <TableColumn name="animais">
                    <ColumnHeader style={{textAlign: 'center'}}>Animais</ColumnHeader>
                    <ColumnCell style={{textAlign: 'center'}} content={(des: DesembarqueDTO) => des.qtdAnimais}/>
                </TableColumn>
                <TableColumn name="status">
                    <ColumnHeader style={{textAlign: 'center'}}>Status</ColumnHeader>
                    <ColumnCell style={{textAlign: 'center'}}
                                content={(des: DesembarqueDTO) => ptBR.enum.status_desembarque[des.status]}/>
                </TableColumn>
                <TableColumn name="gta">
                    <ColumnHeader style={{textAlign: 'center'}}>GTA</ColumnHeader>
                    <ColumnCell style={{textAlign: 'center'}} content={(des: DesembarqueDTO) =>
                        !!des.gtaUnificado &&
                        <IconButton type="button" onClick={() => download(des.gtaUnificado)}>
                            <FileDownload/>
                        </IconButton>
                    }/>
                </TableColumn>
                <TableColumn name="nf">
                    <ColumnHeader style={{textAlign: 'center'}}>NF</ColumnHeader>
                    <ColumnCell style={{textAlign: 'center'}} content={(des: DesembarqueDTO) =>
                        !!des.notaFiscal &&
                        <IconButton type="button" onClick={() => download(des.notaFiscal)}>
                            <FileDownload/>
                        </IconButton>
                    }/>
                </TableColumn>
                <TableColumn name="dFemea">
                    <ColumnHeader style={{textAlign: 'center'}}>D. Fêmea</ColumnHeader>
                    <ColumnCell style={{textAlign: 'center'}} content={(des: DesembarqueDTO) =>
                        !!des.declaracaoFemea &&
                        <IconButton type="button" onClick={() => download(des.declaracaoFemea)}>
                            <FileDownload/>
                        </IconButton>
                    }/>
                </TableColumn>
            </PaginatedTable>
        </>
    );
}
