import React, {useEffect, useRef, useState} from "react";
import {DashboardApi, LinhaRelatorioAcessoDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import useLoader from "../../../main-class/componentes/loader/useLoader";
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import useFormat from "../../../main-class/componentes/format/useFormat";

export function RelatorioAcesso() {
    const dashboardApi = useRef(new DashboardApi(ApiConfiguration));

    const [relatorioAcesso, setRelatorioAcesso] = useState<Array<LinhaRelatorioAcessoDTO>>([]);

    const {loadingAround} = useLoader();
    const {formatDate} = useFormat();

    useEffect(() => {
        loadingAround(dashboardApi.current.consultaRelatorioAcesso())
            .then(relatorio => setRelatorioAcesso(relatorio.linhas || []));
    }, [dashboardApi, loadingAround]);

    return (
        <>
            <ResponsiveContainer width="100%" height={300}>
                <AreaChart
                    width={500}
                    height={300}
                    data={relatorioAcesso}
                >
                    <XAxis dataKey="data"
                           tickFormatter={(data: Date) => formatDate(data, 'DD/MM/YYYY')}
                    />
                    <YAxis/>
                    <Tooltip labelFormatter={(data: Date) => formatDate(data, 'DD/MM/YYYY')} />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Area type="monotone" dataKey="qtdeAtivos28Dias" label={"28 DIAS"} stroke="#8345ed" fill="#8345ed"/>
                    <Area type="monotone" dataKey="qtdeAtivos7Dias" stroke="#457aed" fill="#457aed"/>
                    <Area type="monotone" dataKey="qtdeAtivos1Dia" stroke="#66b1e3" fill="#66b1e3"/>
                </AreaChart>
            </ResponsiveContainer>
        </>
    );
}
