import useLabels from "../../../i18n/useLabels";
import {Box, Card, IconButton, MenuItem} from "@mui/material";
import SelectFormField from "../../../main-class/form/input/SelectFormField";
import TextFormField from "../../../main-class/form/input/TextFormField";
import PaginatedTable from "../../../main-class/componentes/table/PaginatedTable";
import TableColumn from "../../../main-class/componentes/table/TableColumn";
import ColumnHeader from "../../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../../main-class/componentes/table/ColumnCell";
import {ClienteDTO, UsuarioAdministradorDTO} from "../../../dao";
import CopyContent from "../../../main-class/componentes/copyContent/CopyContent";
import {MoreVert, Search} from "@mui/icons-material";
import useConsultaCliente from "./useConsultaCliente";
import React from "react";
import useFormat from "../../../main-class/componentes/format/useFormat";
import CpfCnpjFormField from "../../../main-class/form/input/CpfCnpjFormField";
import IconOptions from "../../../componentes/iconOptions/IconOptions";
import usePermissao from "../../../infra/seguranca/usePermissao";

export default function ConsultaCliente() {
    const {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        status,
        toggleAcesso,
        resetarSenha,
        onDetalhe
    } = useConsultaCliente();
    const {ptBR} = useLabels();
    const {formatCpfCnpj, formatTelefone} = useFormat();
    const {is} = usePermissao();

    return (
        <>
            <Card>
                <h2><Search/> Filtrar</h2>

                <form onSubmit={submitForm}>

                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{flex: '1'}}>
                            <TextFormField label="Código" value={filtro.codigo} control={formControl} name="codigo"
                                           onChange={submitForm}
                            />
                        </Box>
                        <Box sx={{flex: '1'}}>
                            <TextFormField label="Nome" value={filtro.nome} control={formControl} name="nome"
                                           onChange={submitForm}
                            />
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{flex: '1'}}>
                            <CpfCnpjFormField label="CPF/CNPJ" value={filtro.cpfCnpj}
                                              control={formControl} name="cpfCnpj" onChange={submitForm}
                            />
                        </Box>
                        <Box sx={{flex: '1'}}>
                            <SelectFormField name="status" label="Status"
                                             value={filtro.status} control={formControl}
                                             onChange={submitForm}>
                                <MenuItem value={null}/>
                                {
                                    status.map(sts =>
                                        <MenuItem key={"MenuItem_Status_" + sts} value={sts}>
                                            {ptBR.enum.status_usuario[sts]}
                                        </MenuItem>
                                    )
                                }
                            </SelectFormField>
                        </Box>
                    </Box>

                </form>
            </Card>


            <Card>
                <PaginatedTable onPageChange={(pagina, tamanhoPagina) => consulta(filtro, {pagina, tamanhoPagina})}
                                pagina={pagina}>
                    <TableColumn name="codigo" style={{textAlign: 'center'}}>
                        <ColumnHeader>Código</ColumnHeader>
                        <ColumnCell content={(item: ClienteDTO) => item.codigo}/>
                    </TableColumn>
                    <TableColumn name="nome">
                        <ColumnHeader>Nome</ColumnHeader>
                        <ColumnCell content={(item: ClienteDTO) => item.nome}/>
                    </TableColumn>
                    <TableColumn name="cpf-cnpj" style={{textAlign: 'center'}}>
                        <ColumnHeader>CPF / CNPJ</ColumnHeader>
                        <ColumnCell content={(item: ClienteDTO) => formatCpfCnpj(item.cpfCnpj)}/>
                    </TableColumn>
                    <TableColumn name="contato">
                        <ColumnHeader>Contato</ColumnHeader>
                        <ColumnCell content={
                            (item: ClienteDTO) => <>
                                {
                                    item.telefone &&
                                    <CopyContent content={item.telefone}>
                                        {formatTelefone(item.telefone)}
                                    </CopyContent>
                                }
                                {item.telefone && item.email && ' | '}
                                {
                                    item.email &&
                                    <CopyContent content={item.email}>
                                        {item.email}
                                    </CopyContent>
                                }
                            </>
                        }/>
                    </TableColumn>
                    <TableColumn name="status" style={{textAlign: 'center'}}>
                        <ColumnHeader>Status</ColumnHeader>
                        <ColumnCell content={(item: ClienteDTO) => ptBR.enum.status_usuario[item.status]}/>
                    </TableColumn>
                    <TableColumn name="opcoes" style={{textAlign: 'right'}}>
                        <ColumnHeader/>
                        <ColumnCell content={
                            (item: ClienteDTO) => <>
                                {
                                    is(UsuarioAdministradorDTO.PerfilEnum.Gerencial) &&
                                    <IconOptions icon={<MoreVert/>}>
                                        <MenuItem
                                            onClick={() => toggleAcesso(item)}>
                                            {
                                                item.status === ClienteDTO.StatusEnum.Ativo ? 'Desativar acesso' : 'Ativar acesso'
                                            }
                                        </MenuItem>
                                        <MenuItem onClick={() => resetarSenha(item)}>Resetar senha</MenuItem>
                                    </IconOptions>
                                }

                                <IconButton type="button" onClick={() => onDetalhe(item)}><Search/></IconButton>
                            </>
                        }/>
                    </TableColumn>
                </PaginatedTable>
            </Card>
        </>


    );
}

