import {Button} from "@mui/material";
import {useForm} from "react-hook-form";
import useValidation from "../../../main-class/form/validation/useValidation";
import TextFormField from "../../../main-class/form/input/TextFormField";

export default function Email(props: { onSubmitted: (email: string) => any }) {
    const {handleSubmit, control, getValues} = useForm();
    const {validate, required, length} = useValidation();
    const {onSubmitted} = props;

    return (
        <>
            <div className="content">
                <form onSubmit={handleSubmit(({email}) => onSubmitted(email))}>

                    <TextFormField
                        name="email"
                        control={control}
                        value={getValues().email}
                        label="E-mail"
                        validate={
                            validate(
                                required(),
                                length({max: 150, min: 3})
                            )
                        }
                    />

                    <div className="commands">
                        <Button variant="contained" type="submit" color="primary">Acessar</Button>
                    </div>

                </form>
            </div>
        </>
    );
}
