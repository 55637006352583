import useConsultaNoticia from "./useConsultaNoticia";
import {Box, Card, IconButton} from "@mui/material";
import {Delete, Edit, Search} from "@mui/icons-material";
import DateFormField from "../../../main-class/form/input/DateFormField";
import PaginatedTable from "../../../main-class/componentes/table/PaginatedTable";
import TableColumn from "../../../main-class/componentes/table/TableColumn";
import ColumnHeader from "../../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../../main-class/componentes/table/ColumnCell";
import { NoticiaDTO } from "../../../dao";
import useFormat from "../../../main-class/componentes/format/useFormat";
import AutocompleteFormField from "../../../main-class/form/input/AutocompleteFormField";

export default function ConsultaNotificacao(){
    const{
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        remove,
        edita,
        filtraUsuarios,
    } = useConsultaNoticia();
    const {formatDate} = useFormat();
    

    return(
        <>
            <Card>
                <h2><Search/> Filtrar</h2>

                <form onSubmit={submitForm}>
                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{flex: '1'}}>
                            <DateFormField label="Data" value={filtro.data}
                                            control={formControl}
                                            name="data"
                                            onChange={submitForm}
                            />
                        </Box>

                        <Box sx={{flex: '1'}}>
                            <AutocompleteFormField
                                    name="responsavel" label="Responsavel"
                                    value={filtro.responsavel} control={formControl}
                                    onChange={submitForm} getOptionLabel={responsavel => responsavel.nome}
                                    getOptions={filtraUsuarios} compareWith={(v1, v2) => v1.id === v2.id}
                                />
                        </Box>
                    </Box>
                </form>
            </Card>
            
            <Card>
                <PaginatedTable onPageChange={(pagina, tamanhoPagina) => consulta(filtro, {pagina, tamanhoPagina})}
                            pagina={pagina}>
                    
                    <TableColumn name="noticia" >
                        <ColumnHeader>Notícia</ColumnHeader>
                        <ColumnCell content={(item: NoticiaDTO) => item.titulo}/>
                    </TableColumn>

                    <TableColumn name="data" style={{textAlign: 'center'}}>
                        <ColumnHeader>Data</ColumnHeader>
                        <ColumnCell content={(item: NoticiaDTO) => formatDate(item.data as any, 'DD/MM/YYYY')}/>
                    </TableColumn>

                    <TableColumn name="responsavel" >
                        <ColumnHeader>Responsável</ColumnHeader>
                        <ColumnCell content={(item: NoticiaDTO) => item.responsavel.nome}/>
                    </TableColumn>

                    <TableColumn name="opcoes" style={{textAlign: 'right'}}>
                        <ColumnHeader/>
                        <ColumnCell content={
                            (item: NoticiaDTO) => <>
                                <IconButton type="button" onClick={() => edita(item)}><Edit/></IconButton>
                                <IconButton type="button" onClick={() => remove(item)}><Delete/></IconButton>
                            </>
                        }/>
                    </TableColumn>
                    
                </PaginatedTable>
            </Card>
        </>
    )
}
