import {createTheme} from "@mui/material";

const theme = createTheme({
    palette: {
        background: {
            default: '#F2F2F2'
        },
        primary: {
            main: '#19386A'
        },
        secondary: {
            main: '#97D4E6',
            contrastText: '#19386A'
        }
    }
});

export default theme;
