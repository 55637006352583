import React, {useContext} from "react";
import {DialogConfig, DialogContext} from "./DialogContainer";

export default function useDialog() {
    const manager = useContext(DialogContext);

    return {
        open: <T> (element: React.ReactNode, config?: DialogConfig) => manager.open<T>(element, config),
        closeAll: () => manager.closeAll(),
        informacao: (mensagem: React.ReactNode, config?: {
            okLabel?: string,
            titulo?: string
        }) => manager.informacao(mensagem, config),
        confirmacao: (mensagem: React.ReactNode, config?: {
            okLabel?: string,
            cancelLabel?: string,
            titulo?: string
        }) => manager.confirmacao(mensagem, config)
    }
}
