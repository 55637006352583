import useDetalheCliente from "./useDetalheCliente";
import {Box, Button, Card} from "@mui/material";
import "./DetalheCliente.scss";
import React from "react";
import ConsultaNotificacoesCliente from "../notificacoes/ConsultaNotificacoesCliente";

export default function DetalheCliente() {
    const {onLote, onDesembarque, quantidades} = useDetalheCliente();

    return (
        <>
            <Card>
                <h2>Consultas</h2>

                <Box className="opcoes-cliente" sx={{display: 'flex', gap: '20px'}}>
                    <Button onClick={onDesembarque} type="button">
                        <h3>Desembarques</h3>
                        <div className="info">
                            <small>Pendente {quantidades.qtdeDesembarquesPendentes}</small>
                            <small>Iniciado {quantidades.qtdeDesembarquesIniciados}</small>
                            <small>Encerrado {quantidades.qtdeDesembarquesEncerrados}</small>
                        </div>
                    </Button>
                    <Button onClick={onLote} type="button">
                        <h3>Lotes</h3>
                        <div className="info">
                            {quantidades.qtdeLotes}
                        </div>
                    </Button>
                </Box>
            </Card>

            <Card>
                <h2>Timeline</h2>

                <ConsultaNotificacoesCliente/>
            </Card>
        </>
    );
}
