import {useCallback, useContext, useEffect, useState} from "react";
import {SessaoContext, SessaoDTO} from "./Sessao";
import {UsuarioLogadoDTO} from "../../../dao";

export default function useSessao() {
    const [sessao, setSessao] = useState<SessaoDTO>();
    const sessaoManager = useContext(SessaoContext);

    useEffect(() => {
        const subscription = sessaoManager.sessaoObservable
            .subscribe(sessao => {
                setSessao(sessao);
            });

        return () => subscription.unsubscribe();
    }, [sessaoManager]);

    return {
        token: sessao?.token,
        user: sessao?.user,
        isLoggedIn: useCallback(
            () => sessaoManager.isLoggedIn(),
            [sessaoManager]
        ),
        loggedIn: (user: UsuarioLogadoDTO, userToken?: string) => sessaoManager.loggedIn(user, userToken),
        loggedOut: () => sessaoManager.loggedOut(),
    };
}
