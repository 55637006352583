import {BaseSyntheticEvent, useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import useMensageria from "../../componentes/mensageria/useMensageria";
import useLoader from "../../componentes/loader/useLoader";

export interface EdicaoConfig<T> {
    idParam?: string;
    initialEntity?: T;
    buildInitialEntity?: () => Promise<T>;
    findAction: (id: any) => Promise<T>;
    preSave?: (entity: T, id?: any, event?: BaseSyntheticEvent) => Promise<boolean>;
    saveAction: (entity: T, id?: any, event?: BaseSyntheticEvent) => Promise<T>;
    postSave?: (entity: T, id?: any, event?: BaseSyntheticEvent) => any;
    ignoreMensageSucesso?: boolean;
    mensagemSucesso?: string;
}

export default function useEdicao<E>(initialConfig: EdicaoConfig<E>) {
    const {loadingAround} = useLoader();
    const {addMensagemSucesso} = useMensageria();
    const [{
        idParam,
        initialEntity,
        buildInitialEntity,
        findAction,
        preSave,
        saveAction,
        postSave,
        ignoreMensageSucesso,
        mensagemSucesso
    }] = useState<EdicaoConfig<E>>(initialConfig);
    const {[idParam || 'id']: id} = useParams();
    const {
        control,
        handleSubmit,
        getValues,
        reset: resetForm
    } = useForm({defaultValues: (initialEntity || {}) as any});

    const salva = useCallback(async (ety = initialEntity || {} as E, event: BaseSyntheticEvent) => {
        if (preSave) {
            const shouldContinue = await preSave(ety, id, event);
            if (!shouldContinue) {
                return;
            }
        }

        const salvo = await loadingAround(
            saveAction(ety, id, event)
        );

        resetForm(salvo, {keepDirty: true, keepTouched: true, keepIsSubmitted: true});

        if (!ignoreMensageSucesso) {
            addMensagemSucesso(mensagemSucesso || 'Registro salvo com sucesso.');
        }

        if (postSave) {
            postSave(salvo, id, event);
        }
    }, [preSave, saveAction, postSave, resetForm, id, initialEntity, mensagemSucesso, addMensagemSucesso, loadingAround, ignoreMensageSucesso]);

    const reset = useCallback(() => {
        if (id) {
            loadingAround(findAction(id)).then(entity => resetForm(entity));
        } else if (!!buildInitialEntity) {
            loadingAround(buildInitialEntity()).then(entity => resetForm(entity));
        } else {
            resetForm(initialEntity || {});
        }
    }, [id, resetForm, initialEntity, findAction, buildInitialEntity, loadingAround]);

    useEffect(() => {
        reset();
    }, [reset]);

    const submitForm = handleSubmit((entity, event) => salva(entity, event));

    const setEntity = useCallback((entity: E) => {
        resetForm(entity, {keepDirty: true, keepTouched: true, keepIsSubmitted: true});
    }, [resetForm]);

    return {
        entity: getValues() as E,
        setEntity,
        submitForm,
        formControl: control,
        reset
    };
}
