import {AnimalApi, TratamentoDTO} from "../../dao";
import {useEffect, useRef, useState} from "react";
import ApiConfiguration from "../../infra/api/ApiConfiguration";
import {useParams} from "react-router-dom";
import useConsulta from "../../main-class/form/crud/useConsulta";

export interface FiltroTratamento {
    data?: Date;
    lote?: any
    sisbov?: string;
    bosch?: string;
    motivo?: number;
    valorMinimo?: string;
    valorMaximo?: string;
}

export default function useConsultaTratamento() {
    const animalApi = useRef(new AnimalApi(ApiConfiguration));
    const {animal: idAnimal, lote: idLote} = useParams();
    const [motivos , setMotivos] = useState([]) ;

    const {
        filtro,
        pagina,
        consulta,
        formControl,
        submitForm
    } = useConsulta<FiltroTratamento, TratamentoDTO>({
        consultaAction: (filtro, pagina, tamanhoPagina) => animalApi.current.consultaTratamentos(
            filtro.data,
            filtro.sisbov,
            filtro.bosch,
            filtro.motivo,
            filtro.lote,
            Number(idAnimal) || undefined,
            filtro.valorMinimo,
            filtro.valorMaximo,
            Number(idLote) || undefined,
            pagina,
            tamanhoPagina
        ),
    });

    useEffect(() => {
        animalApi.current.consultaMotivosTratamento().then(response => {
            setMotivos(response)
        })
    }, []);

    return {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        motivos
    };


}
