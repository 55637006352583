import CopyContent from "../../../main-class/componentes/copyContent/CopyContent";
import {Card} from "@mui/material";
import React from "react";
import useSumarioCliente from "./useSumarioCliente";
import useFormat from "../../../main-class/componentes/format/useFormat";
import {Outlet} from "react-router-dom";

export default function SumarioCliente() {
    const {cliente} = useSumarioCliente();
    const {formatTelefone} = useFormat();

    return (
        <>
            <Card>
                <h2 style={{margin: '0 0 10px'}}>{cliente.nome} | {cliente.codigo}</h2>
                <div>
                    {
                        cliente.telefone &&
                        <CopyContent content={cliente.telefone}>
                            {formatTelefone(cliente.telefone)}
                        </CopyContent>
                    }
                    {cliente.telefone && cliente.email && ' | '}
                    {
                        cliente.email &&
                        <CopyContent content={cliente.email}>
                            {cliente.email}
                        </CopyContent>
                    }
                </div>
            </Card>

            <Outlet/>
        </>
    );
}
