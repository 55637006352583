import {Box, DialogContent, DialogTitle, IconButton} from "@mui/material";
import React from "react";
import useDialogRef from "../../main-class/template/dialog/useDialogRef";
import {Close} from "@mui/icons-material";

export default function ModalVideoAjuda(props: { urlVideo: string, label: string }) {
    const {urlVideo, label} = props;
    const {dialogRef} = useDialogRef();

    const fecha = () => {
        dialogRef.close(false);
    };

    return (
        <>
            <DialogTitle style={{display: 'flex'}}>
                {label}
                <div style={{flex: '1 1 auto'}}></div>
                <IconButton onClick={fecha}>
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box style={{height: '80vh', width: '80vw', position: 'relative'}}>
                    <iframe src={urlVideo} title="Vídeo"
                            style={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                height: '80vh',
                            }}
                    ></iframe>
                </Box>
            </DialogContent>
        </>
    )
}
