import moment from "moment";

export interface NumericFormatOptions {
    significantDigits: number,
    thousandsSeparator: string,
    decimalSeparator: string,
}

export interface CurrencyFormatOptions extends NumericFormatOptions {
    symbol: string
}


export default function useFormat() {

    const formatCurrency = (value: string | number | undefined, options = BRL): string | undefined => {
        if (value === undefined) {
            return undefined;
        }

        const raw = Number(value).toFixed(options.significantDigits);

        const [currency, decimal] = raw.split('.')
        return `${options.symbol} ${currency.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            options.thousandsSeparator
        )}${options.decimalSeparator}${decimal}`;
    }

    const formatNumeric = (value: string | number | undefined, options = pt_BR): string | undefined => {
        if (value === undefined) {
            return undefined;
        }

        const raw = Number(value).toFixed(options.significantDigits);

        const [currency, decimal] = raw.split('.')
        return `${currency.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            options.thousandsSeparator
        )}${decimal ? options.decimalSeparator : ''}${decimal || ''}`;
    }

    function formatCPF(cpf?: string): string | undefined {
        if (cpf) {
            cpf = cpf.replace(/\D+/, '');
            if (cpf.length >= 11) {
                return cpf.substring(0, 3) + '.' +
                    cpf.substring(3, 6) + '.' +
                    cpf.substring(6, 9) + '-' +
                    cpf.substring(9, 11);
            } else if (cpf.length > 9) {
                return cpf.substring(0, 3) + '.' +
                    cpf.substring(3, 6) + '.' +
                    cpf.substring(6, 9) + '-' +
                    cpf.substring(9);
            } else if (cpf.length > 6) {
                return cpf.substring(0, 3) + '.' +
                    cpf.substring(3, 6) + '.' +
                    cpf.substring(6);
            } else if (cpf.length > 3) {
                return cpf.substring(0, 3) + '.' +
                    cpf.substring(3);
            }

            return cpf;
        }

        return undefined;
    }

    function formatCNPJ(cnpj?: string): string | undefined {
        if (cnpj) {
            cnpj = cnpj.replace(/\D+/, '');
            if (cnpj.length >= 14) {
                return cnpj.substring(0, 2) + '.' +
                    cnpj.substring(2, 5) + '.' +
                    cnpj.substring(5, 8) + '/' +
                    cnpj.substring(8, 12) + '-' +
                    cnpj.substring(12, 14);
            } else if (cnpj.length > 12) {
                return cnpj.substring(0, 2) + '.' +
                    cnpj.substring(2, 5) + '.' +
                    cnpj.substring(5, 8) + '/' +
                    cnpj.substring(8, 12) + '-' +
                    cnpj.substring(12);
            } else if (cnpj.length > 8) {
                return cnpj.substring(0, 2) + '.' +
                    cnpj.substring(2, 5) + '.' +
                    cnpj.substring(5, 8) + '/' +
                    cnpj.substring(8);
            } else if (cnpj.length > 5) {
                return cnpj.substring(0, 2) + '.' +
                    cnpj.substring(2, 5) + '.' +
                    cnpj.substring(5);
            } else if (cnpj.length > 2) {
                return cnpj.substring(0, 2) + '.' +
                    cnpj.substring(2);
            }

            return cnpj;
        }

        return undefined;
    }

    function formatCpfCnpj(cpfCnpj?: string): string | undefined {
        if (cpfCnpj) {
            cpfCnpj = cpfCnpj.replace(/\D+/, '');
            if (cpfCnpj.length > 11) {
                return formatCNPJ(cpfCnpj);
            }

            return formatCPF(cpfCnpj);
        }

        return undefined;
    }

    function formatDate(date: Date | undefined, format: string): string | undefined {
        if (date) {
            return moment(date).format(format);
        }

        return undefined;
    }

    function formatTelefone(telefone?: string): string | undefined {
        if (telefone) {
            telefone = telefone.replace(/^0|\D+/, '');

            if (telefone.length === 10) {
                return '(' + telefone.substring(0, 2) + ') ' +
                    telefone.substring(2, 6) + ' ' +
                    telefone.substring(6);
            } else if (telefone.length === 11) {
                return '(' + telefone.substring(0, 2) + ') ' +
                    telefone.substring(2, 7) + ' ' +
                    telefone.substring(7);
            }
        }

        return undefined;
    }

    function tempoLegivel(value?: number): string | undefined {
        if (value !== undefined && value !== null) {
            let millis = value;
            if (millis > 1000) {
                let secs = Math.floor(millis / 1000);
                millis -= secs * 1000;

                if (secs > 60) {
                    let mins = Math.floor(secs / 60);
                    secs -= mins * 60;

                    if (mins > 60) {
                        const hours = Math.floor(mins / 60);
                        mins -= hours * 60;

                        return `${hours}h ${mins}min ${secs}s ${millis}ms`;
                    }

                    return `${mins}min ${secs}s ${millis}ms`;
                }

                return `${secs}s ${millis}ms`;
            }

            return `${millis}ms`;
        }

        return undefined;
    }

    const pt_BR: NumericFormatOptions = {
        significantDigits: 0,
        thousandsSeparator: '.',
        decimalSeparator: ','
    };

    const BRL: CurrencyFormatOptions = {
        significantDigits: 2,
        thousandsSeparator: '.',
        decimalSeparator: ',',
        symbol: 'R$'
    };

    const en_US: NumericFormatOptions = {
        significantDigits: 0,
        thousandsSeparator: ',',
        decimalSeparator: '.',
    };

    const USD: CurrencyFormatOptions = {
        significantDigits: 2,
        thousandsSeparator: ',',
        decimalSeparator: '.',
        symbol: '$'
    };

    return {
        formatCPF,
        formatCNPJ,
        formatCpfCnpj,
        formatDate,
        formatTelefone,
        tempoLegivel,
        formatCurrency,
        formatNumeric,
        currencyFormats: {BRL, USD},
        numericFormats: {pt_BR, en_US},
    };
}
