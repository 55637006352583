import Header from "../header/Header";
import {Box, CssBaseline} from "@mui/material";
import Footer from "../footer/Footer";
import * as React from "react";
import {createContext, useContext} from "react";
import Menu from "../menu/Menu";

const drawerWidth = 240;

export const TemplateConfigContext = createContext<TemplateConfig>({});

export interface TemplateConfig {
    logoSrc?: string;
    logoComponent?: React.ReactNode;
    headerActions?: React.ReactNode;
    menuBottom?: React.ReactNode;
    direitos?: string;
}

export default function Base(props: { children: React.ReactNode }) {
    const {children} = props;
    const config = useContext(TemplateConfigContext);

    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <CssBaseline/>
                <Box component="header">
                    <Header logoSrc={config.logoSrc}
                            logoComponent={config.logoComponent}
                            headerActions={config.headerActions}/>
                </Box>
                <Box sx={{display: 'flex', flex: "1 1 auto"}}>
                    <Box
                        component="nav"
                        sx={{width: {sm: drawerWidth}}}>
                        <Menu menuBottom={config.menuBottom}/>
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            p: 3,
                            width: {sm: `calc(100% - ${drawerWidth}px)`},
                            padding: 0,
                            paddingLeft: '30px',
                            paddingTop: '25px'
                        }}>
                        {children}
                    </Box>
                </Box>
                <Box component="footer">
                    <Footer direitos={config.direitos}/>
                </Box>
            </Box>
        </>
    );
}
