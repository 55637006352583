import buildTabRoute from "../../main-class/template/route/buildTabRoute";
import RedefinirSenha from "./RedefinirSenha";

export default function redefinirSenhaRoute() {
    return buildTabRoute({
        path: "/redefinir-senha",
        title: "Redefinir senha",
        subroutes: [
            {
                path: "",
                element: <RedefinirSenha />,
            },
        ]
    });
}

