import React from "react";
import useSessao from "../../main-class/componentes/sessao/useSessao";
import useDialog from "../../main-class/template/dialog/useDialog";
import ModelAjuda from "../../componentes/modalAjuda/ModelAjuda";
import {useNavigate} from "react-router-dom";

export default function useHeaderActions() {
    const [anchorOptionsMenu, setAnchorOptionsMenu] = React.useState<null | HTMLElement>(null);
    const optionsOpen = Boolean(anchorOptionsMenu);
    const {loggedOut} = useSessao();
    const {open} = useDialog();
    const  navigate = useNavigate();

    const onOpenOptionsUsuario = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorOptionsMenu(event.currentTarget);
    };

    const onCloseOptionsUsuario = () => {
        setAnchorOptionsMenu(null);
    };

    const onAlterarSenha = () => {
        navigate("/redefinir-senha")
    };

    const onSair = () => {
        loggedOut();
    };

    const onAjuda = () => {
        open(<ModelAjuda />, {
            maxWidth: 'xl'
        });
    };

    return {optionsOpen, anchorOptionsMenu, onOpenOptionsUsuario, onCloseOptionsUsuario, onAlterarSenha, onSair, onAjuda};
}
