import {Validate} from "react-hook-form";
import {lastValueFrom, Subject} from "rxjs";
import useLabels from "../../../i18n/useLabels";

const EMAIL_REGEXP = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+(\.[a-zA-Z0-9_.-]+)+$/;

export default function useValidation() {
    const {ptBR: {validation}} = useLabels();

    function validate<T>(...validations: Array<Validate<T>>): Validate<T> {
        return (value: any) => {
            for (let validation of validations) {
                const result = validation(value);

                if (!!result) {
                    return result
                }
            }

            return null;
        };
    }

    function string(rules: { trim?: boolean, blankCharacteres?: boolean, specialCharacteres?: boolean }) {
        return (value: string) => {
            if (!value) return;

            if (rules.trim && (value.startsWith(" ") || value.endsWith(" "))) {
                return validation.caracteresVaziosInicioFim;
            }

            if (rules.blankCharacteres && (value.indexOf(" ") >= 0)) {
                return validation.caracteresVazios;
            }

            if (rules.specialCharacteres && (value.match(/[^ 0-9A-Za-z]/g))) {
                return validation.caracteresEspeciais;
            }
        };
    }

    function email() {
        return (email: string) => {
            if (!email) return null;

            if (!EMAIL_REGEXP.test(email)) {
                return validation.email;
            }

            return null;
        }
    }

    function required(): Validate<string | Array<any> | any> {
        return (value) => {
            if (value === undefined || value === null) {
                return validation.required;
            }

            if (typeof value === 'string' && !value.length) {
                return validation.required;
            }

            if (Array.isArray(value) && !value.length) {
                return validation.required;
            }

            return null;
        }
    }

    function length(op: { max?: number, min?: number }): Validate<string> {
        return (value) => {
            if (value) {
                if (op.min !== undefined && value.length < op.min) {
                    return validation.minlength.replace('{{requiredLength}}', String(op.min));
                }

                if (op.max !== undefined && value.length > op.max) {
                    return validation.maxlength.replace('{{requiredLength}}', String(op.max));
                }
            }

            return null;
        }
    }

    function minMax(op: { max?: number, min?: number }): Validate<number | string> {
        return (value) => {
            if (value !== undefined && value !== null) {
                if (op.min !== undefined && value < op.min) {
                    return validation.valorMin.replace('{{valor}}', String(op.min));
                }

                if (op.max !== undefined && value > op.max) {
                    return validation.valorMax.replace('{{valor}}', String(op.max));
                }
            }

            return null;
        }
    }

    function cpf() {
        return (cpf: string) => {
            if (!cpf) return null;

            if ((cpf.length === 11) && !isRepeatedNumber(cpf) &&
                (Number(cpf.charAt(9)) === calculateDvMod11(cpf, 9, 2, 11)) &&
                (Number(cpf.charAt(10)) === calculateDvMod11(cpf, 10, 2, 11))) {
                return null;
            }

            return validation.cpf;
        }
    }

    function cpfCnpj() {
        return (cpfCnpj: string) => {
            if (!cpfCnpj) return null;

            const cpfValidation = cpf()(cpfCnpj);
            if (!cpfValidation) {
                return null;
            }

            const cnpjValidation = cnpj()(cpfCnpj);
            if (!cnpjValidation) {
                return null;
            }

            return validation.cpfCnpj;
        };
    }

    function cnpj() {
        return (cgc: string) => {
            if (!cpfCnpj) return null;

            let filial = Number(cgc.substring(9, 12));

            if ((cgc.length === 14) && (!isRepeatedNumber(cgc)) &&
                (Number(cgc.charAt(12)) === calculateDvMod11(cgc, 12, 2, 9)) &&
                (Number(cgc.charAt(13)) === calculateDvMod11(cgc, 13, 2, 9)) &&
                (filial > 0) && (filial < 7100)) {
                return null;
            }

            return validation.cnpj;
        }
    }

    function isRepeatedNumber(cadeia: string): boolean {
        let ind = 1;
        let ch = cadeia.charAt(0);

        while (ind < cadeia.length) {
            if (ch === cadeia.charAt(ind)) {
                ind++;
            } else {
                return false;
            }
        }

        return true;
    }

    function calculateDvMod11(cadeia: string, tam: number, fatorInicial: number, fatorFinal: number): number {
        let fator = fatorInicial;
        let total = 0;

        for (let ind = 0; ind < tam; ind++) {
            total += Number(cadeia.charAt(tam - ind - 1)) * fator;
            fator++;

            if (fator > fatorFinal) {
                fator = fatorInicial;
            }
        }

        total %= 11;

        if (total < 2) {
            return 0;
        }

        return (11 - total);
    }

    function doAsync(execution: (value: any) => Promise<string>) {
        let asyncTimeout: any;
        return (value: string) => {
            clearTimeout(asyncTimeout);

            let validation = new Subject<string>();

            asyncTimeout = setTimeout(() => {
                execution(value)
                    .then(result => validation.next(result))
                    .catch(err => validation.error(err))
                    .finally(() => validation.complete());
            }, 300);

            return lastValueFrom(validation)
        }
    }


    return {validate, required, length, cpf, cnpj, cpfCnpj, email, string, doAsync, minMax};
}
