import React from "react";
import FiltroConsultaPendencia from "../componentes/FiltroConsultaPendencia";
import ListagemPendencias from "../componentes/ListagemPendencias";
import {BehaviorSubject} from "rxjs";
import {FiltroContext, FaixasPendencia, FiltroPendencia} from "./filtroContext";


export default function ConsultaBoiGordo() {

    const value = {
        filtro: new BehaviorSubject<FiltroPendencia | null>({})
    }

    return (
        <>

            <FiltroContext.Provider value={value}>
                {
                    <>
                        <FiltroConsultaPendencia/>
                        <ListagemPendencias faixa={FaixasPendencia.DE_95_DIAS_EM_DIANTE}/>
                        <ListagemPendencias faixa={FaixasPendencia.DE_75_A_94_DIAS}/>
                        <ListagemPendencias faixa={FaixasPendencia.DE_60_A_74_DIAS}/>
                        <ListagemPendencias faixa={FaixasPendencia.ATE_59_DIAS}/>
                    </>
                }
            </FiltroContext.Provider>
        </>
    );
}

