import {LoteApi, LoteDTO} from "../../../../../dao";
import {useContext, useRef} from "react";
import ApiConfiguration from "../../../../../infra/api/ApiConfiguration";
import useEdicao from "../../../../../main-class/form/crud/useEdicao";
import LoteContext from "../../../loteContext";
import {lastValueFrom} from "rxjs";
import useDialog from "../../../../../main-class/template/dialog/useDialog";

export default function useEdicaoMidiasLote() {
    const {lote, reload: reloadLote} = useContext(LoteContext);
    const loteApi = useRef(new LoteApi(ApiConfiguration));
    const {confirmacao} = useDialog();
    const {entity, submitForm, formControl, reset} = useEdicao({
        idParam: 'lote',
        findAction: id => loteApi.current.consultaMidiasDesembarque(id),
        preSave: async (entity) => {
            if (lote.status === LoteDTO.StatusEnum.Pendente) {
                return await lastValueFrom(confirmacao('O cliente será notificado no seu dispositivo móvel. Confirma essa ação?').afterClosed())
            }

            return true;
        },
        saveAction: (entity, id) => loteApi.current.salvaMidiasDesembarque(id, entity),
        postSave: (entity) => {
            reloadLote();
        },
        mensagemSucesso: 'Mídias salvas com sucesso.',
    });

    return {entity, submitForm, formControl, reset};
}
