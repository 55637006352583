import React, {useRef} from "react";
import {AnimalApi, TratamentoDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import {Card} from "@mui/material";
import PaginatedTable from "../../../main-class/componentes/table/PaginatedTable";
import TableColumn from "../../../main-class/componentes/table/TableColumn";
import ColumnHeader from "../../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../../main-class/componentes/table/ColumnCell";
import {useParams} from "react-router-dom";
import useFormat from "../../../main-class/componentes/format/useFormat";
import useConsulta from "../../../main-class/form/crud/useConsulta";
import {FiltroTratamento} from "../../tratamento/useConsultaTratamento";

export default function ConsultaTratamentosAnimal() {
    const animalApi = useRef(new AnimalApi(ApiConfiguration));
    const {animal: idAnimal} = useParams();
    const {pagina, consulta} = useConsulta<FiltroTratamento, TratamentoDTO>({
        consultaAction: async filtro => await animalApi.current.consultaTratamentos(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            Number(idAnimal),
            undefined,
            undefined,
            undefined,
            1,
            10
        ),
    });
    const {formatDate, formatCurrency} = useFormat();

    return (
        <>
            <Card>
                <h2>Histórico de tratamentos</h2>
                <PaginatedTable onPageChange={(pagina, tamanhoPagina) => consulta({}, {pagina, tamanhoPagina})}
                                pagina={pagina}>
                    <TableColumn name="data" style={{textAlign: 'center'}}>
                        <ColumnHeader>Data </ColumnHeader>
                        <ColumnCell
                            content={(item: TratamentoDTO) => formatDate(item.data as any, 'DD/MM/YYYY')}/>
                    </TableColumn>
                    <TableColumn name="motivo" style={{textAlign: 'center'}}>
                        <ColumnHeader>Motivo do tratamento</ColumnHeader>
                        <ColumnCell
                            content={(item: TratamentoDTO) => item.motivo.nome}/>
                    </TableColumn>
                    <TableColumn name="valor" style={{textAlign: 'right'}}>
                        <ColumnHeader>Valor do protocolo</ColumnHeader>
                        <ColumnCell
                            content={(item: TratamentoDTO) => formatCurrency(item.valorProtocolo)}/>
                    </TableColumn>

                </PaginatedTable>
            </Card>
        </>
    );
}
