import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import useConsulta from "../../../main-class/form/crud/useConsulta";
import {ClienteApi, ClienteDTO, LoteApi, LoteDTO} from "../../../dao";
import {useCallback, useContext, useEffect, useRef} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import SincronizacaoContext from "../../../infra/sincronizacao/SincronizacaoContext";


export interface FiltroLote {
    codigo?: string;
    data?: Date;
    etapa?: LoteDTO.EtapaEnum;
    status?: LoteDTO.StatusEnum;
    proprietario?: ClienteDTO;
}

export default function useConsultaLote(filtroInicial?: FiltroLote) {
    const loteApi = useRef(new LoteApi(ApiConfiguration));
    const clienteApi = useRef(new ClienteApi(ApiConfiguration));
    const {cliente, etapa} = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const sincornizacaoState = useContext(SincronizacaoContext);

    const status = Object.values(LoteDTO.StatusEnum);
    const etapas = Object.values(LoteDTO.EtapaEnum);

    const {
        filtro,
        pagina,
        consulta,
        formControl,
        submitForm
    } = useConsulta<FiltroLote, LoteDTO>({
        filtroInicial: {
            ...(filtroInicial || {}),
            status: (searchParams.get('status') || filtroInicial.status) as LoteDTO.StatusEnum,
        },
        consultaAction: (filtro, pagina, tamanhoPagina) => loteApi.current.consulta(
            filtro.codigo,
            filtro.data,
            etapa as any || filtro.etapa,
            filtro.status,
            cliente as any || filtro.proprietario?.id,
            pagina,
            tamanhoPagina
        ),
    });

    useEffect(() => {
        const subscription = sincornizacaoState.loteObservable
            .subscribe(() => consulta())

        return () => subscription.unsubscribe();
    }, [sincornizacaoState, consulta]);

    const filtraProprietarios = useCallback(async (filtro: string) => {
        const pagina = await clienteApi.current.consulta(undefined, filtro, undefined, undefined, 1, 5)

        return pagina.itens || [];
    }, [clienteApi]);

    const onDetalhe = useCallback((lote: LoteDTO) => {
        if(etapa === LoteDTO.EtapaEnum.ProcessamentoEntrada){
            navigate(`/lote/${lote.id}/etapa/processamento_entrada`);
        } else {
            navigate(`/lote/${lote.id}`);
        }
    }, [navigate,etapa])

    const navigateLaudo = useCallback((lote: LoteDTO) => {
        navigate(`/laudo-entrada/${lote.id}`);
    }, [navigate])

    return {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        status,
        etapas,
        showProprietario: !cliente,
        showAdicionarLaudo: filtroInicial?.etapa === LoteDTO.EtapaEnum.Desembarque,
        showProcessamentoEntrada: etapa === LoteDTO.EtapaEnum.ProcessamentoEntrada,
        filtraProprietarios,
        onDetalhe,
        navigateLaudo
    };


}
