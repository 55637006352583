import useConsultaDenuncia from "./useConsultaDenuncia";
import {Box, Card, MenuItem} from "@mui/material";
import {MoreVert, Search} from "@mui/icons-material";
import DateFormField from "../../../main-class/form/input/DateFormField";
import PaginatedTable from "../../../main-class/componentes/table/PaginatedTable";
import TableColumn from "../../../main-class/componentes/table/TableColumn";
import ColumnHeader from "../../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../../main-class/componentes/table/ColumnCell";
import {DenunciaDTO} from "../../../dao";
import useFormat from "../../../main-class/componentes/format/useFormat";
import AutocompleteFormField from "../../../main-class/form/input/AutocompleteFormField";
import useLabels from "../../../i18n/useLabels";
import IconOptions from "../../../componentes/iconOptions/IconOptions";
import SelectFormField from "../../../main-class/form/input/SelectFormField";

export default function ConsultaDenuncia(){
    const{
        pagina,
        consulta,
        filtro,
        formControlConsulta,
        submitFormConsulta,
        filtraUsuarios,
        aprova,
        rejeita,
        status,
        motivo
    } = useConsultaDenuncia();
    const {formatDate} = useFormat();

    const {ptBR} = useLabels();
    

    return(
        <>
            <Card>
                <h2><Search/> Filtrar</h2>

                <form onSubmit={submitFormConsulta}>
                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{flex: '1'}}>
                            <DateFormField label="Data" value={filtro.data}
                                            control={formControlConsulta}
                                            name="data"
                                            onChange={submitFormConsulta}
                            />
                        </Box>

                        <Box sx={{flex: '1'}}>
                            <AutocompleteFormField
                                    name="responsavel" label="Responsavel"
                                    value={filtro.responsavel} control={formControlConsulta}
                                    onChange={submitFormConsulta} getOptionLabel={responsavel => responsavel.nome}
                                    getOptions={filtraUsuarios} compareWith={(v1, v2) => v1.id === v2.id}
                                />
                        </Box>

                        <Box sx={{flex: '1'}}>
                            <SelectFormField name="status" label="Status"
                                             value={filtro.status} control={formControlConsulta}
                                             onChange={submitFormConsulta}>
                                <MenuItem value={null}/>
                                {
                                    status.map(sts =>
                                        <MenuItem key={"MenuItem_Status_" + sts} value={sts}>
                                            {ptBR.enum.status_denuncia[sts]}
                                        </MenuItem>
                                    )
                                }
                            </SelectFormField>
                        </Box>

                        <Box sx={{flex: '1'}}>
                            <SelectFormField name="motivo" label="Motivo"
                                             value={filtro.motivo} control={formControlConsulta}
                                             onChange={submitFormConsulta}>
                                <MenuItem value={null}/>
                                {
                                    motivo.map(sts =>
                                        <MenuItem key={"MenuItem_motivo_" + sts} value={sts}>
                                            {ptBR.enum.motivo_denuncia[sts]}
                                        </MenuItem>
                                    )
                                }
                            </SelectFormField>
                        </Box>
                    </Box>
                </form>
            </Card>
            
            <Card>
                <PaginatedTable onPageChange={(pagina, tamanhoPagina) => consulta(filtro, {pagina, tamanhoPagina})}
                            pagina={pagina}>
                    
                    <TableColumn name="noticia" >
                        <ColumnHeader>Notícia</ColumnHeader>
                        <ColumnCell content={(item: DenunciaDTO) => item.comentario.noticia?.titulo}/>
                    </TableColumn>

                    <TableColumn name="responsavel" >
                        <ColumnHeader>Responsável</ColumnHeader>
                        <ColumnCell content={(item: DenunciaDTO) => item.denunciante.nome}/>
                    </TableColumn>

                    <TableColumn name="comentario" >
                        <ColumnHeader>Comentário</ColumnHeader>
                        <ColumnCell content={(item: DenunciaDTO) => item.comentario.comentario}/>
                    </TableColumn>

                    <TableColumn name="data" style={{textAlign: 'center'}}>
                        <ColumnHeader>Data</ColumnHeader>
                        <ColumnCell content={(item: DenunciaDTO) => formatDate(item.data as any, 'DD/MM/YYYY | HH:mm:ss')}/>
                    </TableColumn>

                    <TableColumn name="motivo" style={{textAlign: 'center'}}>
                        <ColumnHeader>Motivo</ColumnHeader>
                        <ColumnCell content={(item: DenunciaDTO) => ptBR.enum.motivo_denuncia[item.motivo]}/>
                    </TableColumn>

                     <TableColumn name="status" style={{textAlign: 'center'}}>
                        <ColumnHeader>Status</ColumnHeader>
                        <ColumnCell content={(item: DenunciaDTO) => ptBR.enum.status_denuncia[item.status]}/>
                    </TableColumn>

                    <TableColumn name="opcoes" style={{textAlign: 'right'}}>
                        <ColumnHeader/>
                        <ColumnCell content={
                            (item: DenunciaDTO) => <>
                                {
                                    <IconOptions icon={<MoreVert/>}>
                                        <MenuItem onClick={() => aprova(item)}>Aprovar</MenuItem>
                                        <MenuItem onClick={() => rejeita(item)}>Rejeitar</MenuItem>
                                    </IconOptions>
                                }
                            </>
                        }/>
                    </TableColumn>

                    
                </PaginatedTable>
            </Card>
        </>
    )
}
