import ConsultaLote from "./consulta/ConsultaLote";
import DetalheLote from "./detalhe/DetalheLote";
import SumarioLote from "./sumario/SumarioLote";
import buildTabRoute from "../../main-class/template/route/buildTabRoute";
import MidiasLote from "./midia/MidiasLote";
import ConsultaMovimentacao from "../movimentacao/ConsultaMovimentacao";
import ConsultaTratamento from "../tratamento/ConsultaTratamento";
import SumarioAnimal from "../animal/sumario/SumarioAnimal";
import CadastroLaudoMorte from "../laudoMorte/cadastro/CadastroLaudoMorte";
import CadastroLaudoEntrada from "../laudoEntrada/cadastro/CadastroLaudoEntrada";
import {LoteDTO} from "../../dao";

export default function loteRoute() {
    return buildTabRoute({
        path: "/lote",
        title: "Lotes",
        subroutes: [
            {
                path: "",
                element: <ConsultaLote/>,
            },
            {
                path: ":lote",
                title: "Detalhar",
                element: <SumarioLote/>,
                subroutes: [
                    {
                        path: "",
                        element: <DetalheLote/>,
                    },
                    {
                        path: "etapa/:etapa",
                        element: <DetalheLote/>,
                        title: (params: any) => {
                            const {etapa} = params;
                            if (etapa === LoteDTO.EtapaEnum.ProcessamentoEntrada) {
                                return "Processamento de entrada";
                            }

                            return "Etapa";
                        }
                    },
                    {
                        path: "midia-desembarque",
                        title: "Desembarque",
                        element: <MidiasLote/>,
                    },
                    {
                        path: "laudo-entrada",
                        title: "Laudo de entrada",
                        element: <CadastroLaudoEntrada/>,
                    },
                    {
                        path: "movimentacao",
                        title: "Movimentações",
                        element: <ConsultaMovimentacao/>,
                    },
                    {
                        path: "tratamento",
                        title: "Ronda Sanitária",
                        element: <ConsultaTratamento/>,
                    },
                    {
                        path: "animal/:animal",
                        element: <SumarioAnimal/>,
                        subroutes: [
                            {
                                path: "laudo-morte",
                                title: "+ Adicionar",
                                element: <CadastroLaudoMorte/>,
                            }
                        ]
                    }
                ]
            }
        ]
    })
}


