import useDetalheLote from "./useDetalheLote";
import {Box, Button, Card} from "@mui/material";
import {AssignmentTurnedIn, LocalShipping, OpenInBrowser, SystemUpdateAlt} from "@mui/icons-material";
import "./DetalheLote.scss";
import {LoteDTO} from "../../../dao";
import ConsultaAnimal from "../../animal/consulta/ConsultaAnimal";

export default function DetalheLote() {
    const {
        onDetalhe,
        etapa,
        onLaudoEntrada,
        onProcessamentoEntrada,
        onProcessamentoSaida,
        lote,
        onRondaSanitaria,
        onMovimentacao
    } = useDetalheLote();

    const disableLaudoEntrada = lote.etapa === LoteDTO.EtapaEnum.Desembarque;
    const disableMovimentacoes = lote.etapa === LoteDTO.EtapaEnum.Desembarque;
    const disableRondaSanitaria = lote.etapa === LoteDTO.EtapaEnum.Desembarque;
    const disableProcessamentoEntrada = lote.etapa === LoteDTO.EtapaEnum.Desembarque;

    return (
        <>
            {
                etapa !== LoteDTO.EtapaEnum.ProcessamentoEntrada &&
                <Box className="opcoes-lote" sx={{display: 'flex', gap: '20px', marginBottom: '20px'}}>
                    <Button onClick={onDetalhe} type="button">
                        <LocalShipping/>
                        <h3>Desembarque</h3>
                    </Button>
                    <Button onClick={onLaudoEntrada} type="button"
                            disabled={disableLaudoEntrada}>
                        <AssignmentTurnedIn/>
                        <h3>Laudo de entrada</h3>
                    </Button>
                    <Button onClick={onMovimentacao} type="button"
                            disabled={disableMovimentacoes}>
                        <AssignmentTurnedIn/>
                        <h3>Movimentações</h3>
                    </Button>
                    <Button onClick={onRondaSanitaria} type="button"
                            disabled={disableRondaSanitaria}>
                        <AssignmentTurnedIn/>
                        <h3>Ronda Sanitária</h3>
                    </Button>
                    <Button onClick={onProcessamentoEntrada} type="button"
                            disabled={disableProcessamentoEntrada}>
                        <SystemUpdateAlt/>
                        <h3>P. Entrada</h3>
                    </Button>

                    <Button onClick={onProcessamentoSaida} type="button">
                        <OpenInBrowser/>
                        <h3>P. Saida</h3>
                    </Button>
                </Box>
            }

            {
                etapa === LoteDTO.EtapaEnum.ProcessamentoEntrada &&
                <Card>
                    <Box className="opcoes-lote" sx={{display: 'flex', gap: '20px'}}>
                        <h2>Animais Processados: {lote.qtdAnimaisProcessados}</h2>
                        <h2>Animais Restantes: {lote.qtdAnimais - lote.qtdAnimaisProcessados}</h2>
                    </Box>
                </Card>
            }


            <ConsultaAnimal etapa={etapa}/>

        </>

    );
}
