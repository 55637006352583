import React, {createContext, useContext, useEffect, useState} from "react";
import {DialogContext, InternalDialogRef} from "./DialogContainer";
import {Dialog} from "@mui/material";

export const DialogRefContext = createContext(null);

export default function DialogListener() {
    const manager = useContext(DialogContext);
    const [dialogs, setDialogs] = useState<Array<InternalDialogRef<any>>>([]);

    useEffect(() => {
        const subscription = manager.observable().subscribe(setDialogs);

        return () => subscription.unsubscribe();
    }, [manager]);

    return (
        <>
            {
                dialogs.map(dialogRef =>
                    <DialogRefContext.Provider key={dialogRef.id} value={dialogRef}>
                        <Dialog key={'dialog_' + dialogRef.id}
                                fullScreen={dialogRef.config.fullScreen}
                                fullWidth={dialogRef.config.fullWidth}
                                maxWidth={dialogRef.config.maxWidth}
                                onClose={() => dialogRef.close(false)} open={true}>
                            {dialogRef.element}
                        </Dialog>
                    </DialogRefContext.Provider>
                )
            }
        </>
    );
}
