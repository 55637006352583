import Card from "@mui/material/Card";
import useFormat from "../../../main-class/componentes/format/useFormat";
import ColumnCell from "../../../main-class/componentes/table/ColumnCell";
import ColumnHeader from "../../../main-class/componentes/table/ColumnHeader";
import TableColumn from "../../../main-class/componentes/table/TableColumn";
import useDetalhaProcessamentoSaida from "./useDetalheProcessamentoSaida";
import {EmbarqueDTO, ProcessamentoSaidaDTO} from "../../../dao";
import MultipleArquivosFormField from "../../../main-class/form/input/MultipleArquivosFormField";
import useValidation from "../../../main-class/form/validation/useValidation";
import SumarioCliente from "../../cliente/sumario/SumarioCliente";
import TextAreaFormField from "../../../main-class/form/input/TextAreaFormField";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import ArrayTable from "../../../main-class/componentes/table/ArrayTable";
import {EditGtas} from "./components/EditGtas";
import {EditNotasFiscais} from "./components/EditNotasFiscais";


export default function DetalhaProcessamentoSaida() {
    const {
        entity,
        formControl,
        submitForm,
        cancela,
        envia,
    } = useDetalhaProcessamentoSaida();

    const {formatDate, formatNumeric} = useFormat();
    const {validate, required, length} = useValidation();

    const commands = <>

        <div className="acoes">
            <Button variant="contained" type="button" color="primary"
                    onClick={() => envia()}
            >Enviar</Button>
            <Button variant="outlined" type="submit" color="secondary">Salvar</Button>
            <Button variant="text" type="button"
                    onClick={() => cancela()}
                    color="inherit">Cancelar</Button>
        </div>

    </>;

    return (
        <>
            <SumarioCliente></SumarioCliente>

            <Card>
                <h2>Contrato {entity.codigo}</h2>
                <ArrayTable items={entity.embarques}>

                    <TableColumn name="codigo">
                        <ColumnHeader>Código</ColumnHeader>
                        <ColumnCell content={(item: EmbarqueDTO) => item.codigo}/>
                    </TableColumn>

                    <TableColumn name="carreta">
                        <ColumnHeader>Carreta</ColumnHeader>
                        <ColumnCell content={(item: EmbarqueDTO) => item.placaCarreta}/>
                    </TableColumn>

                    <TableColumn name="datahora" style={{textAlign: 'center'}}>
                        <ColumnHeader>Data/hora</ColumnHeader>
                        <ColumnCell
                            content={(item: EmbarqueDTO) => formatDate(item.dataHora, 'DD/MM/YYYY | HH:mm:ss')}/>
                    </TableColumn>

                    <TableColumn name="animais" style={{textAlign: 'center'}}>
                        <ColumnHeader>Animais</ColumnHeader>
                        <ColumnCell content={(item: EmbarqueDTO) => item.qtdAnimais}/>
                    </TableColumn>

                    <TableColumn name="pesototal" style={{textAlign: 'center'}}>
                        <ColumnHeader>Peso Total</ColumnHeader>
                        <ColumnCell
                            content={(item: EmbarqueDTO) => item.pesoTotal ? (formatNumeric(item.pesoTotal) + 'Kg') : ''}/>
                    </TableColumn>

                    <TableColumn name="pesotara" style={{textAlign: 'center'}}>
                        <ColumnHeader>Peso Tara</ColumnHeader>
                        <ColumnCell content={(item: EmbarqueDTO) => formatNumeric(item.pesoTara) + 'Kg'}/>
                    </TableColumn>

                    <TableColumn name="pesoliquido" style={{textAlign: 'center'}}>
                        <ColumnHeader>Peso Liquido</ColumnHeader>
                        <ColumnCell
                            content={(item: EmbarqueDTO) => item.pesoLiquido ? (formatNumeric(item.pesoLiquido) + 'Kg') : ''}/>
                    </TableColumn>

                    <TableColumn name="gta" style={{textAlign: 'center'}}>
                        <ColumnHeader>GTA</ColumnHeader>
                        <ColumnCell content={
                            (item: EmbarqueDTO) =>
                                <EditGtas embarque={item}/>
                        }/>
                    </TableColumn>

                    <TableColumn name="notafiscal" style={{textAlign: 'center'}}>
                        <ColumnHeader>NF</ColumnHeader>
                        <ColumnCell content={(item: EmbarqueDTO) =>
                            <EditNotasFiscais embarque={item}/>
                        }/>
                    </TableColumn>
                </ArrayTable>
            </Card>

            <Card>
                <h2>Dados de pesagem</h2>
                <Box sx={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                    <Box sx={{display: 'flex', gap: '20px', alignItems: 'center', flex: 1}}>
                        {
                            entity.pesagem?.quantidade &&
                            <Box sx={{flex: 1}}>
                                <p style={{margin: '0 0 10px'}}>Quantidade</p>
                                <h2 style={{margin: 0}}>{entity.pesagem?.quantidade}</h2>
                            </Box>
                        }
                        {
                            entity.pesagem?.pesoTotal &&
                            <Box sx={{flex: 1}}>
                                <p style={{margin: '0 0 10px'}}>Peso total</p>
                                <h2 style={{margin: 0}}>{formatNumeric(entity.pesagem?.pesoTotal)}Kg</h2>
                            </Box>
                        }
                        {
                            entity.pesagem?.pesoMedio &&
                            <Box sx={{flex: 1}}>
                                <p style={{margin: '0 0 10px'}}>Peso médio</p>
                                <h2 style={{margin: 0}}>
                                    {
                                        !!entity.data ?
                                            <>{formatNumeric(entity.pesagem?.pesoMedio)}Kg</> :
                                            <>-</>
                                    }
                                </h2>
                            </Box>
                        }
                        {
                            entity.pesagem?.pesoLiquido &&
                            <Box sx={{flex: 1}}>
                                <p style={{margin: '0 0 10px'}}>Peso líquido</p>
                                <h2 style={{margin: 0}}>
                                    {
                                        !!entity.data ?
                                            <>{formatNumeric(entity.pesagem?.pesoLiquido)}Kg</> :
                                            <>-</>
                                    }
                                </h2>
                            </Box>
                        }
                        <Box sx={{flex: 1}}>
                            <p style={{margin: '0 0 10px'}}>Peso tara</p>
                            <h2 style={{margin: 0}}>
                                {
                                    !!entity.data ?
                                        <>{formatNumeric(entity.pesagem?.pesoTara)}Kg</> :
                                        <>-</>
                                }
                            </h2>
                        </Box>
                    </Box>
                    <Box sx={{flex: '1 1 auto', textAlign: 'center'}}/>
                </Box>
            </Card>

            <form onSubmit={submitForm}>
                <Card>
                    <h2>Obeservação:</h2>
                    <TextAreaFormField name="observacao" label="Observações"
                                       validate={validate(length({max: 280}))}
                                       value={entity.observacao} control={formControl}/>
                    <h2>Mídias:</h2>
                    <MultipleArquivosFormField
                        name="midias" accept={[".mp4", ".png", ".jpeg", ".jpg"]}
                        control={formControl as any}
                    />
                </Card>

                <Card>
                    <h2>Anexar relatório de pesagem:</h2>
                    <MultipleArquivosFormField label="Relatórios de pesagem"
                                               name="relatorioPesagem" accept={".pdf"}
                                               control={formControl} value={entity.relatoriosPesagem}
                                               validate={validate(required())}/>

                    {
                        (entity.status === ProcessamentoSaidaDTO.StatusEnum.Pendente ||
                            entity.status === ProcessamentoSaidaDTO.StatusEnum.Iniciado)
                        &&
                        commands
                    }
                </Card>

                {
                    (entity.status === ProcessamentoSaidaDTO.StatusEnum.AguardandoFinanceiro ||
                        entity.status === ProcessamentoSaidaDTO.StatusEnum.Encerrado) &&
                    <Card>
                        <h2>Anexar relatório financeiros:</h2>
                        <Box sx={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                            <Box sx={{flex: 1}}>
                                <MultipleArquivosFormField label="Resumo do lote" name="relatoriosLote"
                                                           control={formControl} value={entity.relatoriosLote}
                                                           accept={".pdf"}
                                                           validate={
                                                               validate(
                                                                   required()
                                                               )
                                                           }/>
                            </Box>
                            <Box sx={{flex: 1}}>
                                <MultipleArquivosFormField label="Relatório individual" name="relatoriosIndividual"
                                                           control={formControl} value={entity.relatoriosIndividual}
                                                           accept={".pdf"}
                                                           validate={
                                                               validate(
                                                                   required()
                                                               )
                                                           }/>
                            </Box>
                            <Box sx={{flex: 1}}>
                                <MultipleArquivosFormField label="Frigorífico" name="relatoriosFrigorifico"
                                                           control={formControl} value={entity.relatoriosFrigorifico}
                                                           accept={".pdf"}/>
                            </Box>
                        </Box>

                        {commands}
                    </Card>
                }


            </form>
        </>
    );
}
