import CopyContent from "../../../main-class/componentes/copyContent/CopyContent";
import {Box, Button, Card} from "@mui/material";
import React, {useCallback, useEffect, useRef, useState} from "react";
import useFormat from "../../../main-class/componentes/format/useFormat";

import {PendenciaApi, QuantidadeAnimaisClienteFaixaDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import useLoader from "../../../main-class/componentes/loader/useLoader";
import {useNavigate} from "react-router-dom";

export default function SumarioCLienteQtdAnimais(props: { cliente: string | number }) {
    const {cliente: idCliente} = props;
    const {formatTelefone} = useFormat();

    const pendenciaApi = useRef(new PendenciaApi(ApiConfiguration));
    const [quantidades, setQuantidades] = useState<QuantidadeAnimaisClienteFaixaDTO>({});
    const {loadingAround} = useLoader();
    const navigate = useNavigate();

    const navigateGrafico = useCallback(() => {
        navigate(`/boigordo/cliente/${idCliente}/grafico`);
    }, [navigate, idCliente]);

    const navigateHistorico = useCallback(() => {
        navigate(`/boigordo/cliente/${idCliente}`);
    }, [navigate, idCliente]);

    useEffect(() => {
        loadingAround(pendenciaApi.current.consultaQuantidadesFaixa(Number(idCliente))).then(setQuantidades);
    }, [idCliente, setQuantidades, loadingAround, pendenciaApi]);

    const {
        cliente,
        quantidadeAnimais60a74DiasFemea,
        quantidadeAnimais60a74DiasMacho,
        quantidadeAnimais75a94DiasFemea,
        quantidadeAnimais75a94DiasMacho,
        quantidadeAnimais95DiasFemea,
        quantidadeAnimais95DiasMacho
    } = quantidades;

    return (
        <>
            <Card>
                <Box sx={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                    <Box sx={{flex: 1}}>
                        <h2 style={{margin: '0 0 10px'}}> {cliente?.codigo} | {cliente?.nome}</h2>
                        <CopyContent content={cliente?.email}>
                            {cliente?.email}
                        </CopyContent>
                        <CopyContent content={cliente?.telefone}> |
                            {formatTelefone(cliente?.telefone)}
                        </CopyContent>
                    </Box>

                    <Box sx={{flex: 1, textAlign: 'center'}}>
                        <div style={{display: 'flex',}}>
                            <Box sx={{flex: '1'}}>
                                <span style={{margin: 0, fontSize: 13}}>Macho</span>
                                <h1 style={{margin: '0 0 5px'}}>{quantidadeAnimais60a74DiasMacho}</h1>
                            </Box>
                            <Box sx={{flex: '1'}}>
                                <p style={{margin: 0, fontSize: 13}}>Fêmea</p>
                                <h1 style={{margin: '0 0 5px'}}>{quantidadeAnimais60a74DiasFemea}</h1>
                            </Box>
                        </div>


                        <h2 style={{margin: 0}}>60 a 74 dias</h2>
                    </Box>

                    <Box sx={{flex: 1, textAlign: 'center'}}>
                        <div style={{display: 'flex',}}>
                            <Box sx={{flex: '1'}}>
                                <p style={{margin: 0, fontSize: 13}}>Macho</p>
                                <h1 style={{margin: '0 0 5px'}}>{quantidadeAnimais75a94DiasMacho}</h1>
                            </Box>
                            <Box sx={{flex: '1'}}>
                                <p style={{margin: 0, fontSize: 13}}>Fêmea</p>
                                <h1 style={{margin: '0 0 5px'}}>{quantidadeAnimais75a94DiasFemea}</h1>
                            </Box>
                        </div>


                        <h2 style={{margin: 0}}>75 a 94 dias</h2>
                    </Box>


                    <Box sx={{flex: 1, textAlign: 'center'}}>
                        <div style={{display: 'flex',}}>
                            <Box sx={{flex: '1'}}>
                                <p style={{margin: 0, fontSize: 13}}>Macho</p>
                                <h1 style={{margin: '0 0 5px'}}>{quantidadeAnimais95DiasMacho}</h1>
                            </Box>
                            <Box sx={{flex: '1'}}>
                                <p style={{margin: 0, fontSize: 13}}>Fêmea</p>
                                <h1 style={{margin: '0 0 5px'}}>{quantidadeAnimais95DiasFemea}</h1>
                            </Box>
                        </div>


                        <h2 style={{margin: 0}}>95 dias ou mais</h2>
                    </Box>

                </Box>
            </Card>

            <Box className="opcoes-lote" sx={{display: 'flex', gap: '20px', marginBottom: '20px'}}>
                <Button onClick={() => navigateGrafico()}><h3>Gráfico</h3></Button>
                <Button onClick={() => navigateHistorico()}><h3>Histórico</h3></Button>
            </Box>
        </>
    );
}
