import {createContext} from "react";
import {BehaviorSubject} from "rxjs";
import {ClienteDTO, PendenciaDTO} from "../../../../dao";


export enum FaixasPendencia {
    ATE_59_DIAS = 'ate_59_dias',
    DE_60_A_74_DIAS = 'de_60_a_74_dias',
    DE_75_A_94_DIAS = 'de_75_a_94_dias',
    DE_95_DIAS_EM_DIANTE = 'de_95_dias_em_diante'
}

export interface FiltroPendencia {
    codigo?: string;
    cliente?: ClienteDTO;
    status?: PendenciaDTO.StatusEnum;
    tipo?: PendenciaDTO.TipoEnum;
    faixa?: FaixasPendencia
}

export const FiltroContext = createContext<{ filtro: BehaviorSubject<FiltroPendencia | null> }>({
    filtro: new BehaviorSubject<FiltroPendencia | null>({})
});
