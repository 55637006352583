import {Box, Button, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem} from "@mui/material";
import React from "react";
import {PendenciaDTO} from "../../../../dao";
import useDialogRef from "../../../../main-class/template/dialog/useDialogRef";
import {Close} from "@mui/icons-material";
import SelectFormField from "../../../../main-class/form/input/SelectFormField";
import {useForm, useWatch} from "react-hook-form";
import useLabels from "../../../../i18n/useLabels";
import MultipleArquivosFormField from "../../../../main-class/form/input/MultipleArquivosFormField";
import TextFormField from "../../../../main-class/form/input/TextFormField";
import useValidation from "../../../../main-class/form/validation/useValidation";

export enum TipoModal {
    recusa_negociacao,
    aprovacao_negociacao,
    recusa_contagem,
    cancelamento_conclusao
}

const MENSAGENS = {
    [TipoModal.recusa_negociacao]: <>
        Você está <strong>RECUSANDO</strong> uma <strong>NEGOCIAÇÃO</strong> pelo cliente.
    </>,
    [TipoModal.aprovacao_negociacao]: <>
        Você está <strong>APROVANDO</strong> uma <strong>NEGOCIAÇÃO</strong> pelo cliente.
    </>,
    [TipoModal.recusa_contagem]: <>
        Você está <strong>RECUSANDO</strong> uma <strong>CONTAGEM</strong> pelo cliente.
    </>,
    [TipoModal.cancelamento_conclusao]: <>
        Você está <strong>CANCELANDO</strong> uma pendência que já foi <strong>CONCLUÍDA</strong>.
    </>,
};

export default function ModalAlteracaoPeloCliente(props: { tipo: TipoModal }) {
    const {tipo} = props;
    const {control, handleSubmit} = useForm({defaultValues: ({}) as any});
    const {dialogRef} = useDialogRef();
    const motivosCliente = Object.values(PendenciaDTO.MotivoClienteEnum)
    const motivosCancelamento = Object.values(PendenciaDTO.MotivoCancelamentoEnum)
    const {ptBR} = useLabels();
    const {required, validate} = useValidation();

    const submitForm = handleSubmit(async (alteracao) => {
        dialogRef.close(alteracao);
    });

    return (
        <>
            <form onSubmit={submitForm}>
                <DialogTitle style={{display: 'flex'}}>
                    Motivo
                    <div style={{flex: '1 1 auto'}}/>
                    <IconButton type="button" onClick={() => dialogRef.close()}>
                        <Close/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {MENSAGENS[tipo]}
                    <br/>
                    <br/>
                    <Box>
                        {
                            <SelectFormField name="motivo" label="Motivo"
                                             control={control}
                                             validate={validate(required())}
                                             required={true}>
                                {
                                    props.tipo !== TipoModal.cancelamento_conclusao &&
                                    motivosCliente.map(mtv =>
                                        <MenuItem key={"MenuItem_Status_" + mtv} value={mtv}>
                                            {ptBR.enum.motivo_alteracao_cliente[mtv]}
                                        </MenuItem>
                                    )
                                }
                                {
                                    props.tipo === TipoModal.cancelamento_conclusao &&
                                    motivosCancelamento.map(mtv =>
                                        <MenuItem key={"MenuItem_Status_" + mtv} value={mtv}>
                                            {ptBR.enum.motivo_cancelamento[mtv]}
                                        </MenuItem>
                                    )
                                }
                            </SelectFormField>
                        }
                        <OutroMotivo control={control}/>
                        <MultipleArquivosFormField name="anexos" accept={['png', 'jpeg', 'jpg']} control={control}/>
                        <br/>
                        Deseja confirmar essa ação?
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button type="button" variant="text" onClick={() => dialogRef.close()}>Não</Button>
                    <Button type="submit" variant="contained" color="secondary">Sim</Button>
                </DialogActions>
            </form>
        </>
    )
}

function OutroMotivo(props: { control: any }) {
    const {control} = props;
    const motivo = useWatch({
        control,
        name: 'motivo'
    });
    const {length, validate} = useValidation();

    return (
        <>
            {
                (motivo === PendenciaDTO.MotivoClienteEnum.Outro || motivo === PendenciaDTO.MotivoCancelamentoEnum) &&
                <TextFormField name="outroMotivo" validate={validate(length({max: 50}))}
                               label="Outro Motivo" control={control} required={true}/>
            }
        </>
    );
}
