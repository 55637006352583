import React from "react";
import {matchPath, resolvePath, useLocation, useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import {Tabs} from "@mui/material";
import Tab from "@mui/material/Tab";
import {RouteConfig} from "../route/buildTabRoute";
import "./TabsBase.scss";

export default function TabsBase(props: { config: RouteConfig, children?: React.ReactNode }) {
    const {config, children} = props;
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    function findActiveRoute(cfg: RouteConfig): RouteConfig {
        const children = cfg.subroutes
            ?.map(r => ({...r, path: resolvePath(r.path, cfg.path).pathname}))
            ?.map(findActiveRoutes)
            ?.reduce((a1, a2) => [...a1, ...a2]) || []

        if (children.length) {
            return children[children.length - 1];
        }

        if (matchPath(cfg.path, location.pathname)) {
            return cfg;
        }

        return undefined;
    }

    function findActiveRoutes(cfg: RouteConfig): Array<RouteConfig> {
        const actives = cfg.subroutes
            ?.map(r => {
                return ({...r, path: resolvePath(r.path, cfg.path).pathname})
            })
            ?.map(findActiveRoutes)
            ?.reduce((a1, a2) => [...a1, ...a2]) || [] as Array<RouteConfig>;

        if (actives.length) {
            return [cfg, ...actives].filter(c => !!c.title);
        }

        if (matchPath(cfg.path, location.pathname)) {
            return [cfg];
        }

        return [];
    }

    const activeRoutes = findActiveRoutes({...config, path: resolvePath(config.path).pathname}) || [config];

    const activeRoute = findActiveRoute({...config, path: resolvePath(config.path).pathname}) || config;

    function parsePath(template: string) {
        const match = matchPath(activeRoute.path, location.pathname);

        if (match) {
            let path = template;
            for (const key of Object.keys(match.params)) {
                path = path.replace(':' + key, match.params[key]);
            }
            return path;
        }

        return template;
    }

    let activeTabs: Array<{ label: string, path: string }> = [];

    function trataAba(tab: { label: string, path: string }) {
        let found = activeTabs.find(t => t.label === tab.label);

        if (!found) {
            activeTabs.push(tab);
        } else {
            found.path = tab.path;
        }
    }

    for (let ar of activeRoutes) {
        trataAba({label: typeof ar.title === 'function' ? (ar.title as any)(params) : ar.title, path: ar.path});

        ar.tabs?.forEach(trataAba);
    }

    activeTabs = activeTabs
        .map(t => ({label: t.label, path: parsePath(t.path)}));

    return (
        <>
            <Box className="tabsBase" sx={{display: 'flex', gap: '20px'}}>
                <Tabs sx={{flex: '1 1 auto'}} value={location.pathname} onChange={(v, newValue) => navigate(newValue)}>
                    {
                        activeTabs.map(t => <Tab key={'Tab_' + t.label} label={t.label} value={t.path}/>)
                    }
                </Tabs>
            </Box>

            {children}
        </>
    );
}
