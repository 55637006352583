import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {LoteApi, LoteDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import {useParams} from "react-router-dom";
import useLoader from "../../../main-class/componentes/loader/useLoader";
import SincronizacaoContext from "../../../infra/sincronizacao/SincronizacaoContext";

export default function useSumarioLote() {
    const loteApi = useRef(new LoteApi(ApiConfiguration));
    const {lote: id} = useParams();
    const {loadingAround} = useLoader();
    const [lote, setLote] = useState({} as LoteDTO);
    const sincornizacaoState = useContext(SincronizacaoContext);

    const load = useCallback(() => {
        loadingAround(loteApi.current.detalhe(Number(id)))
            .then(setLote)
    }, [id, loteApi, loadingAround]);

    useEffect(() => {
        load();
    }, [load]);

    useEffect(() => {
        const subscription = sincornizacaoState.loteObservable
            .subscribe(() => load())

        return () => subscription.unsubscribe();
    }, [sincornizacaoState, load]);

    return {lote, reload: load};
}
