import {ReactNode} from "react";
import {Outlet, Route} from "react-router-dom";
import Base from "../base/Base";

export default function baseRoutes(children: ReactNode, basePath = "/") {
    return (
        <>
            <Route path={basePath} element={
                <Base>
                    <Outlet/>
                </Base>
            }>
                {children}
            </Route>
        </>
    );
}
