import buildTabRoute from "../../main-class/template/route/buildTabRoute";
import ConsultaLaudoMorte from "./consulta/ConsultaLaudoMorte";

export default function laudoMorteRoute() {
    return buildTabRoute({
        path: "/laudo-morte",
        title: "Laudo de Óbito",
        subroutes: [
            {
                path: "",
                element: <ConsultaLaudoMorte/>,
            },
        ]
    });
}
