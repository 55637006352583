import React from "react";

export interface ColumnFooterProps {
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

export default function ColumnFooter(props: ColumnFooterProps) {
    return <></>;
}
