import {ClienteApi, ClienteDTO, DesembarqueApi, DesembarqueDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import useConsulta from "../../../main-class/form/crud/useConsulta";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useCallback, useContext, useEffect, useRef} from "react";
import SincronizacaoContext from "../../../infra/sincronizacao/SincronizacaoContext";
import {lastValueFrom} from "rxjs";
import useLoader from "../../../main-class/componentes/loader/useLoader";
import useDialog from "../../../main-class/template/dialog/useDialog";

export interface FiltroDesembarque {
    codigo?: string;
    carreta?: string;
    data?: Date;
    status?: DesembarqueDTO.StatusEnum;
    proprietario?: ClienteDTO;
}

export default function useConsultaDesembarque() {
    const desembarqueApi = useRef(new DesembarqueApi(ApiConfiguration));
    const clienteApi = useRef(new ClienteApi(ApiConfiguration));
    const {cliente: idCliente, lote: idLote} = useParams();
    const sincronizacaoContext = useContext(SincronizacaoContext);
    const navigate = useNavigate();
    const {loadingAround} = useLoader();
    const {confirmacao} = useDialog();
    const [searchParams] = useSearchParams();


    const status = Object.values(DesembarqueDTO.StatusEnum);

    const {
        filtro,
        pagina,
        consulta,
        formControl,
        submitForm
    } = useConsulta<FiltroDesembarque, DesembarqueDTO>({
        filtroInicial: {status: searchParams.get('status') as DesembarqueDTO.StatusEnum},
        consultaAction: (filtro, pagina, tamanhoPagina) => desembarqueApi.current.consulta(
            filtro.codigo,
            idCliente as any || filtro.proprietario?.id,
            idLote as any,
            filtro.data,
            filtro.status,
            filtro.carreta,
            pagina,
            tamanhoPagina
        ),
    });

    const cancela = useCallback(async (desembarque: DesembarqueDTO) => {
        const confirmado = await lastValueFrom(confirmacao('Deseja realmente cancelar esse desembarque?').afterClosed())

        if (confirmado) {
            await loadingAround(desembarqueApi.current.cancela(desembarque.id));

            consulta();
        }
    }, [confirmacao, loadingAround, desembarqueApi, consulta]);

    useEffect(() => {
        const subscription = sincronizacaoContext.desembarqueObservable
            .subscribe(() => consulta())

        return () => subscription.unsubscribe();
    }, [sincronizacaoContext, consulta]);

    const filtraProprietarios = useCallback(async (filtro: string) => {
        const pagina = await clienteApi.current.consulta(undefined, filtro, undefined, undefined, 1, 5)

        return pagina.itens || [];
    }, [clienteApi]);

    const onDetalhe = useCallback((desembarque: DesembarqueDTO) => {
        navigate(`/cliente/${desembarque.proprietario.id}/desembarque/${desembarque.id}`);
    }, [navigate]);


    return {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        status,
        showProprietario: !idCliente && !idLote,
        filtraProprietarios,
        onDetalhe,
        cancela,
    };


}


