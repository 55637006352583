import React from "react";
import {Card} from "@mui/material";

export default function EdicaoPage(props: { children: React.ReactNode }) {
    const {children} = props;

    return (
        <>
            <Card>
                {children}
            </Card>
        </>
    );
}
