import {useContext} from "react";
import {Mensagem, MensageriaContext} from "./Mensageria";

export default function useMensageria() {
    const mensageria = useContext(MensageriaContext);

    return {
        addMensagem: (mensagem: string | Mensagem) => mensageria.addMensagem(mensagem),
        addMensagemSucesso: (mensagem: string) => mensageria.addMensagem({
            mensagem,
            tipo: "success"
        }),
        addMensagemErro: (mensagem: string) => mensageria.addMensagem({
            mensagem,
            tipo: "error"
        }),
        addMensagemInfo: (mensagem: string) => mensageria.addMensagem({
            mensagem,
            tipo: "info"
        }),
        addMensagemAlerta: (mensagem: string) => mensageria.addMensagem({
            mensagem,
            tipo: "warning"
        }),
    }
}
