import {useForm} from "react-hook-form";
import React, {useCallback, useContext, useRef} from "react";
import {ClienteApi, ClienteDTO, PendenciaApi} from "../../../../dao";
import ApiConfiguration from "../../../../infra/api/ApiConfiguration";
import useLoader from "../../../../main-class/componentes/loader/useLoader";
import {FiltroContext} from "../consulta/filtroContext";
import {Box, Button, Card, Icon} from "@mui/material";
import AutocompleteFormField from "../../../../main-class/form/input/AutocompleteFormField";
import useMensageria from "../../../../main-class/componentes/mensageria/useMensageria";

export default function AdicionarPendencia() {

    const {control, handleSubmit, reset} = useForm({defaultValues: ({}) as any});

    const api = useRef(new ClienteApi(ApiConfiguration));

    const pendenciaApi = useRef(new PendenciaApi(ApiConfiguration));

    const {addMensagemSucesso} = useMensageria();

    const {loadingAround} = useLoader();

    const contexto = useContext(FiltroContext);

    const filtraClientes = useCallback(async (filtro: string) => {

        const pagina = await api.current.consulta(
            undefined,
            filtro,
            undefined,
            undefined,
            1,
            5
        );
        return pagina.itens || [];
    }, [api]);


    const adiciona = useCallback(async (cliente: ClienteDTO) => {

        await loadingAround(pendenciaApi.current.gera(cliente.id));
        contexto.filtro.next({...contexto.filtro.value})
        reset({})
        addMensagemSucesso('Pendencia gerada com sucesso para o cliente: ' + cliente.nome);

    }, [pendenciaApi, contexto, loadingAround, reset, addMensagemSucesso]);

    const submitForm = handleSubmit(async (cliente) => {
        await adiciona(cliente.cliente);
    });

    return (
        <>
            <Card>
                <h2 style={{marginBottom: '10px 0 45px'}}>Adicionar cliente nas pendências: </h2>
                <form onSubmit={submitForm}>
                    <Box sx={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                        <Box sx={{flex: '1'}}>
                            <AutocompleteFormField
                                name="cliente" key="cliente" label="Cliente"
                                control={control} getOptionLabel={cliente => cliente.nome}
                                getOptions={filtraClientes} compareWith={(c1, c2) => c1.id === c2.id}
                            />
                        </Box>
                        <Box sx={{margin: '15px 0 45px'}}>
                            <Button type="button" onClick={submitForm} variant="outlined">
                                <Icon> add</Icon>
                                Adicionar
                            </Button>
                        </Box>
                    </Box>

                </form>
            </Card>
        </>
    )
}
