import useLabels from "../../../i18n/useLabels";
import {Box, Card, IconButton, MenuItem} from "@mui/material";
import SelectFormField from "../../../main-class/form/input/SelectFormField";
import TextFormField from "../../../main-class/form/input/TextFormField";
import PaginatedTable from "../../../main-class/componentes/table/PaginatedTable";
import TableColumn from "../../../main-class/componentes/table/TableColumn";
import ColumnHeader from "../../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../../main-class/componentes/table/ColumnCell";
import {DesembarqueDTO} from "../../../dao";
import {Cancel, Search} from "@mui/icons-material";
import useConsultaDesembarque from "./useConsultaDesembarque";
import React from "react";
import useFormat from "../../../main-class/componentes/format/useFormat";
import DateFormField from "../../../main-class/form/input/DateFormField";
import AutocompleteFormField from "../../../main-class/form/input/AutocompleteFormField";

export default function ConsultaDesembarque() {
    const {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        status,
        showProprietario,
        filtraProprietarios,
        onDetalhe,
        cancela
    } = useConsultaDesembarque();
    const {ptBR} = useLabels();
    const {formatDate} = useFormat();

    return (
        <>

            <Card>
                <h2><Search/> Filtrar</h2>

                <form onSubmit={submitForm}>

                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{flex: '1'}}>
                            <TextFormField label="Código" value={filtro.codigo} control={formControl} name="codigo"
                                           onChange={submitForm}
                            />
                        </Box>
                        <Box sx={{flex: '1'}}>
                            <DateFormField label="Data" value={filtro.data} control={formControl} name="data"
                                           onChange={submitForm}
                            />
                        </Box>
                        <Box sx={{flex: '1'}}>
                            <TextFormField label="Carreta" value={filtro.carreta} control={formControl} name="carreta"
                                           onChange={submitForm}
                            />
                        </Box>
                        <Box sx={{flex: '1'}}>
                            <SelectFormField name="status" label="Status"
                                             value={filtro.status} control={formControl}
                                             onChange={submitForm}>
                                <MenuItem value={null}/>
                                {
                                    status.map(sts =>
                                        <MenuItem key={"MenuItem_Status_" + sts} value={sts}>
                                            {ptBR.enum.status_desembarque[sts]}
                                        </MenuItem>
                                    )
                                }
                            </SelectFormField>
                        </Box>
                    </Box>

                    {
                        showProprietario &&
                        <AutocompleteFormField
                            name="proprietario" label="Proprietário"
                            value={filtro.proprietario} control={formControl}
                            onChange={submitForm} getOptionLabel={cliente => cliente.nome}
                            getOptions={filtraProprietarios} compareWith={(c1, c2) => c1.id === c2.id}
                        />
                    }

                </form>
            </Card>


            <Card>
                <PaginatedTable onPageChange={(pagina, tamanhoPagina) => consulta(filtro, {pagina, tamanhoPagina})}
                                pagina={pagina}>
                    <TableColumn name="codigo">
                        <ColumnHeader>Código</ColumnHeader>
                        <ColumnCell content={(item: DesembarqueDTO) => item.codigo}/>
                    </TableColumn>
                    <TableColumn name="data" style={{textAlign: 'center'}}>
                        <ColumnHeader>Data</ColumnHeader>
                        <ColumnCell
                            content={(item: DesembarqueDTO) => formatDate(item.dataHoraEntrada, 'DD/MM/YYYY | HH:mm:ss')}/>
                    </TableColumn>

                    <TableColumn name="carreta" style={{textAlign: 'center'}}>
                        <ColumnHeader>Carreta</ColumnHeader>
                        <ColumnCell content={(item: DesembarqueDTO) => item.placaCarreta}/>
                    </TableColumn>
                    <TableColumn name="animais" style={{textAlign: 'center'}}>
                        <ColumnHeader>Animais</ColumnHeader>
                        <ColumnCell content={(item: DesembarqueDTO) => item.qtdAnimais}/>
                    </TableColumn>
                    {
                        showProprietario &&
                        <TableColumn name="proprietario">
                            <ColumnHeader>Proprietário</ColumnHeader>
                            <ColumnCell content={(item: DesembarqueDTO) => item.proprietario.nome}/>
                        </TableColumn>
                    }
                    <TableColumn name="status" style={{textAlign: 'center'}}>
                        <ColumnHeader>Status</ColumnHeader>
                        <ColumnCell content={(item: DesembarqueDTO) => ptBR.enum.status_desembarque[item.status]}/>
                    </TableColumn>
                    <TableColumn name="opcoes" style={{textAlign: 'right'}}>
                        <ColumnHeader/>
                        <ColumnCell content={
                            (item: DesembarqueDTO) => <>
                                <IconButton onClick={() => onDetalhe(item)} type="button"><Search/></IconButton>
                                {item.status !== 'cancelado' && <IconButton onClick={() => cancela(item)} type="button"><Cancel/></IconButton>}
                            </>
                        }/>
                    </TableColumn>
                </PaginatedTable>
            </Card>
        </>


    );
}

