import React from "react";
import * as CryptoJS from "crypto-js";

interface SelectItemProps {
    label: React.ReactNode,
    value: any
}

export interface SelectItemDTO extends SelectItemProps {
    id: string;
}

export function parseSelectItem(props: SelectItemProps): SelectItemDTO {
    return {
        id: CryptoJS.SHA256(JSON.stringify(props.value)).toString(CryptoJS.enc.Hex),
        label: props.label,
        value: props.value,
    };
}

export default function SelectItem(props: SelectItemProps) {
    return (
        <>
        </>
    )
}
