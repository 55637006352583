import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from "@mui/material";
import React, {ChangeEvent, useCallback, useEffect, useRef, useState} from "react";
import {Add, AttachFile, Close, DescriptionOutlined, Search, Warning} from "@mui/icons-material";
import useArquivo from "../../componentes/arquivo/useArquivo";
import {IArquivo} from "../../componentes/arquivo/ArquivoContext";
import {last, ReplaySubject} from "rxjs";
import {FieldError} from "react-hook-form";

export interface MultipleArquivosFieldProps {
    disabled?: any;
    value?: Array<IArquivo | any>;
    label?: string;
    variant?: 'standard' | 'outlined' | 'filled';
    accept?: string | string[];
    limit?: string;
    onChange?: any;
    error?: FieldError;
    required?: boolean;
}

export default function MultipleArquivosField(props: MultipleArquivosFieldProps) {
    const inputFileUpload = useRef<HTMLInputElement>();
    const {fileLength, fileFormat, upload} = useArquivo();
    const {disabled, accept, limit, value, onChange, error, required, label, variant} = props;

    function onFileChange(event: ChangeEvent<any>, onChange: (event: any) => any, arquivos: Array<any>) {
        const files: Array<File> = [];

        for (let i = 0; i < event.target.files.length; i++) {
            files.push(event.target.files[i]);
        }

        event.target.value = '';

        const arqFiles = files.map(file => {
            const subject = new ReplaySubject(1);

            return {
                arquivo: {
                    tamanho: file.size,
                    uploadProgress: 0,
                    nome: file.name,
                    observable: subject.asObservable(),
                } as IArquivo,
                subject,
                file
            };
        });

        onChange([
            ...(arquivos || []),
            ...arqFiles.map(arqFile => arqFile.arquivo)
        ]);

        arqFiles.map(arqFile => async function () {
            const {file, arquivo, subject} = arqFile;

            subject.next(arquivo);

            if (fileLength(limit)(arquivo)) {
                subject.next({
                    nome: arquivo.nome,
                    tamanho: arquivo.tamanho,
                    error: true,
                });
                subject.complete();
                return;
            }

            if (fileFormat(accept)(arquivo)) {
                subject.next({
                    nome: arquivo.nome,
                    tamanho: arquivo.tamanho,
                    error: true,
                });
                subject.complete();
                return;
            }

            try {
                const resultado = await upload(file, (event) => {
                    subject.next({
                        ...event.attachment,
                        observable: arquivo.observable
                    });
                })

                subject.next(resultado);
            } catch (ex) {
                subject.next({
                    ...arquivo,
                    error: ex
                });
            } finally {
                subject.complete();
            }

        })
            .map((exec: any) => exec());
    }

    function openFileChooser() {
        inputFileUpload.current.click();
    }

    return (
        <>
            <FormControl variant={variant} required={required}>
                <InputLabel>{label}</InputLabel>
                <OutlinedInput
                    value={'XXX'}
                    label={label}
                    error={!!error}
                    startAdornment={
                        <>
                            {
                                (!value?.length || !error) &&
                                <InputAdornment position="start">
                                    <AttachFile/>
                                </InputAdornment>
                            }
                            {
                                (!!value?.length && !!error) &&
                                <InputAdornment position="start">
                                    <Warning color="error"/>
                                </InputAdornment>
                            }
                        </>
                    }
                    inputComponent={props =>
                        <div style={{minHeight: '56px', width: '100%'}}>
                            <ArquivoList arquivos={value} onChange={onChange}
                                         disabled={disabled} accept={accept} limit={limit}/>
                            {
                                !disabled &&
                                <div style={{
                                    padding: '10px',
                                    display: 'flex',
                                    flexFlow: 'row-reverse'
                                }}>
                                    <Button variant="outlined" onClick={openFileChooser}>
                                        <Add sx={{marginRight: '10px'}}/>
                                        Adicionar
                                    </Button>
                                </div>
                            }
                        </div>
                    }
                />
                <FormHelperText style={{color: 'red'}}>{error?.message}</FormHelperText>
            </FormControl>
            <input type="file" style={{display: 'none'}}
                   onChange={event => onFileChange(event, onChange, value)}
                   ref={inputFileUpload} multiple/>
        </>
    );
}

interface ArquivoListProps {
    arquivos: Array<any>
    onChange: (event: any) => any,
    accept?: string | string[];
    limit?: string;
    disabled?: any;
}

function ArquivoList(props: ArquivoListProps) {
    const {arquivos, onChange, disabled} = props;

    function removeArquivo(arquivo: any) {
        const modified = arquivos.filter((arq: any) => arq !== arquivo);
        return onChange(modified.length ? modified : undefined);
    }

    const dispatchChange = useCallback((before: IArquivo, after: IArquivo) => {
        onChange(arquivos.map(a => {
            if (a === before) {
                return after;
            }

            return a;
        }));
    }, [onChange, arquivos]);

    return (
        <>
            {
                arquivos?.length &&
                <Box sx={{
                    display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '20px', marginBottom: '20px',
                    padding: '10px'
                }}>
                    {
                        arquivos?.map((arq: any, idx: number) =>
                            <ArquivoItem key={'arquivo_' + arq.nome + '_' + idx} arquivo={arq}
                                         onChange={after => dispatchChange(arq, after)}
                                         onRemove={() => removeArquivo(arq)} disabled={disabled}/>)
                    }
                </Box>
            }
        </>
    );
}

function ArquivoItem(props: { arquivo: IArquivo | any, onChange: (arquivo: IArquivo) => any, onRemove: () => any, disabled: any }) {
    const {onRemove, onChange, disabled} = props;
    const [arquivo, setArquivo] = useState<IArquivo>(props.arquivo);
    const {download} = useArquivo();

    function onClick() {
        download(arquivo);
    }

    useEffect(() => {
        if (arquivo.observable) {
            const subscription = arquivo.observable.subscribe(setArquivo);
            return () => subscription.unsubscribe();
        }
    }, [arquivo.observable]);

    useEffect(() => {
        if (arquivo.observable) {
            const subscription = arquivo.observable.pipe(last()).subscribe(arq => {
                setArquivo(arq);
                onChange(arq);
            });
            return () => subscription.unsubscribe();
        }
    }, [arquivo.observable, onChange]);

    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                width: '200px',
                textAlign: 'center',
                background: '#efefef',
                borderRadius: '5px',
                padding: '10px'
            }}>
                <Box sx={{flex: 4}}>
                    <DescriptionOutlined sx={{height: '80px', width: '80px'}}/>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', flex: 1, gap: '10px'}}>
                    <Box sx={{flex: 1}}>
                        {
                            !arquivo?.error &&
                            <Search style={{cursor: 'pointer'}} onClick={onClick}/>
                        }
                        {
                            !!arquivo?.error &&
                            <Warning color="error"/>
                        }
                    </Box>
                    <Box sx={{flex: '1 1 auto', height: '48px', overflow: 'hidden'}}>{arquivo.nome}</Box>
                    <Box sx={{flex: 1}}>
                        {
                            arquivo && arquivo.uploadProgress !== undefined &&
                            <CircularProgress size={24}
                                              variant={arquivo.uploadProgress ? "determinate" : "indeterminate"}
                                              value={arquivo.uploadProgress * 100}/>
                        }
                        {
                            !disabled && arquivo && arquivo.uploadProgress === undefined &&
                            <IconButton onClick={onRemove}>
                                <Close/>
                            </IconButton>
                        }
                    </Box>
                </Box>
            </Box>
        </>
    );
}
