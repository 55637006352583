import useLabels from "../../../i18n/useLabels";
import useFormat from "../../../main-class/componentes/format/useFormat";
import {Box, Card, IconButton, MenuItem} from "@mui/material";
import {Search} from "@mui/icons-material";
import DateFormField from "../../../main-class/form/input/DateFormField";
import SelectFormField from "../../../main-class/form/input/SelectFormField";
import PaginatedTable from "../../../main-class/componentes/table/PaginatedTable";
import TableColumn from "../../../main-class/componentes/table/TableColumn";
import ColumnHeader from "../../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../../main-class/componentes/table/ColumnCell";
import {ProcessamentoSaidaDTO} from "../../../dao";
import React from "react";
import useConsultaProcessamentoSaida from "./useConsultaProcessamentoSaida";
import TextFormField from "../../../main-class/form/input/TextFormField";
import SelectCliente from "../../../componentes/selectCliente/SelectClient";

export default function ConsultaProcessamentoSaida() {


    const {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        status,
        onDetalhe
    } = useConsultaProcessamentoSaida();

    const {ptBR} = useLabels();

    const {formatDate} = useFormat();

    return (
        <>


            <Card>
                <h2><Search/> Filtrar</h2>

                <form onSubmit={submitForm}>

                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{flex: '1'}}>
                            <TextFormField label="Código"
                                           value={filtro.codigo}
                                           control={formControl}
                                           name="codigo"
                                           onChange={submitForm}
                            />
                        </Box>

                        <Box sx={{flex: '1'}}>
                            <DateFormField label="Data"
                                           value={filtro.data}
                                           control={formControl}
                                           name="data"
                                           onChange={submitForm}
                            />
                        </Box>

                        <Box sx={{flex: '1'}}>
                            <SelectCliente name="proprietario" label="Proprietário" control={formControl}
                                           onChange={submitForm}
                            />
                        </Box>

                        <Box sx={{flex: '1'}}>
                            <SelectFormField name="status"
                                             label="Status"
                                             value={filtro.status}
                                             control={formControl}
                                             onChange={submitForm}>
                                <MenuItem value={null}/>
                                {
                                    status.map(sts =>
                                        <MenuItem key={"MenuItem_Status_" + sts} value={sts}>
                                            {ptBR.enum.status_processamento_saida[sts]}
                                        </MenuItem>
                                    )
                                }
                            </SelectFormField>
                        </Box>
                    </Box>


                </form>
            </Card>


            <Card>
                <PaginatedTable onPageChange={(pagina, tamanhoPagina) => consulta(filtro, {pagina, tamanhoPagina})}
                                pagina={pagina}>

                    <TableColumn name="codigo">
                        <ColumnHeader>Contrato</ColumnHeader>
                        <ColumnCell content={(item: ProcessamentoSaidaDTO) => item.codigo}/>
                    </TableColumn>

                    <TableColumn name="proprietario">
                        <ColumnHeader>Proprietário</ColumnHeader>
                        <ColumnCell content={(item: ProcessamentoSaidaDTO) => item.proprietario.nome}/>
                    </TableColumn>

                    <TableColumn name="data" style={{textAlign: 'center'}}>
                        <ColumnHeader>Data</ColumnHeader>
                        <ColumnCell
                            content={(item: ProcessamentoSaidaDTO) => formatDate(item.data, 'DD/MM/YYYY')}/>
                    </TableColumn>

                    <TableColumn name="qtdeAnimais" style={{textAlign: 'center'}}>
                        <ColumnHeader>Qtde. Animais</ColumnHeader>
                        <ColumnCell
                            content={(item: ProcessamentoSaidaDTO) => item.pesagem.quantidade}/>
                    </TableColumn>

                    <TableColumn name="status" style={{textAlign: 'center'}}>
                        <ColumnHeader>Status</ColumnHeader>
                        <ColumnCell
                            content={(item: ProcessamentoSaidaDTO) => ptBR.enum.status_processamento_saida[item.status]}/>
                    </TableColumn>

                    <TableColumn name="opcoes" style={{textAlign: 'right'}}>
                        <ColumnHeader/>
                        <ColumnCell content={
                            (item: ProcessamentoSaidaDTO) => <>
                                <IconButton onClick={() => onDetalhe(item)} type="button"><Search/></IconButton>
                            </>
                        }/>
                    </TableColumn>
                </PaginatedTable>
            </Card>
        </>


    );
}
