import React, {useRef} from "react";
import {AnimalApi, DocumentoDigitalDTO, FinalizacaoLaudoMorteDTO, LaudoMorteDTO, MovimentacaoDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import useDialog from "../../../main-class/template/dialog/useDialog";
import useEdicao from "../../../main-class/form/crud/useEdicao";
import {lastValueFrom} from "rxjs";
import {useNavigate} from "react-router-dom";
import useArquivo from "../../../main-class/componentes/arquivo/useArquivo";
import useMensageria from "../../../main-class/componentes/mensageria/useMensageria";
import ModalAssinatura from "../../../componentes/modalAssinatura/ModalAssinatura";
import AdicionarLocalMorte from "../componentes/CadastroLocalMorte";


export interface InnerLaudoDTO extends LaudoMorteDTO {
    documentoDigital?: DocumentoDigitalDTO;
    diagnostico: FinalizacaoLaudoMorteDTO
}

export default function useCadastroLaudoMorte() {
    const animalApi = useRef(new AnimalApi(ApiConfiguration));
    const navigate = useNavigate();
    const {confirmacao, open} = useDialog();
    const {download} = useArquivo();
    const {addMensagemSucesso, addMensagemErro} = useMensageria();

    const {entity, submitForm, formControl, reset} = useEdicao<InnerLaudoDTO>({
        idParam: 'animal',
        findAction: idAnimal => animalApi.current.detalhaLaudoMorte(idAnimal)
            .then(lm => ({...lm, diagnostico: lm.diagnostico as FinalizacaoLaudoMorteDTO})),
        preSave: async (entity, idAnimal, event) => {
            if ((event.nativeEvent as SubmitEvent).submitter.id === 'finalizar') {
                return await lastValueFrom(confirmacao('O cliente será notificado no seu dispositivo móvel. Essa ação é irreversível. Deseja continuar?').afterClosed());
            }

            return true;
        },
        saveAction: async (entity, idAnimal, event) => {
            if ((event.nativeEvent as SubmitEvent).submitter.id === 'finalizar') {
                const documentoDigital = await animalApi.current.finalizaLaudoMorte(idAnimal, entity.diagnostico);

                return {
                    ...entity,
                    documentoDigital
                };
            } else {
                await animalApi.current.salvaLaudoMorte(Number(idAnimal), entity.diagnostico);

                return undefined;
            }
        },
        postSave: (entity, idAnimal, event) => {
            if ((event.nativeEvent as SubmitEvent).submitter.id === 'finalizar') {
                open(<ModalAssinatura condicaoAssinatura={() => verificaAssinatura(idAnimal)}
                                      label={"Assinatura de Laudo de óbito"}
                                      linkAssinatura={entity.documentoDigital!.linkAssinatura}/>, {
                    fullWidth: true, maxWidth: 'xl'
                }).afterClosed().subscribe(sucesso => {
                    if (sucesso) {
                        navigate(`/laudo-morte`);
                        addMensagemSucesso('Laudo de óbito registrado com sucesso.');
                    }
                });
            } else {
                navigate(`/laudo-morte`);
                addMensagemSucesso('Laudo de óbito registrado com sucesso.');
            }
        },
        ignoreMensageSucesso: true,
    });

    async function verificaAssinatura(idAnimal: number) {
        const laudo = await animalApi.current.detalhaLaudoMorte(idAnimal);

        return laudo.status === LaudoMorteDTO.StatusEnum.Finalizado;
    }

    function downloadDocumentoAssinado() {
        download(entity.documentoAssinado);
    }

    async function salva() {
        if (entity.diagnostico.observacao.length < 280) {
            await animalApi.current.salvaLaudoMorte(Number(entity.animal.id), entity.diagnostico);

            navigate(`/laudo-morte`);

            addMensagemSucesso('Laudo de óbito registrado com sucesso.');
        } else {
            addMensagemErro('A observação deve possuir no máximo 280 caracteres.');
        }

    }

    function salvaLocalMorte() {
        open<MovimentacaoDTO>(
            <AdicionarLocalMorte/>,
            {fullWidth: true, maxWidth: 'md'}
        ).afterClosed().subscribe( () => {
            window.location.reload()
        });
    }

    return {
        entity,
        submitForm,
        formControl,
        downloadDocumentoAssinado,
        reset,
        salva,
        salvaLocalMorte,
        cadastroHabilitado: entity.status !== LaudoMorteDTO.StatusEnum.Finalizado,
    };
}
