import * as React from "react";
import {Outlet, Route} from "react-router-dom";

export default function publicRoutes(children: React.ReactNode ) {

    return (
        <>
            <Route key="publicRoutes" path="public" element={<Outlet/>}>
                {children}
            </Route>
        </>
    );
}
