import EdicaoPage from "../../../main-class/template/crud/EdicaoPage";
import {Box, Button, MenuItem} from "@mui/material";
import TextFormField from "../../../main-class/form/input/TextFormField";
import useValidation from "../../../main-class/form/validation/useValidation";
import SelectFormField from "../../../main-class/form/input/SelectFormField";
import CpfFormField from "../../../main-class/form/input/CpfFormField";
import TelefoneFormField from "../../../main-class/form/input/TelefoneFormField";
import useUsuarioValidation from "../useUsuarioValidation";
import useLabels from "../../../i18n/useLabels";
import useEdicaoUsuario from "./useEdicaoUsuario";
import DadosVeterinario from "./components/DadosVeterinario";

export default function EdicaoUsuario() {
    const {submitForm, entity, formControl, perfis, cancela} = useEdicaoUsuario();

    const {validate, required, length, cpf, email, string} = useValidation();
    const {emailUnico, cpfUnico} = useUsuarioValidation();
    const {ptBR} = useLabels();

    return (
        <EdicaoPage>
            <form onSubmit={submitForm}>
                <Box sx={{display: 'flex', gap: '20px'}}>
                    <Box sx={{flex: 2}}>
                        <TextFormField name="nome" control={formControl} label="Nome"
                                       value={entity.nome} validate={
                            validate(
                                required(),
                                string({trim: true, specialCharacteres: true}),
                                length({min: 2, max: 150})
                            )
                        }/>
                    </Box>

                    <Box sx={{flex: 1}}>
                        <CpfFormField name="cpfCnpj" control={formControl}
                                      label="CPF" validate={
                            validate(
                                required(),
                                cpf(),
                                cpfUnico(entity.id),
                            )
                        }/>
                    </Box>
                </Box>

                <Box sx={{display: 'flex', gap: '20px'}}>
                    <Box sx={{flex: 1}}>
                        <TextFormField name="email" control={formControl} label="E-mail"
                                       value={entity.email} validate={
                            validate(
                                required(),
                                email(),
                                length({max: 50}),
                                emailUnico(entity.id),
                            )
                        }/>
                    </Box>

                    <Box sx={{flex: 1}}>
                        <TelefoneFormField name="celular" control={formControl} label="Telefone Celular"
                                           value={entity.celular} validate={
                            validate(
                                required(),
                                length({min: 11, max: 11})
                            )
                        }/>
                    </Box>

                    <Box sx={{flex: 1}}>
                        <SelectFormField name="perfil" control={formControl} label="Perfil"
                                         value={entity.perfil}

                                         validate={
                                             validate(
                                                 required()
                                             )
                                         }>
                            <MenuItem value={null}/>
                            {
                                perfis.map(perfil =>
                                    <MenuItem key={perfil} value={perfil}>{ptBR.enum.perfil_usuario[perfil]}</MenuItem>
                                )
                            }
                        </SelectFormField>
                    </Box>
                </Box>

                <DadosVeterinario entity={entity} control={formControl}/>

                <div className="acoes">
                    <Button type="submit" variant="contained" color="secondary">Salvar</Button>
                    <Button type="button" color="inherit" onClick={cancela}>Cancelar</Button>
                </div>
            </form>
        </EdicaoPage>
    );
}
