import {useNavigate} from "react-router-dom";
import {NoticiaApi, NoticiaDTO, UsuarioAdministradorApi, UsuarioAdministradorDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import useConsulta from "../../../main-class/form/crud/useConsulta";
import {useCallback, useRef} from "react";


export interface FiltroNoticia {
    data?: Date;
    responsavel?: UsuarioAdministradorDTO;
}

export default function useConsultaNoticia() {
    const noticiaApi = new NoticiaApi(ApiConfiguration);
    const usuarioApi = useRef(new UsuarioAdministradorApi(ApiConfiguration));

    const navigate = useNavigate();
    const {
        filtro, pagina, consulta, remove, formControl, submitForm
    } = useConsulta<FiltroNoticia, NoticiaDTO>({
        consultaAction: (filtro, pagina, tamanhoPagina) => noticiaApi.consulta(
            filtro.data,
            filtro.responsavel?.id,
            pagina,
            tamanhoPagina
        ),
        exclusaoAction: (entity) => noticiaApi.remove(entity.id),
        mensagemConfirmacaoExclusao: (entity) => <>A notícia "{entity.titulo}"
            será <strong>excluída</strong> no sistema.
            Deseja continuar?</>
    });

    const filtraUsuarios = useCallback(async (filtro: string) => {
        const pagina = await usuarioApi.current.consulta(undefined, filtro, undefined, undefined, 1, 5)

        return pagina.itens || [];
    }, [usuarioApi]);

    const edita = useCallback((noticia: NoticiaDTO) => {
        navigate(`./edicao/${noticia.id}`);
    }, [navigate]);

    return {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        edita,
        remove,
        filtraUsuarios,
    }
}
