import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {ClienteApi, ClienteDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import {useParams} from "react-router-dom";
import useLoader from "../../../main-class/componentes/loader/useLoader";
import SincronizacaoContext from "../../../infra/sincronizacao/SincronizacaoContext";

export default function useSumarioCliente() {
    const clienteApi = useRef(new ClienteApi(ApiConfiguration));
    const {cliente: id} = useParams();
    const {loadingAround} = useLoader();
    const [cliente, setCliente] = useState({} as ClienteDTO);
    const sincornizacaoState = useContext(SincronizacaoContext);

    const consultaCliente = useCallback(() => {
        loadingAround(clienteApi.current.detalhe(Number(id))).then(setCliente);
    }, [id, clienteApi, loadingAround]);

    useEffect(() => {
        consultaCliente();
    }, [consultaCliente]);

    useEffect(() => {
        const subscription = sincornizacaoState.clienteObservable
            .subscribe(() => consultaCliente())

        return () => subscription.unsubscribe();
    }, [sincornizacaoState, consultaCliente]);

    return {
        cliente
    };
}
