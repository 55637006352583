import {useCallback, useEffect, useRef, useState} from "react";
import {PendenciaApi, PendenciaDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import {useParams} from "react-router-dom";
import useLoader from "../../../main-class/componentes/loader/useLoader";

export default function useDetalhePendencia() {

    const pendenciaApi = useRef(new PendenciaApi(ApiConfiguration));

    const {pendencia: idPendencia} = useParams();

    const [pendencia, setPendencia] = useState<PendenciaDTO>();

    const {loadingAround} = useLoader();

    const refresh = useCallback(() => {
        loadingAround(pendenciaApi.current.detalha(Number(idPendencia)))
            .then(setPendencia);
    }, [idPendencia, loadingAround, pendenciaApi, setPendencia]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    return {
        pendencia,
        refresh
    };


}
