import React, {createContext, useState} from "react";
import DialogListener from "./DialogListener";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import DialgoConfirmacao from "./DialogConfirmacao";
import DialgoInformacao from "./DialogInformacao";

export interface DialogRef<T> {
    element: React.ReactNode;
    close: (result?: T) => any;

    afterClosed(): Observable<T>;
}

export interface InternalDialogRef<T> extends DialogRef<T> {
    id: string;
    config: DialogConfig;
    closeSubject: Subject<T>;
}

export interface DialogConfig {
    fullScreen?: boolean;
    fullWidth?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export class DialogManager {
    private dialogSubject = new BehaviorSubject<Array<InternalDialogRef<any>>>([]);

    observable(): Observable<Array<InternalDialogRef<any>>> {
        return this.dialogSubject.asObservable();
    }

    open<T>(element: React.ReactNode, config?: DialogConfig): DialogRef<T> {
        const id = String(Math.random() * 10000000);

        const closeSubject = new Subject<T>();

        const dialogRef: InternalDialogRef<T> = {
            id,
            element,
            closeSubject,
            config: config || {},
            close: (value: T) => {
                this.dialogSubject.next([
                    ...this.dialogSubject.value.filter(df => df.id !== id)
                ]);
                closeSubject.next(value);
                closeSubject.complete();
            },
            afterClosed: () => closeSubject.asObservable()
        };

        this.dialogSubject.next(
            [...this.dialogSubject.value, dialogRef]
        );

        return dialogRef;
    }

    confirmacao(mensagem: React.ReactNode, config?: {
        okLabel?: string,
        cancelLabel?: string,
        titulo?: string
    }): DialogRef<boolean> {
        return this.open(<DialgoConfirmacao
            mensagem={mensagem}
            okLabel={config?.okLabel}
            cancelLabel={config?.cancelLabel}
            titulo={config?.titulo}
        />);
    }

    informacao(mensagem: React.ReactNode, config?: {
        okLabel?: string,
        titulo?: string
    }) {
        return this.open(<DialgoInformacao
            mensagem={mensagem}
            okLabel={config?.okLabel}
            titulo={config?.titulo}
        />);
    }

    closeAll() {
        const dialogs = this.dialogSubject.value;

        this.dialogSubject.next([]);

        dialogs.forEach(d => d.close());
    }
}

export const DialogContext = createContext(new DialogManager());

export default function DialgoContainer(props: { children?: React.ReactNode }) {
    const [manager] = useState(new DialogManager())
    const {children} = props;

    return (
        <DialogContext.Provider value={manager}>
            {children}

            <DialogListener/>
        </DialogContext.Provider>
    );
}
