import EdicaoUsuario from "./edicao/EdicaoUsuario";
import buildCrudRoute from "../../main-class/template/crud/buildCrudRoute";
import ConsultaUsuario from "./consulta/ConsultaUsuario";

export default function usuarioRoute() {
    return buildCrudRoute({
        basePath: "usuario",
        consultaElement: <ConsultaUsuario/>,
        edicaoElement: <EdicaoUsuario/>,
        labelConsulta: "Usuários",
        labelCadastro: "+ Novo Usuário",
        labelEdicao: "Editar Usuário"
    });
}
