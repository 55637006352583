import buildTabRoute from "../../main-class/template/route/buildTabRoute";
import ConsultaNoticia from "./consulta/ConsultaNoticia";
import ConsultaDenuncia from "./denuncia/ConsultaDenuncia";
import EdicaoNoticia from "./edicao/EdicaoNoticia";

export default function desembarqueRoute() {
    return<>
        {
            buildTabRoute({
                path:"/noticia",
                title:"Notícias",
                tabs: [{
                    label: "+ Nova Notícia",
                    path: "/noticia/cadastro"
                    },
                    {
                    label:"Denúncias",
                    path:"/noticia/denuncia"
                    }
                ],
                subroutes:[
                    {
                        path:"",
                        element:<ConsultaNoticia/>,
                    },
                    {
                        path:"/noticia/cadastro",
                        element:<EdicaoNoticia/>,
                    },
                    {
                        path:"/noticia/edicao/:id",
                        element:<EdicaoNoticia/>,
                    },
                    {
                        path:"/noticia/denuncia",
                        element:<ConsultaDenuncia/>
                    }
                ]
            })
        }
    </>
}

