import {Control} from "react-hook-form/dist/types";
import {InnerQuantidadeRacaDTO} from "../useCadastroLaudoEntrada";
import {useWatch} from "react-hook-form";
import React from "react";
import useSumarioLote from "../../../lote/sumario/useSumarioLote";

export default function QuantidadeTotal(props: { control: Control }) {
    const {control} = props;
    const {lote} = useSumarioLote();
    const racas: Array<InnerQuantidadeRacaDTO> = useWatch({control, name: 'racas'});

    const quantidade = racas?.filter(qtd => qtd.habilitado)
        .map(qtd => qtd.quantidade)
        .reduce((q1, q2) => q1 + q2, 0);

    return (
        <>
            Total:
            <span style={{marginLeft: '5px', color: lote.qtdAnimais !== quantidade ? 'red' : 'green'}}>
                {
                    quantidade
                }
            </span>
        </>
    );
}
