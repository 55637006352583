import {createContext} from "react";
import {Observable} from "rxjs";

export interface IArquivo {
    nome?: string;
    tamanho?: number;
    uploadProgress?: number;
    error?: any
    observable?: Observable<IArquivo | any>;
}

export interface UploadEvent {
    type: 'progresso' | 'conclusao';
    attachment?: IArquivo;
}

export type UploadProgress = (arquivo: UploadEvent) => any;

export interface ArquivoManager {
    upload(file: File, callback?: UploadProgress): Promise<IArquivo>;

    getURL(arquivo: IArquivo): Promise<string>;
}

const ArquivoManagerContext = createContext<ArquivoManager>(null);

export default ArquivoManagerContext;
