import {ProcessamentoSaidaApi, ProcessamentoSaidaDTO} from "../../../dao";
import {useRef} from "react";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import useEdicao from "../../../main-class/form/crud/useEdicao";
import {lastValueFrom} from "rxjs/internal/lastValueFrom";
import useDialog from "../../../main-class/template/dialog/useDialog";
import {useNavigate} from "react-router-dom";


enum TipoEdicao {
    salvar,
    enviar,
}

export interface EdicaoDocumentoProcessamentoSaida extends ProcessamentoSaidaDTO {
    tipo?: TipoEdicao
}

export default function useDetalhaProcessamentoSaida() {

    const processamentoSaidaApi = useRef(new ProcessamentoSaidaApi(ApiConfiguration));

    const {confirmacao} = useDialog();

    const navigate = useNavigate();

    const {entity, submitForm, formControl} = useEdicao<EdicaoDocumentoProcessamentoSaida>({
        findAction: id => processamentoSaidaApi.current.detalha(id),
        preSave: async entity => {
            if (entity.status === ProcessamentoSaidaDTO.StatusEnum.Pendente) {
                return await lastValueFrom(confirmacao('Você tem certeza que deseja iniciar o desembarque? O cliente será notificado.').afterClosed());
            } else {
                return await lastValueFrom(confirmacao('Você tem certeza que deseja alterar os documentos?').afterClosed());
            }
        },
        saveAction: entity => {
            if (entity.tipo === TipoEdicao.enviar) {
                return processamentoSaidaApi.current.envia(entity);
            } else {
                return processamentoSaidaApi.current.salva(entity);
            }
        },
        mensagemSucesso: 'Documenos salvos com sucesso.'
    });

    function cancela() {
        navigate(`/processamento-saida`)
    }

    function envia() {
        entity.tipo = TipoEdicao.enviar;
        submitForm();
    }

    return {
        entity,
        submitForm,
        formControl,
        cancela,
        envia,
    };
}
