
import buildTabRoute from "../../main-class/template/route/buildTabRoute";
import ConsultaMovimentacao from "./ConsultaMovimentacao";

export default function movimentacaoRoute() {
    return buildTabRoute({
        path: "/movimentacao",
        title: "Movimentações",
        subroutes: [
            {
                path: "",
                element: <ConsultaMovimentacao />,
            },
        ]
    });
}

