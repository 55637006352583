import {useCallback, useContext, useEffect, useRef, useState} from "react";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import {useParams} from "react-router-dom";
import useLoader from "../../../main-class/componentes/loader/useLoader";
import SincronizacaoContext from "../../../infra/sincronizacao/SincronizacaoContext";
import {AnimalApi, AnimalDTO} from "../../../dao";

export default function useSumarioAnimal() {
    const animalApi = useRef(new AnimalApi(ApiConfiguration));
    const {animal: id} = useParams();
    const {loadingAround} = useLoader();
    const [animal, setAnimal] = useState({} as AnimalDTO);
    const sincornizacaoState = useContext(SincronizacaoContext);

    const load = useCallback(() => {
        loadingAround(animalApi.current.detalha(Number(id)))
            .then(setAnimal)
    }, [id, animalApi, loadingAround]);

    useEffect(() => {
        load();
    }, [load]);

    useEffect(() => {
        const subscription = sincornizacaoState.animalObservable
            .subscribe(() => load())

        return () => subscription.unsubscribe();
    }, [sincornizacaoState, load]);

    return {animal, reload: load};
}
