import {Button, TextField} from "@mui/material";
import {useForm} from "react-hook-form";
import useValidation from "../../../main-class/form/validation/useValidation";
import PasswordFormField from "../../../main-class/form/input/PasswordFormField";

export interface SenhaProps {
    email: string;
    onCancelar: () => any;
    onSubmitted: (senha: string) => any;
    onEsqueciMinhaSenha: () => any;
}

export default function Senha(props: SenhaProps) {
    const {handleSubmit, control} = useForm();
    const {email, onSubmitted, onEsqueciMinhaSenha, onCancelar} = props;
    const {validate, required, length} = useValidation();

    return (
        <>
            <div className="content">
                <form onSubmit={handleSubmit(({senha}) => onSubmitted(senha))}>

                    <TextField value={email} label="E-mail" contentEditable={false}/>

                    <PasswordFormField
                        name="senha"
                        control={control}
                        label="Senha"
                        validate={
                            validate(
                                required(),
                                length({max: 50})
                            )
                        }
                    />

                    <div className="commands">
                        <Button variant="contained" type="submit" color="primary">Acessar</Button>
                        <Button variant="text" type="button"
                                onClick={onCancelar}
                                color="inherit">Cancelar</Button>
                        <br/>
                        <Button variant="outlined" type="button"
                                onClick={onEsqueciMinhaSenha}
                                color="inherit">Esqueci minha senha</Button>
                    </div>

                </form>
            </div>
        </>
    );
}
