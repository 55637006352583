import {Button} from "@mui/material";
import {useForm} from "react-hook-form";
import useValidation from "../../main-class/form/validation/useValidation";
import PasswordFormField from "../../main-class/form/input/PasswordFormField";
import useLoginValidation from "../login/useLoginValidation";
import useCriptografia from "../../main-class/componentes/util/useCriptografia";
import useLoader from "../../main-class/componentes/loader/useLoader";
import {useNavigate} from "react-router-dom";
import useMensageria from "../../main-class/componentes/mensageria/useMensageria";
import {AcessoApi, SenhaDTO} from "../../dao";
import ApiConfiguration from "../../infra/api/ApiConfiguration";


export default function RedefinirSenha() {
    const {handleSubmit, control} = useForm();

    const {validate, required, length} = useValidation();
    const {novaSenha} = useLoginValidation();
    const {criptografa} = useCriptografia();
    const {loadingAround} = useLoader();
    const navigate = useNavigate();
    const {addMensagemSucesso} = useMensageria();

    const acessoApi = new AcessoApi(ApiConfiguration);

    async function criptografaSenha(valor: string): Promise<SenhaDTO> {
        const chavePublica = await loadingAround(
            acessoApi.getChavePublica()
        );

        return {
            uuid: chavePublica.uuid,
            senha: criptografa(chavePublica.chavePublica, valor)
        };

    }

    async function redefineSenha(novaSenha: string, senhaAtual: string) {
        await loadingAround(
            acessoApi.alteraSenha({
                senhaAtualCriptografada: await criptografaSenha(senhaAtual),
                novaSenhaCriptografada: await criptografaSenha(novaSenha)
            })
        );


        navigate('/');

        addMensagemSucesso(`Senha redefinida com sucesso!`);
    }

    function cancela() {
        navigate("/")
    }


    return (
        <>
            <div className="content">
                <form onSubmit={handleSubmit(({novaSenha, senhaAtual}) => redefineSenha(novaSenha, senhaAtual))}>

                    <p style={{textAlign: "left"}}>
                        Agora defina uma nova senha de acesso.<br/>
                        <strong>Ela deve possuir:</strong>
                    </p>
                    <ul style={{textAlign: "left", padding: 0, color: "#666"}}>
                        <li>ao menos 8 caracteres;</li>
                        <li>ao menos 1 letra minúsculo;</li>
                        <li>ao menos 1 letra maiúsculo;</li>
                        <li>ao menos 1 número;</li>
                    </ul>

                    <PasswordFormField
                        name="senhaAtual"
                        control={control}
                        label="Senha Atual"
                        validate={
                            validate(
                                required(),
                                length({max: 50}),
                            )
                        }
                    />

                    <PasswordFormField
                        name="novaSenha"
                        control={control}
                        label="Sua Nova Senha"
                        validate={
                            validate(
                                required(),
                                length({max: 50}),
                                novaSenha()
                            )
                        }
                    />

                    <div className="commands">
                        <Button variant="contained" type="submit" color="primary">Redefinir</Button>
                        <Button variant="text" type="button"
                                onClick={cancela}
                                color="inherit">Cancelar</Button>
                    </div>

                </form>
            </div>
        </>
    );
}
