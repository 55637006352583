import {ReactNode, useEffect, useRef, useState} from "react";
import {PendenciaApi, PendenciaDTO, QuantidadePendenciasStatusTipoDTO} from "../../../../dao";
import ApiConfiguration from "../../../../infra/api/ApiConfiguration";
import {Box, Button} from "@mui/material";
import {Check, HighlightOff, Person, ThumbUpAlt} from "@mui/icons-material";
import {FiltroPendencia} from "../consulta/filtroContext";


export default function ResumoSituacaoPendencia(props: { filtro: FiltroPendencia, setFiltro: (filtro: FiltroPendencia) => any }) {
    const {setFiltro, filtro} = props;
    const api = useRef(new PendenciaApi(ApiConfiguration));

    const [quantidades, setQuantidades] = useState<QuantidadePendenciasStatusTipoDTO>({});

    useEffect(() => {
        api.current.consultaResumoGadosCliente().then(response => {
            return setQuantidades(response);
        });
    }, [api]);

    function filtrar(tipo?: PendenciaDTO.TipoEnum, status?: PendenciaDTO.StatusEnum) {
        setFiltro({...filtro, tipo, status});
    }

    return (
        <>
            <Box sx={{display: 'flex', gap: '20px', justifyContent: 'center', marginBottom: '20px'}}>

                <ButtonContagem
                    onClick={() => filtrar(null, PendenciaDTO.StatusEnum.Pendente)}
                    quantidade={quantidades.quantidadePendenciasPendentes}
                    label={"Pendente"}
                    icon={<Person fontSize={"large"}/>}
                    active={filtro?.status === PendenciaDTO.StatusEnum.Pendente && !filtro?.tipo}
                />

                <ButtonContagem
                    onClick={() => filtrar(PendenciaDTO.TipoEnum.Contagem, PendenciaDTO.StatusEnum.Aprovado)}
                    quantidade={quantidades.quantidadePendenciasContagemAprovadas}
                    label={"Contagem Aprovada"}
                    icon={<ThumbUpAlt fontSize={"large"} style={{color: "#66e762"}}/>}
                    active={filtro?.status === PendenciaDTO.StatusEnum.Aprovado &&
                        filtro?.tipo === PendenciaDTO.TipoEnum.Contagem}
                />

                <ButtonContagem
                    onClick={() => filtrar(PendenciaDTO.TipoEnum.Negociacao, PendenciaDTO.StatusEnum.Aprovado)}
                    quantidade={quantidades.quantidadePendenciasNegociacaoAprovadas}
                    label={"Negociação Aprovada"}
                    icon={<Check fontSize={"large"} style={{color: "#66e762"}}/>}
                    active={filtro?.status === PendenciaDTO.StatusEnum.Aprovado &&
                        filtro?.tipo === PendenciaDTO.TipoEnum.Negociacao}
                />

                <ButtonContagem
                    onClick={() => filtrar(null, PendenciaDTO.StatusEnum.RecusaConfirmada)}
                    quantidade={quantidades.quantidadePendenciasRecusadasConfirmadas}
                    label={"Recusa Confirmada"}
                    icon={<HighlightOff fontSize={"large"} style={{color: "#e02938"}}/>}
                    active={filtro?.status === PendenciaDTO.StatusEnum.RecusaConfirmada && !filtro?.tipo}
                />
            </Box>
        </>
    )

}

interface ButtonContagemProps {
    onClick: any;
    quantidade: number;
    label: string;
    icon: ReactNode;
    active: boolean;
}

export function ButtonContagem(props: ButtonContagemProps) {
    return (
        <>

            <Button type="button" onClick={props.onClick} disabled={props.quantidade === 0 || props.active}
                    style={{
                        backgroundColor: props.active ? '#dedede' : '#efefef',
                        padding: '10px',
                        width: '240px',
                        opacity: props.quantidade === 0 ? .75 : 1
                    }}>
                <Box sx={{display: 'flex', gap: '10px', width: 300, alignItems: 'center'}}>
                    {props.icon}
                    <Box sx={{flex: '1 1 auto'}}>
                        <h2 style={{margin: 0}}>{props.quantidade}</h2>
                        <h4 style={{margin: 0}}>{props.label}</h4>
                    </Box>
                </Box>
            </Button>
        </>
    )
}
