import {AnimalDTO} from "../../dao";
import {createContext} from "react";

export interface AnimalState {
    animal?: AnimalDTO;reload: () => any;
}

const AnimalContext = createContext<AnimalState>({
    reload: () => {
    }
});

export default AnimalContext;
