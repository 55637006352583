import * as React from "react";
import {useEffect} from "react";
import useSessao from "../../componentes/sessao/useSessao";
import {Outlet, Route, useNavigate} from "react-router-dom";

export default function privateRoutes(children: React.ReactNode, redirectPath = "/acesso", basePath = "/") {
    return <Route key="privateRoutes" path={basePath}
                  element={<Private redirectPath={redirectPath}/>}>
        {children}
    </Route>
}

function Private(props: { redirectPath: string }) {
    const {redirectPath} = props;
    const {isLoggedIn} = useSessao();
    const navigate = useNavigate();

    const loggedIn = isLoggedIn();

    useEffect(() => {
        if (!loggedIn) {
            navigate(redirectPath);
        }
    }, [loggedIn, navigate, redirectPath]);

    return (
        <>
            <Outlet/>
        </>
    );
}
