import {BehaviorSubject, from, Observable} from "rxjs";
import React, {createContext, useState} from "react";
import LoadingPanel from "./LoadingPanel";

export enum LoaderAction { show, hide}

class Loader {

    private executando = false;
    private ajaxCount: Array<string> = [];

    private actionSubject = new BehaviorSubject<LoaderAction>(LoaderAction.hide);

    get actionObservable(): Observable<LoaderAction> {
        return this.actionSubject.asObservable();
    }

    incrementaAJAX(executando: boolean = false): string {
        this.executando = this.executando || executando;

        const token = String(Math.random() * 1E10);

        this.ajaxCount.push(token);

        if (this.executando &&
            this.ajaxCount.length > 0 && this.actionSubject.value !== LoaderAction.show) {
            setTimeout(() => {

                if (this.executando && this.ajaxCount.length > 0 && this.actionSubject.value !== LoaderAction.show) {
                    this.actionSubject.next(LoaderAction.show);
                }

            }, 150);
        }

        return token;
    }

    decrementaAJAX(token: string) {
        const index = this.ajaxCount.indexOf(token);
        if (index >= 0) {
            this.ajaxCount.splice(index, 1);

            setTimeout(() => {
                if (this.ajaxCount.length <= 0) {
                    if (this.actionSubject.value === LoaderAction.show) {
                        this.actionSubject.next(LoaderAction.hide);
                        this.executando = false;
                    }
                }
            }, 500);
        }
    }

    public listen<T>(call: Observable<T> | Promise<T>): Observable<T> {
        this.executando = true;

        return this.doExecuta(from(call));
    }

    private doExecuta<T>(call: Observable<T>): Observable<T> {
        return new Observable<T>(subscriber => {

            const token = this.incrementaAJAX();

            const unsubs = call.subscribe({
                next: resp => {
                    subscriber.next(resp);
                },
                error: err => {
                    this.decrementaAJAX(token);

                    subscriber.error(err);
                },
                complete: () => {
                    this.decrementaAJAX(token);

                    subscriber.complete();
                }
            });

            return () => {
                this.decrementaAJAX(token);

                unsubs.unsubscribe();
            };
        });
    }

}

export const LoaderContext = createContext(new Loader());


export default function LoaderProvider(props: { children?: React.ReactNode }) {
    const [loader] = useState<Loader>(new Loader());

    return (
        <LoaderContext.Provider value={loader}>
            {props.children}

            <LoadingPanel/>
        </LoaderContext.Provider>
    );
}
