import {EmbarqueDTO} from "../../../../dao";
import {Add, Edit} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import useDialog from "../../../../main-class/template/dialog/useDialog";
import {useCallback, useState} from "react";
import DialogEdicaoNotasFiscaisEmbarque from "./DialogEdicaoNotasFiscaisEmbarque";

export function EditNotasFiscais(props: { embarque: EmbarqueDTO }) {
    const [embarque, setEmbarque] = useState(props.embarque);

    const {open} = useDialog();

    const onEditaNf = useCallback(() => {
        open<EmbarqueDTO>(
            <DialogEdicaoNotasFiscaisEmbarque
                value={embarque}
            />,
            {fullWidth: true, maxWidth: 'md'}
        ).afterClosed().subscribe((atualizado) => {
            if (!!atualizado) {
                setEmbarque(atualizado);
            }
        });
    }, [embarque, open]);

    return (
        <>
            <IconButton onClick={() => onEditaNf()}>
                {!embarque.notasFiscais?.length && <Add/>}
                {!!embarque.notasFiscais?.length && <Edit/>}
            </IconButton>
        </>
    );
}
