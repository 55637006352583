import {FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import * as React from "react";
import {useState} from "react";
import {Controller} from "react-hook-form";
import {FieldProps} from "./FieldProps";

export default function PasswordFormField(props: FieldProps<string>) {
    const {variant, label, name = 'field_' + new Date().getTime(), value} = props;
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <>
            <Controller
                name={name}
                control={props.control}
                defaultValue={value || ""}
                render={({field: {onChange, value}, fieldState: {error}}) => (
                    <FormControl variant={variant}>
                        <InputLabel htmlFor={name + "_input"}>{label}</InputLabel>
                        <OutlinedInput
                            name={name + "_input"}
                            type={showPassword ? 'text' : 'password'}
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end" color="primary"
                                    >
                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label={label}
                        />
                        <FormHelperText>{error ? error.message : null}</FormHelperText>
                    </FormControl>
                )}
                rules={{validate: props.validate}}
            />
        </>
    );
}
