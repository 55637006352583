import {UsuarioAdministradorApi} from "../../dao";
import ApiConfiguration from "../../infra/api/ApiConfiguration";
import useValidation from "../../main-class/form/validation/useValidation";
import useLabels from "../../i18n/useLabels";

export default function useUsuarioValidation() {
    const usuarioAdministradorApi = new UsuarioAdministradorApi(ApiConfiguration);
    const {email, cpf, doAsync} = useValidation();
    const {ptBR} = useLabels();

    function emailUnico(id: any) {
        return doAsync(async (value: string) => {
            if (!email()(value)) {
                const found = await usuarioAdministradorApi.buscaPorEmail(value);

                if (found && found.id !== id) {
                    return ptBR.validation.emailUnico;
                }
            }

            return null;
        });
    }

    function cpfUnico(id: any) {
        return doAsync(async (value: string) => {
            if (!cpf()(value)) {
                const found = await usuarioAdministradorApi.buscaPorCpfCnpj(value);

                if (found && found.id !== id) {
                    return ptBR.validation.cpfUnico;
                }
            }

            return null;
        });
    }

    return {emailUnico, cpfUnico};
}
