import {Box, Button, Card} from "@mui/material";
import React from "react";
import TextFormField from "../../../main-class/form/input/TextFormField";
import useCadastroLaudoEntrada from "./useCadastroLaudoEntrada";
import ItemRaca from "./components/ItemRaca";
import QuantidadeTotal from "./components/QuantidadeTotal";
import InputOcorrencias from "./components/InputOcorrencias";
import NumberFormField from "../../../main-class/form/input/NumberFormField";
import useValidation from "../../../main-class/form/validation/useValidation";
import TextAreaFormField from "../../../main-class/form/input/TextAreaFormField";
import {FileDownload} from "@mui/icons-material";


export default function CadastroLaudoEntrada() {
    const {formControl, submitForm, entity, downloadDocumentoAssinado, cadastroHabilitado} = useCadastroLaudoEntrada();
    const {validate, required, minMax, length} = useValidation();

    return (
        <>
            <form id="laudoForm" onSubmit={submitForm}>
                <Card>
                    <h2 style={{display: 'flex'}}>
                        Raças
                        <div style={{flex: '1 1 auto'}}/>
                        <QuantidadeTotal control={formControl}/>
                    </h2>
                    <Box sx={{display: 'flex', gap: '20px', flexWrap: 'wrap'}}>

                        {
                            entity.racas.map((qtdRaca, index) => <>
                                <Box key={'box-' + qtdRaca.raca.id} sx={{width: '350px'}}>
                                    <ItemRaca key={'item-qtdRaca-' + qtdRaca.raca.id} quantidadeRaca={qtdRaca}
                                              baseName={`racas[${index}]`} control={formControl}
                                              disabled={!cadastroHabilitado}
                                    />
                                </Box>
                            </>)
                        }

                    </Box>

                </Card>

                <Card>
                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <TextFormField name="idade" value={entity.idade}
                                       disabled={!cadastroHabilitado}
                                       validate={validate(
                                           required(),
                                           length({max: 20}),
                                       )}
                                       control={formControl} label="Idade"/>
                        <NumberFormField name="condEscoreCorporal" value={entity.condEscoreCorporal}
                                         control={formControl}
                                         disabled={!cadastroHabilitado}
                                         validate={validate(
                                             required(),
                                             minMax({min: 1, max: 5})
                                         )}
                                         label="Condição Escore Corporal"/>
                        <NumberFormField name="baiaProcessamentoTemporario" control={formControl}
                                         disabled={!cadastroHabilitado}
                                         validate={validate(
                                             required(),
                                         )}
                                         value={entity.baiaProcessamentoTemporario}
                                         label="Baia processamento temporário"/>
                    </Box>
                </Card>

                <Card>
                    <InputOcorrencias name={"ocorrencias"} control={formControl}
                                      disabled={!cadastroHabilitado} value={entity.ocorrencias}/>
                </Card>

                <Card>
                    <TextAreaFormField name="observacao" label="Observações"
                                       disabled={!cadastroHabilitado}
                                       validate={validate(
                                           length({max: 280}),
                                       )}
                                       value={entity.observacao} control={formControl}/>

                    <div className="acoes">
                        {!cadastroHabilitado &&
                            <Button onClick={downloadDocumentoAssinado} variant="outlined" color="primary">
                                <FileDownload />
                                Baixar Laudo de Entrada
                            </Button>}
                        {cadastroHabilitado &&
                            <Button type="submit" variant="contained" color="secondary" form="laudoForm">
                                Finalizar Laudo de Entrada
                            </Button>}
                    </div>
                </Card>
            </form>

        </>
    );
}


