import {FormControl, FormHelperText, InputLabel, OutlinedInput} from "@mui/material";
import {Controller} from "react-hook-form";
import {FieldProps} from "./FieldProps";
import {IMaskInput} from "react-imask";
import React from "react";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props, ref) {
        const {onChange, ...other} = props;
        return (
            <IMaskInput
                {...other}
                mask="000.000.000-00"
                inputRef={ref as any}
                onAccept={(value: any) => onChange({target: {name: props.name, value: value.replaceAll(/\D/g, '')}})}
                overwrite
            />
        );
    },
);

export default function CpfFormField(props: FieldProps<string>) {
    function actualOnChange(onChange: (...event: any[]) => void) {
        return (event: any) => {
            onChange(event);
            if (props.onChange) {
                props.onChange(event);
            }
        };
    }

    return (
        <>
            <Controller
                name={props.name}
                control={props.control}
                defaultValue={props.value || ""}
                rules={{validate: props.validate}}
                render={({field: {onChange, value}, fieldState: {error}}) => (
                    <FormControl variant={props.variant}>
                        <InputLabel htmlFor={props.name + "_input"}>{props.label}</InputLabel>
                        <OutlinedInput
                            value={value}
                            label={props.label}
                            name={props.name + "_input"}
                            onChange={actualOnChange(onChange)}
                            error={!!error}
                            inputComponent={TextMaskCustom as any}
                        />
                        <FormHelperText style={{color: 'red'}}>{error ? error.message : null}</FormHelperText>
                    </FormControl>
                )}
            />
        </>
    );
}
