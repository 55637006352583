import AutocompleteFormField from "../../../main-class/form/input/AutocompleteFormField";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {AnimalApi, LocalDTO} from "../../../dao";
import {FieldProps} from "../../../main-class/form/input/FieldProps";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import useLabels from "../../../i18n/useLabels";

export default function SelectLocal(props: FieldProps<LocalDTO>) {
    const animalApi = useRef(new AnimalApi(ApiConfiguration));
    const [locais, setLocais] = useState<Array<LocalDTO>>([]);
    const {ptBR} = useLabels();

    useEffect(() => {
        animalApi.current.consultaLocais().then(response => {
            setLocais(response)

        })
    }, [animalApi, setLocais]);


    const filtraLocais = useCallback(async (filtro: string): Promise<Array<LocalDTO>> => {
        if (!filtro) {
            return locais.slice(0, Math.min(locais.length, 30));
        }

        return locais
            .filter(l => l.nome.toLowerCase().indexOf(filtro.toLowerCase()) >= 0)
            .slice(0, Math.min(locais.length, 30));
    }, [locais])

    return (
        <>
            <AutocompleteFormField
                name={props.name} label={props.label}
                value={props.value} control={props.control}
                onChange={props.onChange} validate={props.validate}
                getOptionLabel={(local: LocalDTO) => local.nome + ' (' + ptBR.enum.tipo_local[local.tipo] + ')'}
                getOptions={filtraLocais} compareWith={(c1, c2) => c1.id === c2.id}
            />
        </>
    );
}
