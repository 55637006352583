import {Controller} from "react-hook-form";
import {FieldProps} from "./FieldProps";
import React from "react";
import useArquivo from "../../componentes/arquivo/useArquivo";
import {IArquivo} from "../../componentes/arquivo/ArquivoContext";
import MultipleArquivosField from "./MultipleArquivosField";

export interface MultipleArquivosFormFieldProps extends FieldProps<Array<IArquivo | any>> {
    accept?: string | string[];
    limit?: string;
}

export default function MultipleArquivosFormField(props: MultipleArquivosFormFieldProps) {
    const {fileLength, fileFormat} = useArquivo();
    const {disabled, accept, limit, label, variant} = props;

    function validateArquivo(arquivos: Array<IArquivo | any>) {
        const length = arquivos?.filter(arq => !!fileLength(props.limit)(arq));
        if (length?.length) {
            return "Os seguites arquivos ultrapaçam o valor limit de " + props.limit + ": " + length.map(arq => arq.nome).join(', ');
        }

        const format = arquivos?.filter(arq => !!fileFormat(props.accept)(arq))
        if (format?.length) {
            return "Os seguints arquivos possuem formato inválido: " + format.map(arq => arq.nome).join(', ');
        }

        const erro = arquivos?.filter(arq => !!arq.error)
        if (erro?.length) {
            return "Falha no upload dos seguintes arquivos: " + erro.map(arq => arq.nome).join(', ');
        }

        if (arquivos?.find(arq => arq.uploadProgress !== undefined)) {
            return "Aguarde o término do upload dos arquivos.";
        }

        if (props.validate) {
            return props.validate(arquivos);
        }

        return null;
    }

    return (
        <>
            {
                !!props.control &&
                <Controller
                    name={props.name}
                    control={props.control}
                    defaultValue={props.value}
                    rules={{validate: validateArquivo}}
                    render={({field: {onChange, value}, fieldState: {error}}) => {
                        return (
                            <>
                                <MultipleArquivosField
                                    value={value}
                                    onChange={onChange}
                                    disabled={disabled}
                                    accept={accept}
                                    limit={limit}
                                    label={label}
                                    error={error}
                                    variant={variant}
                                />
                            </>
                        );
                    }}
                />
            }
            {
                !props.control &&
                <MultipleArquivosField
                    value={props.value}
                    disabled={disabled}
                    accept={accept}
                    limit={limit}
                    label={label}
                    variant={variant}
                />
            }

        </>
    );
}
