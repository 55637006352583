import React from "react";

export interface ColumnCellProps<T> {
    className?: string;
    style?: React.CSSProperties;
    content: (item: T) => React.ReactNode;
}

export default function ColumnCell<T>(props: ColumnCellProps<T>) {
    return <></>;
}
