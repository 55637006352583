import {useCallback} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useSumarioLote from "../sumario/useSumarioLote";

export default function useDetalheLote() {
    const {lote: id} = useParams();
    const {lote} = useSumarioLote();
    const {etapa} = useParams();
    const navigate = useNavigate();

    const onDetalhe = useCallback(() => {
        navigate(`/lote/${id}/midia-desembarque`);
    }, [id, navigate]);

    const onLaudoEntrada = useCallback(() => {
        navigate(`/lote/${id}/laudo-entrada`);
    }, [id, navigate]);

    const onMovimentacao = useCallback(() => {
        navigate(`/lote/${id}/movimentacao`);
    }, [id, navigate]);

    const onRondaSanitaria = useCallback(() => {
        navigate(`/lote/${id}/tratamento`);
    }, [id, navigate]);

    const onProcessamentoEntrada = useCallback(() => {
        navigate(`/lote/${id}/etapa/processamento_entrada`);
    }, [navigate, id])

    const onProcessamentoSaida = useCallback(() => {
        navigate(`/processamento-saida/lote/${id}`);
    }, [navigate, id])

    return {
        onDetalhe,
        onLaudoEntrada,
        lote,
        onProcessamentoEntrada,
        onProcessamentoSaida,
        onRondaSanitaria,
        onMovimentacao,
        etapa,
        navigate
    };
}
