// @ts-ignore
import {PaginaProcessamentoSaidaLoteDTO, ProcessamentoSaidaApi, ProcessamentoSaidaDTO} from "../../../dao";

import {useCallback, useContext, useEffect, useRef} from "react";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import {useNavigate, useParams} from "react-router-dom";
import useConsulta from "../../../main-class/form/crud/useConsulta";
import useSumarioLote from "../../lote/sumario/useSumarioLote";
import SincronizacaoContext from "../../../infra/sincronizacao/SincronizacaoContext";

export interface FiltroProcessamentoSaida {
    codigo?: string;
    data?: Date;
}

export default function useConsultaLoteProcessamentoSaida() {

    const api = useRef(new ProcessamentoSaidaApi(ApiConfiguration));

    const navigate = useNavigate();

    const {lote} = useSumarioLote();

    const {lote: idLote} = useParams();

    const sincornizacaoState = useContext(SincronizacaoContext);

    const {
        filtro,
        pagina,
        consulta,
        formControl,
        submitForm
    } = useConsulta<FiltroProcessamentoSaida, ProcessamentoSaidaDTO>({
        consultaAction: (filtro, pagina, tamanhoPagina) => api.current.consultaByLote(
            filtro.codigo,
            filtro.data,
            undefined,
            undefined,
            Number(idLote),
            pagina,
            tamanhoPagina
        ),
    });

    const onDetalhe = useCallback((processamento: ProcessamentoSaidaDTO) => {
        navigate(`/processamento-saida/cliente/${lote.proprietario.id}/detalhe/${processamento.id}`);
    }, [navigate, lote]);

    useEffect(() => {
        const subscription = sincornizacaoState.saidaObservable
            .subscribe(() => consulta())

        return () => subscription.unsubscribe();
    }, [sincornizacaoState, consulta]);

    return {
        pagina: pagina as PaginaProcessamentoSaidaLoteDTO,
        consulta,
        filtro,
        formControl,
        submitForm,
        onDetalhe
    };


}
