import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Control, Controller } from 'react-hook-form';

interface Props {
  name: string;
  control: Control;
  defaultValue:string

}

export default function TextEditor({ name, control,defaultValue }: Props) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, onChange } }) => (
        <ReactQuill
          value={value}
          onChange={onChange}
          modules={{
            toolbar: [

              [{header: [1,2,3,4,5,6,false]}],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{list:'ordered' },{list: 'bullet'}],
              [{align: [] },{direction: 'rtl'}],
              ['clean'],

              [{'color':[] },{'background': [] }],
              [{'size':['small',false, 'large', 'huge'] }],

              ['table', 'code-block'],

              [{script:'sub'}, {script:'super'}],

              [{indent:'-1'}, {indent:'+1'}],

              ['link'],
            ],
          }}
          formats={['header','bold','italic','underline','strike','blockquote','list','bullet','link','image','color','background','size','table','align','script','code-block','indent','direction']}
          style={{ height: '300px' }}
        />
      )}
    />
  );
}
