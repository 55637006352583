import {useCallback, useContext} from "react";
import {LoaderContext} from "./Loader";
import {lastValueFrom, Observable} from "rxjs";

export default function useLoader() {
    const loader = useContext(LoaderContext);

    return {
        loadingAround: useCallback(<T>(call: Observable<T> | Promise<T>) => lastValueFrom(loader.listen(call)), [loader]),
    };
}
