import { Box, Button } from "@mui/material";
import EdicaoPage from "../../../main-class/template/crud/EdicaoPage";
import useEdicaoNoticia from "./useEdicaoNoticia";
import TextEditor from "../../../componentes/textEditor/TextEditor";
import TextFormField from "../../../main-class/form/input/TextFormField";
import ArquivoFormField from "../../../main-class/form/input/ArquivoFormField";
import useValidation from "../../../main-class/form/validation/useValidation";

export default function EdicaoNoticia() {
    const {entity, submitForm, cancela, formControl} = useEdicaoNoticia();
    const {validate, required} = useValidation();

    return(
        <EdicaoPage>
            <form onSubmit={submitForm}>
                <Box sx={{display: 'flex', gap:'20px'}}>
                    <Box sx={{flex: 1}}>
                        <TextFormField label="Título" value={entity.titulo} control={formControl} name="titulo"validate={validate(required())}/>
                        </Box>
                    </Box>

                <Box sx={{display: 'flex', gap:'20px'}}>
                    <Box sx={{flex: 2}}>
                        <TextFormField label="Link" value={entity.link} control={formControl} name="link" validate={
                                validate(required())}/>
                    </Box>

                    <Box sx={{flex: 2}}>
                        <TextFormField label="Autor" value={entity.autor} control={formControl} name="autor" validate={
                                validate(required())}/>
                    </Box>
                </Box>

                <ArquivoFormField label="Capa" name="capa" accept={[".png", ".jpeg", ".jpg"]}
                                  control={formControl} value={entity.capa}
                                  validate={
                                      validate(required())}
                />

                <Box sx={{display: 'flex', gap: '20px'}}>
                    <Box sx={{flex: 2}}>
                        <TextEditor
                            name="noticia"
                            control={formControl}
                            defaultValue={entity.noticia}/>
                    </Box>
                </Box>

                <div className="acoes">
                    <Button type="submit" variant="contained" color="secondary">Salvar</Button>
                    <Button type="button" color="inherit" onClick={cancela}>Cancelar</Button>
                </div>
            </form>
        </EdicaoPage>
    );
}
