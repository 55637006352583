import {Card} from "@mui/material";
import {Search} from "@mui/icons-material";
import React from "react";

export default function ConsultaPage(props: { filtro: React.ReactNode, children: React.ReactNode }) {
    const {filtro, children} = props;

    return (
        <>
            {
                filtro &&
                <Card>
                    <h2><Search/> {"Filtrar"}</h2>

                    {filtro}
                </Card>
            }

            <Card>
                {children}
            </Card>
        </>
    );
}
