import React, {useContext} from "react";
import {TemplateConfigContext} from "../base/Base";
import "./Acesso.scss";
import {useTheme, alpha} from "@mui/material";


export default function Acesso(props: { titulo?: string, children?: React.ReactNode, background?: React.ReactNode }) {
    const config = useContext(TemplateConfigContext);
    const {titulo, children, background} = props;
    const theme = useTheme();

    return (
        <>
            {background || ''}
            <div className="acesso" style={{backgroundColor: alpha(theme.palette.primary.main, .35)}}>
                <div className="form">
                    <div className="logo">
                        {config.logoSrc && <img src={config.logoSrc} alt="Logo Fazenda Santa Fé"/>}
                        {config.logoComponent || ''}
                    </div>

                    {titulo && <h2 style={{color: theme.palette.primary.main}}>{titulo}</h2>}

                    {children || ''}
                </div>
            </div>
        </>
    );
}
