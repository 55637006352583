import {Box, Card, MenuItem} from "@mui/material";
import TextFormField from "../../main-class/form/input/TextFormField";
import DateFormField from "../../main-class/form/input/DateFormField";
import SelectFormField from "../../main-class/form/input/SelectFormField";
import PaginatedTable from "../../main-class/componentes/table/PaginatedTable";
import TableColumn from "../../main-class/componentes/table/TableColumn";
import useFormat from "../../main-class/componentes/format/useFormat";
import {Search} from "@mui/icons-material";
import ColumnHeader from "../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../main-class/componentes/table/ColumnCell";
import {TratamentoDTO} from "../../dao";
import useConsultaTratamento from "./useConsultaTratamento";
import React from "react";
import NumberFormField from "../../main-class/form/input/NumberFormField";

export default function ConsultaTratamento() {
    const {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        motivos
    } = useConsultaTratamento();

    const {formatDate, formatCurrency} = useFormat();

    return (
        <>

            <Card>
                <h2><Search/> Filtrar</h2>

                <form onSubmit={submitForm}>

                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{flex: '1'}}>

                            <TextFormField label="BOSCH" value={filtro.bosch} control={formControl} name="bosch"
                                           onChange={submitForm}
                            />
                            <TextFormField label="SISBOV" value={filtro.sisbov} control={formControl} name="sisbov"
                                           onChange={submitForm}
                            />
                            <NumberFormField name="valorMinimo"
                                             value={filtro.valorMinimo}
                                             control={formControl}
                                             onChange={submitForm}
                                             key="minimo"
                                             label="Valor mínimo"/>

                        </Box>
                        <Box sx={{flex: '1'}}>
                            <DateFormField label="Data da finalizacao" value={filtro.data} control={formControl}
                                           name="data"
                                           onChange={submitForm}
                            />
                            <TextFormField label="Lote" value={filtro.lote} control={formControl} name="lote"
                                           onChange={submitForm}
                            />
                            <NumberFormField name="valorMaximo"
                                             value={filtro.valorMaximo}
                                             control={formControl}
                                             onChange={submitForm}
                                             key="maximo"
                                             label="Valor máximo"/>

                        </Box>

                        <Box sx={{flex: '1'}}>


                            <SelectFormField name="motivo" label="Motivo"
                                             value={filtro.motivo} control={formControl}
                                             onChange={submitForm}>
                                <MenuItem value={null}/>
                                {
                                    motivos.map(mtv =>
                                        <MenuItem key={"MenuItem_motivo_" + mtv} value={mtv.id}>
                                            {mtv.nome}
                                        </MenuItem>
                                    )
                                }
                            </SelectFormField>
                        </Box>

                    </Box>

                </form>
            </Card>


            <Card>
                <PaginatedTable onPageChange={(pagina, tamanhoPagina) => consulta(filtro, {pagina, tamanhoPagina})}
                                pagina={pagina}>

                    <TableColumn name="data" style={{textAlign: 'center'}}>
                        <ColumnHeader>Data</ColumnHeader>
                        <ColumnCell
                            content={(item: TratamentoDTO) => formatDate(item.data as any, 'DD/MM/YYYY')}/>
                    </TableColumn>

                    <TableColumn name="lote" style={{textAlign: 'center'}}>
                        <ColumnHeader>Lote</ColumnHeader>
                        <ColumnCell content={(item: TratamentoDTO) => item.animal?.lote?.codigo}/>
                    </TableColumn>

                    <TableColumn name="bosch" style={{textAlign: 'center'}}>
                        <ColumnHeader>Bosch</ColumnHeader>
                        <ColumnCell content={(item: TratamentoDTO) => item.animal.bosch}/>
                    </TableColumn>

                    <TableColumn name="sisbov" style={{textAlign: 'center'}}>
                        <ColumnHeader>Sisbov</ColumnHeader>
                        <ColumnCell content={(item: TratamentoDTO) => item.animal.sisbov}/>
                    </TableColumn>

                    <TableColumn name="motivoTratamento" style={{textAlign: 'center'}}>
                        <ColumnHeader>Motivo do tratamento</ColumnHeader>
                        <ColumnCell content={(item: TratamentoDTO) => item.motivo.nome}/>
                    </TableColumn>

                    <TableColumn name="valorProtocolo" style={{textAlign: 'right'}}>
                        <ColumnHeader>Valor do protocolo</ColumnHeader>
                        <ColumnCell content={(item: TratamentoDTO) => formatCurrency(item.valorProtocolo)}/>
                    </TableColumn>
                </PaginatedTable>
            </Card>
        </>


    );
}

