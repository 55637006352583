import {ArquivoManager, IArquivo, UploadProgress} from "../../main-class/componentes/arquivo/ArquivoContext";
import * as CryptoJS from 'crypto-js';
import {ArquivoApi} from "../../dao";
import ApiConfiguration from "../api/ApiConfiguration";
import {useRef} from "react";

class ArquivoManagerImpl implements ArquivoManager {
    private arquivoApi = new ArquivoApi(ApiConfiguration);

    async getURL(arquivo: IArquivo): Promise<string> {
        return (arquivo as any).url;
    }

    async upload(file: File, callback?: UploadProgress): Promise<IArquivo> {
        callback({
            type: 'progresso',
            attachment: {
                uploadProgress: 0,
                nome: file.name,
                tamanho: file.size,
            }
        });

        const arquivo: IArquivo = await new Promise((resolve, reject) => {

            const reader = new FileReader();

            reader.onload = async () => {
                let arrayBuffer: any = reader.result;

                let wordBuffer = CryptoJS.lib.WordArray.create(arrayBuffer);

                const config = await this.arquivoApi.geraConfiguracaoUpload({
                    nome: file.name,
                    sha256: CryptoJS.SHA256(wordBuffer).toString(CryptoJS.enc.Hex),
                    tamanho: arrayBuffer.byteLength
                });

                const xhr = new XMLHttpRequest();
                const sucesso = await new Promise((resolve) => {
                    xhr.upload.addEventListener("progress", (event) => {
                        if (event.lengthComputable) {
                            callback({
                                type: 'progresso',
                                attachment: {
                                    uploadProgress: event.loaded / event.total,
                                    nome: file.name,
                                    tamanho: file.size,
                                }
                            });
                        }
                    });
                    xhr.addEventListener("loadend", () => {
                        if (xhr.readyState === 4) {
                            if (xhr.status >= 200 && xhr.status < 300) {
                                resolve(true);
                            } else {
                                reject(xhr.responseText);
                            }
                        }
                    });
                    xhr.open("PUT", config.url, true);
                    for (const header of Object.keys(config.headers)) {
                        xhr.setRequestHeader(header, config.headers[header]);
                    }
                    xhr.send(arrayBuffer);
                });

                if (sucesso) {
                    callback({
                        type: 'conclusao',
                        attachment: config.arquivo as IArquivo
                    });

                    resolve(config.arquivo as IArquivo);
                } else {
                    reject("Upload error");
                }
            };

            reader.readAsArrayBuffer(file);
        });

        return arquivo;
    }
}

export default function useArquivoManager() {
    const arquivoManager = useRef(new ArquivoManagerImpl());

    return {arquivoManager: arquivoManager.current};
}
