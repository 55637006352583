import Acesso from "../../main-class/template/acesso/Acesso";
import Senha from "./steps/Senha";
import useLogin, {LoginStep} from "./useLogin";
import Email from "./steps/Email";
import Otp from "./steps/Otp";
import "./Login.scss";
import CadastroSenha from "./steps/CadastroSenha";

export default function Login() {
    const {
        state,
        consultaEmail,
        realizaLogin,
        cancela,
        solicitaOTP,
        setOTP,
        redefineSenha,
        podeSolicitarOTP
    } = useLogin();

    return (
        <Acesso titulo="Painel Administrativo" background={
            <video autoPlay muted loop style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                overflow: 'hidden'
            }}>
                <source src="./background.mp4" type="video/mp4" />
            </video>
        }>
            {state.step === LoginStep.email && <Email onSubmitted={consultaEmail}/>}
            {state.step === LoginStep.senha && <Senha email={state.email} onSubmitted={realizaLogin}
                                                      onCancelar={cancela} onEsqueciMinhaSenha={solicitaOTP}/>}
            {state.step === LoginStep.otp && <Otp onSubmitted={setOTP} podeGerarNovoCodigo={podeSolicitarOTP}
                                                  email={state.email} onCancela={cancela}
                                                  onGeraNovoCodigo={solicitaOTP}/>}
            {state.step === LoginStep.cadastro_senha && <CadastroSenha
                onSubmitted={redefineSenha} onCancelar={cancela}/>}
        </Acesso>
    );
}
