import {
    AnimalApi, DenunciaApi, DenunciaDTO,
    DesembarqueApi,
    DesembarqueDTO, LaudoMorteDTO,
    LoteApi,
    LoteDTO,
    PendenciaApi,
    PendenciaDTO,
    UsuarioAdministradorDTO
} from "../../../dao";
import {useEffect, useRef, useState} from "react";
import useLoader from "../../../main-class/componentes/loader/useLoader";
import {Box, Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import "./RelatorioButton.scss";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import usePermissao from "../../../infra/seguranca/usePermissao";

export function RelatorioGeral() {
    const desembarqueApi = useRef(new DesembarqueApi(ApiConfiguration));
    const loteApi = useRef(new LoteApi(ApiConfiguration));
    const pendenciaApi = useRef(new PendenciaApi(ApiConfiguration));
    const animalApi = useRef(new AnimalApi(ApiConfiguration));
    const denunciaApi = useRef(new DenunciaApi(ApiConfiguration));

    const {loadingAround} = useLoader();
    const navigate = useNavigate();
    const {any} = usePermissao();

    const [qtdDesembarquesPendente, setQtdDesembarquesPendente] = useState(0);
    const [qtdDesembarquesIniciado, setQtdDesembarquesIniciado] = useState(0);
    const [qtdLotesPendentes, setQtdLotesPendentes] = useState(0);
    const [qtdContagensPendentes, setQtdContagensPendentes] = useState(0);
    const [qtdContagensAprovadas, setQtdContagensAprovadas] = useState(0);
    const [qtdNegociacoesAprovadas, setQtdNegociacoesAprovadas] = useState(0);
    const [qtdPendenciasRecusaConfirmada, setQtdPendenciasRecusaConfirmada] = useState(0);
    const [qtdProcessamentoEntrada, setQtdProcessamentoEntrada] = useState(0);
    const [qtdLaudosEntrada, setQtdLaudosEntrada] = useState(0);
    const [qtdLaudosMortePendente, setQtdLaudosMortePendente] = useState(0);
    const [qtdLaudosMorteIniciado, setQtdLaudosMorteIniciado] = useState(0);
    const [qtdDenunciasComentariosPendentes, setQtdDenunciasComentariosPendentes] = useState(0);

    useEffect(() => {
        if (any(UsuarioAdministradorDTO.PerfilEnum.Gerencial, UsuarioAdministradorDTO.PerfilEnum.Administrativo)) {

            loadingAround(
                denunciaApi.current.consulta(
                    undefined,
                    undefined,
                    DenunciaDTO.StatusEnum.Pendente,
                    undefined,
                    1,
                    0
                )
            ).then(pagina => setQtdDenunciasComentariosPendentes(pagina.totalItens || 0));

        }
    }, [
        any,
        denunciaApi,
        loadingAround,
        setQtdDenunciasComentariosPendentes,
    ]);

    useEffect(() => {
        if (any(UsuarioAdministradorDTO.PerfilEnum.Gerencial, UsuarioAdministradorDTO.PerfilEnum.Administrativo, UsuarioAdministradorDTO.PerfilEnum.Veterinario)) {

            loadingAround(
                desembarqueApi.current.consulta(
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    DesembarqueDTO.StatusEnum.Pendente,
                    undefined,
                    1,
                    0
                )
            ).then(pagina => setQtdDesembarquesPendente(pagina.totalItens || 0));

            loadingAround(
                desembarqueApi.current.consulta(
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    DesembarqueDTO.StatusEnum.Iniciado,
                    undefined,
                    1,
                    0
                )
            ).then(pagina => setQtdDesembarquesIniciado(pagina.totalItens || 0));

            loadingAround(
                loteApi.current.consulta(
                    undefined,
                    undefined,
                    undefined,
                    LoteDTO.StatusEnum.Pendente,
                    undefined,
                    1,
                    0
                )
            ).then(pagina => setQtdLotesPendentes(pagina.totalItens || 0));

            loadingAround(
                loteApi.current.consulta(
                    undefined,
                    undefined,
                    LoteDTO.EtapaEnum.ProcessamentoEntrada,
                    undefined,
                    undefined,
                    1,
                    0
                )
            ).then(pagina => setQtdProcessamentoEntrada(pagina.totalItens || 0));

        }
    }, [
        any,
        desembarqueApi,
        loteApi,
        loadingAround,
        setQtdDesembarquesPendente,
        setQtdDesembarquesIniciado,
        setQtdLotesPendentes,
        setQtdProcessamentoEntrada,
    ]);

    useEffect(() => {
        if (any(UsuarioAdministradorDTO.PerfilEnum.Gerencial, UsuarioAdministradorDTO.PerfilEnum.Administrativo, UsuarioAdministradorDTO.PerfilEnum.Comercial)) {

            loadingAround(
                pendenciaApi.current.consultaPendencias(
                    undefined,
                    undefined,
                    PendenciaDTO.StatusEnum.Pendente,
                    undefined,
                    undefined,
                    1,
                    0
                )
            ).then(pagina => setQtdContagensPendentes(pagina.totalItens || 0));

            loadingAround(
                pendenciaApi.current.consultaPendencias(
                    undefined,
                    PendenciaDTO.TipoEnum.Contagem,
                    PendenciaDTO.StatusEnum.Aprovado,
                    undefined,
                    undefined,
                    1,
                    0
                )
            ).then(pagina => setQtdContagensAprovadas(pagina.totalItens || 0));

            loadingAround(
                pendenciaApi.current.consultaPendencias(
                    undefined,
                    PendenciaDTO.TipoEnum.Negociacao,
                    PendenciaDTO.StatusEnum.Aprovado,
                    undefined,
                    undefined,
                    1,
                    0
                )
            ).then(pagina => setQtdNegociacoesAprovadas(pagina.totalItens || 0));

            loadingAround(
                pendenciaApi.current.consultaPendencias(
                    undefined,
                    undefined,
                    PendenciaDTO.StatusEnum.RecusaConfirmada,
                    undefined,
                    undefined,
                    1,
                    0
                )
            ).then(pagina => setQtdPendenciasRecusaConfirmada(pagina.totalItens || 0));
        }
    }, [
        any,
        pendenciaApi,
        loadingAround,
        setQtdContagensPendentes,
        setQtdContagensAprovadas,
        setQtdNegociacoesAprovadas,
        setQtdPendenciasRecusaConfirmada,
    ]);

    useEffect(() => {
        if (any(UsuarioAdministradorDTO.PerfilEnum.Veterinario)) {
            loadingAround(
                loteApi.current.consulta(
                    undefined,
                    undefined,
                    LoteDTO.EtapaEnum.Desembarque,
                    LoteDTO.StatusEnum.Ativo,
                    undefined,
                    1,
                    0
                )
            ).then(pagina => setQtdLaudosEntrada(pagina.totalItens || 0));

            loadingAround(
                animalApi.current.consultaLaudoMorte(
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    LaudoMorteDTO.StatusEnum.Pendente,
                    undefined,
                    undefined,
                    undefined,
                    1,
                    0
                )
            ).then(pagina => setQtdLaudosMortePendente(pagina.totalItens || 0));

            loadingAround(
                animalApi.current.consultaLaudoMorte(
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    LaudoMorteDTO.StatusEnum.Iniciado,
                    undefined,
                    undefined,
                    undefined,
                    1,
                    0
                )
            ).then(pagina => setQtdLaudosMorteIniciado(pagina.totalItens || 0));
        }
    }, [any, animalApi, loadingAround, setQtdLaudosEntrada, setQtdLaudosMortePendente, setQtdLaudosMorteIniciado]);

    return (
        <>
            <Box className="itens-dashboard" sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', gap: '20px', marginBottom: '20px'}}>
                {
                    any(UsuarioAdministradorDTO.PerfilEnum.Gerencial, UsuarioAdministradorDTO.PerfilEnum.Administrativo, UsuarioAdministradorDTO.PerfilEnum.Veterinario) &&
                    <>
                        <Button type="button" disabled={!qtdDesembarquesPendente}
                                onClick={() => navigate('/desembarque?status=pendente')}>
                            <h1>{qtdDesembarquesPendente}</h1>
                            <h3>Desembarques pendentes</h3>
                        </Button>
                        <Button type="button" disabled={!qtdLotesPendentes}
                                onClick={() => navigate('/lote?status=pendente')}>
                            <h1>{qtdLotesPendentes}</h1>
                            <h3>Lotes pendentes</h3>
                        </Button>
                        <Button type="button" disabled={!qtdDesembarquesIniciado}
                                onClick={() => navigate('/desembarque?status=iniciado')}>
                            <h1>{qtdDesembarquesIniciado}</h1>
                            <h3>Desembarques iniciados</h3>
                        </Button>
                        <Button type="button" disabled={!qtdProcessamentoEntrada}
                                onClick={() => navigate('/etapa/processamento_entrada')}>
                            <h1>{qtdProcessamentoEntrada}</h1>
                            <h3>Processamentos de entrada</h3>
                        </Button>
                    </>
                }
                {
                    any(UsuarioAdministradorDTO.PerfilEnum.Gerencial, UsuarioAdministradorDTO.PerfilEnum.Administrativo) &&
                    <>
                        <Button type="button" disabled={!qtdDenunciasComentariosPendentes}
                                onClick={() => navigate('/noticia/denuncia?status=pendente')}>
                            <h1>{qtdDenunciasComentariosPendentes}</h1>
                            <h3>Denúncias Pendentes</h3>
                        </Button>
                    </>
                }
                {
                    any(UsuarioAdministradorDTO.PerfilEnum.Veterinario) &&
                    <>
                        <Button type="button" disabled={!qtdLaudosEntrada}
                                onClick={() => navigate('/laudo-entrada')}>
                            <h1>{qtdLaudosEntrada}</h1>
                            <h3>Laudos de entrada</h3>
                        </Button>
                        <Button type="button" disabled={!qtdLaudosMortePendente}
                                onClick={() => navigate('/laudo-morte?status=pendente')}>
                            <h1>{qtdLaudosMortePendente}</h1>
                            <h3>Laudos de óbito pendentes</h3>
                        </Button>
                        <Button type="button" disabled={!qtdLaudosMorteIniciado}
                                onClick={() => navigate('/laudo-morte?status=iniciado')}>
                            <h1>{qtdLaudosMorteIniciado}</h1>
                            <h3>Laudos de óbito iniciados</h3>
                        </Button>
                    </>
                }
                {
                    any(UsuarioAdministradorDTO.PerfilEnum.Gerencial, UsuarioAdministradorDTO.PerfilEnum.Administrativo, UsuarioAdministradorDTO.PerfilEnum.Comercial) &&
                    <>
                        <Button type="button" disabled={!qtdContagensPendentes}
                                onClick={() => navigate('/boigordo?status=pendente')}>
                            <h1>{qtdContagensPendentes}</h1>
                            <h3>Contagens pendentes</h3>
                        </Button>
                        <Button type="button" disabled={!qtdContagensAprovadas}
                                onClick={() => navigate('/boigordo?status=aprovado&tipo=contagem')}>
                            <h1>{qtdContagensAprovadas}</h1>
                            <h3>Contagens aprovadas</h3>
                        </Button>
                        <Button type="button" disabled={!qtdNegociacoesAprovadas}
                                onClick={() => navigate('/boigordo?status=aprovado&tipo=negociacao')}>
                            <h1>{qtdNegociacoesAprovadas}</h1>
                            <h3>Negociações aprovadas</h3>
                        </Button>
                        <Button type="button" disabled={!qtdPendenciasRecusaConfirmada}
                                onClick={() => navigate('/boigordo?status=recusa_confirmada')}>
                            <h1>{qtdPendenciasRecusaConfirmada}</h1>
                            <h3>Recusas confirmadas</h3>
                        </Button>
                    </>
                }
            </Box>
        </>
    );
}
