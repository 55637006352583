import useConsulta from "../../../../../main-class/form/crud/useConsulta";
import {useParams} from "react-router-dom";
import {useContext, useEffect, useRef} from "react";
import {DesembarqueApi} from "../../../../../dao";
import ApiConfiguration from "../../../../../infra/api/ApiConfiguration";
import SincronizacaoContext from "../../../../../infra/sincronizacao/SincronizacaoContext";

export default function useDesembarqueLote() {
    const desembarqueApi = useRef(new DesembarqueApi(ApiConfiguration));
    const {lote} = useParams();
    const sincornizacaoState = useContext(SincronizacaoContext);

    const {pagina, consulta} = useConsulta({
        consultaAction: (filtro, pagina, tamanhoPagina) => desembarqueApi.current.consulta(
            undefined, undefined, Number(lote), undefined, undefined, undefined, pagina, tamanhoPagina
        ),
    });

    useEffect(() => {
        const subscription = sincornizacaoState.desembarqueObservable
            .subscribe(() => consulta())

        return () => subscription.unsubscribe();
    }, [sincornizacaoState, consulta]);

    return {pagina, consulta};
}
