import {UsuarioAdministradorApi, UsuarioAdministradorDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import {useNavigate} from "react-router-dom";
import useEdicao from "../../../main-class/form/crud/useEdicao";

export default function useEdicaoUsuario() {
    const usuarioAdministradorApi = new UsuarioAdministradorApi(ApiConfiguration);
    const navigate = useNavigate();
    const {entity, submitForm, formControl} = useEdicao({
        findAction: id => usuarioAdministradorApi.detalha(id),
        saveAction: entity => usuarioAdministradorApi.salva(entity),
        postSave: entity => navigate('/usuario')
    });

    const cancela = () => {
        navigate("/usuario");
    }

    const perfis = Object.values(UsuarioAdministradorDTO.PerfilEnum);

    return {entity, submitForm, formControl, cancela, perfis};
}
