import buildTabRoute from "../../main-class/template/route/buildTabRoute";
import ConsultaDesembarque from "./consulta/ConsultaDesembarque";

export default function desembarqueRoute() {
    return buildTabRoute({
        path: "/desembarque",
        title: "Desembarque",
        element: <ConsultaDesembarque/>,
    })
}
