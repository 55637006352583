import {Card} from "@mui/material";
import React from "react";
import "./DetalhePendencia.scss";
import SumarioPendenciaQtdAnimais from "../sumario/SumarioPendenciaQtdAnimais";
import ConsultaHistoricoPendencia from "./componentes/ConsultaHistoricoPendencia";
import DadosPendencia from "./componentes/DadosPendencia";
import useDetalhePendencia from "./useDetalhePendencia";

export default function DetalhePendencia() {
    const {pendencia, refresh} = useDetalhePendencia();

    return (
        <>

            {pendencia && <SumarioPendenciaQtdAnimais pendencia={pendencia}/>}

            <Card>

                <h2>Código: {pendencia?.codigo}</h2>


                <ConsultaHistoricoPendencia/>


                {pendencia && <DadosPendencia pendencia={pendencia} onUpdate={refresh}/>}
            </Card>
        </>
    );
}

