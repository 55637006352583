import {useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {PendenciaApi, QuantidadeAnimaisPorDiasDeCochoDTO, QuantidadesAnimaisPorDiasDeCochoDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import useLoader from "../../../main-class/componentes/loader/useLoader";

export default function useGraficoPendencia() {
    const {cliente} = useParams();

    const [quantidades, setQuantidades] = useState<QuantidadesAnimaisPorDiasDeCochoDTO>();

    const pendenciaApi = useRef(new PendenciaApi(ApiConfiguration));

    const {loadingAround} = useLoader();

    const label = (props: { payload: any, x: any, y: any, width: any, height: any, value: any }) => {
        return Number(props.value) > 0 ?
            <text x={props.x + props.width / 2} y={props.y} fill="#808080" stroke="#808080" textAnchor="middle"
                  dy={-6}>{`${props.value}`}</text> : <text/>;
    };

    const max = (quantidades: QuantidadeAnimaisPorDiasDeCochoDTO []) => {
        return !!quantidades ? Math.max(...quantidades.map(q => q.quantidadeMacho), ...quantidades.map(q => q.quantidadeFemea)) + 20 : 1000;
    }

    useEffect(() => {
        loadingAround(pendenciaApi.current.consultaQuantidadesGadosCliente(Number(cliente)))
            .then(setQuantidades);
    }, [setQuantidades, loadingAround, pendenciaApi, cliente]);

    return {
        label,
        max,
        cliente,
        quantidades,
        menorDia: Math.min(quantidades?.quantidades?.map(qtde => qtde.diasDeCocho)?.reduce((d1, d2) => Math.min(d1, d2)) ?? 60, 60),
    };
}
