import {Card} from "@mui/material";
import PaginatedTable from "../../../../main-class/componentes/table/PaginatedTable";
import TableColumn from "../../../../main-class/componentes/table/TableColumn";
import ColumnHeader from "../../../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../../../main-class/componentes/table/ColumnCell";
import {PaginaTotalPendenciaDTO, PendenciaApi, PendenciaDTO} from "../../../../dao";
import React, {useContext, useEffect, useRef} from "react";
import useLabels from "../../../../i18n/useLabels";
import useFormat from "../../../../main-class/componentes/format/useFormat";
import useConsulta from "../../../../main-class/form/crud/useConsulta";
import ApiConfiguration from "../../../../infra/api/ApiConfiguration";
import FaixaQuantidadeAnimais from "./FaixaQuantidadeAnimais";
import OpcoesPendencia from "./OpcoesPendencia";
import {FaixasPendencia, FiltroContext, FiltroPendencia} from "../consulta/filtroContext";

const colors = {
    [FaixasPendencia.DE_95_DIAS_EM_DIANTE]: 'red',
    [FaixasPendencia.DE_75_A_94_DIAS]: '#f1d452',
    [FaixasPendencia.DE_60_A_74_DIAS]: '#4fef40',
    [FaixasPendencia.ATE_59_DIAS]: 'inherit',
}

export default function ListagemPendencias(props: { faixa: FaixasPendencia }) {

    const {faixa} = props;

    const api = useRef(new PendenciaApi(ApiConfiguration));
    const contexto = useContext(FiltroContext);
    const {ptBR} = useLabels();
    const {formatDate, formatTelefone} = useFormat();


    const {
        pagina,
        consulta
    } = useConsulta<FiltroPendencia, PendenciaDTO>({
        filtroInicial: {
        },
        consultaAction: (filtro, pagina, tamanhoPagina) => api.current.consultaPendencias(
            filtro.codigo,
            filtro.tipo,
            filtro.status,
            filtro.cliente?.id,
            faixa,
            pagina,
            tamanhoPagina
        ),
    });

    useEffect(() => {
        let subscription = contexto.filtro.subscribe(value => {
            consulta(value);
        });

        return () => subscription.unsubscribe();
    }, [contexto, consulta])

    return (
        (faixa !== FaixasPendencia.ATE_59_DIAS || !!pagina?.totalItens) &&
        <>

            <FaixaQuantidadeAnimais label={ptBR.enum.faixa_pendencias[faixa]}
                                    color={colors[faixa]} pendencias={pagina as PaginaTotalPendenciaDTO}/>

            <Card>

                <PaginatedTable
                    onPageChange={(pagina, tamanhoPagina) => consulta(contexto.filtro.value, {pagina, tamanhoPagina})}
                    pagina={pagina}>

                    <TableColumn name="codigo">
                        <ColumnHeader>Código</ColumnHeader>
                        <ColumnCell content={(item: PendenciaDTO) => item.codigo}/>
                    </TableColumn>

                    <TableColumn name="cliente">
                        <ColumnHeader>Cliente</ColumnHeader>
                        <ColumnCell content={(item: PendenciaDTO) => (
                            <>
                                {item.cliente.nome}<br/>
                                {formatTelefone(item.cliente.telefone)}
                            </>
                        )}/>
                    </TableColumn>

                    <TableColumn name="dataHora" style={{textAlign: 'center'}}>
                        <ColumnHeader>Data/Hora</ColumnHeader>
                        <ColumnCell
                            content={(item: PendenciaDTO) => formatDate(item.dataHora, 'DD/MM/YYYY | HH:mm:ss')}/>
                    </TableColumn>

                    <TableColumn name="tipo" style={{textAlign: 'center'}}>
                        <ColumnHeader>Tipo</ColumnHeader>
                        <ColumnCell content={(item: PendenciaDTO) => ptBR.enum.tipo_pendencia[item.tipo]}/>
                    </TableColumn>

                    <TableColumn name="status" style={{textAlign: 'center'}}>
                        <ColumnHeader>Status</ColumnHeader>
                        <ColumnCell content={(item: PendenciaDTO) => ptBR.enum.status_pendencia[item.status]}/>
                    </TableColumn>

                    {
                        faixa === FaixasPendencia.DE_95_DIAS_EM_DIANTE &&
                        <TableColumn name="total_95" style={{textAlign: 'center'}}>
                            <ColumnHeader>Total<br/>(95 dias ou mais)</ColumnHeader>
                            <ColumnCell content={(item: PendenciaDTO) => (
                                <>
                                    <small>{item.quantidadeAnimais95DiasMacho} machos</small><br/>
                                    <small>{item.quantidadeAnimais95DiasFemea} fêmeas</small>
                                </>
                            )}/>
                        </TableColumn>
                    }

                    {
                        (faixa === FaixasPendencia.DE_95_DIAS_EM_DIANTE ||
                            faixa === FaixasPendencia.DE_75_A_94_DIAS) &&
                        <TableColumn name="total_75_94" style={{textAlign: 'center'}}>
                            <ColumnHeader>Total<br/>(75 a 94 dias)</ColumnHeader>
                            <ColumnCell content={(item: PendenciaDTO) => (
                                <>
                                    <small>{item.quantidadeAnimais75a94DiasMacho} machos</small><br/>
                                    <small>{item.quantidadeAnimais75a94DiasFemea} fêmeas</small>
                                </>
                            )}/>
                        </TableColumn>
                    }

                    {
                        (faixa === FaixasPendencia.DE_95_DIAS_EM_DIANTE ||
                            faixa === FaixasPendencia.DE_75_A_94_DIAS ||
                            faixa === FaixasPendencia.DE_60_A_74_DIAS) &&
                        <TableColumn name="total_60_74" style={{textAlign: 'center'}}>
                            <ColumnHeader>Total<br/>(60 a 74 dias)</ColumnHeader>
                            <ColumnCell content={(item: PendenciaDTO) => (
                                <>
                                    <small>{item.quantidadeAnimais60a74DiasMacho} machos</small><br/>
                                    <small>{item.quantidadeAnimais60a74DiasFemea} fêmeas</small>
                                </>
                            )}/>
                        </TableColumn>
                    }

                    {
                        faixa === FaixasPendencia.ATE_59_DIAS &&
                        <TableColumn name="total_59" style={{textAlign: 'center'}}>
                            <ColumnHeader>Total<br/>(até 59 dias)</ColumnHeader>
                            <ColumnCell content={(item: PendenciaDTO) => (
                                <>
                                    <small>{item.quantidadeAnimais59diasMacho} machos</small><br/>
                                    <small>{item.quantidadeAnimais59DiasFemea} fêmeas</small>
                                </>
                            )}/>
                        </TableColumn>
                    }

                    <TableColumn name="total_animais" style={{textAlign: 'center'}}>
                        <ColumnHeader>Total<br/>(Animais)</ColumnHeader>
                        <ColumnCell content={(item: PendenciaDTO) => (
                            <>
                                <small>{item.quantidadeTotalAnimaisMachos} machos</small><br/>
                                <small>{item.quantidadeTotalAnimaisFemeas} fêmeas</small>
                            </>
                        )}/>
                    </TableColumn>

                    <TableColumn name="opcoes" style={{textAlign: 'right'}}>
                        <ColumnHeader/>
                        <ColumnCell content={(item: PendenciaDTO) => {
                            return (
                                <>
                                    <OpcoesPendencia pendencia={item}/>
                                </>
                            )
                        }}/>
                    </TableColumn>

                </PaginatedTable>
            </Card>
        </>
    );
}
