import React, {createContext, useState} from "react";
import ptBR from "../../../i18n/pt-br.json";
import MensagemGlobal from "./MensagemGlobal";
import {Observable, Subject} from "rxjs";

export interface Mensagem {
    mensagem: string,
    tipo: 'success' | 'error' | 'warning' | 'info';
}

export class Mensageria {
    private subject = new Subject<Mensagem>();

    get mensagemAdded(): Observable<Mensagem> {
        return this.subject.asObservable();
    }

    async handleError(error: any) {
        const cause = error.rejection || error;
        if (cause instanceof Response) {
            const actions: { [key: number]: any } = {
                400: (dto: any) => this.badRequest(dto),
                501: () => this.addMensagem({mensagem: ptBR.erro.mensagem['MSG-501'], tipo: 'error'}),
                502: () => this.addMensagem({mensagem: ptBR.erro.mensagem['MSG-502'], tipo: 'error'}),
                503: () => this.addMensagem({mensagem: ptBR.erro.mensagem['MSG-502'], tipo: 'error'}),
                504: () => this.addMensagem({mensagem: ptBR.erro.mensagem['MSG-502'], tipo: 'error'}),
            };

            if (cause.status) {
                const action = actions[cause.status];
                if (action) {
                    try {
                        action(await cause.json());
                        return;
                    } catch (ex) {
                    }
                }
            }
        }

        console.error(error);

        this.addMensagem({mensagem: ptBR.erro.mensagem['MSG-500'], tipo: 'error'});
    }

    private badRequest(erro: any) {
        if (erro.codigo) {
            const messageKey = (ptBR.erro.mensagem.backend as any)['MSG-' + erro.codigo] as any;

            if (!messageKey) {
                if (erro.mensagem) {
                    this.addMensagem({mensagem: this.parseMessage(erro.mensagem, erro.parametros), tipo: 'error'});
                } else {
                    console.warn('Retorno do servidor sem mensagem registrada: ', erro);

                    this.addMensagem({mensagem: ptBR.erro.mensagem['MSG-500'], tipo: 'error'});
                }
            } else {
                const messageParsed = this.parseMessage(messageKey, erro.parametros);

                this.addMensagem({mensagem: messageParsed, tipo: 'error'});
            }
        } else {
            console.error(erro);

            this.addMensagem({mensagem: ptBR.erro.mensagem['MSG-500'], tipo: 'error'});
        }
    }

    private parseMessage(message: string, parameters: any) {
        return !parameters ? message :
            message.replaceAll(/\{\{([^}]+)}}/g, (g) => parameters[g.replaceAll(/[{}]/g, '')]);
    }

    addMensagem(mensagem: Mensagem | string) {
        this.subject.next(typeof mensagem === 'string' ? {mensagem, tipo: 'info'} : mensagem);
    }
}

export const MensageriaContext = createContext<Mensageria>(new Mensageria());

export default function MensageriaProvider(props: { children?: React.ReactNode }) {
    const [mensageria] = useState<Mensageria>(new Mensageria());

    return (
        <MensageriaContext.Provider value={mensageria}>
            {props.children}

            <MensagemGlobal/>
        </MensageriaContext.Provider>
    );
}
