import {Button, TextField} from "@mui/material";
import {PendenciaApi, PendenciaDTO} from "../../../../dao";
import React, {useCallback, useRef} from "react";
import {useNavigate} from "react-router-dom";
import useDialog from "../../../../main-class/template/dialog/useDialog";
import ModalAlteracaoPeloCliente, {TipoModal} from "./ModalAlteracaoPeloCliente";
import useLabels from "../../../../i18n/useLabels";
import MultipleArquivosFormField from "../../../../main-class/form/input/MultipleArquivosFormField";
import ApiConfiguration from "../../../../infra/api/ApiConfiguration";
import useLoader from "../../../../main-class/componentes/loader/useLoader";
import {lastValueFrom} from "rxjs";
import TipoEnum = PendenciaDTO.TipoEnum;
import StatusEnum = PendenciaDTO.StatusEnum;

export default function Concluida(props: { pendencia: PendenciaDTO, onUpdate: any }) {
    const pendenciaApi = useRef(new PendenciaApi(ApiConfiguration));
    const {pendencia, onUpdate} = props;
    const navigate = useNavigate();
    const {open} = useDialog();
    const {ptBR} = useLabels();
    const {loadingAround} = useLoader();

    const volta = useCallback(() => {
        navigate("/boigordo");
    }, [navigate]);

    const cancela = useCallback(async () => {
        const resultado = await lastValueFrom(open(<ModalAlteracaoPeloCliente
            tipo={TipoModal.cancelamento_conclusao}/>).afterClosed());
        if (!!resultado) {
            await loadingAround(pendenciaApi.current.concluiPendencia(pendencia.id));
            onUpdate();
        }
    }, [pendencia, onUpdate, loadingAround, pendenciaApi, open]);

    return (
        <>
            {
                !!pendencia.termo &&
                <>
                    <h2>Termo de negociação de boi gordo</h2>

                    <TextField
                        multiline
                        disabled
                        name="termo" key="termo"
                        value={pendencia.termo} label="Termo"
                    />
                </>
            }

            {
                (pendencia.motivoRecusaContagem || pendencia.motivoRecusaNegociacao) &&
                <>
                    <h2>Motivo de Recusa</h2>
                    {
                        pendencia.motivoRecusaContagem?.map(motivo => (<>
                            {
                                motivo === PendenciaDTO.MotivoRecusaContagemEnum.Outro ?
                                    pendencia.outroMotivoRecusaContagem :
                                    ptBR.enum.motivo_recusa_contagem[motivo]
                            }<br/>
                        </>))
                    }
                    {
                        pendencia.motivoRecusaNegociacao?.map(motivo => (<>
                            {
                                motivo === PendenciaDTO.MotivoRecusaNegociacaoEnum.Outro ?
                                    pendencia.outroMotivoRecusaNegociacao :
                                    ptBR.enum.motivo_recusa_negociacao[motivo]
                            }<br/>
                        </>))
                    }
                </>
            }
            {
                (pendencia.motivoCliente) &&
                <>
                    <h2>Motivo de Alteração pelo Cliente</h2>
                    {
                        pendencia.motivoCliente === PendenciaDTO.MotivoClienteEnum.Outro ?
                            pendencia.outroMotivoAlteracaoCliente :
                            ptBR.enum.motivo_alteracao_cliente[pendencia.motivoCliente]
                    }
                    {
                        pendencia?.motivoCliente && pendencia?.anexosMotivoCliente &&
                        <MultipleArquivosFormField name="arquivo" value={pendencia?.anexosMotivoCliente}
                                                   disabled={true}/>
                    }
                </>
            }
            {
                (pendencia.motivoCancelamento) &&
                <>
                    <h2>Motivo de Cancelamento</h2>
                    {
                        pendencia.motivoCancelamento === PendenciaDTO.MotivoCancelamentoEnum.Outro ?
                            pendencia.outroMotivoCancelamento :
                            ptBR.enum.motivo_cancelamento[pendencia.motivoCancelamento]
                    }
                </>
            }


            <div className="acoes">
                {
                    pendencia.tipo === TipoEnum.Negociacao && pendencia.status === StatusEnum.Concluido &&
                    <Button onClick={() => cancela()} variant="outlined" color="error">Cancelar</Button>
                }
                <Button color="inherit" onClick={() => volta()}>Voltar</Button>
            </div>
        </>
    );
}
