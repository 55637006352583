import {AnimalApi, LocalDTO, MotivoMovimentacaoDTO, MovimentacaoDTO} from "../../dao";
import React, {useEffect, useRef, useState} from "react";
import ApiConfiguration from "../../infra/api/ApiConfiguration";
import {useParams} from "react-router-dom";
import useConsulta from "../../main-class/form/crud/useConsulta";
import FormEditarMidiasMovimentacao from "./componentes/FormEditarMidiasMovimentacao";
import useDialog from "../../main-class/template/dialog/useDialog";

export interface FiltroMovimentacao {
    data?: Date;
    lote?: any
    sisbov?: string;
    bosch?: string;
    motivo?: number;
    de?: LocalDTO;
    para?: LocalDTO;
}

export default function useConsultaMovimentacao() {
    const animalApi = useRef(new AnimalApi(ApiConfiguration));
    const {open} = useDialog();
    const {animal: idAnimal, lote: idLote} = useParams();
    const [motivos, setMotivos] = useState<Array<MotivoMovimentacaoDTO>>([]);

    const {
        filtro,
        pagina,
        consulta,
        formControl,
        submitForm,
    } = useConsulta<FiltroMovimentacao, MovimentacaoDTO>({
        consultaAction: (filtro, pagina, tamanhoPagina) => animalApi.current.consultaMovimentacoes(
            filtro.data,
            filtro.sisbov,
            filtro.bosch,
            filtro.motivo,
            filtro.lote,
            filtro.de?.id,
            filtro.para?.id,
            Number(idAnimal) || undefined,
            Number(idLote) || undefined,
            undefined,
            pagina,
            tamanhoPagina
        ),
    });

    function onEditaMidia(movimentacaoDTO: MovimentacaoDTO) {
        open<MovimentacaoDTO>(
            <FormEditarMidiasMovimentacao
                value={movimentacaoDTO}
            />,
            {fullWidth: true, maxWidth: 'md'}
        ).afterClosed().subscribe(async atualizado => {
            if (!!atualizado) {
                await animalApi.current.salvaMidiasMovimentacao(movimentacaoDTO.id, {midias: atualizado.midias})
                consulta()
            }
        });
    }

    useEffect(() => {
        animalApi.current.consultaMotivosMovimentacao().then(response => {
            setMotivos(response)
        })
    }, []);


    return {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        motivos,
        onEditaMidia
    };


}
