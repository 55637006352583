import {FormControl, FormHelperText, InputLabel, OutlinedInput} from "@mui/material";
import {Controller} from "react-hook-form";
import {FieldProps} from "./FieldProps";
import React from "react";

export default function TextFormField(props: FieldProps<string>) {

    function actualOnChange(onChange: (...event: any[]) => void) {
        return (event: any) => {
            onChange(event);
            if (props.onChange) {
                props.onChange(event);
            }
        };
    }

    return (
        <>
            <Controller
                name={props.name}
                control={props.control}
                defaultValue={props.value || ""}
                render={({field: {onChange, value}, fieldState: {error}}) => (
                    <FormControl variant={props.variant} required={props.required}>
                        <InputLabel htmlFor={props.name + "_input"}>{props.label}</InputLabel>
                        <OutlinedInput
                            id={props.name + "_input"}
                            value={value}
                            disabled={props.disabled}
                            label={props.label}
                            onChange={actualOnChange(onChange)}
                            error={!!error}
                        />
                        <FormHelperText style={{color: 'red'}}>{error ? error.message : null}</FormHelperText>
                    </FormControl>
                )}
                rules={{validate: props.validate}}
            />
        </>
    );
}
