import {createContext} from "react";
import {LoteDTO} from "../../dao";

export interface LoteState {
    lote?: LoteDTO;
    reload: () => any;
}

const LoteContext = createContext<LoteState>({reload: () => {}});

export default LoteContext;
