import {PendenciaDTO} from "../../../../dao";
import React from "react";
import AguardandoContagem from "./AguardandoContagem";
import AguardandoDecisaoCliente from "./AguardandoDecisaoCliente";
import AguardandoConclusao from "./AguardandoConclusao";
import AguardandoNegociacao from "./AguardandoNegociacao";
import Concluida from "./Concluida";

const COMMAND_BUILDERS: any = {
    [PendenciaDTO.TipoEnum.Contagem]: {
        [PendenciaDTO.StatusEnum.Pendente]: (pendencia: PendenciaDTO, onUpdate: any) => <AguardandoContagem
            pendencia={pendencia}
            onUpdate={onUpdate}/>,
        [PendenciaDTO.StatusEnum.RecusaEnviada]: (pendencia: PendenciaDTO, onUpdate: any) => <AguardandoDecisaoCliente
            pendencia={pendencia} onUpdate={onUpdate}/>,
        [PendenciaDTO.StatusEnum.RecusaVisualizada]: (pendencia: PendenciaDTO, onUpdate: any) =>
            <AguardandoDecisaoCliente
                pendencia={pendencia} onUpdate={onUpdate}/>,
        [PendenciaDTO.StatusEnum.RecusaConfirmada]: (pendencia: PendenciaDTO, onUpdate: any) => <AguardandoConclusao
            pendencia={pendencia} onUpdate={onUpdate}/>,
        [PendenciaDTO.StatusEnum.Concluido]: (pendencia: PendenciaDTO, onUpdate: any) => <Concluida
            pendencia={pendencia}
            onUpdate={onUpdate}/>,
        [PendenciaDTO.StatusEnum.Aprovado]: (pendencia: PendenciaDTO, onUpdate: any) => <AguardandoNegociacao
            pendencia={pendencia}
            onUpdate={onUpdate}/>,
    },
    [PendenciaDTO.TipoEnum.Negociacao]: {
        [PendenciaDTO.StatusEnum.Enviado]: (pendencia: PendenciaDTO, onUpdate: any) => <AguardandoDecisaoCliente
            pendencia={pendencia} onUpdate={onUpdate}/>,
        [PendenciaDTO.StatusEnum.Visualizado]: (pendencia: PendenciaDTO, onUpdate: any) => <AguardandoDecisaoCliente
            pendencia={pendencia} onUpdate={onUpdate}/>,
        [PendenciaDTO.StatusEnum.Confirmado]: (pendencia: PendenciaDTO, onUpdate: any) => <AguardandoConclusao
            pendencia={pendencia}
            onUpdate={onUpdate}/>,
        [PendenciaDTO.StatusEnum.RecusaEnviada]: (pendencia: PendenciaDTO, onUpdate: any) => <AguardandoDecisaoCliente
            pendencia={pendencia} onUpdate={onUpdate}/>,
        [PendenciaDTO.StatusEnum.RecusaVisualizada]: (pendencia: PendenciaDTO, onUpdate: any) =>
            <AguardandoDecisaoCliente
                pendencia={pendencia} onUpdate={onUpdate}/>,
        [PendenciaDTO.StatusEnum.RecusaConfirmada]: (pendencia: PendenciaDTO, onUpdate: any) => <AguardandoConclusao
            pendencia={pendencia} onUpdate={onUpdate}/>,
        [PendenciaDTO.StatusEnum.Aprovado]: (pendencia: PendenciaDTO, onUpdate: any) => <AguardandoConclusao
            pendencia={pendencia}
            onUpdate={onUpdate}/>,
        [PendenciaDTO.StatusEnum.Concluido]: (pendencia: PendenciaDTO, onUpdate: any) => <Concluida
            pendencia={pendencia}
            onUpdate={onUpdate}/>,
        [PendenciaDTO.StatusEnum.Cancelado]: (pendencia: PendenciaDTO, onUpdate: any) => <Concluida
            pendencia={pendencia}
            onUpdate={onUpdate}/>,
    }
}

export default function DadosPendencia(props: { pendencia: PendenciaDTO, onUpdate: any }) {
    const {pendencia, onUpdate} = props;

    const commandBuilder = !!COMMAND_BUILDERS[pendencia.tipo] ? COMMAND_BUILDERS[pendencia.tipo][pendencia.status] : undefined;

    return (

        <>

            {
                commandBuilder &&
                commandBuilder(pendencia, onUpdate)
            }

        </>

    );
}
