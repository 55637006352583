import {Button} from "@mui/material";
import {useForm} from "react-hook-form";
import useValidation from "../../../main-class/form/validation/useValidation";
import PasswordFormField from "../../../main-class/form/input/PasswordFormField";
import useLoginValidation from "../useLoginValidation";

export interface SenhaProps {
    onCancelar: () => any;
    onSubmitted: (senha: string) => any;
}

export default function CadastroSenha(props: SenhaProps) {
    const {getValues, handleSubmit, control} = useForm();
    const {onSubmitted, onCancelar} = props;
    const {validate, required, length} = useValidation();
    const {confirmacaoSenha, novaSenha} = useLoginValidation();

    return (
        <>
            <div className="content">
                <form onSubmit={handleSubmit(({senha}) => onSubmitted(senha))}>

                    <p style={{textAlign: "left"}}>
                        Agora defina uma nova senha de acesso.<br/>
                        <strong>Ela deve possuir:</strong>
                    </p>
                    <ul style={{textAlign: "left", padding: 0, color: "#666"}}>
                        <li>ao menos 8 caracteres;</li>
                        <li>ao menos 1 letra minúsculo;</li>
                        <li>ao menos 1 letra maiúsculo;</li>
                        <li>ao menos 1 número;</li>
                    </ul>

                    <PasswordFormField
                        name="senha"
                        control={control}
                        label="Nova Senha"
                        validate={
                            validate(
                                required(),
                                length({max: 50}),
                                novaSenha()
                            )
                        }
                    />

                    <PasswordFormField
                        name="confirmacaoSenha"
                        control={control}
                        label="Confirme sua Nova Senha"
                        validate={
                            validate(
                                required(),
                                length({max: 50}),
                                confirmacaoSenha(() => getValues().senha)
                            )
                        }
                    />

                    <div className="commands">
                        <Button variant="contained" type="submit" color="primary">Acessar</Button>
                        <Button variant="text" type="button"
                                onClick={onCancelar}
                                color="inherit">Cancelar</Button>
                    </div>

                </form>
            </div>
        </>
    );
}
