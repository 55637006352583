import React from "react";
import {AcessoApi, SenhaDTO} from "../../dao";
import ApiConfiguration from "../../infra/api/ApiConfiguration";
import useCriptografia from "../../main-class/componentes/util/useCriptografia";

export default function useInputOtp() {
    const acessoApi = new AcessoApi(ApiConfiguration);
    const {criptografa} = useCriptografia();

    async function onType(event: React.KeyboardEvent, email: string, setOtp: (otp: string) => any | undefined, index: number) {
        const element = event.currentTarget as HTMLInputElement;
        const inputs = element.parentElement!.getElementsByTagName('input');

        let htmlInputElement = inputs.item(index)!;

        if (event.key === "Backspace") {
            htmlInputElement.value = '';
            if (index > 0) {
                inputs.item(index - 1)!.focus();
            }
        } else {
            if (htmlInputElement.value.match(/^[0-9]$/)) {

                if (index < inputs.length - 1) {
                    inputs.item(index + 1)!.focus();
                }

                event.preventDefault();
            } else {
                htmlInputElement.value = '';

                event.preventDefault();
            }
        }

        let codigo = '';
        for (let i = 0; i < inputs.length; i++) {
            const input: any = inputs.item(i);
            if (input.value !== '' && input.value) {
                codigo += input.value;
            }
        }

        if (codigo.length === 6) {
            try {
                await validaOTP(email, codigo);

                if (!!setOtp) {
                    setOtp(codigo);
                }
            } catch (ex) {
                for (let i = 0; i < inputs.length; i++) {
                    inputs.item(i)!.value = '';
                }

                inputs.item(0)!.focus();

                throw ex;
            }
        }
    }

    async function validaOTP(email: string, otp: string) {
        await acessoApi.validaOTP({
            email: email,
            otpCriptografado: await criptografaSenha(otp)
        });
    }

    async function criptografaSenha(valor: string): Promise<SenhaDTO> {
        const chavePublica = await acessoApi.getChavePublica();

        return {
            uuid: chavePublica.uuid,
            senha: criptografa(chavePublica.chavePublica, valor)
        };
    }

    return {onType};
}
