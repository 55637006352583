import useLabels from "../../../i18n/useLabels";
import {Box, Button, Card} from "@mui/material";
import React from "react";
import useFormat from "../../../main-class/componentes/format/useFormat";
import useDocumentoDesembarque from "./useDocumentoDesembarque";
import EdicaoPage from "../../../main-class/template/crud/EdicaoPage";
import {DesembarqueDTO, LoteDTO} from "../../../dao";
import ArquivoFormField from "../../../main-class/form/input/ArquivoFormField";
import useValidation from "../../../main-class/form/validation/useValidation";
import InputDeclaracaoFemea from "./components/InputDeclaracaoFemea";
import SelectFormField from "../../../main-class/form/input/SelectRadioFormField";
import SelectItem from "../../../main-class/form/input/SelectItem";
import MultipleArquivosFormField from "../../../main-class/form/input/MultipleArquivosFormField";

export default function DocumentoDesembarque() {
    const {
        entity, submitForm, formControl, showDadosPesagem, podeEncerrar, reset, encerra
    } = useDocumentoDesembarque();
    const {ptBR} = useLabels();
    const {formatDate, formatNumeric} = useFormat();
    const {validate, required} = useValidation();

    return (
        <>
            <Card>
                <Box sx={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                    <Box sx={{display: 'flex', gap: '20px', alignItems: 'center', flex: 1}}>
                        <Box sx={{flex: 1}}>
                            <p style={{margin: '0 0 10px'}}>Código</p>
                            <h2 style={{margin: 0}}>{entity.codigo}</h2>
                        </Box>
                        <Box sx={{flex: 3}}>
                            <p style={{margin: '0 0 10px'}}>Data/Hora</p>
                            <h2 style={{margin: 0}}>{formatDate(entity.dataHoraEntrada, 'DD/MM/YYYY | HH:mm:ss')}</h2>
                        </Box>
                        <Box sx={{flex: 1}}>
                            <p style={{margin: '0 0 10px'}}>Carreta</p>
                            <h2 style={{margin: 0}}>{entity.placaCarreta}</h2>
                        </Box>
                    </Box>
                    <Box sx={{flex: '1 1 auto', textAlign: 'center'}}/>
                    <Box sx={{flex: 1, textAlign: 'center'}}>
                        <h2 style={{margin: '0 0 10px'}}>Etapa</h2>
                        <h1 style={{margin: 0}}>{ptBR.enum.etapa_lote[entity.lote?.etapa || LoteDTO.EtapaEnum.Desembarque]}</h1>
                    </Box>
                </Box>
            </Card>

            <EdicaoPage>
                <h2>Início do desembarque</h2>

                <form onSubmit={submitForm}>
                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{flex: 1}}>
                            <ArquivoFormField label="Nota Fiscal" name="notaFiscal" accept={".pdf"}
                                              control={formControl} value={entity.notaFiscal}
                                              validate={
                                                  validate(
                                                      required()
                                                  )
                                              }/>
                            <SelectFormField label="Tem declaração de fêmea?" name="temDeclaracaoFemea"
                                             control={formControl} value={entity.temDeclaracaoFemea}
                                             validate={validate(
                                                 required()
                                             )}>
                                <SelectItem label="Sim" value={true}/>
                                <SelectItem label="Não" value={false}/>
                            </SelectFormField>
                            <InputDeclaracaoFemea
                                control={formControl}
                                declaracaoFemea={entity.declaracaoFemea}
                            />
                        </Box>
                        <Box sx={{flex: 1}}>
                            <MultipleArquivosFormField label="Guia de Transporte do Animal"
                                                       name="gtas" accept={".pdf"}
                                                       control={formControl} value={entity.gtas}
                                                       validate={
                                                           validate(
                                                               required()
                                                           )
                                                       }/>
                        </Box>
                    </Box>

                    <div className="acoes">
                        <Button type="submit" variant="contained" color="secondary">
                            {entity.status === DesembarqueDTO.StatusEnum.Pendente ? "Iniciar desembarque" : "Alterar"}
                        </Button>
                        <Button type="reset" onClick={reset} color="inherit">Cancelar</Button>
                    </div>
                </form>
            </EdicaoPage>

            {
                showDadosPesagem &&
                <Card>
                    <h2>Dados de pesagem</h2>
                    <Box sx={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                        <Box sx={{display: 'flex', gap: '20px', alignItems: 'center', flex: 1}}>
                            <Box sx={{flex: 1}}>
                                <p style={{margin: '0 0 10px'}}>Quantidade</p>
                                <h2 style={{margin: 0}}>{entity.qtdAnimais}</h2>
                            </Box>
                            <Box sx={{flex: 1}}>
                                <p style={{margin: '0 0 10px'}}>Peso total</p>
                                <h2 style={{margin: 0}}>{formatNumeric(entity.pesoTotal)}Kg</h2>
                            </Box>
                            <Box sx={{flex: 1}}>
                                <p style={{margin: '0 0 10px'}}>Peso médio</p>
                                <h2 style={{margin: 0}}>
                                    {
                                        !!entity.dataHoraSaida ?
                                            <>{formatNumeric(entity.pesoMedio)}Kg</> :
                                            <>-</>
                                    }
                                </h2>
                            </Box>
                            <Box sx={{flex: 1}}>
                                <p style={{margin: '0 0 10px'}}>Peso líquido</p>
                                <h2 style={{margin: 0}}>
                                    {
                                        !!entity.dataHoraSaida ?
                                            <>{formatNumeric(entity.pesoLiquido)}Kg</> :
                                            <>-</>
                                    }
                                </h2>
                            </Box>
                            <Box sx={{flex: 1}}>
                                <p style={{margin: '0 0 10px'}}>Peso tara</p>
                                <h2 style={{margin: 0}}>
                                    {
                                        !!entity.dataHoraSaida ?
                                            <>{formatNumeric(entity.pesoTara)}Kg</> :
                                            <>-</>
                                    }
                                </h2>
                            </Box>
                        </Box>
                        <Box sx={{flex: '1 1 auto', textAlign: 'center'}}/>
                    </Box>

                    {
                        podeEncerrar &&
                        <div className="acoes">
                            <Button type="button" variant="contained"
                                    disabled={!entity.dataHoraSaida}
                                    onClick={encerra} color="secondary">Encerrar
                                desembarque</Button>
                            <Button type="reset" color="inherit">Cancelar</Button>
                        </div>
                    }
                </Card>
            }

        </>


    );
}

