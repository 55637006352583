import buildTabRoute from "../../main-class/template/route/buildTabRoute";
import ConsultaProcessamentoSaida from "./consulta/ConsultaProcessamentoSaida";
import DetalhaProcessamentoSaida from "./detalhe/DetalheProcessamentoSaida";
import ConsultaLoteProcessamentoSaida from "./lote/ConsultaLoteProcessamentoSaida";

export default function processamentoSaidaRoute() {
    return buildTabRoute({
        path: "/processamento-saida",
        title: "Processamentos de Saida",
        subroutes: [
            {
                path: "",
                element: <ConsultaProcessamentoSaida/>,
            },
            {
                title: "Proc. Saída Lote",
                path: "lote/:lote",
                element: <ConsultaLoteProcessamentoSaida/>,
            },
            {
                title: "Detalhe",
                path: "cliente/:cliente/detalhe/:id",
                element: <DetalhaProcessamentoSaida/>,
            },
        ]
    });
}
