import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {FieldProps} from "../../../main-class/form/input/FieldProps";
import {AnimalApi, LocalMorteDTO} from "../../../dao";
import {Controller} from "react-hook-form";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";

export default function SelectLocalMorte(props: FieldProps<LocalMorteDTO>) {
    const animalApi = useRef(new AnimalApi(ApiConfiguration));
    const [locaisMorte, setLocaisMorte] = useState<Array<LocalMorteDTO>>([]);

    useEffect(() => {
        animalApi.current.consultaLocaisMorte()
            .then(locais => setLocaisMorte(locais));
    }, [setLocaisMorte, animalApi]);

    function actualOnChange(onChange: (...event: any[]) => void) {
        return (event: any) => {
            const value = locaisMorte.find(lm => Number(lm.id) === Number(event.target.value));
            onChange(value);
            if (props.onChange) {
                props.onChange(value);
            }
        };
    }

    return (
        <>
            <Controller
                name={props.name}
                control={props.control}
                defaultValue={props.value}
                render={({field: {onChange, value}, fieldState: {error}}) => (
                    <>
                        <FormControl variant={props.variant} required={props.required}>
                            <InputLabel htmlFor={props.name + "_input"}>{props.label}</InputLabel>
                            <Select
                                id={props.name + "_input"}
                                value={locaisMorte.length && value?.id}
                                disabled={props.disabled}
                                onChange={actualOnChange(onChange)}
                                error={!!error}
                                label={props.label}
                            >
                                <MenuItem value={''}/>
                                {
                                    locaisMorte.map(local =>
                                        <MenuItem key={'MenuItem_localMorte_' + local.id} value={local.id}>
                                            {local.nome}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                            <FormHelperText style={{color: 'red'}}>{error ? error.message : null}</FormHelperText>
                        </FormControl>
                    </>
                )}
                rules={{validate: props.validate}}
            />
        </>
    );
}
