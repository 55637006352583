import useSessao from "../../main-class/componentes/sessao/useSessao";
import {useJwt} from "react-jwt";
import {UsuarioAdministradorDTO} from "../../dao";

interface Claims {
    tpu: 'uad' | 'ucl',
    pfu?: 'gerencial' | 'administrativo' | 'comercial' | 'veterinario'
}

export default function usePermissao() {
    const {token} = useSessao();
    const {decodedToken} = useJwt<Claims>(token);

    function is(perfil: UsuarioAdministradorDTO.PerfilEnum) {
        return !!decodedToken?.pfu && (decodedToken!.pfu! as any) === perfil;
    }

    function any(...perfis: Array<UsuarioAdministradorDTO.PerfilEnum>) {
        return !!perfis.find(is);
    }

    return {is, any};
}
