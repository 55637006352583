import {useForm} from "react-hook-form";
import React, {useCallback, useRef} from "react";
import {AnimalApi} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import useLoader from "../../../main-class/componentes/loader/useLoader";
import { Button,  DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextFormField from "../../../main-class/form/input/TextFormField";
import useDialogRef from "../../../main-class/template/dialog/useDialogRef";
import useValidation from "../../../main-class/form/validation/useValidation";

export default function AdicionarLocalMorte() {

    const {control, handleSubmit, getValues } = useForm({defaultValues:  undefined});
    const {dialogRef} = useDialogRef();
    const animalApi = useRef(new AnimalApi(ApiConfiguration));
    const {validate, required , length} = useValidation();
    const local = getValues();

    const {loadingAround} = useLoader();


    const cancel = useCallback(() => {
        dialogRef.close();
    }, [dialogRef]);

    const salva = useCallback(async (local: any) => {
        await loadingAround(animalApi.current.salvaLocalMorte({id: undefined, nome: local.local.nome}));
    }, [animalApi, loadingAround]);

    const submitForm = handleSubmit(async (local) => {
        await salva(local);

        dialogRef.close()
    });

    return (
        <>
            <DialogTitle style={{display: 'flex'}}>
                Novo local de óbito
            </DialogTitle>
            <form onSubmit={submitForm}>
                <DialogContent style={{paddingTop: '20px'}}>
                    <TextFormField name="local.nome" validate={validate(
                        required(), length({max: 30})
                    )}
                                   control={control} label="Local de óbito"
                                   value={local.nome}/>
                </DialogContent>
                <DialogActions>

                    <Button type="button"
                            onClick={() => cancel()}>
                        Cancelar
                    </Button>
                    <Button variant="contained" type="submit">
                        Salvar
                    </Button>

                </DialogActions>
            </form>
        </>
    )
}
