import {MoreVert} from "@mui/icons-material";
import {MenuItem} from "@mui/material";
import {PendenciaApi, PendenciaDTO} from "../../../../dao";
import IconOptions from "../../../../componentes/iconOptions/IconOptions";
import React, {useCallback, useContext, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {lastValueFrom} from "rxjs";
import useDialog from "../../../../main-class/template/dialog/useDialog";
import useLoader from "../../../../main-class/componentes/loader/useLoader";
import ApiConfiguration from "../../../../infra/api/ApiConfiguration";
import {FiltroContext} from "../consulta/filtroContext";

export default function OpcoesPendencia(props: any) {

    const navigate = useNavigate();

    const {confirmacao} = useDialog();

    const {loadingAround} = useLoader();

    const api = useRef(new PendenciaApi(ApiConfiguration));

    const contexto = useContext(FiltroContext);

    function historico(pendencia: PendenciaDTO) {
        navigate(`/boigordo/cliente/${pendencia.cliente.id}`);
    }

    function grafico(pendencia: PendenciaDTO) {
        navigate(`/boigordo/cliente/${pendencia.cliente.id}/grafico`);
    }

    function detalhe(pendencia: PendenciaDTO) {
        navigate(`/boigordo/pendencia/${pendencia.id}`);
    }

    const cancela = useCallback(async (pendencia: PendenciaDTO) => {

        const confirmado = await lastValueFrom(
            confirmacao(
                `Você está EXCLUINDO o cliente ${pendencia.cliente.nome} da lista de pendências. Confirma essa ação?`
            ).afterClosed()
        );

        if (confirmado) {
            await loadingAround(api.current.cancela(pendencia.id));
            contexto.filtro.next({...contexto.filtro.value})
        }

    }, [api, loadingAround, confirmacao, contexto]);


    const aprova = useCallback(async (pendencia: PendenciaDTO) => {

        const confirmado = await lastValueFrom(
            confirmacao(`A contagem física do cliente  ${pendencia.cliente.nome} será APROVADA. Confirma essa ação?`)
                .afterClosed()
        );

        if (confirmado) {
            await loadingAround(api.current.aprovaContagem(pendencia.id))
            contexto.filtro.next({...contexto.filtro.value})
        }

    }, [api, loadingAround, confirmacao, contexto]);

    const recusa = useCallback(async (pendencia: PendenciaDTO) => {

        const confirmado = await lastValueFrom(
            confirmacao(`O cliente ${pendencia.cliente.nome} será notificado no seu dispositivo móvel sobre a recusa da contagem física. Confirma essa ação?`)
                .afterClosed()
        );

        if (confirmado) {
            await loadingAround(api.current.rejeitaContagem(pendencia.id))
            contexto.filtro.next({...contexto.filtro.value})
        }

    }, [api, loadingAround, confirmacao, contexto]);


    return (
        <>
            <IconOptions icon={<MoreVert/>}>
                <MenuItem onClick={() => {
                    detalhe(props?.pendencia)
                }}>
                    Detalhe
                </MenuItem>
                <MenuItem onClick={() => {
                    grafico(props?.pendencia)
                }}>
                    Gráfico de peso
                </MenuItem>
                <MenuItem onClick={() => {
                    historico(props?.pendencia)
                }}>
                    Histórico do cliente
                </MenuItem>

                {
                    props.pendencia?.status === PendenciaDTO.StatusEnum.Pendente &&
                    <MenuItem onClick={() => {
                        aprova(props.pendencia)
                    }}>
                        Aprovar Contagem
                    </MenuItem>
                }

                {
                    props.pendencia?.status === PendenciaDTO.StatusEnum.Pendente &&
                    <MenuItem onClick={() => {
                        recusa(props.pendencia)
                    }}>
                        Recusar Contagem
                    </MenuItem>
                }

                {
                    props.pendencia?.status === PendenciaDTO.StatusEnum.Pendente &&
                    <MenuItem onClick={() => {
                        cancela(props.pendencia)
                    }}>
                        Cancelar
                    </MenuItem>
                }

            </IconOptions>

        </>
    )

}
