import {ClienteDTO, DesembarqueApi, DesembarqueDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import {useContext, useEffect, useRef} from "react";
import useEdicao from "../../../main-class/form/crud/useEdicao";
import useDialog from "../../../main-class/template/dialog/useDialog";
import {lastValueFrom} from "rxjs";
import useLoader from "../../../main-class/componentes/loader/useLoader";
import useMensageria from "../../../main-class/componentes/mensageria/useMensageria";
import SincronizacaoContext from "../../../infra/sincronizacao/SincronizacaoContext";

export interface FiltroDesembarque {
    codigo?: string;
    carreta?: string;
    data?: Date;
    status?: DesembarqueDTO.StatusEnum;
    proprietario?: ClienteDTO;
}

export interface EdicaoDocumentoDesembarque extends DesembarqueDTO {
    temDeclaracaoFemea?: boolean;
}

export default function useDocumentoDesembarque() {
    const desembarqueApi = useRef(new DesembarqueApi(ApiConfiguration));
    const {confirmacao} = useDialog();
    const sincornizacaoState = useContext(SincronizacaoContext);
    const {entity, submitForm, formControl, reset, setEntity} = useEdicao<EdicaoDocumentoDesembarque>({
        idParam: 'desembarque',
        findAction: id => desembarqueApi.current.detalha(id)
            .then(dec => ({...dec, temDeclaracaoFemea: !!dec.declaracaoFemea})),
        preSave: async entity => {
            if (entity.status === DesembarqueDTO.StatusEnum.Pendente) {
                return await lastValueFrom(confirmacao('Você tem certeza que deseja iniciar o desembarque? O cliente será notificado.').afterClosed());
            } else {
                return await lastValueFrom(confirmacao('Você tem certeza que deseja alterar os documentos?').afterClosed());
            }
        },
        saveAction: entity => desembarqueApi.current.salvaDocumentos({
            id: entity.id,
        declaracaoFemea: entity.temDeclaracaoFemea ? entity.declaracaoFemea : undefined,
            gtas: entity.gtas,
            notaFiscal: entity.notaFiscal
        }),
        postSave: entity => {
            setEntity({
                ...entity,
                temDeclaracaoFemea: !!entity.declaracaoFemea,
            });
        },
        mensagemSucesso: 'Documenos salvos com sucesso.'
    });
    const {loadingAround} = useLoader();
    const {addMensagemSucesso} = useMensageria();

    useEffect(() => {
        const subscription = sincornizacaoState.desembarqueObservable
            .subscribe(() => reset())

        return () => subscription.unsubscribe();
    }, [sincornizacaoState, reset]);

    async function encerra() {
        if (!entity.dataHoraSaida) {
            return;
        }

        const confirmado = await lastValueFrom(confirmacao('Deseja realmente encerrar esse desembarque? O cliente será notificado.').afterClosed())

        if (confirmado) {
            await loadingAround(desembarqueApi.current.encerra(entity.id));

            setEntity({
                ...entity,
                status: DesembarqueDTO.StatusEnum.Encerrado,
            });

            addMensagemSucesso('Desembarque encerrado com sucesso.');
        }
    }

    return {
        entity,
        submitForm,
        formControl,
        showDadosPesagem: !!entity.pesoTotal && !!entity.qtdAnimais,
        podeEncerrar: entity.status === DesembarqueDTO.StatusEnum.Iniciado,
        reset,
        encerra,
    };


}


