import JSEncrypt from 'jsencrypt';

export default function useCriptografia() {

    function criptografa(chavePublica: string, senha: string): string {
        const rsa = new JSEncrypt(undefined);
        rsa.setPublicKey(chavePublica);
        return rsa.encrypt(senha) as string;
    }

    return {criptografa};
}
