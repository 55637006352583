import loginRoute from "./login/loginRoute";
import privateRoutes from "../main-class/template/route/privateRoutes";
import midiasLaudoEntradaRoute from "./laudoEntrada/midias/midiasLaudoEntradaRoute";
import publicRoutes from "../main-class/template/route/publicRoutes";
import homeRoute from "./home/homeRoute";
import usuarioRoute from "./usuario/usuarioRoute";
import desembarqueRoute from "./desembarque/desembarqueRoute";
import clienteRoute from "./cliente/clienteRoute";
import loteRoute from "./lote/loteRoute";
import laudoEntradaRoute from "./laudoEntrada/laudoEntradaRoute";
import laudoMorteRoute from "./laudoMorte/laudoMorteRoute";
import tratamentoRoute from "./tratamento/tratamentoRoute";
import movimentacaoRoute from "./movimentacao/movimentacaoRoute";
import pendenciaRoute from "./pendencia/pendenciaRoute";
import * as React from "react";
import {Routes} from "react-router-dom";
import baseRoutes from "../main-class/template/route/baseRoutes";
import buildTabRoute from "../main-class/template/route/buildTabRoute";
import ConsultaLote from "./lote/consulta/ConsultaLote";
import processamentoSaidaRoute from "./saida/processamentoSaidaRoute";
import redefinirSenhaRoute from "./redefinir-senha/redefinirSenhaRoute";
import noticiaRoute from "./noticia/noticiaRoute";


const routes = <Routes>
    {
        [
            loginRoute(),
            publicRoutes([
                midiasLaudoEntradaRoute(),

            ]),
            privateRoutes(
                baseRoutes(
                    [
                        homeRoute(),
                        usuarioRoute(),
                        desembarqueRoute(),
                        clienteRoute(),
                        loteRoute(),
                        laudoEntradaRoute(),
                        laudoMorteRoute(),
                        tratamentoRoute(),
                        movimentacaoRoute(),
                        pendenciaRoute(),
                        processamentoSaidaRoute(),
                        redefinirSenhaRoute(),
                        noticiaRoute(),
                        buildTabRoute({
                            title: "Processamento de entrada",
                            path: "etapa/:etapa",
                            element: <ConsultaLote/>
                        })
                    ]
                )
            ),
        ]
    }
</Routes>;

export default routes;
