import {useState} from "react";
import {AcessoApi, SenhaDTO} from "../../dao";
import ApiConfiguration from "../../infra/api/ApiConfiguration";
import useCriptografia from "../../main-class/componentes/util/useCriptografia";
import {useNavigate} from "react-router-dom";
import useSessao from "../../main-class/componentes/sessao/useSessao";
import useLoader from "../../main-class/componentes/loader/useLoader";
import useMensageria from "../../main-class/componentes/mensageria/useMensageria";

export enum LoginStep {
    email = 'email',
    senha = 'senha',
    otp = 'otp',
    cadastro_senha = 'cadastro_senha'
}

export interface LoginState {
    step: LoginStep;
    email?: string;
    otp?: string;
}

export default function useLogin() {
    const [{email, otp, step}, setState] = useState<LoginState>({step: LoginStep.email});
    const [podeSolicitarOTP, setPodeSolicitarOTP] = useState(true);
    const {criptografa} = useCriptografia();
    const {loggedIn} = useSessao();
    const {loadingAround} = useLoader();
    const navigate = useNavigate();
    const {addMensagemSucesso} = useMensageria();

    const acessoApi = new AcessoApi(ApiConfiguration);

    async function consultaEmail(email: string) {
        const inicioAcesso = await loadingAround(acessoApi.iniciaLogin({
            email
        }));

        if (inicioAcesso.possuiSenha) {
            setState({step: LoginStep.senha, email});
        } else {
            doSolicitaOTP(email);
        }
    }

    async function criptografaSenha(valor: string): Promise<SenhaDTO> {
        const chavePublica = await loadingAround(
            acessoApi.getChavePublica()
        );

        return {
            uuid: chavePublica.uuid,
            senha: criptografa(chavePublica.chavePublica, valor)
        };
    }

    async function realizaLogin(senha: string) {
        const usuario = await loadingAround(
            acessoApi.login({
                email: email as string,
                senhaCriptografada: await criptografaSenha(senha)
            }, {})
        );

        loggedIn(usuario);

        navigate('/');

        addMensagemSucesso(`Bem-vindo, ${usuario.nome}`);
    }

    async function solicitaOTP() {
        await doSolicitaOTP(email);
    }

    async function doSolicitaOTP(email: string) {
        if (podeSolicitarOTP) {
            setPodeSolicitarOTP(false);

            await loadingAround(
                acessoApi.solicitaOTP({email})
            );

            setState({email, step: LoginStep.otp});

            setTimeout(() => {
                setPodeSolicitarOTP(true);
            }, 30000);
        }
    }

    async function setOTP(otp: string) {
        setState({email, otp, step: LoginStep.cadastro_senha});
    }

    async function redefineSenha(senha: string) {
        const usuario = await loadingAround(
            acessoApi.redefineSenha({
                email,
                otpCriptografado: await criptografaSenha(otp),
                senhaCriptografada: await criptografaSenha(senha)
            })
        );

        loggedIn(usuario);

        navigate('/');

        addMensagemSucesso(`Senha redefinida com sucesso! Bem-vindo, ${usuario.nome}`);
    }

    function cancela() {
        setState({step: LoginStep.email});
    }

    return {
        state: {
            email,
            otp,
            step
        },
        consultaEmail,
        realizaLogin,
        solicitaOTP,
        setOTP,
        redefineSenha,
        cancela,
        podeSolicitarOTP
    };
}
