import {Button} from "@mui/material";
import MultipleArquivosFormField from "../../../../../main-class/form/input/MultipleArquivosFormField";
import React from "react";
import useEdicaoMidiasLote from "./useEdicaoMidiasLote";
import useValidation from "../../../../../main-class/form/validation/useValidation";

export default function EdicaoMidiasLote() {
    const {formControl, submitForm, entity, reset} = useEdicaoMidiasLote();
    const {validate, required} = useValidation();

    return (
        <>
            <form onSubmit={submitForm}>
                <MultipleArquivosFormField name={`midias`} label="Mídia"
                                           validate={validate(required())} accept={['.mp4']}
                                           control={formControl} value={entity.midias}/>
                <div className="acoes">
                    <Button type="submit" variant="contained" color="secondary">Salvar</Button>
                    <Button type="reset" onClick={reset} color="inherit">Cancelar</Button>
                </div>
            </form>
        </>
    )
}
