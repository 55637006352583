import {Box, DialogContent, DialogTitle, IconButton} from "@mui/material";
import React, {useEffect} from "react";
import useDialogRef from "../../main-class/template/dialog/useDialogRef";
import {Close} from "@mui/icons-material";
import useDialog from "../../main-class/template/dialog/useDialog";

export default function ModalAssinatura(props: { linkAssinatura: string, condicaoAssinatura: any, label: string }) {
    const {linkAssinatura, condicaoAssinatura, label} = props;
    const {dialogRef} = useDialogRef();
    const {confirmacao} = useDialog();

    useEffect(() => {
        const intervalId = setInterval(async () => {
            const fecha = await condicaoAssinatura();

            if (fecha) {
                dialogRef.close(true);
            }
        }, 2000);

        return () => clearInterval(intervalId);
    }, [dialogRef, condicaoAssinatura]);

    const fecha = () => {
        confirmacao('Deseja cancelar a assinatura? Caso tenha realizado a assinatura é recomendado aguardar alguns instantes antes de fechar a janela.')
            .afterClosed().subscribe(confirmado => {
            if (confirmado) {
                dialogRef.close(false);
            }
        });
    };

    return (
        <>
            <DialogTitle style={{display: 'flex'}}>
                {label}
                <div style={{flex: '1 1 auto'}}></div>
                <IconButton onClick={fecha}>
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <small style={{textAlign: 'center', marginBottom: '20px'}}>Depois da assinatura, mantenha a modal aberta. O sistema pode demorar alguns instantes para
                    reconhecer a assinatura.</small>
                <Box style={{height: '80vh', position: 'relative'}}>
                    <iframe src={linkAssinatura} title="Assinatura"
                            style={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                height: '80vh',
                            }}
                    ></iframe>
                </Box>
            </DialogContent>
        </>
    )
}
