import {UsuarioAdministradorApi, UsuarioAdministradorDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import useConsulta from "../../../main-class/form/crud/useConsulta";
import useDialog from "../../../main-class/template/dialog/useDialog";
import useLoader from "../../../main-class/componentes/loader/useLoader";
import useMensageria from "../../../main-class/componentes/mensageria/useMensageria";
import {lastValueFrom} from "rxjs";

export interface FiltroUsuario {
    perfil?: UsuarioAdministradorDTO.PerfilEnum;
    nome?: string;
    status?: UsuarioAdministradorDTO.StatusEnum;
}

export default function useConsultaUsuario() {

    const usuarioApi = new UsuarioAdministradorApi(ApiConfiguration);
    const {
        filtro,
        pagina,
        consulta,
        edita,
        remove,
        formControl,
        submitForm
    } = useConsulta<FiltroUsuario, UsuarioAdministradorDTO>({
        consultaAction: (filtro, pagina, tamanhoPagina) => usuarioApi.consulta(
            filtro.perfil,
            filtro.nome,
            filtro.status,
            pagina,
            tamanhoPagina
        ),
        exclusaoAction: (entity) => usuarioApi.remove(entity.id),
        mensagemConfirmacaoExclusao: (entity) => <>O usuário {entity.nome} será <strong>excluído</strong> no sistema.
            Deseja continuar?</>
    });

    const {confirmacao} = useDialog();
    const {loadingAround} = useLoader();
    const {addMensagemSucesso} = useMensageria();
    const perfis = Object.values(UsuarioAdministradorDTO.PerfilEnum);
    const status = Object.values(UsuarioAdministradorDTO.StatusEnum);

    async function inativa(usuario: UsuarioAdministradorDTO) {
        const confirmado = await lastValueFrom(
            confirmacao(
                <>O usuário {usuario.nome} será <strong>inativado</strong> no sistema. Deseja continuar?</>
            ).afterClosed()
        );

        if (confirmado) {
            await loadingAround(
                usuarioApi.inativa(usuario.id)
            );

            consulta(filtro, {
                pagina: pagina.paginaAtual,
                tamanhoPagina: pagina.tamanhoPagina
            });

            addMensagemSucesso("Usuário inativado com sucesso.");
        }
    }

    async function ativa(usuario: UsuarioAdministradorDTO) {

        const confirmado = await lastValueFrom(
            confirmacao(
                <>O usuário {usuario.nome} será <strong>ativado</strong> no sistema. Deseja continuar?</>
            ).afterClosed()
        );

        if (confirmado) {
            await loadingAround(
                usuarioApi.ativa(usuario.id)
            );

            consulta(filtro, {
                pagina: pagina.paginaAtual,
                tamanhoPagina: pagina.tamanhoPagina
            });

            addMensagemSucesso("Usuário ativado com sucesso.");
        }
    }

    return {pagina, consulta, filtro, formControl, submitForm, perfis, status, remove, edita, ativa, inativa};
}
