import {FormControl, FormHelperText, InputLabel, Select} from "@mui/material";
import {Controller} from "react-hook-form";
import {FieldProps} from "./FieldProps";
import React from "react";

export default function SelectFormField(props: FieldProps<any> & { children: React.ReactNode }) {

    function actualOnChange(onChange: (...event: any[]) => void) {
               console.log(props.onChange)

        return (event: any) => {
            onChange(event);
            if (props.onChange) {
                props.onChange(event);
            }
        };
    }

    return (
        <>
            <Controller
                name={props.name}
                control={props.control}
                defaultValue={props.value === undefined ? "" : props.value}
                render={({field: {onChange, value}, fieldState: {error}}) => (
                    <FormControl variant={props.variant} required={props.required}>
                        <InputLabel htmlFor={props.name + "_input"}>{props.label}</InputLabel>
                        <Select
                            id={props.name + "_input"}
                            value={value}
                            onChange={actualOnChange(onChange)}
                            error={!!error}
                            label={props.label}>
                            {props.children}
                        </Select>
                        <FormHelperText style={{color: 'red'}}>{error ? error.message : null}</FormHelperText>
                    </FormControl>
                )}
                rules={{validate: props.validate}}
            />
        </>
    );
}
