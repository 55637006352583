import {useForm} from "react-hook-form";
import useDialogRef from "../../../../main-class/template/dialog/useDialogRef";
import {Button, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import MultipleArquivosFormField from "../../../../main-class/form/input/MultipleArquivosFormField";
import React, {useCallback} from "react";
import {EmbarqueDTO} from "../../../../dao";


export default function DialogEdicaoNotasFiscaisEmbarque(props: { value: EmbarqueDTO }) {

    const {control, getValues, handleSubmit} = useForm({
        defaultValues: props.value,
    });
    const embarque = getValues();
    const {dialogRef} = useDialogRef();

    const actualHandleSubmit = handleSubmit(entity => {
        dialogRef.close(entity);
    });

    const cancel = useCallback(() => {
        dialogRef.close();
    }, [dialogRef]);

    return (
        <>
            <DialogTitle style={{display: 'flex'}}>
                Nota fiscal
                <div style={{flex: '1 1 auto'}}/>
                <IconButton type="button" onClick={() => dialogRef.close()}>
                    <Close/>
                </IconButton>
            </DialogTitle>
            <form onSubmit={actualHandleSubmit}>
                <DialogContent style={{paddingTop: '20px'}}>
                    <MultipleArquivosFormField
                        name="notasFiscais" accept={[".pdf"]}
                        control={control as any} label="Notas Fiscais"
                        value={embarque.notasFiscais}/>


                </DialogContent>
                <DialogActions>

                    <Button type="button"
                            onClick={() => cancel()}>
                        Cancelar
                    </Button>
                    <Button variant="contained" type="submit">
                        Aplicar Alteração
                    </Button>

                </DialogActions>
            </form>
        </>
    )
        ;
}
