import {useCallback, useContext} from "react";
import ArquivoManagerContext, {IArquivo, UploadProgress} from "./ArquivoContext";


export default function useArquivo() {
    const arquivoManager = useContext(ArquivoManagerContext);

    function isFormat(arquivo: IArquivo, format: string | string[]) {
        let formats = [];
        if (typeof format === 'string') {
            formats = [format];
        } else {
            formats = format;
        }

        for (const format of formats) {
            if (arquivo.nome.toLowerCase().indexOf(format.toLowerCase()) === arquivo.nome.length - format.length) {
                return true;
            }
        }

        return false;
    }

    function fileFormat(allowedFormat: string | string[]) {
        return (arquivo?: IArquivo) => {
            if (arquivo && allowedFormat) {
                if (!isFormat(arquivo, allowedFormat)) {
                    return `Formato inválido.`;
                }
            }

            return null;
        }
    }

    function fileLength(limit: string) {
        return (arquivo?: IArquivo) => {
            if (arquivo && limit) {
                const total = parseLimit(limit);
                if (arquivo.tamanho > total) {
                    return "Arquivo ultrapassa o limite de " + limit;
                }
            }

            return null;
        }
    }

    function parseLimit(limit: string) {
        let total = Number(limit.replace(/\D+/g, ''));

        if (limit.toUpperCase().indexOf('GB') >= 0) {
            total = total * 1024 * 1024 * 1024;
        } else if (limit.toUpperCase().indexOf('MB') >= 0) {
            total = total * 1024 * 1024;
        } else if (limit.toUpperCase().indexOf('KB') >= 0) {
            total = total * 1024;
        }

        return total;
    }

    async function download(arquivo: IArquivo, target = "_blank") {
        const url = await arquivoManager.getURL(arquivo);

        const link = document.createElement('a');

        link.href = url;
        link.target = target;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function downloadBase64(contentType: string, base64Data: string, fileName: string) {
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        console.log(downloadLink)
        downloadLink.click();
    }

    const upload = useCallback(async (file: File, callback?: UploadProgress) => {
        return await arquivoManager.upload(file, callback);
    }, [arquivoManager]);

    return {fileFormat, fileLength, upload, download, downloadBase64, isFormat};
}
