import {Box, Card} from "@mui/material";
import React, {useContext} from "react";
import useConsultaOcorrencia from "./useConsultaMidiasLaudoEntrada";
import Header from "../../../main-class/template/header/Header";
import {TemplateConfigContext} from "../../../main-class/template/base/Base";
import useLabels from "../../../i18n/useLabels";
import useArquivo from "../../../main-class/componentes/arquivo/useArquivo";

export default function ConsultaMidiasLaudoEntrada() {

    const {ocorrencias} = useConsultaOcorrencia();
    const config = useContext(TemplateConfigContext);
    const {ptBR} = useLabels();
    const {isFormat} = useArquivo();

    return (
        <>
            <div>
                <Header logoSrc={config.logoSrc} />
                {
                    ocorrencias.map((ocorrencia, index) => {

                        if (ocorrencia.midias != null) {
                            return (
                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '20px', justifyItems: 'center'}}>
                                    <Card>
                                        <h3>{ptBR.enum.tipo_ocorrencia[ocorrencia.ocorrencia]}</h3>
                                        {ocorrencia.midias.map((midia, index) => {
                                            return (
                                                <Box sx={{height: '350px'}}>
                                                    {
                                                        isFormat(midia, '.mp4') &&
                                                        <video style={{width: "100%"}} controls >
                                                            <source src={midia.url} type="video/mp4" />
                                                        </video>
                                                    }
                                                    {
                                                        isFormat(midia, ['.jpeg', '.jpg', '.png']) &&
                                                        <img style={{width: "100%"}} src={midia.url} alt={'image' + midia.id}/>
                                                    }
                                                </Box>

                                            );
                                        })
                                        }
                                    </Card>

                                </Box>
                            );
                        }

                        return <></>

                    })
                }

            </div>
        </>
    );
}


