import React from "react";
import {RelatorioGeral} from "./dashboard/RelatorioGeral";
import usePermissao from "../../infra/seguranca/usePermissao";
import {UsuarioAdministradorDTO} from "../../dao";
import PerfilEnum = UsuarioAdministradorDTO.PerfilEnum;
import {Box, Card} from "@mui/material";
import {RelatorioAcesso} from "./dashboard/RelatorioAcesso";
import {RelatorioTelas} from "./dashboard/RelatorioTelas";

export function Home() {
    const {any} = usePermissao();

    return (
        <>
            <RelatorioGeral />
            {
                any(PerfilEnum.Administrativo, PerfilEnum.Gerencial) &&
                <Box sx={{display: 'flex', gap: '20px'}}>
                    <Card sx={{flex: '1'}}>
                        <h2>Atividade dos usuários</h2>
                        <RelatorioAcesso />
                    </Card>
                    <Card sx={{flex: '1'}}>
                        <h2>Acesso a funcionalidades</h2>
                        <RelatorioTelas />
                    </Card>
                </Box>
            }
        </>
    );
}
