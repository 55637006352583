import {Button} from "@mui/material";
import InputOtp from "../../../componentes/otp/InputOtp";

interface OtpProps {
    email: string;
    podeGerarNovoCodigo: boolean;
    onCancela: () => any;
    onGeraNovoCodigo: () => any;
    onSubmitted: (otp: string) => any;
}

export default function Otp(props: OtpProps) {
    const {podeGerarNovoCodigo, email, onSubmitted, onCancela, onGeraNovoCodigo} = props;

    return (
        <>
            <div className="content">
                <form>

                    <p>
                        Digite o código de 6 dígitos enviado para seu e-mail.<br/>
                        O código pode demorar alguns segundos para chegar.
                    </p>

                    <InputOtp email={email} onOtpDigitado={onSubmitted}/>

                    <div className="commands">
                        <Button variant="outlined"
                                onClick={onGeraNovoCodigo}
                                disabled={!podeGerarNovoCodigo}
                                type="button" color="secondary">
                            {podeGerarNovoCodigo ? "Enviar novo código" : "Aguarde..."}
                        </Button>
                        <br/>
                        <br/>
                        <Button variant="text"
                                onClick={onCancela}
                                type="button" color="inherit">Cancelar</Button>
                    </div>

                </form>
            </div>
        </>
    );
}
