import {Card} from "@mui/material";
import React from "react";
import DesembarquesLote from "./components/desembarque/DesembarquesLote";
import EdicaoMidiasLote from "./components/edicao/EdicaoMidiasLote";

export default function MidiasLote() {

    return (
        <>
            <Card>
                <DesembarquesLote/>
            </Card>

            <Card>
                <h2>Mídias do desembarque</h2>

                <EdicaoMidiasLote/>

            </Card>
        </>
    );
}
