
import {AnimalApi, AnimalDTO} from "../../../dao";
import {useEffect, useRef, useState} from "react";

import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import {useNavigate, useParams} from "react-router-dom";
import useConsulta from "../../../main-class/form/crud/useConsulta";

export interface FiltroAnimal {
    sisbov?: string;
    bosch?: string;
    raca?: number;
    status?: AnimalDTO.StatusEnum;
    pesoMinimoBalancinha?: string;
    pesoMaximoBalancinha?: string;
    data?: Date;
}

export default function useConsultaAnimal() {

    const animalApi = useRef(new AnimalApi(ApiConfiguration));
    const {lote: idLote} = useParams();
    const status = Object.values(AnimalDTO.StatusEnum);
    const [raca , setRaca] = useState([]);
    const navigate = useNavigate();

    const {
        filtro,
        pagina,
        consulta,
        formControl,
        submitForm
    } = useConsulta<FiltroAnimal, AnimalDTO>({
        consultaAction: (filtro, pagina, tamanhoPagina) => animalApi.current.consulta(
            filtro.sisbov,
            filtro.bosch,
            filtro.raca,
            filtro.status,
            filtro.pesoMinimoBalancinha,
            filtro.pesoMaximoBalancinha,
            Number(idLote),
            filtro.data,
            pagina,
            tamanhoPagina
        ),
    });

    useEffect(() => {
        animalApi.current.consultaRacas().then(response => {
            setRaca(response)
        })
    }, []);



    return {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        status,
        raca,
        navigate
    };


}
