import {Button, Icon, IconButton, Menu, MenuItem, Tooltip, useTheme} from "@mui/material";
import useHeaderActions from "./useHeaderActions";
import useSessao from "../../main-class/componentes/sessao/useSessao";
import BotaoCarga from "../../componentes/botaoCarga/BotaoCarga";

export default function HeaderActions() {
    const {
        optionsOpen,
        anchorOptionsMenu,
        onOpenOptionsUsuario,
        onCloseOptionsUsuario,
        onAlterarSenha,
        onSair,
        onAjuda
    } = useHeaderActions();

    const theme = useTheme();

    const {user} = useSessao();

    return (
        <>
            <BotaoCarga/>
            <Button
                id="basic-button"
                aria-controls={optionsOpen ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={optionsOpen ? 'true' : undefined}
                onClick={onOpenOptionsUsuario}
                style={{color: theme.palette.primary.main}}
            >
                <Icon color="primary" style={{marginRight: '10px'}}>account_circle</Icon>
                {user?.nome}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorOptionsMenu}
                open={optionsOpen}
                onClose={onCloseOptionsUsuario}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={onAlterarSenha}>Alterar Senha</MenuItem>
            </Menu>
            <Tooltip title="Ajuda">
                <IconButton onClick={onAjuda} color="primary">
                    <Icon color="primary">help_outline</Icon>
                </IconButton>
            </Tooltip>
            <Tooltip title="Sair">
                <IconButton onClick={onSair} color="primary">
                    <Icon color="primary">logout</Icon>
                </IconButton>
            </Tooltip>
        </>
    );
}
