import {Box, Card} from "@mui/material";
import {Outlet} from "react-router-dom";
import React from "react";
import useSumarioAnimal from "./useSumarioAnimal";
import AnimalContext from "../animalContext";
import useLabels from "../../../i18n/useLabels";

export default function SumarioAnimal() {
    const {animal, reload} = useSumarioAnimal();
    const {ptBR} = useLabels();


    return (
        <>
            <AnimalContext.Provider value={{animal: animal || {}, reload}}>
                <Card>
                    <Box sx={{display: 'flex', gap: '20px', alignItems: 'center'}}>

                        <Box sx={{flex: 1, textAlign: 'start'}}>
                            <h2 style={{margin: '0 0 10px'}}>Dados do animal</h2>
                            <Box sx={{display: 'flex', gap: '100px', textAlign: 'start'}}>
                                <span style={{margin: '0 0 10px'}}>SISBOV: {animal.sisbov}</span>
                                <span style={{margin: '0 0 10px'}}>BOSCH: {animal.bosch}</span>
                                <span style={{margin: '0 0 10px'}}>Sexo: {ptBR.enum.sexo[animal.sexo]}</span>
                                <span style={{margin: '0 0 10px'}}>Dias de cocho: {animal.diasDeCocho}</span>
                                <span style={{margin: '0 0 10px'}}>Raça: {animal.raca?.nome}</span>
                            </Box>
                        </Box>

                    </Box>
                </Card>

                <Outlet/>
            </AnimalContext.Provider>
        </>
    );
}
