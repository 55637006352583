import {Controller} from "react-hook-form";
import {FieldProps} from "./FieldProps";
import React from "react";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {TextField} from "@mui/material";
import moment from "moment";

export default function DateFormField(props: FieldProps<Date>) {

    function actualOnChange(onChange: (...event: any[]) => void) {
        return (event: moment.Moment | null) => {
            const valid = event?.isValid() ?? false;
            onChange(valid ? event : undefined);
            if (props.onChange) {
                props.onChange(valid ? event?.toDate() : undefined);
            }
        };
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Controller
                    name={props.name}
                    control={props.control}
                    defaultValue={props.value}
                    render={({field: {onChange, value, ...restField}, fieldState: {error}}) => {
                        return (
                            <DesktopDatePicker
                                value={value || null}
                                inputFormat="DD/MM/YYYY"
                                label={props.label}
                                onChange={actualOnChange(onChange)}
                                {...restField}
                                renderInput={(params) => <TextField
                                    {...params}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                />}
                            />
                        );
                    }}
                    rules={{validate: props.validate}}
                />
            </LocalizationProvider>
        </>
    );
}
