
import buildTabRoute from "../../main-class/template/route/buildTabRoute";
import ConsultaTratamento from "./ConsultaTratamento";

export default function tratamentoRoute() {
    return buildTabRoute({
        path: "/ronda-sanitaria",
        title: "Ronda Sanitária",
        subroutes: [
            {
                path: "",
                element: <ConsultaTratamento />,
            },
        ]
    });
}

