import {Box, Card, IconButton, MenuItem} from "@mui/material";
import TextFormField from "../../main-class/form/input/TextFormField";
import DateFormField from "../../main-class/form/input/DateFormField";
import SelectFormField from "../../main-class/form/input/SelectFormField";
import PaginatedTable from "../../main-class/componentes/table/PaginatedTable";
import TableColumn from "../../main-class/componentes/table/TableColumn";
import useFormat from "../../main-class/componentes/format/useFormat";
import {Add, Edit, Search} from "@mui/icons-material";
import ColumnHeader from "../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../main-class/componentes/table/ColumnCell";
import {MovimentacaoDTO} from "../../dao";
import useConsultaMovimentacao from "./useConsultaMovimentacao";
import React from "react";
import useLabels from "../../i18n/useLabels";
import SelectLocal from "./componentes/SelectLocal";

export default function ConsultaMovimentacao() {
    const {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        motivos,
        onEditaMidia
    } = useConsultaMovimentacao();

    const {formatDate} = useFormat();
    const {ptBR} = useLabels();

    return (
        <>

            <Card>
                <h2><Search/> Filtrar</h2>

                <form onSubmit={submitForm}>

                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{flex: '1'}}>

                            <TextFormField label="BOSCH" value={filtro.bosch} control={formControl} name="bosch"
                                           onChange={submitForm}
                            />
                            <TextFormField label="SISBOV" value={filtro.sisbov} control={formControl} name="sisbov"
                                           onChange={submitForm}
                            />

                        </Box>
                        <Box sx={{flex: '1'}}>
                            <DateFormField label="Data da finalizacao" value={filtro.data} control={formControl}
                                           name="data"
                                           onChange={submitForm}
                            />
                            <TextFormField label="Lote" value={filtro.lote} control={formControl} name="lote"
                                           onChange={submitForm}
                            />

                        </Box>

                        <Box sx={{flex: '1'}}>
                            <SelectLocal name="de" label="De"
                                         value={filtro.de} control={formControl}
                                         onChange={submitForm}/>

                            <SelectLocal name="para" label="Para"
                                         value={filtro.para} control={formControl}
                                         onChange={submitForm}/>


                        </Box>


                        <Box sx={{flex: '1'}}>


                            <SelectFormField name="motivo" label="Motivo"
                                             value={filtro.motivo} control={formControl}
                                             onChange={submitForm}>
                                <MenuItem value={null}/>
                                {
                                    motivos.map(motivo =>
                                        <MenuItem key={"MenuItem_motivo_" + motivo} value={motivo.id}>
                                            {motivo.nome}
                                        </MenuItem>
                                    )
                                }
                            </SelectFormField>
                        </Box>

                    </Box>

                </form>
            </Card>


            <Card>
                <PaginatedTable onPageChange={(pagina, tamanhoPagina) => consulta(filtro, {pagina, tamanhoPagina})}
                                pagina={pagina}>

                    <TableColumn name="data">
                        <ColumnHeader>Data</ColumnHeader>
                        <ColumnCell
                            content={(item: MovimentacaoDTO) => formatDate(item.dataDeEntrada as any, 'DD/MM/YYYY')}/>
                    </TableColumn>

                    <TableColumn name="lote">
                        <ColumnHeader>Lote</ColumnHeader>
                        <ColumnCell content={(item: MovimentacaoDTO) => item.animal?.lote?.codigo}/>
                    </TableColumn>

                    <TableColumn name="bosch">
                        <ColumnHeader>Bosch</ColumnHeader>
                        <ColumnCell content={(item: MovimentacaoDTO) => item.animal.bosch}/>
                    </TableColumn>

                    <TableColumn name="sisbov">
                        <ColumnHeader>Sisbov</ColumnHeader>
                        <ColumnCell content={(item: MovimentacaoDTO) => item.animal.sisbov}/>
                    </TableColumn>

                    <TableColumn name="motivoMovimentacao">
                        <ColumnHeader>Motivo da movimentação</ColumnHeader>
                        <ColumnCell content={(item: MovimentacaoDTO) => item.motivo.nome}/>
                    </TableColumn>

                    <TableColumn name="de" style={{textAlign: 'center'}}>
                        <ColumnHeader>De</ColumnHeader>
                        <ColumnCell content={(item: MovimentacaoDTO) => <>
                            {item.de.nome}<br/>
                            <small>{ptBR.enum.tipo_local[item.de.tipo]}</small>
                        </>}/>
                    </TableColumn>

                    <TableColumn name="para" style={{textAlign: 'center'}}>
                        <ColumnHeader>Para</ColumnHeader>
                        <ColumnCell content={(item: MovimentacaoDTO) => <>
                            {item.para.nome}<br/>
                            <small>{ptBR.enum.tipo_local[item.para.tipo]}</small>
                        </>}/>
                    </TableColumn>

                    <TableColumn name="midias" style={{width: '60px', textAlign: 'center'}}>
                        <ColumnHeader>Mídias</ColumnHeader>
                        <ColumnCell content={(item: MovimentacaoDTO) => item.midias?.length > 0 ? 'Sim' : 'Não'}/>
                    </TableColumn>

                    <TableColumn name="edicao" style={{width: '60px', textAlign: 'center'}}>
                        <ColumnHeader></ColumnHeader>
                        <ColumnCell content={(item: MovimentacaoDTO) =>
                            <>
                                <IconButton onClick={() => onEditaMidia(item)}>
                                    {!item.midias?.length && <Add/>}
                                    {!!item.midias?.length && <Edit/>}
                                </IconButton>
                            </>
                        }/>
                    </TableColumn>
                </PaginatedTable>
            </Card>
        </>


    );
}

