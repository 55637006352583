import {useCallback, useEffect, useRef, useState} from "react";
import {lastValueFrom} from "rxjs";
import {Pagina} from "../../componentes/table/PaginatedTable";
import {useForm} from "react-hook-form";
import useDialog from "../../template/dialog/useDialog";
import {UsuarioAdministradorDTO} from "../../../dao";
import {useNavigate} from "react-router-dom";
import useMensageria from "../../componentes/mensageria/useMensageria";
import useLoader from "../../componentes/loader/useLoader";

export interface Paginacao {
    pagina: number;
    tamanhoPagina: number;
}

export interface ConsultaState<M> {
    paginacao: Paginacao;
    pagina: Pagina<M>;
}

export interface ConsultaConfig<F, M> {
    filtroInicial?: F,
    paginacaoInicial?: Paginacao,
    consultaAction: (filtro: F, pagina: number, tamanhoPagina: number) => Promise<Pagina<M>>,
    exclusaoAction?: (entidade: M) => Promise<any>,
    mensagemConfirmacaoExclusao?: (entity: M) => React.ReactNode,
}

export default function useConsulta<F, M>(initialConfig: ConsultaConfig<F, M>) {
    const {loadingAround} = useLoader();
    const {addMensagemSucesso} = useMensageria();
    const [config] = useState<ConsultaConfig<F, M>>(initialConfig);
    const [{paginacao, pagina}, setState] = useState<ConsultaState<M>>({
        paginacao: config.paginacaoInicial || {pagina: 1, tamanhoPagina: 10},
        pagina: null
    });
    const {control, handleSubmit, getValues, reset} = useForm({defaultValues: (config.filtroInicial || {}) as any});
    const {confirmacao} = useDialog();
    const navigate = useNavigate();
    const consultaTimeout = useRef<any>();

    const consulta = useCallback((
        filtro = config.filtroInicial || {} as F,
        paginate = {
            pagina: 1,
            tamanhoPagina: paginacao.tamanhoPagina
        }
    ) => {
        clearTimeout(consultaTimeout.current);
        reset(filtro);
        consultaTimeout.current = setTimeout(async () => {
            const pagina = await loadingAround(
                config.consultaAction(filtro, paginate.pagina, paginate.tamanhoPagina)
            );

            setState({paginacao, pagina});
        }, 500);
    }, [reset, loadingAround, setState, config, consultaTimeout, paginacao]);

    async function remove(entity: M) {
        if (config.exclusaoAction) {
            const confirmado = await lastValueFrom(
                confirmacao(config.mensagemConfirmacaoExclusao ?
                    config.mensagemConfirmacaoExclusao(entity) :
                    `Confirma a exclusão do registro? Essa ação não poderá ser desfeita.`)
                    .afterClosed()
            );

            if (confirmado) {
                await loadingAround(
                    config.exclusaoAction(entity)
                );

                addMensagemSucesso('Registro excluído com sucesso.');

                consulta(getValues(), paginacao);
            }
        }
    }

    const submitForm = handleSubmit((filtro) => consulta(filtro));

    function edita(usuario: UsuarioAdministradorDTO) {
        navigate(`./edicao/${usuario.id}`);
    }

    useEffect(() => {
        consulta();
    }, [consulta]);

    return {filtro: getValues() as F, pagina, consulta, edita, remove, submitForm, formControl: control};
}
