import React, {useCallback, useRef} from "react";
import {ClienteApi, ClienteDTO} from "../../dao";
import ApiConfiguration from "../../infra/api/ApiConfiguration";
import AutocompleteFormField from "../../main-class/form/input/AutocompleteFormField";

interface SelectClienteProps {
    name: string;
    label: string;
    value?: ClienteDTO;
    control: any;
    onChange: any;
}

export default function SelectCliente(props: SelectClienteProps) {
    const clienteApi = useRef(new ClienteApi(ApiConfiguration));
    const {name, label, value, control, onChange} = props;

    const filtraClientes = useCallback(async (filtro: string) => {
        const pagina = await clienteApi.current.consulta(
            undefined,
            filtro,
            undefined,
            undefined,
            1,
            5
        );
        return pagina.itens || [];
    }, [clienteApi]);

    return (
        <>
            <AutocompleteFormField
                name={name} label={label}
                value={value} control={control}
                onChange={onChange} getOptionLabel={cliente => cliente.nome}
                getOptions={filtraClientes} compareWith={(c1, c2) => c1.id === c2.id}
            />
        </>
    );
}
