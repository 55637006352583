import {UsuarioAdministradorDTO} from "../../../dao";
import ConsultaPage from "../../../main-class/template/crud/ConsultaPage";
import {Box, IconButton, MenuItem} from "@mui/material";
import SelectFormField from "../../../main-class/form/input/SelectFormField";
import TextFormField from "../../../main-class/form/input/TextFormField";
import PaginatedTable from "../../../main-class/componentes/table/PaginatedTable";
import TableColumn from "../../../main-class/componentes/table/TableColumn";
import ColumnHeader from "../../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../../main-class/componentes/table/ColumnCell";
import {Delete, Edit, Lock, LockOpen} from "@mui/icons-material";
import useLabels from "../../../i18n/useLabels";
import useConsultaUsuario from "./useConsultaUsuario";
import CopyContent from "../../../main-class/componentes/copyContent/CopyContent";


export default function ConsultaUsuario() {
    const {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        perfis,
        status,
        remove,
        edita,
        ativa,
        inativa
    } = useConsultaUsuario();
    const {ptBR} = useLabels();

    return (
        <ConsultaPage
            filtro={
                <form onSubmit={submitForm}>

                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{flex: '1'}}>
                            <SelectFormField name="perfil" label="Perfil"
                                             value={filtro.perfil} control={formControl}
                                             onChange={submitForm}>
                                <MenuItem value={null}/>
                                {
                                    perfis.map(perfil =>
                                        <MenuItem key={"MenuItem_Perfil_" + perfil}
                                                  value={perfil}>{ptBR.enum.perfil_usuario[perfil]}</MenuItem>
                                    )
                                }
                            </SelectFormField>
                        </Box>
                        <Box sx={{flex: '2'}}>
                            <TextFormField label="Nome" value={filtro.nome} control={formControl} name="nome"
                                           onChange={submitForm}
                            />
                        </Box>
                        <Box sx={{flex: '1'}}>
                            <SelectFormField name="status" label="Status"
                                             value={filtro.status} control={formControl}
                                             onChange={submitForm}>
                                <MenuItem value={null}/>
                                {
                                    status.map(sts =>
                                        <MenuItem key={"MenuItem_Status_" + sts} value={sts}>
                                            {ptBR.enum.status_usuario[sts]}
                                        </MenuItem>
                                    )
                                }
                            </SelectFormField>
                        </Box>
                    </Box>

                </form>
            }>

            <PaginatedTable onPageChange={(pagina, tamanhoPagina) => consulta(filtro, {pagina, tamanhoPagina})}
                            pagina={pagina}>
                <TableColumn name="nome">
                    <ColumnHeader>Nome</ColumnHeader>
                    <ColumnCell content={(item: UsuarioAdministradorDTO) => item.nome}/>
                </TableColumn>
                <TableColumn name="perfil" style={{textAlign: 'center'}}>
                    <ColumnHeader>Perfil</ColumnHeader>
                    <ColumnCell content={(item: UsuarioAdministradorDTO) => ptBR.enum.perfil_usuario[item.perfil]}/>
                </TableColumn>
                <TableColumn name="contato">
                    <ColumnHeader>Contato</ColumnHeader>
                    <ColumnCell content={
                        (item: UsuarioAdministradorDTO) => <>
                            <CopyContent content={item.celular}>
                                {item.celular}
                            </CopyContent>
                            {' | '}
                            <CopyContent content={item.email}>
                                {item.email}
                            </CopyContent>
                        </>
                    }/>
                </TableColumn>
                <TableColumn name="status" style={{textAlign: 'center'}}>
                    <ColumnHeader>Status</ColumnHeader>
                    <ColumnCell content={(item: UsuarioAdministradorDTO) => ptBR.enum.status_usuario[item.status]}/>
                </TableColumn>
                <TableColumn name="opcoes" style={{textAlign: 'right'}}>
                    <ColumnHeader/>
                    <ColumnCell content={
                        (item: UsuarioAdministradorDTO) => <>
                            <IconButton type="button" onClick={() => edita(item)}><Edit/></IconButton>
                            {item.status === UsuarioAdministradorDTO.StatusEnum.Ativo &&
                                <IconButton type="button" onClick={() => inativa(item)}><Lock/></IconButton>}
                            {item.status !== UsuarioAdministradorDTO.StatusEnum.Ativo &&
                                <IconButton type="button" onClick={() => ativa(item)}><LockOpen/></IconButton>}
                            <IconButton type="button" onClick={() => remove(item)}><Delete/></IconButton>
                        </>
                    }/>
                </TableColumn>
            </PaginatedTable>
        </ConsultaPage>
    );
}
