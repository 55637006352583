import ArquivoFormField from "../../../../main-class/form/input/ArquivoFormField";
import React from "react";
import useValidation from "../../../../main-class/form/validation/useValidation";
import {Control} from "react-hook-form/dist/types";
import {ArquivoDTO} from "../../../../dao";
import {useWatch} from "react-hook-form";

interface InputDeclaracaoFemeaProps {
    control?: Control<any>;
    declaracaoFemea?: ArquivoDTO;
}

export default function InputDeclaracaoFemea(props: InputDeclaracaoFemeaProps) {
    const {declaracaoFemea, control} = props;
    const temDeclaracaoFemea = useWatch({
        control,
        name: "temDeclaracaoFemea"
    });
    const {validate, required} = useValidation();

    return (
        <>

            {
                temDeclaracaoFemea &&
                <ArquivoFormField label="Declaração de fêmea" name="declaracaoFemea"
                                  control={control} value={declaracaoFemea} accept={".pdf"}
                                  validate={
                                      validate(
                                          required()
                                      )
                                  }/>
            }

        </>
    )
}
