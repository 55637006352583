import {FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup} from "@mui/material";
import {Controller} from "react-hook-form";
import {FieldProps} from "./FieldProps";
import React from "react";
import SelectItem, {parseSelectItem, SelectItemDTO} from "./SelectItem";


export default function SelectRadioFormField(props: FieldProps<any> & { children: React.ReactNode }) {
    const {children} = props;

    const items = children instanceof Array ? children
            .filter(col => col.type === SelectItem)
            .map(col => parseSelectItem(col.props)) :
        [parseSelectItem((children as React.ReactElement).props)];

    function actualOnChange(onChange: (...event: any[]) => void, items: Array<SelectItemDTO>) {
        return (event: any, value: string) => {
            onChange(items.find(it => it.id === value)?.value);
            if (props.onChange) {
                props.onChange(items.find(it => it.id === value)?.value);
            }
        };
    }

    return (
        <>
            <Controller
                name={props.name}
                control={props.control}
                defaultValue={props.value === undefined ? "" : props.value}
                render={({field: {onChange, value}, fieldState: {error}}) => {
                    const selectedValue = value === undefined ? undefined : items.find(it => it.value === value)?.id || '';
                    return (
                        <>
                            <FormLabel htmlFor={props.name + "_input"}>{props.label}</FormLabel>
                            <FormControl variant={props.variant} required={props.required}>
                                <RadioGroup row
                                            id={props.name + "_input"} value={selectedValue}
                                            onChange={actualOnChange(onChange, items)}>
                                    {
                                        items.map(item => <FormControlLabel
                                            key={props.name + "_controlLabel_" + item.id}
                                            value={item.id} label={item.label}
                                            control={
                                                <Radio key={props.name + "_radio_" + item.value} disabled={props.disabled}/>
                                            }/>)
                                    }
                                </RadioGroup>
                                <FormHelperText style={{color: 'red'}}>{error ? error.message : null}</FormHelperText>
                            </FormControl>
                        </>
                    );
                }}
                rules={{validate: props.validate}}
            />
        </>
    );
}
