import {Alert, Snackbar} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {Mensagem, MensageriaContext} from "./Mensageria";

export default function MensagemGlobal() {
    const [state, setState] = useState<{ open: boolean, mensagem?: Mensagem }>({open: false});
    const mensageria = useContext(MensageriaContext);

    useEffect(() => {
        const subscription = mensageria.mensagemAdded.subscribe(mensagem => {
            setState({open: true, mensagem});
        });

        return () => subscription.unsubscribe();
    });

    function handleClose() {
        setState({open: false});
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                open={state.open} onClose={handleClose}>
                <Alert severity={state.mensagem?.tipo || 'info'} variant="filled">
                    {state.mensagem?.mensagem || ''}
                </Alert>
            </Snackbar>
        </>
    );
}
