import React from "react";
import {Outlet, Route} from 'react-router-dom';
import TabsBase from "../tabs/TabsBase";

export interface RouteConfig {
    path: string;
    title?: string | ((params?: any) => string);
    element?: React.ReactNode;
    tabs?: Array<{ label: string, path: string }>;
    subroutes?: Array<RouteConfig>;
}

export default function buildTabRoute(config: RouteConfig) {
    return _buildTabRoute({...config, root: true});
}

function _buildTabRoute(config: RouteConfig & { root: boolean }) {
    const {path, element, subroutes, root} = config;

    return (
        <>
            <Route key={'Route_' + path}
                   path={path} element={
                root ?
                    <TabsBase config={config}>
                        {element || <Outlet/>}
                    </TabsBase> :
                    <>
                        {element || <Outlet/>}
                    </>
            }>
                {
                    subroutes?.map((r) =>
                        _buildTabRoute({...r, root: false})
                    )
                }
            </Route>
        </>
    );
}

