import {useRef} from "react";
import {ClienteApi} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import useConsulta from "../../../main-class/form/crud/useConsulta";
import {useParams} from "react-router-dom";

export default function useConsultaNotificacoesCliente() {
    const clienteApi = useRef(new ClienteApi(ApiConfiguration));
    const {cliente: idCliente} = useParams();
    const {consulta, pagina} = useConsulta({
        consultaAction: (filtro, pagina, tamanhoPagina) => clienteApi.current.consultaNotificacoes(
            Number(idCliente),
            undefined,
            undefined,
            undefined,
            pagina,
            tamanhoPagina,
        )
    });

    return {consulta, pagina};
}
