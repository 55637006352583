import buildTabRoute from "../../main-class/template/route/buildTabRoute";
import CadastroLaudoEntrada from "./cadastro/CadastroLaudoEntrada";
import {LoteDTO} from "../../dao";
import ConsultaLote from "../lote/consulta/ConsultaLote";
import SumarioLote from "../lote/sumario/SumarioLote";

export default function laudoEntradaRoute() {
    return buildTabRoute({
        path: "/laudo-entrada",
        title: "Laudo de Entrada",
        subroutes: [
            {
                path: "",
                element: <ConsultaLote key={"laudo-entrada"}
                                       etapa={LoteDTO.EtapaEnum.Desembarque}
                                       status={LoteDTO.StatusEnum.Ativo}/>,
            },
            {
                path: ":lote",
                element: <SumarioLote/>,
                subroutes: [
                    {
                        path: "",
                        title: "+ Adicionar",
                        element: <CadastroLaudoEntrada/>,
                    }
                ]
            }
        ]
    });
}
