import {Box, Card, MenuItem} from "@mui/material";
import {Search} from "@mui/icons-material";
import TextFormField from "../../../../main-class/form/input/TextFormField";
import SelectFormField from "../../../../main-class/form/input/SelectFormField";
import AutocompleteFormField from "../../../../main-class/form/input/AutocompleteFormField";
import React, {useCallback, useContext, useRef} from "react";
import {ClienteApi, PendenciaDTO} from "../../../../dao";
import useLabels from "../../../../i18n/useLabels";
import ApiConfiguration from "../../../../infra/api/ApiConfiguration";
import {useForm} from "react-hook-form";
import {FiltroContext, FiltroPendencia} from "../consulta/filtroContext";
import ResumoSituacaoPendencia from "./ResumoSituacaoPendencia";
import AdicionarPendencia from "./AdicionarPendencia";
import {useSearchParams} from "react-router-dom";



export default function FiltroConsultaPendencia() {

    const tipos = Object.values(PendenciaDTO.TipoEnum);

    const status = Object.values(PendenciaDTO.StatusEnum).filter(p=> ![
        PendenciaDTO.StatusEnum.Confirmado,
        PendenciaDTO.StatusEnum.Concluido,
        PendenciaDTO.StatusEnum.Cancelado].includes(p));

    const {ptBR} = useLabels();

    const [searchParams] = useSearchParams();

    const {control, handleSubmit, getValues, reset} = useForm({defaultValues: ({
            status: searchParams.get('status'),
            tipo: searchParams.get('tipo')
        }) as any});

    const contexto = useContext(FiltroContext);

    const submitForm = handleSubmit((filtro) => {
        contexto.filtro.next(filtro)
    });

    const api = useRef(new ClienteApi(ApiConfiguration));

    const filtraClientes = useCallback(async (filtro: string) => {

        const pagina = await api.current.consulta(
            undefined,
            filtro,
            undefined,
            undefined,
            1,
            5
        );
        return pagina.itens || [];
    }, [api]);

    const setFiltro = useCallback((filtro: FiltroPendencia) => {
        reset(filtro);
        submitForm();
    }, [reset, submitForm]);

    return (
        <>
            <Card>
                <h2><Search/>Filtrar</h2>

                <ResumoSituacaoPendencia filtro={getValues()} setFiltro={setFiltro}/>

                <form onSubmit={submitForm}>

                    <Box sx={{display: 'flex', gap: '20px'}}>

                        <Box sx={{flex: '1'}}>

                            <TextFormField name="codigo" key="codigo" label="Código" control={control}
                                           onChange={submitForm}/>

                            <SelectFormField name="tipo" key="tipo" label="Tipo" control={control}
                                             onChange={submitForm}>

                                <MenuItem value={null} key="MenuItem_de_Tipo"/>

                                {
                                    tipos.map(tp =>
                                        <MenuItem key={"MenuItem_de_" + tp} value={tp}>
                                            {ptBR.enum.tipo_pendencia[tp]}
                                        </MenuItem>
                                    )
                                }
                            </SelectFormField>

                        </Box>

                        <Box sx={{flex: '1'}}>
                            <SelectFormField name="status" key="status" label="Status" control={control}
                                             onChange={submitForm}>
                                <MenuItem value={null} key="MenuItem_de_Status"/>
                                {
                                    status.map(sts =>
                                        <MenuItem key={"MenuItem_de_" + sts} value={sts}>
                                            {ptBR.enum.status_pendencia[sts]}
                                        </MenuItem>
                                    )
                                }
                            </SelectFormField>

                            <AutocompleteFormField
                                name="cliente" key="cliente" label="Cliente"
                                control={control}
                                onChange={submitForm} getOptionLabel={cliente => cliente.nome}
                                getOptions={filtraClientes} compareWith={(c1, c2) => c1.id === c2.id}
                            />

                        </Box>

                    </Box>

                </form>


            </Card>


            <AdicionarPendencia/>
        </>
    )

}



