import useConsultaNotificacoesCliente from "./useConsultaNotificacoesCliente";
import PaginatedTable from "../../../main-class/componentes/table/PaginatedTable";
import TableColumn from "../../../main-class/componentes/table/TableColumn";
import ColumnHeader from "../../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../../main-class/componentes/table/ColumnCell";
import {NotificacaoDTO} from "../../../dao";
import React from "react";
import useFormat from "../../../main-class/componentes/format/useFormat";

export default function ConsultaNotificacoesCliente() {
    const {consulta, pagina} = useConsultaNotificacoesCliente();
    const {formatDate} = useFormat();

    return (
        <>
            <PaginatedTable onPageChange={(pagina, tamanhoPagina) => consulta({}, {pagina, tamanhoPagina})}
                            pagina={pagina}>
                <TableColumn name="dataHora" style={{textAlign:"center", width: '200px'}}>
                    <ColumnHeader>Data/Hora</ColumnHeader>
                    <ColumnCell content={(item: NotificacaoDTO) => formatDate(item.dataHora, 'DD/MM/YYYY | HH:mm:ss')}/>
                </TableColumn>
                <TableColumn name="notificacao">
                    <ColumnHeader>Notificação</ColumnHeader>
                    <ColumnCell content={(item: NotificacaoDTO) => item.notificacao}/>
                </TableColumn>
            </PaginatedTable>
        </>
    );
}
