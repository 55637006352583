import React from "react";

export interface ColumnHeaderProps {
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

export default function ColumnHeader(props: ColumnHeaderProps) {
    return <></>;
}
