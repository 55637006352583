import {IconButton, MenuItem, Select} from "@mui/material";
import {ChevronLeft, ChevronRight, FirstPage, LastPage} from "@mui/icons-material";
import React from "react";
import ArrayTable from "./ArrayTable";


interface PaginatedTableProps<T> {
    pagina?: Pagina<T>;
    onPageChange?: (pagina: number, tamanhoPagina: number) => any;
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
}

export interface Pagina<M> {
    itens?: Array<M>;
    totalItens?: number;
    paginaAtual?: number;
    tamanhoPagina?: number;
    hasProxima?: boolean;
    hasAnterior?: boolean;
    totalPaginas?: number;
    empty?: boolean;
}

export default function PaginatedTable<T extends { id?: any }>(props: PaginatedTableProps<T>) {
    const {pagina, onPageChange, children, className, style} = props;

    const de = ((pagina?.paginaAtual || 1) - 1) * (pagina?.tamanhoPagina || 10) + 1;
    const ate = Math.min(pagina?.totalItens, (pagina?.paginaAtual || 1) * (pagina?.tamanhoPagina || 10));
    const hasProxima = pagina?.hasProxima || false;
    const hasAnterior = pagina?.hasAnterior || false;

    return <>
        <ArrayTable items={pagina?.itens} style={style} className={className}>
            {children}
        </ArrayTable>
        <div style={{display: 'flex', justifyContent: 'end', gap: '10px', alignItems: 'center'}}>
            <Select
                variant="standard"
                value={pagina?.tamanhoPagina || 10}
                onChange={event => onPageChange(pagina?.paginaAtual || 1, event.target.value as number)}>
                <MenuItem value={10}>10 itens</MenuItem>
                <MenuItem value={25}>25 itens</MenuItem>
                <MenuItem value={50}>50 itens</MenuItem>
            </Select>
            <IconButton type="button" disabled={!hasAnterior}
                        onClick={event => onPageChange(1, pagina?.tamanhoPagina || 10)}>
                <FirstPage/>
            </IconButton>
            <IconButton type="button" disabled={!hasAnterior}
                        onClick={event => onPageChange(pagina?.paginaAtual - 1, pagina?.tamanhoPagina || 10)}>
                <ChevronLeft/>
            </IconButton>
            {de} - {ate} de {pagina?.totalItens || 0}
            <IconButton type="button" disabled={!hasProxima}
                        onClick={event => onPageChange(pagina?.paginaAtual + 1, pagina?.tamanhoPagina || 10)}>
                <ChevronRight/>
            </IconButton>
            <IconButton type="button" disabled={!hasProxima}
                        onClick={event => onPageChange(pagina?.totalPaginas || 1, pagina?.tamanhoPagina || 10)}>
                <LastPage/>
            </IconButton>
        </div>
    </>;
}
