import { useNavigate } from "react-router-dom";
import useEdicao from "../../../main-class/form/crud/useEdicao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import { NoticiaApi, NoticiaDTO} from "../../../dao";

export default function useEdicaoNoticia() {
    const noticiaApi = new NoticiaApi(ApiConfiguration);
    const navigate = useNavigate();
    const {entity, submitForm, formControl,setEntity} = useEdicao<NoticiaDTO>({
        findAction: id => noticiaApi.detalha(id),
        saveAction: entity => noticiaApi.salva({
            id:entity.id!==undefined?entity.id:undefined,
            titulo: entity.titulo,
            capa: entity.capa,
            noticia: entity.noticia,
            data: new Date() as any,
            comentarios: 0,
            link: entity.link,
            autor: entity.autor
        }),
        postSave: entity => {
            setEntity({
                titulo: entity.titulo,
                capa: entity.capa,
                noticia: entity.noticia,
                data: new Date() as any,
                comentarios: 0,
                link:entity.link,
                autor: entity.autor
            })
            navigate('/noticia')
        },
        mensagemSucesso: 'Noticia salva com sucesso.'
    });

    const cancela = () => {
        navigate("/noticia");
        console.log(entity)
    }

    return{
        entity,
        submitForm,
        formControl,
        cancela,
    }
}