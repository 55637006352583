import {useTheme} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {LoaderAction, LoaderContext} from "./Loader";
import "./LoadingPanel.scss";
import useLabels from "../../../i18n/useLabels";

export default function LoadingPanel() {
    const [show, setShow] = useState(false);
    const theme = useTheme();
    const {ptBR} = useLabels();
    const loader = useContext(LoaderContext);

    useEffect(() => {
        const subscription = loader.actionObservable
            .subscribe(action => {
                setShow(action === LoaderAction.show);
            });

        return () => subscription.unsubscribe();
    });

    return (
        <>
            <div className="preloader" style={{opacity: show ? 0.75 : 0, display: show ? 'block' : 'none'}}>
                <div className="cssload-speeding-wheel" style={{
                    borderTopColor: theme.palette.primary.main,
                    borderBottomColor: theme.palette.primary.main
                }}></div>
                <p className="preloader-text">{ptBR.mcl.loader.aguarde}</p>
            </div>
        </>
    );
}
