import {Subject} from "rxjs";
import {createContext} from "react";

export class SincronizacaoState {
    private cliente = new Subject();
    private desembarque = new Subject();
    private lote = new Subject();
    private animal = new Subject();
    private saida = new Subject();

    notifyCliente() {
        this.cliente.next(null);
    }

    notifyDesembarque() {
        this.desembarque.next(null);
    }

    notifyLote() {
        this.lote.next(null);
    }

    notifySaida() {
        this.saida.next(null);
    }

    get clienteObservable() {
        return this.cliente.asObservable();
    }

    get desembarqueObservable() {
        return this.desembarque.asObservable();
    }

    get loteObservable() {
        return this.lote.asObservable();
    }

    get saidaObservable() {
        return this.saida.asObservable();
    }

    get animalObservable() {
        return this.animal.asObservable();
    }
}

const SincronizacaoContext = createContext<SincronizacaoState>(null);

export default SincronizacaoContext;
