import React, {useRef} from "react";
import {AnimalApi, MovimentacaoDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import {Card} from "@mui/material";
import PaginatedTable from "../../../main-class/componentes/table/PaginatedTable";
import TableColumn from "../../../main-class/componentes/table/TableColumn";
import ColumnHeader from "../../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../../main-class/componentes/table/ColumnCell";
import {useParams} from "react-router-dom";
import useFormat from "../../../main-class/componentes/format/useFormat";
import useConsulta from "../../../main-class/form/crud/useConsulta";
import {FiltroMovimentacao} from "../../movimentacao/useConsultaMovimentacao";
import useLabels from "../../../i18n/useLabels";

export default function ConsultaMovimentacoesAnimal() {
    const animalApi = useRef(new AnimalApi(ApiConfiguration));
    const {animal: idAnimal} = useParams();
    const {pagina, consulta} = useConsulta<FiltroMovimentacao, MovimentacaoDTO>({
        consultaAction: async filtro => await animalApi.current.consultaMovimentacoes(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            Number(idAnimal),
            undefined,
            undefined,
            1,
            10
        ),
    });
    const {formatDate} = useFormat();
    const {ptBR} = useLabels();

    return (
        <>
            <Card>
                <h2>Histórico de movimentações</h2>
                <PaginatedTable onPageChange={(pagina, tamanhoPagina) => consulta({}, {pagina, tamanhoPagina})}
                                pagina={pagina}>
                    <TableColumn name="data" style={{textAlign: 'center'}}>
                        <ColumnHeader>Data </ColumnHeader>
                        <ColumnCell
                            content={(item: MovimentacaoDTO) => formatDate(item.dataDeEntrada as any, 'DD/MM/YYYY')}/>
                    </TableColumn>
                    <TableColumn name="de" style={{textAlign: 'center'}}>
                        <ColumnHeader>De</ColumnHeader>
                        <ColumnCell
                            content={(item: MovimentacaoDTO) => <>
                                {item.de.nome}<br/>
                                <small>{ptBR.enum.tipo_local[item.de.tipo]}</small>
                            </>}/>
                    </TableColumn>
                    <TableColumn name="para" style={{textAlign: 'center'}}>
                        <ColumnHeader>Para</ColumnHeader>
                        <ColumnCell
                            content={(item: MovimentacaoDTO) => <>
                                {item.para.nome}<br/>
                                <small>{ptBR.enum.tipo_local[item.para.tipo]}</small>
                            </>}/>
                    </TableColumn>

                </PaginatedTable>
            </Card>
        </>
    );
}
