import {OcorrenciaLaudoEntradaDTO} from "../../../../dao";
import ArrayTable from "../../../../main-class/componentes/table/ArrayTable";
import TableColumn from "../../../../main-class/componentes/table/TableColumn";
import ColumnHeader from "../../../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../../../main-class/componentes/table/ColumnCell";
import React from "react";
import {Controller} from "react-hook-form";
import FormAddOcorrencias from "./FormAddOcorrencia";
import {Control} from "react-hook-form/dist/types";
import {IconButton} from "@mui/material";
import {Close, Search} from "@mui/icons-material";
import useLabels from "../../../../i18n/useLabels";
import useDialog from "../../../../main-class/template/dialog/useDialog";
import FormEdicaoOcorrencias from "./FormEditaOcorrencia";

interface InputOcorrenciasProps {
    name: string;
    control: Control;
    value: Array<OcorrenciaLaudoEntradaDTO>;
    disabled?: any;
}

export default function InputOcorrencias(props: InputOcorrenciasProps) {
    const {name, control, value: defaultValue, disabled} = props;
    const {ptBR} = useLabels();
    const {open} = useDialog();
    const enumOcorrencias = Object.values(OcorrenciaLaudoEntradaDTO.OcorrenciaEnum);

    function onAddOCorrencia(value: Array<OcorrenciaLaudoEntradaDTO> | undefined, ocorrencia: OcorrenciaLaudoEntradaDTO, onChange: any) {
        onChange([
            ...(value || []), ocorrencia
        ])
    }

    function onRemoveOcorrencia(value: Array<OcorrenciaLaudoEntradaDTO> | undefined, ocorrencia: OcorrenciaLaudoEntradaDTO, onChange: any) {
        onChange([
            ...value.filter(oco => oco !== ocorrencia)
        ]);
    }

    function onEditaOcorrencia(value: Array<OcorrenciaLaudoEntradaDTO> | undefined, ocorrencia: OcorrenciaLaudoEntradaDTO, onChange: any) {
        open<OcorrenciaLaudoEntradaDTO>(
            <FormEdicaoOcorrencias
                value={{...ocorrencia}}
                disabled={disabled}
            />,
            {fullWidth: true, maxWidth: 'md'}
        ).afterClosed().subscribe(atualizado => {
            if (!!atualizado) {
                onChange([
                    ...(value.map(o => o === ocorrencia ? atualizado : o))
                ])
            }
        });
    }


    return (
        <>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({field: {onChange, value}, fieldState: {error}}) => (
                    <>
                        {!disabled && (value?.length || 0) < enumOcorrencias.length &&
                            <FormAddOcorrencias
                                opcoes={enumOcorrencias.filter(o => !value?.find((item: OcorrenciaLaudoEntradaDTO) => item.ocorrencia === o))}
                                onOcorrenciaAdd={ocorrencia => onAddOCorrencia(value, ocorrencia, onChange)}/>
                        }

                        <ArrayTable items={value}>
                            <TableColumn name="ocorrencia">
                                <ColumnHeader>Ocorrencia</ColumnHeader>
                                <ColumnCell
                                    content={(item: OcorrenciaLaudoEntradaDTO) => ptBR.enum.tipo_ocorrencia[item.ocorrencia]}/>
                            </TableColumn>
                            <TableColumn name="quantidade" style={{width: '120px', textAlign: 'right'}}>
                                <ColumnHeader>Quantidade</ColumnHeader>
                                <ColumnCell content={(item: OcorrenciaLaudoEntradaDTO) => item.quantidade}/>
                            </TableColumn>

                            <TableColumn name="edicao" style={{width: '60px', textAlign: 'center'}} >
                                <ColumnHeader>Mídias</ColumnHeader>
                                <ColumnCell content={(item: OcorrenciaLaudoEntradaDTO) => <>
                                    <IconButton onClick={() => onEditaOcorrencia(value, item, onChange)}>
                                        <Search/>
                                    </IconButton>
                                </>}/>
                            </TableColumn>

                            {!disabled &&
                                <TableColumn name="exclusao" style={{width: '60px'}}>
                                    <ColumnHeader></ColumnHeader>
                                    <ColumnCell style={{textAlign: 'right'}} content={(item: OcorrenciaLaudoEntradaDTO) => <>
                                        <IconButton onClick={() => onRemoveOcorrencia(value, item, onChange)}>
                                            <Close/>
                                        </IconButton>
                                    </>}/>
                                </TableColumn>
                            }
                        </ArrayTable>

                    </>
                )}/>
        </>
    );
}
