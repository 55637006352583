import usePermissao from "../seguranca/usePermissao";
import {UsuarioAdministradorDTO} from "../../dao";

export default function useMenu() {
    const {is, any} = usePermissao();

    const menu = [{
        key: 'dashboard',
        label: "Dashboard",
        route: "/",
        icon: 'dashboard'
    }];

    if (any(UsuarioAdministradorDTO.PerfilEnum.Gerencial, UsuarioAdministradorDTO.PerfilEnum.Administrativo)) {
        menu.push({
            key: 'noticia',
            label: "notícias",
            route: "/noticia",
            icon: "newspaper"
        })
    }

    if (any(UsuarioAdministradorDTO.PerfilEnum.Gerencial, UsuarioAdministradorDTO.PerfilEnum.Administrativo)) {
        menu.push(
            {
                key: 'cliente',
                label: "Cliente",
                route: "/cliente",
                icon: 'person'
            },
        );
    }

    if (any(UsuarioAdministradorDTO.PerfilEnum.Gerencial, UsuarioAdministradorDTO.PerfilEnum.Administrativo, UsuarioAdministradorDTO.PerfilEnum.Veterinario)) {
        menu.push(
            {
                key: 'desembaque',
                label: "Desembarque",
                route: "/desembarque",
                icon: 'local_shipping'
            },
            {
                key: 'lote',
                label: "Lote",
                route: "/lote",
                icon: 'workspaces'
            },
        );
    }

    if (any(UsuarioAdministradorDTO.PerfilEnum.Veterinario)) {
        menu.push(
            {
                key: 'laudo_entrada',
                label: "Laudo de Entrada",
                route: "/laudo-entrada",
                icon: 'edit_note'
            },
        )
    }

    if (any(UsuarioAdministradorDTO.PerfilEnum.Gerencial, UsuarioAdministradorDTO.PerfilEnum.Administrativo, UsuarioAdministradorDTO.PerfilEnum.Veterinario)) {
        menu.push(
            {
                key: 'processamento_entrada',
                label: "P. Entrada",
                route: "/etapa/processamento_entrada",
                icon: 'system_update_alt'
            },
            {
                key: 'ronda_sanitaria',
                label: "Ronda sanitária",
                route: "/ronda-sanitaria",
                icon: 'health_and_safety'
            },
            {
                key: 'movimentacao',
                label: "Movimentações",
                route: "/movimentacao",
                icon: 'change_circle'
            },
        );
    }

    if (any(UsuarioAdministradorDTO.PerfilEnum.Gerencial, UsuarioAdministradorDTO.PerfilEnum.Administrativo, UsuarioAdministradorDTO.PerfilEnum.Comercial)) {
        menu.push(
            {
                key: 'boigordo',
                label: "BoiGordo",
                route: "/boigordo",
                icon: 'pets'
            },
        );
    }

    if (any(UsuarioAdministradorDTO.PerfilEnum.Gerencial, UsuarioAdministradorDTO.PerfilEnum.Administrativo, UsuarioAdministradorDTO.PerfilEnum.Comercial)) {
        menu.push(
            {
                key: 'processamento_saida',
                label: "P. Saída",
                route: "/processamento-saida",
                icon: 'open_in_browser'
            },
        );
    }

    if (any(UsuarioAdministradorDTO.PerfilEnum.Veterinario)) {
        menu.push(
            {
                key: 'laudo_morte',
                label: "Laudo de Óbito",
                route: "/laudo-morte",
                icon: 'cancel'
            }
        )
    }

    if (is(UsuarioAdministradorDTO.PerfilEnum.Gerencial)) {
        menu.push({
            key: 'usuario',
            label: "Usuários",
            route: "/usuario",
            icon: 'manage_accounts'
        });
    }


    return {menu};
}
