import {Button} from "@mui/material";
import React, {useCallback, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {lastValueFrom} from "rxjs";
import {EnvioNegociacaoDTO, PendenciaApi, PendenciaDTO} from "../../../../dao";
import useDialog from "../../../../main-class/template/dialog/useDialog";
import TextAreaFormField from "../../../../main-class/form/input/TextAreaFormField";
import {useForm} from "react-hook-form";
import ApiConfiguration from "../../../../infra/api/ApiConfiguration";
import useLoader from "../../../../main-class/componentes/loader/useLoader";

export default function AguardandoNegociacao(props: { pendencia: PendenciaDTO, onUpdate: any }) {
    const pendenciaApi = useRef(new PendenciaApi(ApiConfiguration));
    const {pendencia, onUpdate} = props;
    const navigate = useNavigate();
    const {confirmacao} = useDialog();
    const [aprovando, setAprovando] = useState<boolean>();
    const {loadingAround} = useLoader();

    const volta = useCallback(() => {
        navigate("/boigordo");
    }, [navigate]);

    const criaNegociacao = useCallback(async () => {
        setAprovando(true);
    }, [setAprovando]);

    const recusaNegociacao = useCallback(async () => {
        const confirmado = await lastValueFrom(confirmacao(<>
            O cliente será notificado no seu dispositivo móvel sobre a <strong>RECUSA</strong> de uma negociação.<br/><br/>
            Confirma essa ação?
        </>).afterClosed())
        if (confirmado) {
            await loadingAround(pendenciaApi.current.rejeitaNegociacao(pendencia.id));
            onUpdate();
        }
    }, [confirmacao, pendencia, onUpdate, loadingAround, pendenciaApi]);

    const {control, handleSubmit, getValues} = useForm<any>({ // Substituir pelo DTO de requisiçào
        defaultValues: {
            termo: "Olá, bom dia " + pendencia?.cliente?.nome + "\n" +
                "\n" +
                "Para formalização:\n" +
                "- Seus animais foram escalados para o frigorífico: PREENCHER CAMPO\n" +
                "- Quantidade: PREENCHER CAMPO\n" +
                "- Sexo: PREENCHER CAMPO\n" +
                "- Data: PREENCHER CAMPO\n" +
                "- Forma de pagamento: PREENCHER CAMPO\n" +
                "- Negociação: PREENCHER CAMPO\n" +
                "\n" +
                "Podemos confirmar?"
        },
    });

    const salva = useCallback(async (entity: EnvioNegociacaoDTO) => {
        const confirmado = await lastValueFrom(confirmacao(<>
            O cliente será notificado no seu dispositivo móvel.<br/><br/>
            Confirma essa ação?
        </>).afterClosed())
        if (confirmado) {
            await loadingAround(pendenciaApi.current.enviaNegociacao(pendencia.id, entity));
            onUpdate();
        }
    }, [confirmacao, onUpdate, loadingAround, pendenciaApi, pendencia.id]);

    const submitForm = handleSubmit((entity, event) => salva(entity));

    return (
        <>
            {
                aprovando &&
                <form onSubmit={submitForm}>
                    <h2>Termo de negociação de boi gordo</h2>

                    <TextAreaFormField
                        name="termo" key="termo"
                        value={getValues().termo} label="Termo" control={control}
                    />

                    <div className="acoes">
                        <Button type="submit" variant="contained"
                                color="primary">Enviar</Button>
                        <Button type="button" color="inherit"
                                onClick={() => volta()}>Voltar</Button>
                    </div>
                </form>
            }

            {
                !aprovando &&
                <div className="acoes">
                    <Button onClick={() => criaNegociacao()} variant="contained"
                            color="secondary">Criar negociação</Button>
                    <Button onClick={() => recusaNegociacao()} variant="contained"
                            color="error">Recusar negociação</Button>
                    <Button color="inherit" onClick={() => volta()}>Voltar</Button>
                </div>
            }
        </>
    );
}
