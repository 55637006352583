import {Box, Checkbox} from "@mui/material";
import {Control} from "react-hook-form/dist/types/form";
import {Controller, useWatch} from "react-hook-form";
import {InnerQuantidadeRacaDTO} from "../useCadastroLaudoEntrada";
import useValidation from "../../../../main-class/form/validation/useValidation";
import NumberFormField from "../../../../main-class/form/input/NumberFormField";

interface ItemRacaProps {
    quantidadeRaca: InnerQuantidadeRacaDTO;
    control: Control,
    baseName: string,
    disabled?: any
}

export default function ItemRaca(props: ItemRacaProps) {

    const {quantidadeRaca, control, baseName, disabled} = props;

    return (
        <Box sx={{display: 'flex', gap: '10px'}}>
            <Box sx={{width: '180px', whiteSpace: 'nowrap'}}>
                <Controller
                    name={baseName + ".habilitado"}
                    control={control}
                    defaultValue={quantidadeRaca.habilitado}
                    render={({field: {onChange, value}, fieldState: {error}}) => (
                        <Checkbox checked={value} onChange={onChange} disabled={disabled}/>
                    )}/>
                {quantidadeRaca.raca.nome}
            </Box>

            <Box sx={{flex: '1 1 auto'}}>
                <InputQuantidadeRaca control={control} quantidadeInicial={quantidadeRaca.quantidade}
                                     baseName={baseName} disabled={disabled}/>
            </Box>
        </Box>
    );
}

function InputQuantidadeRaca(props: { control: Control, baseName: string, quantidadeInicial?: number, disabled?: any }) {
    const {control, baseName, quantidadeInicial, disabled} = props;
    const habilitado = useWatch({
        control: control,
        name: baseName + '.habilitado',
    });
    const {validate, required, minMax} = useValidation();

    return (
        <>
            {
                habilitado &&
                <NumberFormField control={control} name={baseName + ".quantidade"}
                                 disabled={disabled}
                                 value={quantidadeInicial}
                                 validate={validate(
                                     required(),
                                     minMax({min: 1})
                                 )}
                />
            }
        </>
    )
}
