import useLabels from "../../i18n/useLabels";

export default function useLoginValidation() {
    const {ptBR: {validation}} = useLabels();

    function novaSenha() {
        return (senha: string) => {
            if (senha) {
                if (senha.length < 8) {
                    return validation.novaSenhaMinLength;
                }

                if (!senha.match(/[A-Z]/)) {
                    return validation.novaSenhaUpperChar
                }

                if (!senha.match(/[a-z]/)) {
                    return validation.novaSenhaLowerChar;
                }

                if (!senha.match(/[0-9]/)) {
                    return validation.novaSenhaNumeric;
                }
            }

            return null;

        }
    }

    function confirmacaoSenha(senhaProvider: () => string) {
        return (confirmacao: string) => {
            const senha = senhaProvider();
            if (!senha || !confirmacao) return null;


            if (senha !== confirmacao) {
                return validation.confirmacaoSenha;
            }
        }
    }

    return {novaSenha, confirmacaoSenha};
}
