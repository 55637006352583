import {UsuarioAdministradorDTO} from "../../../../dao";
import {Box, MenuItem} from "@mui/material";
import TextFormField from "../../../../main-class/form/input/TextFormField";
import SelectFormField from "../../../../main-class/form/input/SelectFormField";
import {useWatch} from "react-hook-form";
import useValidation from "../../../../main-class/form/validation/useValidation";
import useLabels from "../../../../i18n/useLabels";

export interface DadosVeterinarioProps {
    entity: UsuarioAdministradorDTO;
    control: any
}

export default function DadosVeterinario(props: DadosVeterinarioProps) {
    const {control, entity} = props;
    const perfil = useWatch({control, name: 'perfil'});
    const {validate, required, length} = useValidation();
    const {ptBR} = useLabels();

    const ufs = Object.values(UsuarioAdministradorDTO.EstadoIdentificacaoEnum);

    return (
        <>
            {
                perfil === UsuarioAdministradorDTO.PerfilEnum.Veterinario &&
                <Box sx={{display: 'flex', gap: '20px'}}>
                    <TextFormField name="identificacao" control={control} label="CRMV"
                                   value={entity.identificacao} validate={
                        validate(
                            required(),
                            length({max: 20}),
                        )
                    }/>
                    <SelectFormField name="estadoIdentificacao" control={control} label="Estado CRMV"
                                     value={entity.estadoIdentificacao} validate={
                        validate(
                            required()
                        )

                    }>
                        <MenuItem value={null}/>
                        {
                            ufs.map(uf =>
                                <MenuItem key={uf} value={uf}>{ptBR.enum.uf[uf]}</MenuItem>
                            )
                        }
                    </SelectFormField>
                </Box>
            }
        </>
    )
}
