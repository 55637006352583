import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Sessao from "./main-class/componentes/sessao/Sessao";
import {ThemeProvider} from "@mui/material";
import theme from "./theme";
import MensageriaProvider from "./main-class/componentes/mensageria/Mensageria";
import LoaderProvider from "./main-class/componentes/loader/Loader";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <MensageriaProvider>
                <Sessao>
                    <LoaderProvider>
                        <App/>
                    </LoaderProvider>
                </Sessao>
            </MensageriaProvider>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
