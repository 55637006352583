import useLabels from "../../../i18n/useLabels";
import {Box, Card, IconButton, MenuItem} from "@mui/material";
import SelectFormField from "../../../main-class/form/input/SelectFormField";
import TextFormField from "../../../main-class/form/input/TextFormField";
import PaginatedTable from "../../../main-class/componentes/table/PaginatedTable";
import TableColumn from "../../../main-class/componentes/table/TableColumn";
import ColumnHeader from "../../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../../main-class/componentes/table/ColumnCell";
import {LaudoMorteDTO} from "../../../dao";
import {Add, Edit, Search} from "@mui/icons-material";
import useConsultaLaudoMorte from "./useConsultaLaudoMorte";
import React from "react";
import useFormat from "../../../main-class/componentes/format/useFormat";
import DateFormField from "../../../main-class/form/input/DateFormField";
import AutocompleteFormField from "../../../main-class/form/input/AutocompleteFormField";

export default function ConsultaLaudoMorte() {
    const {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        status,
        tipos,
        locais,
        navigateLaudo,
        filtraVeterinarios,
        inLoteContext
    } = useConsultaLaudoMorte();
    const {ptBR} = useLabels();
    const {formatDate} = useFormat();

    return (
        <>

            <Card>
                <h2><Search/> Filtrar</h2>

                <form onSubmit={submitForm}>

                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{flex: '1'}}>
                            <TextFormField label="Código do laudo" value={filtro.codigo} control={formControl}
                                           name="codigo"
                                           onChange={submitForm}
                            />
                            <TextFormField label="BOSCH" value={filtro.bosch} control={formControl} name="bosch"
                                           onChange={submitForm}
                            />
                            <TextFormField label="SISBOV" value={filtro.sisbov} control={formControl} name="sisbov"
                                           onChange={submitForm}
                            />

                        </Box>
                        <Box sx={{flex: '1'}}>
                            <DateFormField label="Data da finalizacao" value={filtro.data} control={formControl}
                                           name="data"
                                           onChange={submitForm}
                            />
                            <SelectFormField name="status" label="Status"
                                             value={filtro.status} control={formControl}
                                             onChange={submitForm}>
                                <MenuItem value={null}/>
                                {
                                    status.map(sts =>
                                        <MenuItem key={"MenuItem_Status_" + sts} value={sts}>
                                            {ptBR.enum.status_laudo_morte[sts]}
                                        </MenuItem>
                                    )
                                }
                            </SelectFormField>
                            <SelectFormField name="local" label="Local do óbito"
                                             value={filtro.local} control={formControl}
                                             onChange={submitForm}>
                                <MenuItem value={null}/>
                                {
                                    locais.map(sts =>
                                        <MenuItem key={"MenuItem_Local_" + sts} value={sts.id}>
                                            {sts.nome}
                                        </MenuItem>
                                    )
                                }
                            </SelectFormField>

                        </Box>

                        <Box sx={{flex: '1'}}>
                            <AutocompleteFormField
                                name="veterinario" label="Veterinário"
                                value={filtro.veterinario} control={formControl}
                                onChange={submitForm} getOptionLabel={veterinario => veterinario.nome}
                                getOptions={filtraVeterinarios} compareWith={(v1, v2) => v1.id === v2.id}
                            />
                            <SelectFormField name="tipo" label="Tipo do óbito"
                                             value={filtro.local} control={formControl}
                                             onChange={submitForm}>
                                <MenuItem value={null}/>
                                {
                                    tipos.map(sts =>
                                        <MenuItem key={"MenuItem_Tipo_" + sts} value={sts.id}>
                                            {sts.nome}
                                        </MenuItem>
                                    )
                                }
                            </SelectFormField>
                            {
                                !inLoteContext &&
                                <TextFormField label="Lote" value={filtro.lote} control={formControl} name="lote"
                                               onChange={submitForm}
                                />
                            }
                        </Box>

                    </Box>

                </form>
            </Card>


            <Card>
                <PaginatedTable onPageChange={(pagina, tamanhoPagina) => consulta(filtro, {pagina, tamanhoPagina})}
                                pagina={pagina}>
                    <TableColumn name="codigo">
                        <ColumnHeader>Código</ColumnHeader>
                        <ColumnCell content={(item: LaudoMorteDTO) => item.codigo}/>
                    </TableColumn>

                    <TableColumn name="dataSap">
                        <ColumnHeader>Data (SAP)</ColumnHeader>
                        <ColumnCell content={(item: LaudoMorteDTO) => formatDate(item.dataSap as any, 'DD/MM/YYYY')}/>
                    </TableColumn>

                    <TableColumn name="dataFinalizacao">
                        <ColumnHeader>Data/Hora (Finalizado)</ColumnHeader>
                        <ColumnCell
                            content={(item: LaudoMorteDTO) => formatDate(item.dataFinalizacao, 'DD/MM/YYYY HH:mm')}/>
                    </TableColumn>

                    {
                        !inLoteContext &&
                        <TableColumn name="lote">
                            <ColumnHeader>Lote</ColumnHeader>
                            <ColumnCell content={(item: LaudoMorteDTO) => item.animal.lote.codigo}/>
                        </TableColumn>
                    }

                    <TableColumn name="bosch">
                        <ColumnHeader>Bosch</ColumnHeader>
                        <ColumnCell content={(item: LaudoMorteDTO) => item.animal?.bosch}/>
                    </TableColumn>

                    <TableColumn name="sisbov">
                        <ColumnHeader>Sisbov</ColumnHeader>
                        <ColumnCell content={(item: LaudoMorteDTO) => item.animal?.sisbov}/>
                    </TableColumn>

                    <TableColumn name="local">
                        <ColumnHeader>Local</ColumnHeader>
                        <ColumnCell content={(item: LaudoMorteDTO) => item.diagnostico?.local?.nome}/>
                    </TableColumn>

                    <TableColumn name="tipo">
                        <ColumnHeader>Tipo óbito</ColumnHeader>
                        <ColumnCell content={(item: LaudoMorteDTO) => item.diagnostico?.tipo.nome}/>
                    </TableColumn>

                    <TableColumn name="nome">
                        <ColumnHeader>Veterinário</ColumnHeader>
                        <ColumnCell content={(item: LaudoMorteDTO) => item.veterinarioResponsavel?.nome}/>
                    </TableColumn>

                    <TableColumn name="status">
                        <ColumnHeader>Status</ColumnHeader>
                        <ColumnCell content={(item: LaudoMorteDTO) => ptBR.enum.status_laudo_morte[item.status]}/>
                    </TableColumn>

                    <TableColumn name="opcoes" style={{textAlign: 'right'}}>
                        <ColumnHeader/>
                        <ColumnCell content={
                            (item: LaudoMorteDTO) => <>
                                <IconButton onClick={() => navigateLaudo(item)} type="button">
                                    {item.status === LaudoMorteDTO.StatusEnum.Finalizado && <Search/>}
                                    {item.status === LaudoMorteDTO.StatusEnum.Iniciado && <Edit/>}
                                    {item.status === LaudoMorteDTO.StatusEnum.Pendente && <Add/>}
                                </IconButton>
                            </>
                        }/>
                    </TableColumn>
                </PaginatedTable>
            </Card>
        </>


    );
}

