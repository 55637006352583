import useFormat from "../../../main-class/componentes/format/useFormat";
import {Box, Card, IconButton} from "@mui/material";
import {Search} from "@mui/icons-material";
import TextFormField from "../../../main-class/form/input/TextFormField";
import DateFormField from "../../../main-class/form/input/DateFormField";
import PaginatedTable from "../../../main-class/componentes/table/PaginatedTable";
import TableColumn from "../../../main-class/componentes/table/TableColumn";
import ColumnHeader from "../../../main-class/componentes/table/ColumnHeader";
import ColumnCell from "../../../main-class/componentes/table/ColumnCell";
import {ProcessamentoSaidaDTO} from "../../../dao";
import React from "react";
import useConsultaLoteProcessamentoSaida from "./useConsultaLoteProcessamentoSaida";
import SumarioLote from "../../lote/sumario/SumarioLote";

export default function ConsultaLoteProcessamentoSaida() {

    const {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        onDetalhe
    } = useConsultaLoteProcessamentoSaida();

    const {formatDate, formatNumeric} = useFormat();

    return (
        <>

            <SumarioLote/>

            <Card>
                <h2><Search/> Filtrar</h2>

                <form onSubmit={submitForm}>

                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{flex: '1'}}>
                            <TextFormField label="Código"
                                           value={filtro.codigo}
                                           control={formControl}
                                           name="codigo"
                                           onChange={submitForm}
                            />
                        </Box>

                        <Box sx={{flex: '1'}}>
                            <DateFormField label="Data"
                                           value={filtro.data}
                                           control={formControl}
                                           name="data"
                                           onChange={submitForm}
                            />
                        </Box>

                    </Box>


                </form>
            </Card>

            <Card>

                <Box sx={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                    <Box sx={{display: 'flex', flex: 2}}>
                        <h2 style={{margin: '0 0 10px'}}>Animais embarcados: </h2>
                        <p style={{margin: '0 0 10px 10px'}}>{pagina?.qtdAnimaisEmbarcados || 0}</p>
                    </Box>
                    <Box sx={{display: 'flex', flex: 2}}>
                        <h2 style={{margin: '0 0 10px'}}>Animais restantes: </h2>
                        <p style={{margin: '0 0 10px 10px'}}>{pagina?.qtdAnimaisRestantes || 0}</p>
                    </Box>
                </Box>

            </Card>


            <Card>
                <PaginatedTable onPageChange={(pagina, tamanhoPagina) => consulta(filtro, {pagina, tamanhoPagina})}
                                pagina={pagina}>


                    <TableColumn name="data" style={{textAlign: 'center'}}>
                        <ColumnHeader>Data</ColumnHeader>
                        <ColumnCell
                            content={(item: ProcessamentoSaidaDTO) => formatDate(item.data, 'DD/MM/YYYY')}/>
                    </TableColumn>


                    <TableColumn name="codigo">
                        <ColumnHeader>Contrato</ColumnHeader>
                        <ColumnCell content={(item: ProcessamentoSaidaDTO) => item.codigo}/>
                    </TableColumn>

                    <TableColumn name="qtdAnimais">
                        <ColumnHeader>Animais Retirados</ColumnHeader>
                        <ColumnCell content={(item: ProcessamentoSaidaDTO) => item.pesagem.quantidade}/>
                    </TableColumn>

                    <TableColumn name="pesoMedio" style={{textAlign: 'center'}}>
                        <ColumnHeader>Peso Médio</ColumnHeader>
                        <ColumnCell
                            content={(item: ProcessamentoSaidaDTO) => formatNumeric(item.pesagem.pesoMedio) + 'Kg'}/>
                    </TableColumn>

                    <TableColumn name="opcoes" style={{textAlign: 'right'}}>
                        <ColumnHeader/>
                        <ColumnCell content={
                            (item: ProcessamentoSaidaDTO) => <>
                                <IconButton onClick={() => onDetalhe(item)} type="button"><Search/></IconButton>
                            </>
                        }/>
                    </TableColumn>
                </PaginatedTable>
            </Card>
        </>


    );
}
