import {Button, DialogContent, DialogTitle, IconButton} from "@mui/material";
import React from "react";
import useDialogRef from "../../main-class/template/dialog/useDialogRef";
import {Close, HelpOutline, NavigateNext} from "@mui/icons-material";
import ModalVideoAjuda from "../modalVideoAjuda/ModalVideoAjuda";
import useDialog from "../../main-class/template/dialog/useDialog";

interface IItemAjuda {
    label: string;
    urlVideo: string;
}

interface IEtapaAjuda {
    title: string;
    itens: Array<IItemAjuda>;
}

const opcoesAjuda: Array<IEtapaAjuda> = [
    {
        title: "Desembarque",
        itens: [
            {
                label: 'O que devo fazer quando uma carreta chegar na fazenda?',
                urlVideo: './ajuda/inicio-desembarque.mp4'
            },
            {
                label: 'O que devo fazer quando uma carreta sair da fazenda?',
                urlVideo: './ajuda/encerrar-desembarque.mp4'
            },
            {
                label: 'O que devo fazer para que o parceiro veja os animais descendo da carreta?',
                urlVideo: './ajuda/disponibilizando-midias-do-desembarque.mp4'
            },
            {
                label: 'Como o veterinário faz o laudo de entrada?',
                urlVideo: './ajuda/laudo-entrada.mp4'
            },
        ]
    },
    {
        title: "Processamento de Entrada",
        itens: [
            {
                label: 'Como funciona o processamento de entrada?',
                urlVideo: './ajuda/processamento-entrada.mp4'
            },
        ]
    },
    {
        title: "Manejo",
        itens: [
            {
                label: 'Como faço pra notificar moviemntações e suas mídias?',
                urlVideo: './ajuda/movimentacoes.mp4'
            },
            {
                label: 'Como faço para notificar ronda sanitária?',
                urlVideo: './ajuda/ronda-sanitaria.mp4'
            },
        ]
    },
    {
        title: "Laudo de óbito",
        itens: [
            {
                label: 'Como faço um laudo de óbito?',
                urlVideo: './ajuda/laudo-obito.mp4'
            },
        ]
    },
];

export default function ModelAjuda() {
    const {dialogRef} = useDialogRef();

    const fecha = () => {
        dialogRef.close(false);
    };

    return (
        <>
            <DialogTitle style={{display: 'flex'}}>
                <HelpOutline sx={{marginRight: '10px'}} /> Ajuda
                <div style={{flex: '1 1 auto'}}></div>
                <IconButton onClick={fecha}>
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {
                    opcoesAjuda.map(etapa => (
                        <EtapaAjuda etapa={etapa} key={etapa.title}/>
                    ))
                }
            </DialogContent>
        </>
    )
}

function EtapaAjuda(props: { etapa: IEtapaAjuda }) {
    const {etapa} = props;
    const {open} = useDialog();


    function openVideo(item: IItemAjuda) {
        open(<ModalVideoAjuda urlVideo={item.urlVideo} label={item.label}/>, {
            maxWidth: 'xl'
        });
    }

    return (
        <>
            <h4 style={{textTransform: 'uppercase'}}>{etapa.title}</h4>

            <ul>
                {
                    etapa.itens.map(item => (
                        <li key={'li_' + item.label}>

                            <Button type="button" key={'button_' + item.label}
                                        onClick={() => openVideo(item)}>
                                {item.label} <NavigateNext key={'icon_' + item.label}/>
                            </Button>
                        </li>
                    ))
                }
            </ul>
        </>
    )
}
