import {ClienteApi, ClienteDTO} from "../../../dao";
import ApiConfiguration from "../../../infra/api/ApiConfiguration";
import useConsulta from "../../../main-class/form/crud/useConsulta";
import {useCallback, useContext, useEffect, useRef} from "react";
import useDialog from "../../../main-class/template/dialog/useDialog";
import useLoader from "../../../main-class/componentes/loader/useLoader";
import {lastValueFrom} from "rxjs";
import {useNavigate} from "react-router-dom";
import SincronizacaoContext from "../../../infra/sincronizacao/SincronizacaoContext";

export interface FiltroCliente {
    codigo?: string;
    nome?: string;
    cpfCnpj?: string;
    status?: ClienteDTO.StatusEnum;
}

export default function useConsultaCliente() {
    const clienteApi = useRef(new ClienteApi(ApiConfiguration));
    const {confirmacao} = useDialog();
    const {loadingAround} = useLoader();
    const navigate = useNavigate();
    const sincornizacaoState = useContext(SincronizacaoContext);

    const status = Object.values(ClienteDTO.StatusEnum);

    const {
        filtro,
        pagina,
        consulta,
        formControl,
        submitForm
    } = useConsulta<FiltroCliente, ClienteDTO>({
        consultaAction: (filtro, pagina, tamanhoPagina) => clienteApi.current.consulta(
            filtro.codigo,
            filtro.nome,
            filtro.cpfCnpj,
            filtro.status,
            pagina,
            tamanhoPagina
        ),
    });

    const onDetalhe = useCallback((cliente: ClienteDTO) => {
        navigate(`/cliente/${cliente.id}`);
    }, [navigate])

    const toggleAcesso = useCallback(async (cliente: ClienteDTO) => {
        const confirmado = await lastValueFrom(
            confirmacao(
                cliente.status === ClienteDTO.StatusEnum.Ativo ?
                    `O fazendeiro ${cliente.nome} terá seu acesso desativado no aplicativo. Deseja continuar?` :
                    `O fazendeiro ${cliente.nome} terá seu acesso reativado no aplicativo. Deseja continuar?`
            ).afterClosed()
        );

        if (confirmado) {
            await loadingAround(clienteApi.current.toggleAcesso(cliente.id));

            consulta();
        }
    }, [clienteApi, consulta, loadingAround, confirmacao]);

    const resetarSenha = useCallback(async (cliente: ClienteDTO) => {
        const confirmado = await lastValueFrom(
            confirmacao(
                `O fazendeiro ${cliente.nome} terá sua senha resetada no aplicativo. Deseja continuar?`
            ).afterClosed()
        );

        if (confirmado) {
            await loadingAround(clienteApi.current.resetaSenha(cliente.id));

            consulta();
        }
    }, [clienteApi, consulta, loadingAround, confirmacao]);

    useEffect(() => {
        const subscription = sincornizacaoState.clienteObservable
            .subscribe(() => consulta())

        return () => subscription.unsubscribe();
    }, [sincornizacaoState, consulta]);

    return {
        pagina,
        consulta,
        filtro,
        formControl,
        submitForm,
        status,
        toggleAcesso,
        resetarSenha,
        onDetalhe
    };


}
