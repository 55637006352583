import ColumnHeader from "./ColumnHeader";
import ColumnCell from "./ColumnCell";
import React from "react";
import ColumnFooter from "./ColumnFooter";

export interface TableColumnProps {
    name: string;
    children?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
}

export function parseTableColumn(props: TableColumnProps) {
    const content = props.children instanceof Array ? props.children : [props.children];

    const header = content.find(c => c.type === ColumnHeader);
    const cell = content.find(c => c.type === ColumnCell);
    const footer = content.find(c => c.type === ColumnFooter);

    return {
        name: props.name,
        header: header?.props,
        cell: cell?.props,
        footer: footer?.props,
        className: props.className,
        style: props.style,
    };
}

export default function TableColumn(props: TableColumnProps) {
    return (
        <>
        </>
    );
}
